import styled, { css } from "styled-components";
import { colors, fonts, transition } from "../../../styles/variables";
import { ReactComponent as EmptyBottle } from "../../../assets/images/icons/outlined-bottle.svg";
import { ReactComponent as GiftIcon } from "../../../assets/images/icons/gift-transparent.svg";
import breakpoints from "../../../styles/breakpoints";

export const BoxCartToggleIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const IconWrapper = styled.div<{ reduceRightMargin?: boolean }>`
  margin-bottom: -2px;
  width: 25px;
  display: flex;
  justify-content: center;
  transition: ${transition.headerMenuBarTransition};
  margin-right: ${(props) => (props.reduceRightMargin ? "0" : "4")}px;
  ${breakpoints.smDown} {
    margin-right: ${(props) => (props.reduceRightMargin ? "-4" : "2")}px;
  }
  ${breakpoints.xsDown} {
    width: 23px;
  }
  ${breakpoints.xxsDown} {
    width: 20px;
  }
`;

export const BottleIcon = styled(EmptyBottle)<{ isSelected: boolean; variant: "side" | "header" }>`
  width: 11px;
  height: 25px;
  stroke-width: 1.1px;
  stroke: ${colors.black};

  ${breakpoints.smDown} {
    width: 9px;
    height: 20px;
  }

  ${(props) =>
    props.variant === "side" &&
    css`
      stroke: ${colors.black};
    `}
  ${(props) =>
    props.variant === "header" &&
    css`
      stroke: ${props.isSelected ? colors.white : colors.black};
      fill: ${colors.white};
    `}
`;

export const PresentIcon = styled(GiftIcon)<{ isSelected: boolean; variant: "side" | "header" }>`
  width: 25px;
  height: 25px;
  stroke-width: 1.8px;

  ${breakpoints.smDown} {
    width: 20px;
    height: 20px;
  }
  ${(props) =>
    props.variant === "side" &&
    css`
      stroke: ${colors.black};
    `}
  ${(props) =>
    props.variant === "header" &&
    css`
      stroke: ${props.isSelected ? colors.white : colors.black};
      fill: ${colors.white};
    `}
`;

export const CircleWithNumber = styled.div<{
  number: number;
  isSelected: boolean;
  variant: "side" | "header";
}>`
  color: ${colors.white};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 14px;
  position: absolute;
  font-family: ${fonts.fontBauMedium};
  transform: translate(9px, -3px);
  &:after {
    content: "${(props) => props.number}";
  }
  ${breakpoints.xxsDown} {
    width: 13px;
    height: 13px;
    font-size: 8px;
    line-height: 11px;
    transform: translate(7px, -3px);
  }

  ${(props) =>
    props.variant === "side" &&
    css`
      background-color: ${props.isSelected ? colors.coral : colors.darkGray};
    `}
  ${(props) =>
    props.variant === "header" &&
    css`
      background-color: ${colors.coral};
    `}
`;
