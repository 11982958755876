import React from "react";

import styled from "styled-components";

import { fonts, colors } from "../../styles/variables";
import breakpoints from "../../styles/breakpoints";

import dancingCouple from "../../assets/images/elements/dancing-couple.svg";
import logoImage from "../../assets/images/elements/headerLogo.svg";

const StyledContainer = styled.div`
  margin: 0;
  padding: 50px 0px;
  text-align: center;
  ${breakpoints.smDown} {
    padding: 10px;
  }
  background-color: ${colors.peachTint};
  min-width: 100%;
  overflow-x: hidden;
  min-height: ${(props) => (props.height ? `${props.height}px` : "100%")};
  flex-direction: column;
  flex: 1;
`;

const StyledImg = styled.img`
  max-width: 500px;
  padding: 20px 10px;
  ${breakpoints.smDown} {
    max-width: 100%;
  }
`;

const StyledText = styled.h5`
  font-size: 28px;
  line-height: 30px;
  ${breakpoints.smDown} {
    font-size: 24px;
    line-height: 26px;
  }
  font-family: ${fonts.fontInterstateUltraBlack};
  text-transform: uppercase;
`;

const StyledHeaderLogoImage = styled.img`
  max-width: 200px;
  width: 100%;
  justify-content: center;
`;

const StyledHeader = styled.div`
  height: 80px;
  width: 100%;
  background-color: ${colors.coral};
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
`;

const MessageContainer = styled.div`
  ${breakpoints.smDown} {
    width: 90%;
    padding-top: 60px;
    padding-right: auto;
    padding-bottom: 0px;
    padding-left: auto;
  }
  ${breakpoints.smUp} {
    max-width: 800px;
    padding: 60px 20px 0px 20px;
  }
  display: flex;
  flex-direction: column;
`;

/**
 * Browser not supported page.
 * */
const BrowserNotSupported = () => (
  <StyledContainer height={window.innerHeight}>
    <StyledHeader>
      <StyledHeaderLogoImage src={logoImage} alt="Good Pair Days" />
    </StyledHeader>
    <StyledInnerContainer>
      <MessageContainer>
        <StyledText>
          {"Oh no! It looks like our website doesn't work well with your current web browser. " +
            "Please try updating your browser or testing an alternate one. " +
            "We find the best browsers for our site to be the latest versions of " +
            "Chrome, Safari, Edge or Firefox."}
        </StyledText>
      </MessageContainer>
      <StyledImg src={dancingCouple} alt="drunk-tech-team" />
    </StyledInnerContainer>
  </StyledContainer>
);

export default BrowserNotSupported;
