import React from "react";
import { HamburgerMenu, Line1, Line2, Line3 } from "./style";

interface HeaderHamburgerMenuProps {
  isMobileMenuOpen: boolean;
}

const HeaderHamburgerMenu = ({ isMobileMenuOpen }: HeaderHamburgerMenuProps) => (
  <HamburgerMenu>
    <Line1 isMobileMenuOpen={isMobileMenuOpen} />
    <Line2 isMobileMenuOpen={isMobileMenuOpen} />
    <Line3 isMobileMenuOpen={isMobileMenuOpen} />
  </HamburgerMenu>
);

export default HeaderHamburgerMenu;
