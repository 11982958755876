import { gql } from "@apollo/client";

/**
 * GraphQL Mutations
 * */

export const REDEEM_GIFT = gql`
  mutation RedeemGift($input: RedeemGiftInput!) {
    redeemGift(input: $input) {
      isValid
      redeemedGift {
        id
        fromMemberName
        fromMemberPersonalNote
        giftDescription
        monthsPurchased
        toMember {
          id
          firstName
          hasUpdatedPassword
          hasPendingGift
        }
      }
      errors {
        messages
        field
      }
    }
  }
`;

export const CREATE_CORPORATE_ORDER = gql`
  mutation CreateCorporateOrder($input: CreateCorporateOrderInput!) {
    createCorporateOrder(input: $input) {
      isSuccessful
      errors {
        messages
        field
      }
    }
  }
`;

/**
 * GraphQL Queries
 * */

export const GET_GIFT_FLOW_INFO = gql`
  query GiftFlow {
    giftFlow @client {
      months
      winePriceRangeId
      step
      fromMemberId
      toMemberEmail
      toMemberFirstName
      toMemberLastName
      toMemberPersonalNote
      holdUntilDate
      giftDeliveryId
      giftTemplateId
      toMemberShippingAddressId
      toMemberMobileCountryCode
      toMemberMobileNumber
      redBottles
      whiteBottles
      sparklingBottles
      roseBottles
      __typename
    }
  }
`;

export const GET_GIFT_FLOW_SIGN_UP_FORM_INFO = gql`
  query GiftFlowSignUpForm {
    giftFlowSignUpForm @client {
      email
      firstName
      lastName
      password
      birthDate
      confirmPassword
      __typename
    }
  }
`;

export const GET_ALL_GIFT_DELIVERIES = gql`
  query AllGiftDeliveries {
    allGiftDeliveries {
      id
      name
    }
  }
`;

/**
 * Resolvers, Defaults & Type definitions
 * https://www.apollographql.com/docs/link/links/state
 * */
export const resolverGiftFlow = {
  defaults: {
    giftFlow: {
      months: null,
      winePriceRangeId: null,
      step: 1,
      fromMemberId: null,
      toMemberEmail: "",
      toMemberFirstName: "",
      toMemberLastName: "",
      toMemberPersonalNote: "",
      holdUntilDate: null,
      giftDeliveryId: null,
      giftTemplateId: null,
      toMemberShippingAddressId: null,
      toMemberMobileCountryCode: "",
      toMemberMobileNumber: "",
      redBottles: null,
      whiteBottles: null,
      sparklingBottles: null,
      roseBottles: null,
      __typename: "GiftFlowObjectStore",
    },
    giftFlowSignUpForm: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      birthDate: "",
      confirmPassword: "",
      __typename: "GiftFlowSignUpFormObject",
    },
  },
  resolvers: {
    Mutation: {
      setGiftFlowInfo: async (obj, args, { cache }) => {
        const data = {
          __typename: "Store",
          giftFlow: {
            __typename: "GiftFlowObjectStore",
            ...args,
          },
        };
        await cache.writeQuery({
          query: GET_GIFT_FLOW_INFO,
          data,
        });
        return data;
      },
      setGiftFlowSignUpFormInfo: async (obj, args, { cache }) => {
        const data = {
          __typename: "Store",
          giftFlowSignUpForm: {
            __typename: "GiftFlowSignUpFormObject",
            ...args,
          },
        };
        await cache.writeQuery({
          query: GET_GIFT_FLOW_INFO,
          data,
        });
        return data;
      },
    },
  },
};
