import React from "react";
import styled from "styled-components";

import { getCountrySettings } from "../../../helpers/tools";
import { SHOULD_SHOW_CHRISTMAS_CUT_OFF_NOTICE } from "../../../helpers/constants";

const StyledListItem = styled.li`
  line-height: 1.4;
  padding-bottom: ${(props) => (props.addBottomPadding ? "15px" : "10px")};
`;

const StyledListItemLast = styled(StyledListItem)`
  padding-bottom: 0px;
`;

const StyledUnorderedList = styled.ul`
  padding: 0 0 0 18px;
  font-size: 14px;
  line-height: 1.4;
  list-style-position: outside;
`;

const StyledSpan = styled.span`
  font-weight: bold;
`;

const StyledPara = styled.p`
  line-height: 1.4;
  font-size: 14px;
  padding-top: 10px;
`;

const StyledParaBottomNote = styled(StyledPara)`
  padding-top: 0px;
`;

const ShippingDatesNotice = () => {
  const { IS_COUNTRY_UK } = getCountrySettings();

  const renderChristmasCutoffDates = () => {
    // Render UK version
    if (IS_COUNTRY_UK) {
      return (
        <>
          <StyledPara>
            If you{"'"}re looking to make sure you get your hands on some perfect bottles for Christmas, or
            wish to send a gift in time for the big day, please place your orders by the{" "}
            <strong>19th of December</strong> to avoid missing out.
          </StyledPara>
          <StyledPara>
            Send Email Gift Options will be sent to your inbox immediately (incl. on Christmas Day!)
          </StyledPara>
        </>
      );
    }

    // Render AU version
    return (
      <>
        <StyledPara>
          Whether you are looking to get your hands on some perfect bottles for Christmas, or possibly even
          sending those thoughtful gifts, please find the cut off dates for ordering to ensure delivery before
          the 25th of December.
          <br />
          <br />
          Please see below.
        </StyledPara>
        <StyledUnorderedList>
          <StyledListItem>
            <StyledSpan>Sydney:</StyledSpan>
            &nbsp;19th December.
          </StyledListItem>
          <StyledListItem>
            <StyledSpan>Newcastle, Wollongong, Central Coast:</StyledSpan>
            &nbsp;18th December.
          </StyledListItem>
          <StyledListItem>
            <StyledSpan>Rest of NSW:</StyledSpan>
            &nbsp;17th December.
          </StyledListItem>
          <StyledListItem>
            <StyledSpan>Melbourne:</StyledSpan>
            &nbsp;17th December.
          </StyledListItem>
          <StyledListItem>
            <StyledSpan>Rest of VIC:</StyledSpan>
            &nbsp;17th December.
          </StyledListItem>
          <StyledListItem>
            <StyledSpan>Brisbane, Gold Coast:</StyledSpan>
            &nbsp;17th December.
          </StyledListItem>
          <StyledListItem>
            <StyledSpan>Rest of QLD:</StyledSpan>
            &nbsp;13th December.
          </StyledListItem>
          <StyledListItem>
            <StyledSpan>Adelaide:</StyledSpan>
            &nbsp;14th December.
          </StyledListItem>
          <StyledListItem>
            <StyledSpan>Rest of SA:</StyledSpan>
            &nbsp;13th December.
          </StyledListItem>
          <StyledListItem>
            <StyledSpan>WA:</StyledSpan>
            &nbsp;10th December.
          </StyledListItem>
          <StyledListItem>
            <StyledSpan>ACT:</StyledSpan>
            &nbsp;17th December.
          </StyledListItem>
          <StyledListItem>
            <StyledSpan>Tasmania:</StyledSpan>
            &nbsp;13th December.
          </StyledListItem>
          <StyledListItem>
            <StyledSpan>Darwin (NT):</StyledSpan>
            &nbsp;10th December.
          </StyledListItem>
        </StyledUnorderedList>

        <StyledParaBottomNote>
          These dates are based on advice from the couriers that deliver our boxes, but we recommend that you
          get in as early as possible!
          <br />
          <br />
          Send Email Gift Options will be sent to your inbox immediately (incl. on Christmas Day!)
        </StyledParaBottomNote>
      </>
    );
  };

  const renderUKShippingTimes = () => (
    <>
      <StyledPara>
        {"We dispatch the majority of orders on the same or next business day. "}
        {"Our courier partner delivers Monday to Friday, between 7am to 9pm,"}
        {" with some Saturday deliveries for mainland customers."}
      </StyledPara>
      <StyledUnorderedList>
        <StyledListItem addBottomPadding>
          <StyledSpan>Mainland UK: </StyledSpan>
          &nbsp;Allow 1-2 business days after payment.
        </StyledListItem>
        <StyledListItem addBottomPadding>
          <StyledSpan>Northern Ireland and British Islands:</StyledSpan>
          &nbsp;Allow 2-4 business days after payment.
        </StyledListItem>
      </StyledUnorderedList>
      <StyledParaBottomNote>
        <StyledSpan>Please note: </StyledSpan>
        {"We do not currently deliver to Republic of Ireland, JE & GY postcodes, or PO Boxes. "}
      </StyledParaBottomNote>
    </>
  );

  const renderAUShippingTimes = () => (
    <>
      <StyledPara>
        {"Please note! Whilst we're still shipping normally and dispatching same day on average, delivery"}
        {" times for deliveries outside of NSW are sent with Australia Post who are facing delays across"}
        {" their network. Please allow for additional delays above their regular shipping times below."}
      </StyledPara>
      <StyledPara>
        {"We dispatch the majority of orders on the same or next business day. "}
        {"We use Australia Post and Fastway to deliver your precious bottles. "}
        {"If you have a preference between the two, please let our customer service team know."}
      </StyledPara>
      <StyledUnorderedList>
        <StyledListItem addBottomPadding>
          <StyledSpan>East Coast deliveries (e.g. Sydney, Melbourne, Brisbane etc): </StyledSpan>
          &nbsp;Expect 1-2 business days after dispatch.
        </StyledListItem>
        <StyledListItem addBottomPadding>
          <StyledSpan>West Coast deliveries (e.g. Perth):</StyledSpan>
          &nbsp;Expect 5-7 business days after dispatch.
        </StyledListItem>
        <StyledListItem addBottomPadding>
          <StyledSpan>In-between Coasts deliveries (e.g. Adelaide & Alice Springs):</StyledSpan>
          &nbsp;Expect 2-5 business days after dispatch.
        </StyledListItem>
        <StyledListItemLast>
          <StyledSpan>Rural deliveries (e.g. Broome):</StyledSpan>
          &nbsp;Can take a little longer depending
          {" on Australia Post's timings."}
        </StyledListItemLast>
      </StyledUnorderedList>
      <StyledParaBottomNote>
        <StyledSpan>Please note: </StyledSpan>
        {"In times of high courier demand such as holiday periods "}
        {"or surges in online ordering, their shipping times may extend past their agreed times."}
      </StyledParaBottomNote>
    </>
  );

  const renderShippingTimes = () => (IS_COUNTRY_UK ? renderUKShippingTimes() : renderAUShippingTimes());

  return SHOULD_SHOW_CHRISTMAS_CUT_OFF_NOTICE ? renderChristmasCutoffDates() : renderShippingTimes();
};

export default ShippingDatesNotice;
