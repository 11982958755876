import { normalize } from "styled-normalize";
import styled, { createGlobalStyle } from "styled-components";

import breakpoints from "../../../styles/breakpoints";
import { typography, transition, baseColors, fonts, header } from "../../../styles/variables";

export const GlobalStyle = createGlobalStyle`
${normalize}

*, &:before, &:after {
  box-sizing: border-box;
}
html,
body {
  font-family: ${typography.baseFontFamily};
  font-size: ${typography.baseFontSize};
  margin: 0;
  padding: 0;
  min-height: 100%;
  color: ${baseColors.baseColor};
  line-height: ${typography.baseLineHeight};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  flex: 1;
}
body {
  overflow-x: hidden;
}
#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}
header, footer {
  flex-shrink: 0;
}
.content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 20px;
  font-family: ${typography.titleFontFamily}, sans-serif;
  line-height: ${typography.titleLineHeight};
  &:last-child {
    margin-bottom: 0;
  }
}

h1,
.h1 {
  font-size: 51px;
}
h2,
.h2 {
  font-size: 45px;
}
h3,
.h3 {
  font-size: 36px;
}
h4,
.h4 {
  font-size: 26px;
}
h5,
.h5 {
  font-size: 18px;
}
h6,
.h6 {
  font-size: 14px;
}

button,
a {
  transition: ${transition.baseTransition};
  cursor: pointer;
}

a {
  text-decoration: none;
  color: ${baseColors.baseLinkColor};
  &:hover,
  &:focus {
    color: ${baseColors.baseLinkHoverColor};
    text-decoration: none;
  }
}

button {
  border: none;
  background-color: transparent;
  &:hover, &:focus {
    outline: none !important;
  }
}

p, ul {
  margin-top: 0;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

ul {
  padding: 0;
}

b, .bold {
  font-family: ${fonts.fontInterstateUltraBlack};
  text-transform: uppercase;
}
img {
  max-width: 100%;
  height: auto;
}
.boxTrayHolder {

}
`;

export const BoxHolder = styled.div<{
  isHeaderShown: boolean;
}>`
  ${breakpoints.smDown} {
    position: sticky;
    left: 0;
    transition: ${transition.headerMenuBarTransition};
    top: ${(props) => (props.isHeaderShown ? header.mobileHeight : "0px")};
    z-index: 200;
  }
`;
