import styled from "styled-components";

import breakpoints from "../../../styles/breakpoints";
import { CONTAINER_MAX_WIDTH } from "../../../styles/variables";

export const ContainerFluid = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
`;

export const Container = styled(ContainerFluid)`
  margin: 0 auto;
  ${breakpoints.xsUp} {
    max-width: ${CONTAINER_MAX_WIDTH.XS_UP}px;
  }
  ${breakpoints.smUp} {
    max-width: ${CONTAINER_MAX_WIDTH.SM_UP}px;
  }
  ${breakpoints.mdUp} {
    max-width: ${CONTAINER_MAX_WIDTH.MD_UP}px;
  }
  ${breakpoints.lgUp} {
    max-width: ${CONTAINER_MAX_WIDTH.LG_UP}px;
  }
`;
