import React, { Component } from "react";

import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";

import PropTypes from "prop-types";
import { setUser as setSentryUser } from "@sentry/browser";
import { client, persistent } from "./graphql/client";
import { logError } from "./helpers/logging";
import { forceLogOut, isLoggedIn } from "./helpers/auth";
import Routes from "./routes";

import { StyledToastContainer } from "./components/atoms/GlobalAlert";
import StyledLoading from "./components/atoms/Loading/StyledLoading";
import AppErrorBoundary from "./components/molecules/AppErrorBoundary";
import { GET_AUTH } from "./graphql/resolvers/auth";
import { GET_LOCAL_SHOPPING_CART, RESET_LOCAL_SHOPPING_CART } from "./graphql/resolvers/cart";
import { FETCH_POLICY_CACHE_ONLY } from "./helpers/constants";
import { AU_COUNTRY_ID, getCountryIdFromUrl } from "./helpers/urls.helper";
import { getCountryIdOfLoggedInMemberInCache, setCountryIdOfLoggedInMemberInCache } from "./helpers/tools";
import { useWelcomePackCookie } from "./helpers/hooks";

// TODO DEV-203 replace this with apollo-link-state
window.store = {};

/**
 * Renders our app entry point
 * */
class App extends Component {
  static propTypes = {
    history: PropTypes.shape({}),
  };

  static defaultProps = {
    history: {},
  };

  state = {
    loaded: false,
  };

  async componentDidMount() {
    // Loads application only once the persisted cache is loaded
    try {
      if (localStorage.getItem(process.env.REACT_APP_STORE_LOCAL_STORAGE)) {
        await persistent.restore();

        const urlCountryId = getCountryIdFromUrl();

        // if auth cache is broken, logout and reset cache
        const authDataQuery = await client.query({
          query: GET_AUTH,
          options: { fetchPolicy: FETCH_POLICY_CACHE_ONLY },
        });
        const auth = authDataQuery?.data?.auth;
        const memberId = authDataQuery?.data?.auth?.memberId;
        if (!auth || (isLoggedIn() && !memberId)) {
          // CASE: When history or cache is manually cleared while user is logged in to GPD,
          // but environment keys: REACT_APP_STORE_LOCAL_STORAGE and REACT_APP_AUTH_LOCAL_STORAGE are TRUE,
          // However, authQuery.auth.memberId is null.
          forceLogOut();
        } else if (isLoggedIn() && memberId) {
          // User is successfully logged in
          setSentryUser({ id: memberId });
          const cachedCountryId = getCountryIdOfLoggedInMemberInCache();
          if (!cachedCountryId) {
            // We want to populate the cache for the existing logged in users for AU website.
            // Cache will be populated upon a fresh new login, so in the future it will not be needed.
            setCountryIdOfLoggedInMemberInCache(AU_COUNTRY_ID);
          } else if (urlCountryId && cachedCountryId !== urlCountryId) {
            forceLogOut();
          }
        } else if (!isLoggedIn() && !memberId) {
          // User is not logged in
          const localShoppingCartQuery = await client.query({
            query: GET_LOCAL_SHOPPING_CART,
            options: { fetchPolicy: FETCH_POLICY_CACHE_ONLY },
          });
          const countryIdInLocalCart = localShoppingCartQuery?.data?.localShoppingCart?.countryId;
          if (countryIdInLocalCart !== urlCountryId) {
            await client.mutate({ mutation: RESET_LOCAL_SHOPPING_CART });
          }
        }
      } else {
        await persistent.persist();
      }
    } catch (error) {
      logError(error);
    }

    // Sets 'loaded' to true, so application can be loaded
    this.setState({
      loaded: true,
    });
  }

  render() {
    const { history } = this.props;
    const { loaded } = this.state;

    if (!loaded) return <StyledLoading />;

    persistent.getLogs(
      process.env.REACT_APP_ENV_NAME === "local" ||
        process.env.REACT_APP_ENV_NAME === "develop" ||
        process.env.REACT_APP_ENV_NAME === "staging"
    );

    return (
      // Provides apollo client across the application
      <ApolloProvider client={client}>
        {/* Route-Based code splitting.  */}
        {/* https://reactjs.org/docs/code-splitting.html#route-based-code-splitting */}
        <BrowserRouter>
          <AppErrorBoundary history={history}>
            <React.Suspense fallback={<StyledLoading />}>
              <Routes persistentCache={persistent} />
            </React.Suspense>
          </AppErrorBoundary>
        </BrowserRouter>

        {/* Required components for global alerts.  */}
        <StyledToastContainer />
      </ApolloProvider>
    );
  }
}

/**
 * Wraps the class component in a functional component so that we can use hooks.
 * */
const AppWrapper = (props) => {
  useWelcomePackCookie();
  return <App {...props} />;
};

export default AppWrapper;
