import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACES } from "../../../helpers/constants";
import { NAVBAR_GIFT_ITEMS } from "../../../helpers/navbarProcessor";
import { useHeaderContext } from "../../organisms/Header/context";
import {
  DropdownContentWrapper,
  SectionColumn,
  ColumnTitle,
  ItemWrapperLink,
  ItemName,
  Cross,
} from "../../organisms/Header/style";
import { GiftsDropdownImage, GiftsDropdownWrapper, GiftIcon } from "./style";

interface NavbarItemProps {
  i18nKey: string;
  url: string;
  isExternalUrl?: boolean;
}

interface DesktopGiftsDropdownProps {
  onCrossClicked: () => void;
  isAnyDropdownActive: boolean;
}

const DesktopGiftsDropdown = ({ onCrossClicked, isAnyDropdownActive }: DesktopGiftsDropdownProps) => {
  const { t } = useTranslation([I18N_NAMESPACES.COMMON, I18N_NAMESPACES.TOP_NAVBAR]);
  const { setActiveDesktopMenuItem } = useHeaderContext();

  const renderNavbarItem = ({ i18nKey, url }: NavbarItemProps): ReactElement => (
    <ItemWrapperLink to={url} onClick={() => setActiveDesktopMenuItem("")} key={i18nKey}>
      <ItemName>{t(i18nKey)}</ItemName>
    </ItemWrapperLink>
  );

  const renderGiftsSection = (): ReactElement => (
    <>
      <GiftIcon />
      <SectionColumn>
        <ColumnTitle>{t("common:gifts")}</ColumnTitle>
        {NAVBAR_GIFT_ITEMS.map((item) => renderNavbarItem(item))}
      </SectionColumn>
    </>
  );

  return (
    <GiftsDropdownWrapper isAnyDropdownActive={isAnyDropdownActive}>
      <GiftsDropdownImage />
      <DropdownContentWrapper>
        {renderGiftsSection()}
        <Cross onClick={() => onCrossClicked()} />
      </DropdownContentWrapper>
    </GiftsDropdownWrapper>
  );
};

export default DesktopGiftsDropdown;
