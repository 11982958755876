import React from "react";
import styled from "styled-components";
import NotificationButton from "../NotificationButton";
import { useDeviceSize } from "../../../helpers/hooks";
import HeaderUser from "../../atoms/HeaderUser";
import HeaderHamburgerMenu from "../../atoms/HeaderHamburgerMenu";
import breakpoints from "../../../styles/breakpoints";
import MemberPointsBalance from "../../atoms/MemberPointsBalance";
import URL_PATTERNS from "../../../urls";

const HeaderHamburgerMenuWrapper = styled.div`
  cursor: pointer;
  padding: 12px 0 12px 12px;
  ${breakpoints.mdUp} {
    display: none;
  }
`;

const HeaderUserWrapper = styled.div`
  cursor: pointer;
`;

const StyledHeaderLoggedIn = styled.div`
  ${breakpoints.mdUp} {
    order: 3;
    justify-content: flex-start;
    margin-left: 32px;
  }
  margin-left: 40px;
  display: flex;
  align-items: center;
  ${breakpoints.smDown} {
    margin-left: auto;
    height: 40px;
    display: flex;
    align-items: center;
  }
`;

interface HeaderLoggedInProps {
  hasUnreadRecentNotifications: boolean;
  refetchMemberLayoutInfoData: () => {};
  onMouseClickMyAccounts: () => void;
  toggleNotifications: () => void;
  toggleMobileMenu: () => void;
  isMobileMenuOpen: boolean;
  isMemberDropDownOpen: boolean;
  isAnyDropDownOpen: boolean;
  points: number | null | undefined;
  firstName: string;
}

const HeaderLoggedIn = ({
  hasUnreadRecentNotifications,
  refetchMemberLayoutInfoData,
  onMouseClickMyAccounts,
  toggleNotifications,
  toggleMobileMenu,
  isMobileMenuOpen,
  isMemberDropDownOpen,
  isAnyDropDownOpen,
  points,
  firstName,
}: HeaderLoggedInProps) => {
  const { isSmallOrMedium } = useDeviceSize();
  return (
    <StyledHeaderLoggedIn>
      <NotificationButton
        hasUnreadRecentNotifications={hasUnreadRecentNotifications}
        refetchMemberLayoutInfoData={refetchMemberLayoutInfoData}
        toggleNotifications={toggleNotifications}
        isAnyDropDownOpen={isAnyDropDownOpen}
      />
      {!isSmallOrMedium && (
        <MemberPointsBalance points={points} variant="header" link={URL_PATTERNS.MY_PROGRESS} />
      )}
      {isSmallOrMedium ? (
        <HeaderHamburgerMenuWrapper onClick={() => toggleMobileMenu()}>
          <HeaderHamburgerMenu isMobileMenuOpen={isMobileMenuOpen} />
        </HeaderHamburgerMenuWrapper>
      ) : (
        <HeaderUserWrapper onClick={onMouseClickMyAccounts}>
          <HeaderUser isMemberDropDownOpen={isMemberDropDownOpen} firstName={firstName} />
        </HeaderUserWrapper>
      )}
    </StyledHeaderLoggedIn>
  );
};

export default HeaderLoggedIn;
