import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useFlag } from "@unleash/proxy-client-react";
import { I18N_NAMESPACES, UNLEASH_EARN_POINTS_V3 } from "../../../helpers/constants";
import { NAVBAR_REWARD_ITEMS, NAVBAR_REWARD_LOGGED_IN_ITEMS } from "../../../helpers/navbarProcessor";
import { useHeaderContext } from "../../organisms/Header/context";
import { isLoggedIn } from "../../../helpers/auth";
import {
  DropdownContentWrapper,
  SectionColumn,
  ColumnTitle,
  ItemWrapperLink,
  ItemName,
  Cross,
} from "../../organisms/Header/style";
import { DropdownImage, DropdownWrapper, Icon } from "./style";

interface NavbarItemProps {
  i18nKey: string;
  url: string;
  isExternalUrl?: boolean;
}

interface DesktopRewardsDropdownProps {
  onCrossClicked: () => void;
  isAnyDropdownActive: boolean;
}

const DesktopRewardsDropdown = ({ onCrossClicked, isAnyDropdownActive }: DesktopRewardsDropdownProps) => {
  const { t } = useTranslation([I18N_NAMESPACES.COMMON, I18N_NAMESPACES.TOP_NAVBAR]);
  const isEarnPointsV3Enabled = useFlag(UNLEASH_EARN_POINTS_V3);

  const { setActiveDesktopMenuItem } = useHeaderContext();

  const navBarItems = isLoggedIn() ? NAVBAR_REWARD_LOGGED_IN_ITEMS : NAVBAR_REWARD_ITEMS;

  const renderNavbarItem = ({ i18nKey, url }: NavbarItemProps): ReactElement => (
    <ItemWrapperLink to={url} onClick={() => setActiveDesktopMenuItem("")} key={i18nKey}>
      <ItemName>
        {t(i18nKey, {
          context: isEarnPointsV3Enabled ? "v3" : "default",
          interpolation: { escapeValue: false },
        })}
      </ItemName>
    </ItemWrapperLink>
  );

  const renderSection = (): ReactElement => (
    <>
      <Icon />
      <SectionColumn>
        <ColumnTitle>{t("topNavbar:rewards")}</ColumnTitle>
        {navBarItems.map((item) => renderNavbarItem(item))}
      </SectionColumn>
    </>
  );

  return (
    <DropdownWrapper isAnyDropdownActive={isAnyDropdownActive}>
      <DropdownImage />
      <DropdownContentWrapper>
        {renderSection()}
        <Cross onClick={() => onCrossClicked()} />
      </DropdownContentWrapper>
    </DropdownWrapper>
  );
};

export default DesktopRewardsDropdown;
