import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useFlag } from "@unleash/proxy-client-react";

import { executeLogOut } from "../../../helpers/auth";
import {
  I18N_NAMESPACES,
  UNLEASH_EARN_POINTS_V3,
  UNLEASH_NEW_PRODUCT_CATEGORIES,
} from "../../../helpers/constants";
import {
  NAVBAR_DESKTOP_MEMBER_MORE_ITEMS,
  NAVBAR_DESKTOP_MEMBER_MY_TASTES_ITEMS,
  NAVBAR_DESKTOP_MEMBER_HOME_AND_BOX_ITEMS,
  NAVBAR_DESKTOP_MEMBER_PAST_WINES_ITEMS,
  NAVBAR_DESKTOP_MEMBER_REWARDS_ITEMS,
  NAVBAR_DESKTOP_MEMBER_SETTINGS_ITEMS,
  NAVBAR_DESKTOP_MEMBER_PAST_DRINKS_ITEMS,
} from "../../../helpers/navbarProcessor";
import URL_PATTERNS from "../../../urls";
import { useHeaderContext } from "../../organisms/Header/context";
import {
  SectionColumn,
  ColumnTitle,
  ItemWrapper,
  ItemWrapperLink,
  ItemName,
  Cross,
} from "../../organisms/Header/style";
import {
  MemberDropdownWrapper,
  MemberDropdownRow,
  MenuDropdownImage,
  MenuImageWrapper,
  MemberMenuDropdownWrapper,
  SectionWrapper,
  NextBoxIcon,
  MyRewardsIcon,
  PastWinesIcon,
  MoreIcon,
  MyTastesIcon,
  SettingsIcon,
} from "./style";

interface NavbarItemProps {
  i18nKey: string;
  url: string;
  shouldNotCapitalize?: boolean;
  shouldOpenSideTray?: boolean;
}

interface DesktopMemberDropdownProps {
  onCrossClicked: () => void;
  isAnyDropDownActive: boolean;
}

const DesktopMemberDropdown = ({ onCrossClicked, isAnyDropDownActive }: DesktopMemberDropdownProps) => {
  const { t } = useTranslation([I18N_NAMESPACES.COMMON, I18N_NAMESPACES.TOP_NAVBAR]);
  const isEarnPointsV3Enabled = useFlag(UNLEASH_EARN_POINTS_V3);
  const isProductCollectionsEnabled = useFlag(UNLEASH_NEW_PRODUCT_CATEGORIES);
  const { setActiveDesktopMenuItem } = useHeaderContext();

  const renderNavbarItem = ({
    i18nKey,
    url,
    shouldNotCapitalize,
    shouldOpenSideTray = false,
  }: NavbarItemProps): ReactElement => (
    <ItemWrapperLink
      to={{
        pathname: url,
        state: { shouldOpenSideTray },
      }}
      onClick={() => setActiveDesktopMenuItem("")}
      key={i18nKey}
    >
      <ItemName shouldNotCapitalize={shouldNotCapitalize ?? false}>
        {t(i18nKey, {
          context: isEarnPointsV3Enabled ? "v3" : "default",
          interpolation: { escapeValue: false },
        })}
      </ItemName>
    </ItemWrapperLink>
  );

  const renderHomeAndBoxSection = (): ReactElement => (
    <SectionWrapper>
      <NextBoxIcon />
      <SectionColumn noMarginRight>
        <ColumnTitle>{t("topNavbar:homeAndBox")}</ColumnTitle>
        {NAVBAR_DESKTOP_MEMBER_HOME_AND_BOX_ITEMS.map((item: NavbarItemProps) => renderNavbarItem(item))}
      </SectionColumn>
    </SectionWrapper>
  );

  const renderRewardsSection = (): ReactElement => (
    <SectionWrapper>
      <MyRewardsIcon />
      <SectionColumn noMarginRight>
        <ColumnTitle>{t("topNavbar:rewards")}</ColumnTitle>
        {NAVBAR_DESKTOP_MEMBER_REWARDS_ITEMS.map((item: NavbarItemProps) => renderNavbarItem(item))}
      </SectionColumn>
    </SectionWrapper>
  );

  const renderPastWinesSection = (): ReactElement => (
    <SectionWrapper>
      <PastWinesIcon />
      <SectionColumn noMarginRight>
        <ColumnTitle>{t("topNavbar:pastWines")}</ColumnTitle>
        {NAVBAR_DESKTOP_MEMBER_PAST_WINES_ITEMS.map((item: NavbarItemProps) => renderNavbarItem(item))}
      </SectionColumn>
    </SectionWrapper>
  );

  const renderPastDrinksSection = (): ReactElement => (
    <SectionWrapper>
      <PastWinesIcon />
      <SectionColumn noMarginRight>
        <ColumnTitle>{t("topNavbar:pastDrinks")}</ColumnTitle>
        {NAVBAR_DESKTOP_MEMBER_PAST_DRINKS_ITEMS.map((item: NavbarItemProps) => renderNavbarItem(item))}
      </SectionColumn>
    </SectionWrapper>
  );

  const renderMoreSection = (): ReactElement => (
    <SectionWrapper>
      <MoreIcon />
      <SectionColumn noMarginRight>
        <ColumnTitle>{t("topNavbar:more")}</ColumnTitle>
        {NAVBAR_DESKTOP_MEMBER_MORE_ITEMS.map((item: NavbarItemProps) => renderNavbarItem(item))}

        <ItemWrapper onClick={() => executeLogOut(URL_PATTERNS.HOME)}>
          <ItemName>{t("topNavbar:logout")}</ItemName>
        </ItemWrapper>
      </SectionColumn>
    </SectionWrapper>
  );

  const renderMyTastesSection = (): ReactElement => (
    <SectionWrapper>
      <MyTastesIcon />
      <SectionColumn noMarginRight>
        <ColumnTitle>{t("topNavbar:myTaste")}</ColumnTitle>
        {NAVBAR_DESKTOP_MEMBER_MY_TASTES_ITEMS.map((item: NavbarItemProps) => renderNavbarItem(item))}
      </SectionColumn>
    </SectionWrapper>
  );

  const renderSettingsSection = (): ReactElement => (
    <SectionWrapper>
      <SettingsIcon />
      <SectionColumn noMarginRight>
        <ColumnTitle>{t("topNavbar:settings")}</ColumnTitle>
        {NAVBAR_DESKTOP_MEMBER_SETTINGS_ITEMS.map((item: NavbarItemProps) => renderNavbarItem(item))}
      </SectionColumn>
    </SectionWrapper>
  );

  return (
    <MemberDropdownWrapper>
      <MenuImageWrapper>
        <MenuDropdownImage />
      </MenuImageWrapper>
      <MemberMenuDropdownWrapper isAnyDropdownActive={isAnyDropDownActive}>
        <MemberDropdownRow increasedMarginBottom>
          {renderHomeAndBoxSection()}
          {renderRewardsSection()}
          {isProductCollectionsEnabled ? renderPastDrinksSection() : renderPastWinesSection()}
          <Cross onClick={() => onCrossClicked()} />
        </MemberDropdownRow>
        <MemberDropdownRow>
          {renderMyTastesSection()}
          {renderSettingsSection()}
          {renderMoreSection()}
        </MemberDropdownRow>
      </MemberMenuDropdownWrapper>
    </MemberDropdownWrapper>
  );
};

export default DesktopMemberDropdown;
