import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useFlag } from "@unleash/proxy-client-react";
import {
  I18N_NAMESPACES,
  MOBILE_NAVBAR_ACCORDIONS,
  UNLEASH_NEW_PRODUCT_CATEGORIES,
  WINE_CLASS_IDS,
} from "../../../helpers/constants";
import urlPatterns from "../../../urls";
import { NAVBAR_MOBILE_WINES_ITEMS } from "../../../helpers/navbarProcessor";
import { NAVBAR_WINES_HEIGHTS } from "../../../styles/variables";
import AccordionItem from "../AccordionItem";
import {
  AccordionTopPart,
  StyledChevron,
  AccordionWrapper,
  AccordionContentWrapper,
} from "../MobileHeaderNavbar/style";

interface MobileWinesAccordionProps {
  openAccordion: string | undefined;
  setOpenAccordion: Function;
}

const MobileWinesAccordion = ({
  openAccordion,
  setOpenAccordion,
}: MobileWinesAccordionProps): ReactElement => {
  const isThisAccordionOpen = openAccordion === MOBILE_NAVBAR_ACCORDIONS.WINES;
  const { t } = useTranslation([I18N_NAMESPACES.COMMON]);
  const isNewProductCategoriesEnabled = useFlag(UNLEASH_NEW_PRODUCT_CATEGORIES);

  return (
    <AccordionWrapper
      open={isThisAccordionOpen}
      height={NAVBAR_WINES_HEIGHTS.full}
      onClick={() => setOpenAccordion(isThisAccordionOpen ? "" : MOBILE_NAVBAR_ACCORDIONS.WINES)}
    >
      <AccordionTopPart isThisAccordionOpen={isThisAccordionOpen}>
        {t("common:wines")}
        <StyledChevron open={isThisAccordionOpen} />
      </AccordionTopPart>
      <AccordionContentWrapper open={isThisAccordionOpen} height={NAVBAR_WINES_HEIGHTS.accordion}>
        {NAVBAR_MOBILE_WINES_ITEMS.map((item) => {
          const sake_url = urlPatterns.WINE_LIST_CLASS(`${WINE_CLASS_IDS.DB_ID_WINE_CLASS_SAKE}`);
          const shouldNotShowSake = isNewProductCategoriesEnabled && item.url === sake_url;
          if (shouldNotShowSake) {
            return null;
          }

          return <AccordionItem i18nKey={item.i18nKey} url={item.url} key={item.i18nKey} />;
        })}
      </AccordionContentWrapper>
    </AccordionWrapper>
  );
};

export default MobileWinesAccordion;
