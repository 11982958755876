import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Gallery = (props) => {
  const { children } = props;

  const settings = {
    autoplay: true,
    dots: false,
    arrows: false,
    speed: 500,
  };

  return <Slider {...settings}>{children}</Slider>;
};

Gallery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Gallery;
