import { logError } from "./logging";

const AU_COUNTRY_CODE = "au";
const UK_COUNTRY_CODE = "uk";
const USER_DOES_NOT_WANT_TO_CHANGE_COUNTRY = "userDoesNotWantToChangeCountry";
const USER_COUNTRY_FROM_IP = "userCountry";
const COUNTRY_ID = "countryId";
const UK_COUNTRY_ID = 191;
const AU_COUNTRY_ID = 117;

const EU_COUNTRIES = [
  "GB",
  "EU",
  "AD",
  "AL",
  "AT",
  "BA",
  "BE",
  "BG",
  "BY",
  "CH",
  "CS",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FO",
  "FR",
  "FX",
  "GI",
  "GR",
  "HR",
  "HU",
  "IE",
  "IS",
  "IT",
  "LI",
  "LT",
  "LU",
  "LV",
  "MC",
  "MD",
  "MK",
  "MT",
  "NL",
  "NO",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SJ",
  "SK",
  "SM",
  "UA",
  "VA",
];

const COUNTRY_INFO_BY_CODE = {
  au: {
    language: "en-AU",
    countryId: AU_COUNTRY_ID,
  },
  uk: {
    language: "en-GB",
    countryId: UK_COUNTRY_ID,
  },
};

const getNewCountryUrl = (newLanguage) => {
  const rxGetCountryCode = /^.{8}[^/]*\/([^/]*)/;
  const urlObject = rxGetCountryCode.exec(window.location.href);
  const relevantUrl = urlObject.input.replace(urlObject[0], "");
  const newUrl = newLanguage + relevantUrl;
  return newUrl;
};

/**
 * Extracts the countryCode from the URL.
 * @returns {String}
 */
const extractCountryFromUrl = () => {
  const rxGetCountryCode = /^.{8}[^/]*\/([^/]*)/;
  const countryFromURL = rxGetCountryCode.exec(window.location.href)[1];
  return countryFromURL;
};

/**
 * Remove the countryCode from the URL.
 * @returns {String}
 */
export const getCountryRemovedUrl = (path) => {
  const regex = new RegExp(`/${AU_COUNTRY_CODE}|${UK_COUNTRY_CODE}/`);
  return path.replace(regex, "");
};

/**
 * Extracts the countryID from the URL. Returns countryId if found in URL, otherwise returns false.
 * @returns  {Number || Boolean}
 */
const getCountryIdFromUrl = () => {
  const country = extractCountryFromUrl();
  if (country !== AU_COUNTRY_CODE && country !== UK_COUNTRY_CODE) return false;
  const { countryId } = COUNTRY_INFO_BY_CODE[country];
  return countryId;
};

const getStripeKey = () => {
  const country = getCountry();
  const stripeKey = process.env[`REACT_APP_STRIPE_${country.toUpperCase()}_PUBLISHABLE_KEY`];
  return stripeKey;
};

const isGuideUrl = () => window.location.href.includes("/guides/");

const getRestAPIURL = () => {
  const country = getCountry();
  const baseURL = process.env[`REACT_APP_API_${country.toUpperCase()}_ENDPOINT`];
  return baseURL;
};

const getGraphQlURL = () => {
  const baseURL = getRestAPIURL();
  const graphqlURL = `${baseURL}/graphql`;
  return graphqlURL;
};

const extractCountryFromIp = async () => {
  const storedCountryCode = window.localStorage.getItem(USER_COUNTRY_FROM_IP);
  if (!storedCountryCode) {
    try {
      const url =
        `${process.env.REACT_APP_EXTREME_IP_LOOKUP_URL}` +
        `?key=${process.env.REACT_APP_EXTREME_IP_LOOKUP_KEY}`;
      const result = await fetch(url);
      const data = await result.json();
      const { countryCode: fetchedCountryCode } = data;
      window.localStorage.setItem(USER_COUNTRY_FROM_IP, fetchedCountryCode);
      return fetchedCountryCode;
    } catch (error) {
      return false;
    }
  }
  return storedCountryCode;
};

/**
 * Compares ipCountry and urlCountry to see if the user should be offered the option to change country.
 * If ipCountry and urlCountry are different, and it's not a guide url, offer the user to change country
 * @returns {Boolean}
 * */
const offerToChangeCountry = async () => {
  if (window.localStorage.getItem(USER_DOES_NOT_WANT_TO_CHANGE_COUNTRY)) return false;

  const ipCountry = await extractCountryFromIp();
  const urlCountry = extractCountryFromUrl();

  // true if IP is from EU, and url is /AU/
  const offerToChangeToUK = EU_COUNTRIES.includes(ipCountry) && urlCountry === AU_COUNTRY_CODE;

  // true if IP is from outside EU, and url is set to /UK/
  const offerToChangeToAU = !EU_COUNTRIES.includes(ipCountry) && urlCountry === UK_COUNTRY_CODE;

  const showOfferToChangeCountry = !isGuideUrl() && (offerToChangeToUK || offerToChangeToAU);
  return showOfferToChangeCountry;
};

/**
 * Check and return country from url, if valid.
 * Otherwise, check and return country based on cachedCountryId, if valid.
 * Otherwise, check and return country based cachedIpCountry, if valid.
 * Lastly, return AU_COUNTRY_CODE if all else fails.
 *
 * @returns  {String}
 */
const getCountry = () => {
  // Get and verify country from URL.
  const urlCountry = extractCountryFromUrl();
  const isUrlCountryVerified = urlCountry === UK_COUNTRY_CODE || urlCountry === AU_COUNTRY_CODE;
  if (isUrlCountryVerified) return urlCountry;

  // Get and verify countryId from cache.
  // Do not use getCountryIdOfLoggedInMemberInCache to avoid circular dependency.
  const cachedCountryId = parseInt(localStorage.getItem(COUNTRY_ID), 10) || 0;
  if (cachedCountryId) return cachedCountryId === UK_COUNTRY_ID ? UK_COUNTRY_CODE : AU_COUNTRY_CODE;

  // Get and verify ipCountry from cache.
  const cachedIpCountry = window.localStorage.getItem(USER_COUNTRY_FROM_IP);
  return EU_COUNTRIES.includes(cachedIpCountry) ? UK_COUNTRY_CODE : AU_COUNTRY_CODE;
};

/**
 * Checks if current url is in a group of urls.
 * @param {string} pathName
 * @param {object} urlPatterns
 * @returns {boolean}
 */
export const checkIfUrlIsInUrlGroup = (pathName, urlPatterns) => {
  try {
    return Object.values(urlPatterns).some((urlPattern) => pathName.includes(urlPattern));
  } catch (error) {
    logError(error);
  }
  return false;
};

export {
  getCountry,
  getNewCountryUrl,
  getGraphQlURL,
  getRestAPIURL,
  isGuideUrl,
  offerToChangeCountry,
  getStripeKey,
  getCountryIdFromUrl,
  extractCountryFromUrl,
  COUNTRY_INFO_BY_CODE,
  USER_DOES_NOT_WANT_TO_CHANGE_COUNTRY,
  COUNTRY_ID,
  AU_COUNTRY_ID,
  UK_COUNTRY_ID,
  AU_COUNTRY_CODE,
  UK_COUNTRY_CODE,
  USER_COUNTRY_FROM_IP,
  EU_COUNTRIES,
};
