import React, { ReactElement } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { fonts } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";
import urlPatterns from "../../../urls";

import SocialList from "../../atoms/SocialList";
import { COUNTRY_SPECIFIC_SETTINGS, I18N_NAMESPACES } from "../../../helpers/constants";
import { trackingEvents, useTrackEvent } from "../../../helpers/Analytics";
import { getCountry, getNewCountryUrl, isGuideUrl } from "../../../helpers/urls.helper";
import { isLoggedIn } from "../../../helpers/auth";
import FooterNavbar from "../../atoms/FooterNavbar";
import CountryDropdown from "../../atoms/CountryDropdown";

const StyledFooterContent = styled.div`
  ${breakpoints.mdUp} {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
`;

const StyledFooterContentLeftPart = styled.div``;

const StyledFooterContentRightPart = styled.div`
  ${breakpoints.mdUp} {
    flex-shrink: 0;
  }
  ${breakpoints.mdDown} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledFooterContentInfoText = styled.p`
  font-size: 12px;
  margin-bottom: 15px;
`;

const StyledFooterCopyrightText = styled.p`
  font-size: 10px;
  font-family: ${fonts.fontBauMedium};
  ${breakpoints.mdDown} {
    display: none;
  }
`;

const StyledFooterContentTitle = styled.h3`
  font-size: 20px;
  font-family: ${fonts.fontInterstateBlackCompressed};
  margin-bottom: 5px;
`;

const StyledFooterContentEmail = styled.a`
  display: block;
  margin-bottom: 2px;
`;

const StyledFooterContentAddress = styled.a`
  display: block;
  margin-bottom: 15px;
`;

const ConnectSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const FooterContent = () => {
  const { t } = useTranslation(I18N_NAMESPACES.COMMON);
  const trackEvent = useTrackEvent();

  const country = getCountry();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeCountry = (value: any) => {
    const newUrl = getNewCountryUrl(value);
    window.location.href = `/${newUrl}`;
  };

  const shouldShowLanguageDropdown = !isGuideUrl() && !isLoggedIn();

  const renderConnectSection = (): ReactElement => (
    <ConnectSectionWrapper>
      <StyledFooterContentTitle>{t("common:sayHello")}</StyledFooterContentTitle>
      <StyledFooterContentEmail
        href={t("common:CONTACT_US_EMAIL_MAILTO")}
        onClick={() => trackEvent(trackingEvents.TRACKING_EVENT_CONTACTED_SUPPORT_TEAM, {})}
      >
        {t("common:CONTACT_EMAIL_ADDRESS")}
      </StyledFooterContentEmail>
      <StyledFooterContentAddress href={urlPatterns.CONTACT}>
        {t("common:location")}
      </StyledFooterContentAddress>
      <SocialList />
    </ConnectSectionWrapper>
  );

  return (
    <StyledFooterContent>
      <StyledFooterContentLeftPart>
        <FooterNavbar />
        <StyledFooterContentInfoText>{t("common:footerContentInfo")}</StyledFooterContentInfoText>
        <StyledFooterCopyrightText>{t("common:copyright")}</StyledFooterCopyrightText>
      </StyledFooterContentLeftPart>

      <StyledFooterContentRightPart>
        {shouldShowLanguageDropdown && (
          <CountryDropdown
            onSelectCountry={(value: string) => changeCountry(value)}
            renderCountryLabel={(countryId: string) => {
              const { FLAG, SHORT_NAME, CURRENCY_LABEL } = COUNTRY_SPECIFIC_SETTINGS[countryId] || {};
              return `${FLAG} ${SHORT_NAME} (${CURRENCY_LABEL})`;
            }}
            selectedCountry={country}
          />
        )}
        {renderConnectSection()}
      </StyledFooterContentRightPart>
    </StyledFooterContent>
  );
};

export default FooterContent;
