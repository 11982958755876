import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

// Australian translations that are bundled to the main bundle.
import enAUCommon from "../locales/en-AU/common.json";
import enAUHome from "../locales/en-AU/home.json";
import enAUMyAccount from "../locales/en-AU/myAccount.json";
import enAUQuiz from "../locales/en-AU/quiz.json";

// British translations that are bundled to the main bundle.
import enGBCommon from "../locales/en-GB/common.json";
import enGBHome from "../locales/en-GB/home.json";
import enGBMyAccount from "../locales/en-GB/myAccount.json";
import enGBQuiz from "../locales/en-GB/quiz.json";

// English translations that are bundled to the main bundle.
import enCommon from "../locales/en/common.json";
import enHome from "../locales/en/home.json";
import enMyAccount from "../locales/en/myAccount.json";
import enMyBox from "../locales/en/myBox.json";
import enQuiz from "../locales/en/quiz.json";
import enTopNavbar from "../locales/en/topNavbar.json";
import enMyTasteProfile from "../locales/en/myTasteProfile.json";
import { I18N_NAMESPACES } from "./constants";
import { getCountry, COUNTRY_INFO_BY_CODE, AU_COUNTRY_CODE } from "./urls.helper";

// if countrycode isn't detected in the URL, default to en_AU.
// When we have the new routes here, there should be a redirect here to au if nothing else is present.
const defaultCountryObject = COUNTRY_INFO_BY_CODE[AU_COUNTRY_CODE];
const countryFromURL = getCountry();
const urlLanguageObject = COUNTRY_INFO_BY_CODE[countryFromURL];

const { language } = urlLanguageObject || defaultCountryObject;

i18n

  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    lng: language,

    // ns are the Namespaces that we want loaded by default every time we import i18n.
    ns: [
      I18N_NAMESPACES.MY_ACCOUNT,
      I18N_NAMESPACES.TOP_NAVBAR,
      I18N_NAMESPACES.MY_TASTE_PROFILE,
      I18N_NAMESPACES.REWARDS,
    ],
  });

export default i18n;

/**
 * Returns cash amount with two digits if it's a float.
 * @param {number | null | undefined} amount
 * @returns {string}
 */
export const getCashAmountLabel = (amount) => {
  // const isCashCostFloat = isFloat(amount ?? 0);
  const isNegativeAmount = (amount ?? 0) < 0;
  const translationKey = isNegativeAmount ? "common:negativeCurrency" : "common:currency";
  const cashString = i18n.t(translationKey, {
    amount: Math.abs(amount),
    // TODO: Investigate need for more decimals at a later date.
    // context: isCashCostFloat && CURRENCY_FLOAT_CONTEXT,

    // The specified fallbackLng is set in order for the tests to run.
    fallbackLng: "en-AU",
  });
  return cashString;
};

// The translations below are added to the main bundle to enable faster loading of the home page.
// These are the translations needed for non-logged in users that land on the home page.
i18n.addResourceBundle("en", I18N_NAMESPACES.COMMON, enCommon);
i18n.addResourceBundle("en", I18N_NAMESPACES.HOME, enHome);
i18n.addResourceBundle("en", I18N_NAMESPACES.MY_ACCOUNT, enMyAccount);
i18n.addResourceBundle("en", I18N_NAMESPACES.MY_BOX, enMyBox);
i18n.addResourceBundle("en", I18N_NAMESPACES.QUIZ, enQuiz);
i18n.addResourceBundle("en", I18N_NAMESPACES.TOP_NAVBAR, enTopNavbar);
i18n.addResourceBundle("en", I18N_NAMESPACES.MY_TASTE_PROFILE, enMyTasteProfile);

i18n.addResourceBundle("en-AU", I18N_NAMESPACES.COMMON, enAUCommon);
i18n.addResourceBundle("en-AU", I18N_NAMESPACES.HOME, enAUHome);
i18n.addResourceBundle("en-AU", I18N_NAMESPACES.MY_ACCOUNT, enAUMyAccount);
i18n.addResourceBundle("en-AU", I18N_NAMESPACES.QUIZ, enAUQuiz);

i18n.addResourceBundle("en-GB", I18N_NAMESPACES.COMMON, enGBCommon);
i18n.addResourceBundle("en-GB", I18N_NAMESPACES.HOME, enGBHome);
i18n.addResourceBundle("en-GB", I18N_NAMESPACES.MY_ACCOUNT, enGBMyAccount);
i18n.addResourceBundle("en-GB", I18N_NAMESPACES.QUIZ, enGBQuiz);
