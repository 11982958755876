import styled from "styled-components";
import { colors, fonts } from "../../../styles/variables";
import iconClose from "../../../assets/images/icons/cross.svg";

export const BoxAndCartTrayMobileBottomSectionWrapper = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${colors.coral};
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  border-radius: 0px 0px 10px 10px;
  font-family: ${fonts.fontBauMedium};
  font-size: 12px;
  line-height: 17.5px;
  cursor: pointer;
  z-index: 1;
`;

export const CloseIconAndStringWrapper = styled.div`
  display: flex;
`;

export const CloseIcon = styled.img.attrs({ src: iconClose })`
  height: 14px;
  width: 14px;
  margin: auto 10px auto 0;
`;
