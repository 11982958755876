import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useFlag } from "@unleash/proxy-client-react";

import {
  I18N_NAMESPACES,
  UNLEASH_BEER_SUBSCRIPTION,
  UNLEASH_NEW_PRODUCT_CATEGORIES,
  WINE_CLASS_IDS,
} from "../../../helpers/constants";
import {
  NAVBAR_DESKTOP_WINES_COLOR_ITEMS,
  NAVBAR_DESKTOP_WINES_EXPLORE_ITEMS,
  NAVBAR_DESKTOP_WINES_PRICE_ITEMS,
} from "../../../helpers/navbarProcessor";
import urlPatterns from "../../../urls";
import { useHeaderContext } from "../../organisms/Header/context";
import {
  DropdownContentWrapper,
  SectionColumn,
  ColumnTitle,
  ItemWrapperLink,
  ItemName,
  ColorCircle,
  Cross,
} from "../../organisms/Header/style";
import { useAppContext } from "../../organisms/Layout/context";
import { MemberLayoutInfo_collections } from "../../../types/__generated__/MemberLayoutInfo";
import {
  ExploreWinesIcon,
  WineColourIcon,
  WinesDropdownImage,
  WinesDropdownWrapper,
  WinePriceIcon,
  ProductsIcon,
} from "./style";

interface NavbarItemProps {
  i18nKey: string;
  url: string;
  color?: string;
  state?: StateProps;
}

interface StateProps {
  selectedFilters?: {
    winePriceRangeId: number;
  };
  clearFilters?: boolean;
}

interface DesktopWinesDropdownProps {
  onCrossClicked: () => void;
  isAnyDropdownActive: boolean;
}

const DesktopWinesDropdown = ({ onCrossClicked, isAnyDropdownActive }: DesktopWinesDropdownProps) => {
  const { t } = useTranslation([I18N_NAMESPACES.COMMON, I18N_NAMESPACES.TOP_NAVBAR]);
  const isNewProductCategoriesEnabled = useFlag(UNLEASH_NEW_PRODUCT_CATEGORIES);
  const isBeerSubscriptionEnabled = useFlag(UNLEASH_BEER_SUBSCRIPTION);

  const { collections } = useAppContext();
  const yPaddingInPx = 104;
  const menuItemHeightInPx = 46;
  const titleHeightInPxIncludingMargin = 44;

  const collectionsLength = collections?.length || 0;

  const menuItemCount = isBeerSubscriptionEnabled ? collectionsLength + 1 : collectionsLength;

  // Specify the minimum height of the dropdown to prevent it becoming too small in case there
  // are only a few items in the collections.
  const minDropdownHeight = 380;
  let dropdownHeight = yPaddingInPx + titleHeightInPxIncludingMargin + menuItemHeightInPx * menuItemCount;
  dropdownHeight = dropdownHeight < minDropdownHeight ? minDropdownHeight : dropdownHeight;

  const { setActiveDesktopMenuItem } = useHeaderContext();

  const renderNavbarItem = ({ i18nKey, url, color, state }: NavbarItemProps): ReactElement => (
    <ItemWrapperLink
      to={{
        pathname: url,
        state: state ?? null, // Used by "All Wines" and "New Releases" links to clear filters
      }}
      onClick={() => setActiveDesktopMenuItem("")}
      key={i18nKey}
    >
      {color && <ColorCircle color={color} />}
      <ItemName>{t(i18nKey)}</ItemName>
    </ItemWrapperLink>
  );

  const renderExploreWinesSection = (): ReactElement => (
    <>
      <ExploreWinesIcon />
      <SectionColumn>
        <ColumnTitle>{t("topNavbar:exploreWines")}</ColumnTitle>
        {NAVBAR_DESKTOP_WINES_EXPLORE_ITEMS.map((item) => renderNavbarItem(item))}
      </SectionColumn>
    </>
  );

  const renderWinesColorSection = (): ReactElement => (
    <>
      <WineColourIcon />
      <SectionColumn>
        <ColumnTitle>{t("common:colour")}</ColumnTitle>
        {NAVBAR_DESKTOP_WINES_COLOR_ITEMS.map((item) => {
          const sake_url = urlPatterns.WINE_LIST_CLASS(`${WINE_CLASS_IDS.DB_ID_WINE_CLASS_SAKE}`);
          const shouldNotShowSake = isNewProductCategoriesEnabled && item.url === sake_url;
          if (shouldNotShowSake) {
            return null;
          }

          return renderNavbarItem(item);
        })}
      </SectionColumn>
    </>
  );

  const renderWinesPriceSection = (): ReactElement => (
    <>
      <WinePriceIcon />
      <SectionColumn>
        <ColumnTitle>{t("common:winePrice")}</ColumnTitle>
        {NAVBAR_DESKTOP_WINES_PRICE_ITEMS.map((item) => renderNavbarItem(item))}
      </SectionColumn>
    </>
  );

  const renderNotWinesSection = (): ReactElement => (
    <>
      <ProductsIcon />
      <SectionColumn>
        <ColumnTitle>{t("common:products")}</ColumnTitle>
        {isBeerSubscriptionEnabled &&
          renderNavbarItem({
            url: urlPatterns.BEER_LANDING,
            i18nKey: "common:beerClub",
          })}
        {renderNavbarItem({ url: urlPatterns.ALL_PRODUCT_COLLECTIONS, i18nKey: "common:allProducts" })}
        {collections?.map((collection: MemberLayoutInfo_collections | null) => {
          const url = urlPatterns.PRODUCT_COLLECTION(collection?.slug);

          const navbarItem: NavbarItemProps = {
            i18nKey: collection?.name || "",
            url,
          };
          return renderNavbarItem(navbarItem);
        })}
      </SectionColumn>
    </>
  );

  return (
    <WinesDropdownWrapper isAnyDropdownActive={isAnyDropdownActive}>
      <WinesDropdownImage dropdownHeight={dropdownHeight} />
      <DropdownContentWrapper>
        {renderExploreWinesSection()}
        {renderWinesColorSection()}
        {renderWinesPriceSection()}
        {isNewProductCategoriesEnabled && renderNotWinesSection()}
        <Cross onClick={() => onCrossClicked()} />
      </DropdownContentWrapper>
    </WinesDropdownWrapper>
  );
};

export default DesktopWinesDropdown;
