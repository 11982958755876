import React from "react";
import styled from "styled-components";

import PropTypes from "prop-types";
import starFill from "../../../assets/images/icons/start-fill.svg";
import starEmpty from "../../../assets/images/icons/star-empty.svg";

const FeedbackRatingItem = styled.span`
  display: inline-block;
  margin: 0px 2px;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
`;

const FeedbackRatingItemFill = styled(FeedbackRatingItem)`
  background-image: url(${starFill});
`;

const FeedbackRatingItemEmpty = styled(FeedbackRatingItem)`
  background-image: url(${starEmpty});
`;

const FeedbackRating = (props) => {
  const { center, rating } = props;
  const FeedbackRatingList = styled.div`
    width: 100%;
    text-align: ${center && "center"};
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  `;
  if (rating === 5.5) {
    return (
      <FeedbackRatingList>
        <FeedbackRatingItemFill />
        <FeedbackRatingItemFill />
        <FeedbackRatingItemFill />
        <FeedbackRatingItemFill />
        <FeedbackRatingItemFill />
      </FeedbackRatingList>
    );
  }
  if (rating === 4.5) {
    return (
      <FeedbackRatingList>
        <FeedbackRatingItemFill />
        <FeedbackRatingItemFill />
        <FeedbackRatingItemFill />
        <FeedbackRatingItemFill />
        <FeedbackRatingItemEmpty />
      </FeedbackRatingList>
    );
  }
  if (rating === 3.5) {
    return (
      <FeedbackRatingList>
        <FeedbackRatingItemFill />
        <FeedbackRatingItemFill />
        <FeedbackRatingItemFill />
        <FeedbackRatingItemEmpty />
        <FeedbackRatingItemEmpty />
      </FeedbackRatingList>
    );
  }
  if (rating === 2.5) {
    return (
      <FeedbackRatingList>
        <FeedbackRatingItemFill />
        <FeedbackRatingItemFill />
        <FeedbackRatingItemEmpty />
        <FeedbackRatingItemEmpty />
        <FeedbackRatingItemEmpty />
      </FeedbackRatingList>
    );
  }
  if (rating === 1.5) {
    return (
      <FeedbackRatingList>
        <FeedbackRatingItemFill />
        <FeedbackRatingItemEmpty />
        <FeedbackRatingItemEmpty />
        <FeedbackRatingItemEmpty />
        <FeedbackRatingItemEmpty />
      </FeedbackRatingList>
    );
  }
  if (rating === 0.5) {
    return (
      <FeedbackRatingList>
        <FeedbackRatingItemEmpty />
        <FeedbackRatingItemEmpty />
        <FeedbackRatingItemEmpty />
        <FeedbackRatingItemEmpty />
        <FeedbackRatingItemEmpty />
      </FeedbackRatingList>
    );
  }
  return (
    <FeedbackRatingList>
      <FeedbackRatingItemFill />
      <FeedbackRatingItemFill />
      <FeedbackRatingItemFill />
      <FeedbackRatingItemFill />
      <FeedbackRatingItemFill />
    </FeedbackRatingList>
  );
};

FeedbackRating.propTypes = {
  center: PropTypes.bool,
  rating: PropTypes.number,
};

FeedbackRating.defaultProps = {
  center: null,
  rating: null,
};

export default FeedbackRating;
