import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import urlPatterns from "../../../urls";

import logo from "../../../assets/images/elements/footerLogo.svg";

const StyledFooterLogo = styled(Link)`
  display: inline-block;
  width: 127px;
  margin-right: 80px;
  flex-shrink: 0;
`;

const StyledFooterLogoImage = styled.img`
  display: block;
  width: 127px;
  height: 140px;
`;

const FooterLogo = () => (
  <StyledFooterLogo to={urlPatterns.HOME}>
    <StyledFooterLogoImage src={logo} alt="footerLogo" />
  </StyledFooterLogo>
);

export default FooterLogo;
