import React from "react";

import notificationProcessor from "../../../helpers/notificationProcessor";
import NotificationItem from "../NotificationItem";
import { MemberRecentNotifications_me_recentNotifications as NotificationProps } from "../../../types/__generated__/MemberRecentNotifications";

const Notification = (props: NotificationProps) => {
  const { jsonData, activityType, isNotificationRead, createdDate } = props;

  const notificationObject = notificationProcessor(activityType?.id, JSON.parse(jsonData));

  if (!notificationObject) return <div />;

  return (
    <NotificationItem icon={notificationObject.icon} isRead={isNotificationRead} createdDate={createdDate}>
      {notificationObject.body}
    </NotificationItem>
  );
};

export default Notification;
