import { gql } from "@apollo/client";
import { COUNTRY_SPECIFIC_SETTINGS, PRODUCT_TYPE_IDS } from "../../helpers/constants";
import { getCountry, getCountryIdFromUrl } from "../../helpers/urls.helper";
import { MEMBER_CHECKOUT_INFOS } from "./member";

/**
 * QUERIES
 * */

/**
 *  MUTATIONS
 * */
export const RESET_SHOPPING_CART = gql`
  mutation ResetShoppingCart($input: ResetShoppingCartInput!) {
    resetShoppingCart(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

export const ADD_SHOPPING_CART_ITEM = gql`
  mutation AddShoppingCartItem($input: AddShoppingCartItemInput!) {
    addShoppingCartItem(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const ADD_SHOPPING_CART_ITEM_BULK = gql`
  mutation BulkAddShoppingCartItems($input: BulkAddShoppingCartItemsInput!) {
    bulkAddShoppingCartItems(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const DELETE_SHOPPING_CART_ITEM = gql`
  mutation DeleteShoppingCartItem($input: DeleteShoppingCartItemInput!) {
    deleteShoppingCartItem(input: $input) {
      isDeleted
      errors {
        field
        messages
      }
    }
  }
`;

export const DELETE_PRODUCT_TYPE_SHOPPING_CART_ITEMS = gql`
  mutation DeleteProductTypeShoppingCartItems($input: DeleteProductTypeShoppingCartItemsInput!) {
    deleteProductTypeShoppingCartItems(input: $input) {
      isDeleted
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_SHOPPING_CART_ITEM = gql`
  mutation UpdateShoppingCartItem($input: UpdateShoppingCartItemInput!) {
    updateShoppingCartItem(input: $input) {
      shoppingCart {
        id
        total
        totalShippingFee
        discount
        shoppingcartitemSet {
          id
          quantity
          product {
            id
            sellingPrice
          }
          extraInfo
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const CREATE_ORDERS_PENDING_AUTHORIZATION = gql`
  mutation CreateOrdersPendingAuthorization($input: CreateOrdersPendingAuthorizationInput!) {
    createOrdersPendingAuthorization(input: $input) {
      clientSecret
      errors {
        field
        messages
      }
      latestOrderId
    }
  }
`;

export const APPLY_REFERRAL_CODE = gql`
  mutation ApplyReferralCode($input: ApplyReferralCodeInput!) {
    applyReferralCode(input: $input) {
      isSuccessful
      discountApplied
      discountLimit
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_REFERRAL_CODE = gql`
  ${MEMBER_CHECKOUT_INFOS}
  mutation UpdateReferralCode($input: UpdateReferralCodeInput!) {
    updateReferralCode(input: $input) {
      me {
        ...MemberCheckoutInfos
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const CHECKOUT = gql`
  mutation Checkout($input: CheckoutInput!) {
    checkout(input: $input) {
      isSuccessful
      latestOrderId
      errors {
        field
        messages
      }
    }
  }
`;

export const TOGGLE_SHOPPING_CART_SUBSCRIPTION = gql`
  mutation ToggleShoppingCartSubscription($input: ToggleShoppingCartSubscriptionInput!) {
    toggleShoppingCartSubscription(input: $input) {
      shouldWinesBeAddedToSubscription
      errors {
        field
        messages
      }
    }
  }
`;

export const GENERATE_ONE_OFF_RECOMMENDATIONS = gql`
  mutation GenerateOneOffRecommendations($input: GenerateOneOffRecommendationsInput!) {
    generateOneOffRecommendations(input: $input) {
      isSuccessful
      newShoppingCartItem {
        product {
          wine {
            id
            wineType {
              id
              wineClass {
                id
              }
            }
            winePriceRange {
              id
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

/**
 * Resolvers, Defaults & Type definitions
 * https://www.apollographql.com/docs/link/links/state
 * */

export const GET_LOCAL_SHOPPING_CART = gql`
  query LocalShoppingCart {
    localShoppingCart @client {
      countryId
      visible
      totalShippingFee
      totalProductsCost
      items {
        quantity
        product {
          id
          name
          sellingPrice
          coverPhotoSmall
          slug
          productType {
            id
          }
        }
        extraInfo {
          giftDeliveryId
          redBottles
          whiteBottles
          sparklingBottles
          roseBottles
          monthsPurchased
          bottlesPerBox
          spendPerBox
        }
      }
    }
  }
`;

export const ADD_ITEM_TO_LOCAL_SHOPPING_CART = gql`
  mutation AddItemToLocalShoppingCart($input: AddItemToLocalShoppingCartInput!) {
    addItemToLocalShoppingCart(input: $input) @client {
      localShoppingCart {
        items {
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export const ADD_ITEMS_TO_LOCAL_SHOPPING_CART = gql`
  mutation AddItemsToLocalShoppingCart($input: AddItemsToLocalShoppingCartInput!) {
    addItemsToLocalShoppingCart(input: $input) @client {
      localShoppingCart {
        items {
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export const DELETE_ITEM_IN_LOCAL_SHOPPING_CART = gql`
  mutation DeleteItemInLocalShoppingCart($input: DeleteItemInLocalShoppingCartInput!) {
    deleteItemInLocalShoppingCart(input: $input) @client {
      localShoppingCart {
        items {
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export const DELETE_ITEMS_BY_PRODUCT_TYPE_IN_LOCAL_SHOPPING_CART = gql`
  mutation DeleteItemsByProductTypeInLocalShoppingCart(
    $input: DeleteItemsByProductTypeInLocalShoppingCartInput!
  ) {
    deleteItemsByProductTypeInLocalShoppingCart(input: $input) @client {
      localShoppingCart {
        items {
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export const INCREASE_QUANTITY_FOR_ITEM_IN_LOCAL_SHOPPING_CART = gql`
  mutation IncreaseQuantityItemInLocalShoppingCart($input: IncreaseQuantityItemInLocalShoppingCartInput!) {
    increaseQuantityForItemInLocalShoppingCart(input: $input) @client {
      localShoppingCart {
        items {
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export const DECREASE_QUANTITY_FOR_ITEM_IN_LOCAL_SHOPPING_CART = gql`
  mutation DecreaseQuantityItemInLocalShoppingCart($input: DecreaseQuantityItemInLocalShoppingCartInput!) {
    decreaseQuantityForItemInLocalShoppingCart(input: $input) @client {
      localShoppingCart {
        items {
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export const HIDE_LOCAL_SHOPPING_CART = gql`
  mutation HideLocalShoppingCart {
    hideLocalShoppingCart @client {
      localShoppingCart {
        visible
        items {
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export const SHOW_LOCAL_SHOPPING_CART = gql`
  mutation ShowLocalShoppingCart {
    showLocalShoppingCart @client {
      localShoppingCart {
        visible
        items {
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export const RESET_LOCAL_SHOPPING_CART = gql`
  mutation ResetLocalShoppingCart {
    resetLocalShoppingCart @client {
      localShoppingCart {
        visible
        items {
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export const ONE_CLICK_TOP_UP = gql`
  mutation OneClickTopup($input: OneClickTopupInput!) {
    oneClickTopup(input: $input) {
      isSuccessful
      latestOrderId
      errors {
        field
        messages
      }
    }
  }
`;

export const CREATE_ONE_CLICK_TOP_UP_PAYMENT_INTENT = gql`
  mutation CreateOneClickTopupPaymentIntent($input: CreateOneClickTopupPaymentIntentInput!) {
    createOneClickTopupPaymentIntent(input: $input) {
      clientSecret
      errors {
        field
        messages
      }
      defaultPaymentMethodApiUuid
    }
  }
`;

export const REMOVE_SHOPPING_CART_DISCOUNT = gql`
  mutation RemoveShoppingCartDiscount($input: RemoveShoppingCartDiscountInput!) {
    removeShoppingCartDiscount(input: $input) {
      isDeleted
      errors {
        field
        messages
      }
      me {
        shoppingCart {
          discount
          discountCode
          discountType {
            id
            name
          }
        }
      }
    }
  }
`;

function findCartItem(items, productId) {
  return items.find((item) => item.product.id === productId);
}

function increaseQuantityInCartItem(item) {
  if (item) {
    return { ...item, quantity: item.quantity + 1 };
  }

  return undefined;
}

function decreaseQuantityInCartItem(item) {
  if (item && item.quantity > 0) {
    return { ...item, quantity: item.quantity - 1 };
  }

  return undefined;
}

function deleteItemFromCart(productId, items) {
  return items.filter((item) => item.product.id !== productId);
}

function deleteItemsByProductTypeFromCart(productTypeId, items) {
  return items.filter((item) => item.product.productType.id !== productTypeId);
}

function calculateTotalProductsCost(items) {
  return items.reduce(
    (currentTotalCost, item) => currentTotalCost + item.product.sellingPrice * item.quantity,
    0
  );
}

const country = getCountry();
const urlCountryId = getCountryIdFromUrl();
const { SHIPPING_FEE } = COUNTRY_SPECIFIC_SETTINGS[country];
const NO_SHIPPING_FEE = 0;
function calculateTotalShippingFee(items, minBottlesForFreeShipping) {
  const containsOneOffWine = items.find(
    (item) => item.product.productType.id === PRODUCT_TYPE_IDS.DB_ID_PRODUCT_TYPE_WINE
  );
  if (containsOneOffWine) {
    const numberOfBottles = items.reduce((acc, current) => acc + current.quantity, 0);

    return numberOfBottles < minBottlesForFreeShipping ? SHIPPING_FEE : NO_SHIPPING_FEE;
  }

  // for other products such as gifts, special packs, it should be free.
  return NO_SHIPPING_FEE;
}

/**
 * When saving a shopping cart item in the local state we need to provide __typename fields to let
 * apollo-client combine data.
 */
function addMissingTypeNames(shoppingCartItem) {
  // extraInfo is optional, in case it's not provided apollo-link-state expects null (not empty object)
  const extraInfo = shoppingCartItem.extraInfo
    ? {
        ...shoppingCartItem.extraInfo,
        __typename: "ExtraInfoInLocalShoppingCart",
      }
    : null;

  return {
    quantity: shoppingCartItem.quantity,
    product: {
      ...shoppingCartItem.product,
      productType: {
        ...shoppingCartItem.product.productType,
        __typename: "ProductTypeInLocalShoppingCart",
      },
      __typename: "ProductInLocalShoppingCart",
    },
    extraInfo,
    __typename: "CartItemInLocalShoppingCart",
  };
}

export const resolverLocalCart = {
  defaults: {
    localShoppingCart: {
      visible: false,
      totalProductsCost: 0,
      totalShippingFee: 0,
      items: [],
      countryId: urlCountryId,
      __typename: "LocalShoppingCart",
    },
  },
  resolvers: {
    Mutation: {
      addItemToLocalShoppingCart: async (obj, args, { cache }) => {
        const {
          input: { item, minBottlesForFreeShipping },
        } = args;
        const {
          localShoppingCart,
          localShoppingCart: { items },
        } = cache.readQuery({ query: GET_LOCAL_SHOPPING_CART });
        const shoppingItem = increaseQuantityInCartItem(findCartItem(items, item.product.id));

        const newCartItem = shoppingItem || addMissingTypeNames(item);
        let newItems = [...items];
        if (!shoppingItem) {
          newItems = [...items, newCartItem];
        }

        const newTotalCost = calculateTotalProductsCost(newItems);
        const newShippingFee = calculateTotalShippingFee(newItems, minBottlesForFreeShipping);
        const data = {
          __typename: "Store",
          localShoppingCart: {
            ...localShoppingCart,
            visible: true,
            items: newItems,
            totalProductsCost: newTotalCost,
            totalShippingFee: newShippingFee,
            __typename: "LocalShoppingCart",
          },
        };
        await cache.writeQuery({
          query: GET_LOCAL_SHOPPING_CART,
          data,
        });
        return data;
      },
      addItemsToLocalShoppingCart: async (obj, args, { cache }) => {
        let {
          input: { items },
        } = args;
        const {
          localShoppingCart,
          localShoppingCart: { items: localShoppingCartItems },
        } = cache.readQuery({ query: GET_LOCAL_SHOPPING_CART });
        items = items.map((item) => {
          const cartItem = findCartItem(localShoppingCartItems, item.product.id);
          if (cartItem) {
            cartItem.quantity += item.quantity;
            return cartItem;
          }
          return item;
        });
        items = [...items, ...localShoppingCartItems.filter((item) => !findCartItem(items, item.product.id))];
        items = items.map((item) => addMissingTypeNames(item));
        const newTotalCost = calculateTotalProductsCost(items);
        const newShippingFee = calculateTotalShippingFee(items, args.input.minBottlesForFreeShipping);
        const data = {
          __typename: "Store",
          localShoppingCart: {
            ...localShoppingCart,
            visible: true,
            items,
            totalProductsCost: newTotalCost,
            totalShippingFee: newShippingFee,
            __typename: "LocalShoppingCart",
          },
        };
        await cache.writeQuery({
          query: GET_LOCAL_SHOPPING_CART,
          data,
        });
        return data;
      },
      deleteItemInLocalShoppingCart: async (obj, args, { cache }) => {
        const {
          localShoppingCart,
          localShoppingCart: { items },
        } = cache.readQuery({ query: GET_LOCAL_SHOPPING_CART });
        const newItems = deleteItemFromCart(args.input.productId, items);
        const newTotalCost = calculateTotalProductsCost(newItems);
        const newShippingFee = calculateTotalShippingFee(newItems, args.input.minBottlesForFreeShipping);
        const data = {
          __typename: "Store",
          localShoppingCart: {
            ...localShoppingCart,
            items: newItems,
            totalProductsCost: newTotalCost,
            totalShippingFee: newShippingFee,
            __typename: "LocalShoppingCart",
          },
        };
        await cache.writeQuery({
          query: GET_LOCAL_SHOPPING_CART,
          data,
        });
        return data;
      },
      deleteItemsByProductTypeInLocalShoppingCart: async (obj, args, { cache }) => {
        const {
          localShoppingCart,
          localShoppingCart: { items },
        } = cache.readQuery({ query: GET_LOCAL_SHOPPING_CART });
        const newItems = deleteItemsByProductTypeFromCart(args.input.productTypeId, items);
        const newTotalCost = calculateTotalProductsCost(newItems);
        const newShippingFee = calculateTotalShippingFee(newItems, args.input.minBottlesForFreeShipping);
        const data = {
          __typename: "Store",
          localShoppingCart: {
            ...localShoppingCart,
            items: newItems,
            totalProductsCost: newTotalCost,
            totalShippingFee: newShippingFee,
            __typename: "LocalShoppingCart",
          },
        };
        await cache.writeQuery({
          query: GET_LOCAL_SHOPPING_CART,
          data,
        });
        return data;
      },
      increaseQuantityForItemInLocalShoppingCart: async (obj, args, { cache }) => {
        const {
          localShoppingCart,
          localShoppingCart: { items },
        } = cache.readQuery({ query: GET_LOCAL_SHOPPING_CART });

        const increasedItem = increaseQuantityInCartItem(findCartItem(items, args.input.productId));
        const newItems = [...items.filter((item) => item.product.id !== args.input.productId), increasedItem];
        const newTotalCost = calculateTotalProductsCost(newItems);
        const newShippingFee = calculateTotalShippingFee(newItems, args.input.minBottlesForFreeShipping);

        const data = {
          __typename: "Store",
          localShoppingCart: {
            ...localShoppingCart,
            items: newItems,
            totalProductsCost: newTotalCost,
            totalShippingFee: newShippingFee,
            __typename: "LocalShoppingCart",
          },
        };
        await cache.writeQuery({
          query: GET_LOCAL_SHOPPING_CART,
          data,
        });
        return data;
      },
      decreaseQuantityForItemInLocalShoppingCart: async (obj, args, { cache }) => {
        const {
          localShoppingCart,
          localShoppingCart: { items },
        } = cache.readQuery({ query: GET_LOCAL_SHOPPING_CART });
        const { minBottlesForFreeShipping } = args.input;
        const item = findCartItem(items, args.input.productId);
        const shouldRemoveItem = item.quantity === 1;
        let newItems = items;
        if (shouldRemoveItem) {
          newItems = deleteItemFromCart(args.input.productId, items);
        } else {
          newItems = [
            ...items.filter((localItem) => localItem.product.id !== args.input.productId),
            decreaseQuantityInCartItem(item),
          ];
        }
        const newTotalCost = calculateTotalProductsCost(newItems);
        const newShippingFee = calculateTotalShippingFee(newItems, minBottlesForFreeShipping);
        const data = {
          __typename: "Store",
          localShoppingCart: {
            ...localShoppingCart,
            items: newItems,
            totalProductsCost: newTotalCost,
            totalShippingFee: newShippingFee,
            __typename: "LocalShoppingCart",
          },
        };
        await cache.writeQuery({
          query: GET_LOCAL_SHOPPING_CART,
          data,
        });
        return data;
      },
      hideLocalShoppingCart: async (obj, args, { cache }) => {
        const { localShoppingCart } = cache.readQuery({
          query: GET_LOCAL_SHOPPING_CART,
        });
        const data = {
          __typename: "Store",
          localShoppingCart: {
            ...localShoppingCart,
            visible: false,
            __typename: "LocalShoppingCart",
          },
        };
        await cache.writeQuery({
          query: GET_LOCAL_SHOPPING_CART,
          data,
        });
        return data;
      },
      showLocalShoppingCart: async (obj, args, { cache }) => {
        const { localShoppingCart } = cache.readQuery({
          query: GET_LOCAL_SHOPPING_CART,
        });
        const data = {
          __typename: "Store",
          localShoppingCart: {
            ...localShoppingCart,
            visible: true,
            __typename: "LocalShoppingCart",
          },
        };
        await cache.writeQuery({
          query: GET_LOCAL_SHOPPING_CART,
          data,
        });
        return data;
      },
      resetLocalShoppingCart: async (obj, args, { cache }) => {
        const data = {
          __typename: "Store",
          localShoppingCart: {
            visible: false,
            items: [],
            totalProductsCost: 0,
            totalShippingFee: 0,
            countryId: urlCountryId,
            __typename: "LocalShoppingCart",
          },
        };
        await cache.writeQuery({
          query: GET_LOCAL_SHOPPING_CART,
          data,
        });
        return data;
      },
    },
  },
};
