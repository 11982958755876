import { TASTE_PROFILE_MATCH_MAKER_ID, TASTE_PROFILE_WINE_TYPE_RANKING_ID } from "./helpers/constants";
import { getCountry } from "./helpers/urls.helper";

/*
 * TODO: For any changes in routes, if any of them are defined in base admin,
 * make sure to update them as well.
 * https://base.goodpairdays.com/admin/front_end_route/
 */

const country = getCountry();
const baseRouteUrl = `/${country}`;

const URL_PATTERNS = {
  // Institutional Site URLs
  LANDING: "/",
  HOME: `${baseRouteUrl}/`,
  WINE_LANDING: `${baseRouteUrl}/wine/`,
  ABOUT: `${baseRouteUrl}/about/`,
  CONTACT: `${baseRouteUrl}/contact/`,
  TERMS: `${baseRouteUrl}/terms/`,
  PRIVACY: `${baseRouteUrl}/privacy/`,
  REVIEWS: `${baseRouteUrl}/reviews/`,
  FAQ: `${baseRouteUrl}/faq/`,
  FORCE_RELOAD: `${baseRouteUrl}/reload/`,
  CHRISTMAS_CUT_OFF_DATES: `${baseRouteUrl}/christmas-delivery-cutoff-dates/`,

  // Beer URLs
  BEER_LANDING: `${baseRouteUrl}/beer/`,
  BEER_SUBSCRIBE: `${baseRouteUrl}/beer/subscribe/`,

  // Rewards 3.0 URLS
  REWARDS_HOW_IT_WORKS: `${baseRouteUrl}/rewards/how-it-works/`,
  REWARDS_STORE: `${baseRouteUrl}/rewards/store/`,
  REWARD_MERCH_DETAILS_BY_PRODUCT_ID: (id = ":id") => `${baseRouteUrl}/rewards/details_by_id/${id}/`,
  REWARD_MERCH_DETAILS_BY_SLUG: (slug = ":slug") => `${baseRouteUrl}/rewards/details/${slug}/`,

  // Buying and Checkout URLs
  ALL_WINES: `${baseRouteUrl}/wine-lists/details/all-wines/`,
  WINES: `${baseRouteUrl}/wines/`,
  ALL_PRODUCT_COLLECTIONS: `${baseRouteUrl}/collections/`,
  PRODUCT_COLLECTION: (slug = ":slug") => `${baseRouteUrl}/collections/${slug}/`,
  PRODUCT_DETAILS_BY_SLUG: (slug = ":slug") => `${baseRouteUrl}/products/details/${slug}`,
  WINE_DETAILS: (slug = ":slug") => `${baseRouteUrl}/wines/details/${slug}/`,
  WINE_LIST: (wineListSlug = ":wineListSlug") => `${baseRouteUrl}/wine-lists/details/${wineListSlug}/`,
  WINE_LIST_CLASS: (wineClassId = ":wineClassId") => `${baseRouteUrl}/wine-lists/wine-class/${wineClassId}/`,
  WINE_LIST_TYPE: (wineTypeId = ":wineTypeId") => `${baseRouteUrl}/wine-lists/wine-type/${wineTypeId}/`,
  WINE_LIST_PRICE_RANGE: (winePriceRangeId = ":winePriceRangeId") =>
    `${baseRouteUrl}/wine-lists/wine-price-range/${winePriceRangeId}/`,
  WINE_DETAILS_BY_ID: (id = ":id") => `${baseRouteUrl}/wines/details_by_id/${id}/`,
  ADD_WINE_TO_BOX_BY_ID: (id = ":id") => `${baseRouteUrl}/wines/add-to-box/${id}/`,
  MY_ONE_OFF_RECOMMENDATIONS: `${baseRouteUrl}/my-one-off-recommendations/`,
  GIFT_LANDING: `${baseRouteUrl}/gifts/`,
  SEND_GIFT: `${baseRouteUrl}/gifts/send/`,
  REDEEM_GIFT: `${baseRouteUrl}/gifts/redeem/`,
  CORPORATE_GIFT: `${baseRouteUrl}/gifts/corporate/`,
  QUIZ: `${baseRouteUrl}/quiz/`,
  QUIZ_RESULTS: `${baseRouteUrl}/quiz/results/`,
  CHECKOUT: `${baseRouteUrl}/checkout/`,
  CHECKOUT_THANK_YOU: `${baseRouteUrl}/checkout-thank-you/`,
  THANK_YOU_PAGE_ONBOARDING: `${baseRouteUrl}/checkout-thank-you/onboarding/`,
  SPECIAL_PACKS: `${baseRouteUrl}/special-packs/`,
  SPECIAL_PACK_ADVENT_CALENDAR: `${baseRouteUrl}/special-packs/details/wine-advent-calendars/`,
  SPECIAL_PACK_DETAILS: (slug = ":slug") => `${baseRouteUrl}/special-packs/details/${slug}/`,
  REFERRAL: (referralCode = ":referralCode") => `${baseRouteUrl}/invite/${referralCode}/`,
  DISCOUNT_CODE: (promoCode = ":promoCode") => `${baseRouteUrl}/discount-code/${promoCode}/`,
  REACTIVATE: (memberWelcomePromoCode = ":memberWelcomePromoCode") =>
    `${baseRouteUrl}/reactivate/${memberWelcomePromoCode}/`,

  // Log-in and Sign-up URLs
  SET_NEW_PASSWORD: `${baseRouteUrl}/set-new-password/:uid/:token/`,
  SIGN_UP: `${baseRouteUrl}/signup/`,
  PASSWORD_RESET: `${baseRouteUrl}/password-reset/`,
  LOGIN: `${baseRouteUrl}/login/`,
  LOGOUT: `${baseRouteUrl}/logout/`,

  // Member's area URLs
  MY_DASHBOARD: `${baseRouteUrl}/my-dashboard/`,
  ONBOARDING: `${baseRouteUrl}/my-dashboard/onboarding/`,
  MY_NEXT_WINES: `${baseRouteUrl}/my-next-wines/`,
  MY_ACCOUNT: `${baseRouteUrl}/my-account/`,
  MY_ACCOUNT_TRAY: (openTray = ":openTray") => `${baseRouteUrl}/my-account/${openTray}/`,
  MY_SUBSCRIPTION: `${baseRouteUrl}/my-subscription/`,
  MY_CELLAR: `${baseRouteUrl}/my-cellar/`,
  MY_CELLAR_FILTER_BY_CLASS: (wineClassId = ":wineClassId") =>
    `${baseRouteUrl}/my-cellar/filter/${wineClassId}`,
  MY_CELLAR_BOOKMARKED: `${baseRouteUrl}/my-cellar/bookmarked/`,
  MY_CELLAR_DETAILS: (slug = ":slug") => `${baseRouteUrl}/my-cellar/details/${slug}/`,

  MY_CELLAR_WITH_DETAILS_TRAY: (slug = ":slug") => `${baseRouteUrl}/my-cellar/details-tray/${slug}/`,
  MY_CELLAR_WITH_DETAILS_TRAY_BY_ID: (wine_id = ":wine_id") =>
    `${baseRouteUrl}/my-cellar/details-tray-by-id/${wine_id}/`,
  MY_ORDERS: `${baseRouteUrl}/my-orders/`,
  MY_ORDERS_ONE_OFFS: `${baseRouteUrl}/my-orders/one-offs/`,
  MY_ORDERS_GIFTS: `${baseRouteUrl}/my-orders/gifts/`,
  MY_ORDERS_PENDING_PAYMENT: `${baseRouteUrl}/my-orders/pending-payment/`,
  TASTE_CHALLENGE: (slug = ":slug") => `${baseRouteUrl}/taste-challenge/${slug}/`,
  MY_BADGES: `${baseRouteUrl}/my-badges/`,
  BADGE_DETAILS: (slug = ":slug") => `${baseRouteUrl}/my-badges/${slug}`,
  CHALLENGE_DETAILS: (slug = ":slug") => `${baseRouteUrl}/my-badges/challenge-details/${slug}`,
  MY_PROGRESS: `${baseRouteUrl}/my-progress/`,
  INVITE_FRIEND: `${baseRouteUrl}/my-dashboard/invite/`,
  UPDATE_QUIZ: (retakeQuizVersion = ":retakeQuizVersion") => `${baseRouteUrl}/quiz/${retakeQuizVersion}/`,
  SELECT_WELCOME_PACK: `${baseRouteUrl}/select-welcome-pack/`,
  SELECT_FREE_WINE_COLOR: (credit_id = ":credit_id") => `${baseRouteUrl}/set-credit-wine-color/${credit_id}`,
  MY_TASTE_PROFILE: `${baseRouteUrl}/my-taste-profile/`,
  MY_TASTE_PROFILE_MATCH_MAKER: `${baseRouteUrl}/my-taste-profile/#${TASTE_PROFILE_MATCH_MAKER_ID}`,
  // We need to keep the MY_TASTE_PROFILE_WINE_TYPE_RANKING URL on a single line,
  // otherwise it adds %20 to the URL for each space in the intendation.
  // eslint-disable-next-line max-len
  MY_TASTE_PROFILE_WINE_TYPE_RANKING: `${baseRouteUrl}/my-taste-profile/#${TASTE_PROFILE_WINE_TYPE_RANKING_ID}`,

  // Guide URLS
  GUIDE_101: "/guides/wine-101/",
  GUIDE_GRAPE_COLOR: "/guides/wine-grapes/",
  GUIDE_REGION: "/guides/wine-regions/",
  GUIDE_TASTING: "/guides/wine-tasting/",
  GUIDE_MORE: "/guides/food-and-wine/",
  GUIDE_101_GLOSSARY: (slug = ":slug") => `/guides/wine-101/${slug}/`,
  GUIDE_101_POST: (slug = ":slug") => `/guides/wine-101/article/${slug}/`,
  GUIDE_GRAPE_COLOR_GLOSSARY: (slug = ":slug") => `/guides/wine-grapes/${slug}/`,
  GUIDE_GRAPE_COLOR_POST: (slug = ":slug") => `/guides/wine-grapes/article/${slug}/`,
  GUIDE_REGION_GLOSSARY: (slug = ":slug") => `/guides/wine-regions/${slug}/`,
  GUIDE_REGION_POST: (slug = ":slug") => `/guides/wine-regions/article/${slug}/`,
  GUIDE_TASTING_GLOSSARY: (slug = ":slug") => `/guides/wine-tasting/${slug}/`,
  GUIDE_TASTING_POST: (slug = ":slug") => `/guides/wine-tasting/article/${slug}/`,
  GUIDE_MORE_GLOSSARY: (slug = ":slug") => `/guides/food-and-wine/${slug}/`,
  GUIDE_MORE_POST: (slug = ":slug") => `/guides/food-and-wine/article/${slug}/`,
  GUIDE_SEARCH_ARTICLES: "/guides/search/",

  NOT_FOUND: "/404/",

  // TODO (Pending integration with new design)
  /*
  FREE_BOX_CAMPAIGN: (seoCampaignName = ':seoCampaignName?') =>baseRouteUrl + `/free-box/${seoCampaignName}`,
  */
};

export default URL_PATTERNS;
