import styled from "styled-components";
import { Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import { colors, fonts } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";

export const FooterNavbarContainer = styled.div`
  width: 90%;
  margin-bottom: 24px;
  ${breakpoints.mdDown} {
    width: 100%;
  }
`;

export const ColumnContainer = styled(Col)`
  text-align: left;
  ${breakpoints.smDown} {
    margin-bottom: 8px;
  }
`;

export const ColumnTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
  font-family: ${fonts.fontInterstateUltraBlack};
  text-transform: uppercase;
  ${breakpoints.smDown} {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1.4px;
  }
`;

const ColumnLinkStyle = `
  display: block;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 16px;
  font-family: ${fonts.fontBauRegular};
  text-transform: capitalize;
  &:hover {
    color: ${colors.white};
  }
  ${breakpoints.smDown} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ColumnLink = styled(Link)`
  ${ColumnLinkStyle}
`;

export const ColumnLinkExternal = styled.a`
  ${ColumnLinkStyle}
`;
