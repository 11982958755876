import React from "react";
import JsonLdGeneric from "../JsonLdGeneric";

/**
 * Returns a component that adds a JSON-LD tag of type LiquorStore.
 * @returns {Component}
 * */
const JsonLdLiquorStore = () => {
  const structuredLiquorStoreJsonLdData = {
    "@context": "https://schema.org/",
    "@type": "LiquorStore",
    "@id": "https://goodpairdays.com/",
    name: "Good Pair Days",
    url: "https://goodpairdays.com/",
    image: ["https://goodpairdays.com/social-media-preview.jpg"],
  };

  return <JsonLdGeneric jsonData={structuredLiquorStoreJsonLdData} />;
};

export default JsonLdLiquorStore;
