import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import iconClose from "../../../assets/images/icons/cross.svg";
import walkingImage from "../../../assets/images/general/GPD_walking.png";
import walkingImageMobile from "../../../assets/images/general/GPD_walking_mobile.png";
import { colors, fonts, transition } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";
import { I18N_NAMESPACES } from "../../../helpers/constants";
import {
  getNewCountryUrl,
  getCountry,
  AU_COUNTRY_CODE,
  UK_COUNTRY_CODE,
  USER_DOES_NOT_WANT_TO_CHANGE_COUNTRY,
} from "../../../helpers/urls.helper";

import Button from "../../atoms/Button";

const StyledButton = styled(Button)`
  flex-grow: ${(props) => props.languageButton && "1"};
  flex-basis: ${(props) => props.languageButton && "0"};
  margin: ${(props) => props.languageButton && "0px 5px"};
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const FullScreenMask = styled.div`
  cursor: pointer;
  z-index: 7500;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: 0.5;
`;

const ModalCoreDiv = styled.div`
  padding: 0 !important;
  background-color: ${colors.peach};
  transition: ${transition.baseTransition};
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 7501;
  min-height: 150px;
  width: 472px;
  padding: 10px;
  ${breakpoints.smDown} {
    width: 335px;
    max-height: calc(100% - 10px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
`;

const AnnouncementWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 29.37px;
  padding: 0 28px 0 30px;
  ${breakpoints.smDown} {
    padding: 0 20px;
    margin-top: 20.5px;
  }
`;

const CloseAnnouncementWrapper = styled.header`
  width: 100%;
  margin-bottom: 10.37px;
  ${breakpoints.smDown} {
    margin-bottom: 9.5px;
  }
`;

const CloseAnnouncement = styled.button`
  border: none !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  width: 20px;
  height: 20px;
  background: url(${iconClose}) no-repeat;
  background-size: 11.5px 11.5px;
  ${breakpoints.smDown} {
    background-size: 15px 15px;
  }
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p {
    font-family: ${fonts.fontBauRegular};
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 25px;
    ${breakpoints.smDown} {
      margin-bottom: 20px;
      text-align: center;
    }
  }
  a,
  div {
    width: 100%;
    margin-bottom: 25px;
    ${breakpoints.smDown} {
      margin-bottom: 30px;
    }
  }
`;

const AnnouncementImage = styled.img`
  margin-bottom: 20px;
  max-width: 414px;
  ${breakpoints.smDown} {
    display: none;
  }
`;

const AnnouncementImageMobile = styled.img`
  margin-bottom: 14px;
  max-width: 295px;
  ${breakpoints.smUp} {
    display: none;
  }
`;

const ChangeLanguagePopup = ({ closePopupHandler }) => {
  const history = useHistory();
  const { t } = useTranslation(I18N_NAMESPACES.COMMON);

  const bodySelector = document.querySelector("body");
  const scrollPosition = useRef(0);

  const enableScrollLock = () => {
    scrollPosition.current = window.pageYOffset;
    bodySelector.style.overflow = "hidden";
    bodySelector.style.position = "fixed";
    bodySelector.style.top = `-${scrollPosition.current}px`;
    bodySelector.style.width = "100%";
  };

  const disableScrollLock = () => {
    bodySelector.style.removeProperty("overflow");
    bodySelector.style.removeProperty("position");
    bodySelector.style.removeProperty("top");
    bodySelector.style.removeProperty("width");
    window.scrollTo(0, scrollPosition.current);
  };

  const handleCloseAnnouncement = () => {
    disableScrollLock();
    window.localStorage.setItem(USER_DOES_NOT_WANT_TO_CHANGE_COUNTRY, true);
    closePopupHandler();
  };

  const handleChangeLanguage = () => {
    const countryCode = getCountry();
    const newCountry = countryCode === AU_COUNTRY_CODE ? UK_COUNTRY_CODE : AU_COUNTRY_CODE;
    const newUrl = getNewCountryUrl(newCountry);
    history.push(`/${newUrl}`);
    window.location.reload();
  };

  useEffect(() => {
    enableScrollLock();
    return () => {
      disableScrollLock();
    };
  }, []);

  if (window.localStorage.getItem(USER_DOES_NOT_WANT_TO_CHANGE_COUNTRY)) {
    disableScrollLock();
    return <></>;
  }

  return (
    <>
      <FullScreenMask onClick={handleCloseAnnouncement} />
      <ModalCoreDiv>
        <AnnouncementWrapper id="changeLanguagePopup">
          <CloseAnnouncementWrapper>
            <CloseAnnouncement onClick={handleCloseAnnouncement} />
          </CloseAnnouncementWrapper>
          <PopupContent>
            <AnnouncementImage src={walkingImage} alt="Walking with Good Pair Days Box" />
            <AnnouncementImageMobile src={walkingImageMobile} alt="Walking with Good Pair Days Box" />
            <p>{t("common:changeLanguage.message")}</p>
            <ButtonsWrapper>
              <StyledButton lg languageButton onClick={handleChangeLanguage}>
                {t("common:changeLanguage.button")}
              </StyledButton>
              <StyledButton lg ghost languageButton onClick={handleCloseAnnouncement}>
                {t("common:continue")}
              </StyledButton>
            </ButtonsWrapper>
          </PopupContent>
        </AnnouncementWrapper>
      </ModalCoreDiv>
    </>
  );
};

ChangeLanguagePopup.propTypes = {
  closePopupHandler: PropTypes.func.isRequired,
};

export default ChangeLanguagePopup;
