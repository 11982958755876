import urlPatterns from "../urls";
import dashboard from "../assets/images/icons/dashboard.svg";
import book from "../assets/images/icons/book.svg";
import grapes from "../assets/images/icons/grapes.svg";
import globe from "../assets/images/icons/globe.svg";
import tongue from "../assets/images/icons/tongue.svg";
import recipes from "../assets/images/icons/recipes.svg";
import blog from "../assets/images/icons/blog.svg";
import whoWeAre from "../assets/images/icons/who-we-are.svg";
import howItWorks from "../assets/images/icons/how-it-works.svg";
import contactUs from "../assets/images/icons/contact-us.svg";
import faqs from "../assets/images/icons/faqs.svg";
import giveGift from "../assets/images/icons/give-gift.svg";
import corporateGift from "../assets/images/icons/corporate-gift.svg";
import redeemGift from "../assets/images/icons/redeem-gift.svg";
import reviews from "../assets/images/icons/reviews.svg";
import settings from "../assets/images/icons/settings-color.svg";
import lips from "../assets/images/icons/lips.svg";
import bookmark from "../assets/images/icons/bookmark.svg";
import pastWines from "../assets/images/icons/past-wines.svg";
import myOrders from "../assets/images/icons/my-orders.svg";
import myRewards from "../assets/images/icons/my-rewards.svg";
import rewardStore from "../assets/images/icons/reward-store.svg";
import earnPoints from "../assets/images/icons/earn-points.svg";
import myProgress from "../assets/images/icons/my-progress.svg";
import inviteFriends from "../assets/images/icons/invite-friends.svg";
import howRewardsWork from "../assets/images/icons/how-rewards-work.svg";
import box from "../assets/images/icons/box.svg";

import { colors } from "../styles/variables";
import {
  WINE_LIST_SLUGS,
  WINE_CLASS_IDS,
  WINE_TYPE_IDS,
  SETTINGS_TRAY,
  WINE_PRICE_RANGE_IDS,
} from "./constants";
import externalUrls, { BLOG_URLS } from "./externalUrls";

export const NAVBAR_MOBILE_WINES_ITEMS = [
  {
    i18nKey: "topNavbar:allWines",
    url: urlPatterns.ALL_WINES,
  },
  {
    i18nKey: "topNavbar:newReleases",
    url: urlPatterns.WINE_LIST(WINE_LIST_SLUGS.NEW_RELEASES),
  },
  {
    i18nKey: "topNavbar:red",
    url: urlPatterns.WINE_LIST_CLASS(`${WINE_CLASS_IDS.DB_ID_WINE_CLASS_RED}`),
  },
  {
    i18nKey: "topNavbar:white",
    url: urlPatterns.WINE_LIST_CLASS(`${WINE_CLASS_IDS.DB_ID_WINE_CLASS_WHITE}`),
  },
  {
    i18nKey: "topNavbar:sparkling",
    url: urlPatterns.WINE_LIST_CLASS(`${WINE_CLASS_IDS.DB_ID_WINE_CLASS_SPARKLING}`),
  },
  {
    i18nKey: "topNavbar:rose",
    url: urlPatterns.WINE_LIST_CLASS(`${WINE_CLASS_IDS.DB_ID_WINE_CLASS_ROSE}`),
  },
  {
    i18nKey: "topNavbar:orange",
    url: urlPatterns.WINE_LIST_TYPE(`${WINE_TYPE_IDS.DB_ID_WINE_TYPE_ORANGE_WINE}`),
  },
  {
    i18nKey: "topNavbar:dessert",
    url: urlPatterns.WINE_LIST_CLASS(`${WINE_CLASS_IDS.DB_ID_WINE_CLASS_SPECIAL}`),
  },
  {
    i18nKey: "topNavbar:sake",
    url: urlPatterns.WINE_LIST_CLASS(`${WINE_CLASS_IDS.DB_ID_WINE_CLASS_SAKE}`),
  },
  {
    i18nKey: "topNavbar:collections",
    url: urlPatterns.WINES,
  },
  {
    i18nKey: "topNavbar:specialPacks",
    url: urlPatterns.SPECIAL_PACKS,
  },
];

export const NAVBAR_DESKTOP_WINES_EXPLORE_ITEMS = [
  {
    i18nKey: "topNavbar:allWines",
    url: urlPatterns.ALL_WINES,
    state: {
      clearFilters: true,
    },
  },
  {
    i18nKey: "topNavbar:newReleases",
    url: urlPatterns.WINE_LIST(WINE_LIST_SLUGS.NEW_RELEASES),
    state: {
      clearFilters: true,
    },
  },
  {
    i18nKey: "topNavbar:collections",
    url: urlPatterns.WINES,
  },
  {
    i18nKey: "topNavbar:specialPacks",
    url: urlPatterns.SPECIAL_PACKS,
  },
];

export const NAVBAR_DESKTOP_WINES_COLOR_ITEMS = [
  {
    i18nKey: "topNavbar:red",
    url: urlPatterns.WINE_LIST_CLASS(`${WINE_CLASS_IDS.DB_ID_WINE_CLASS_RED}`),
    color: colors.purple,
  },
  {
    i18nKey: "topNavbar:white",
    url: urlPatterns.WINE_LIST_CLASS(`${WINE_CLASS_IDS.DB_ID_WINE_CLASS_WHITE}`),
    color: colors.warning,
  },
  {
    i18nKey: "topNavbar:sparkling",
    url: urlPatterns.WINE_LIST_CLASS(`${WINE_CLASS_IDS.DB_ID_WINE_CLASS_SPARKLING}`),
    color: colors.blue,
  },
  {
    i18nKey: "topNavbar:rose",
    url: urlPatterns.WINE_LIST_CLASS(`${WINE_CLASS_IDS.DB_ID_WINE_CLASS_ROSE}`),
    color: colors.pinkSalmon,
  },
  {
    i18nKey: "topNavbar:orange",
    url: urlPatterns.WINE_LIST_TYPE(`${WINE_TYPE_IDS.DB_ID_WINE_TYPE_ORANGE_WINE}`),
    color: colors.orangeWine,
  },
  {
    i18nKey: "topNavbar:dessert",
    url: urlPatterns.WINE_LIST_CLASS(`${WINE_CLASS_IDS.DB_ID_WINE_CLASS_SPECIAL}`),
    color: colors.dessert,
  },
  {
    i18nKey: "topNavbar:sake",
    url: urlPatterns.WINE_LIST_CLASS(`${WINE_CLASS_IDS.DB_ID_WINE_CLASS_SAKE}`),
    color: colors.successThin,
  },
];

export const NAVBAR_DESKTOP_WINES_PRICE_ITEMS = [
  {
    i18nKey: "common:winePriceRange_1",
    url: urlPatterns.WINE_LIST_PRICE_RANGE(`${WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_1}`),
  },
  {
    i18nKey: "common:winePriceRange_2",
    url: urlPatterns.WINE_LIST_PRICE_RANGE(`${WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_2}`),
  },
  {
    i18nKey: "common:winePriceRange_3",
    url: urlPatterns.WINE_LIST_PRICE_RANGE(`${WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_3}`),
  },
  {
    i18nKey: "common:winePriceRange_4",
    url: urlPatterns.WINE_LIST_PRICE_RANGE(`${WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_4}`),
  },
  {
    i18nKey: "common:winePriceRange_5",
    url: urlPatterns.WINE_LIST_PRICE_RANGE(`${WINE_PRICE_RANGE_IDS.DB_ID_WINE_PRICE_RANGE_5}`),
  },
];

export const NAVBAR_MOBILE_LEARN_ITEMS = [
  {
    i18nKey: "topNavbar:Wine101",
    url: urlPatterns.GUIDE_101,
    icon: book,
  },
  {
    i18nKey: "topNavbar:grapeVarieties",
    url: urlPatterns.GUIDE_GRAPE_COLOR,
    icon: grapes,
  },
  {
    i18nKey: "topNavbar:regionGuides",
    url: urlPatterns.GUIDE_REGION,
    icon: globe,
  },
  {
    i18nKey: "topNavbar:tastingGuides",
    url: urlPatterns.GUIDE_TASTING,
    icon: tongue,
  },
  {
    i18nKey: "topNavbar:foodWine",
    url: urlPatterns.GUIDE_MORE,
    icon: recipes,
  },
  {
    i18nKey: "topNavbar:blog",
    url: externalUrls.BLOG,
    icon: blog,
  },
  {
    i18nKey: "topNavbar:myTaste",
    url: urlPatterns.MY_TASTE_PROFILE,
    icon: lips,
  },
];

export const NAVBAR_DESKTOP_LEARN_ABOUT_ITEMS = [
  {
    i18nKey: "topNavbar:who",
    url: urlPatterns.ABOUT,
  },
  {
    i18nKey: "topNavbar:reviews",
    url: urlPatterns.REVIEWS,
  },
  {
    i18nKey: "topNavbar:faqs",
    url: externalUrls.FAQ,
    isExternalUrl: true,
  },
];

export const NAVBAR_MOBILE_REWARDS_ITEMS = [
  {
    i18nKey: "topNavbar:howRewardsWork",
    url: urlPatterns.REWARDS_HOW_IT_WORKS,
    icon: howRewardsWork,
  },
  {
    i18nKey: "topNavbar:rewardStore",
    url: urlPatterns.REWARDS_STORE,
    icon: rewardStore,
  },
];

export const NAVBAR_MOBILE_REWARDS_ITEMS_LOGGED_IN = [
  {
    i18nKey: "topNavbar:howRewardsWork",
    url: urlPatterns.REWARDS_HOW_IT_WORKS,
    icon: howRewardsWork,
  },
  {
    i18nKey: "topNavbar:myProgress",
    url: urlPatterns.MY_PROGRESS,
    icon: myProgress,
  },
  {
    i18nKey: "topNavbar:rewardStore",
    url: urlPatterns.REWARDS_STORE,
    icon: rewardStore,
  },
  {
    i18nKey: "topNavbar:earnPoints",
    url: urlPatterns.MY_BADGES,
    icon: earnPoints,
  },
];

export const NAVBAR_DESKTOP_LEARN_ABOUT_ITEMS_FOR_UNSUBSCRIBED_MEMBERS = [
  {
    i18nKey: "common:howItWorks",
    url: urlPatterns.LANDING,
  },
  ...NAVBAR_DESKTOP_LEARN_ABOUT_ITEMS,
];

export const NAVBAR_DESKTOP_LEARN_GUIDES_ITEMS = [
  {
    i18nKey: "topNavbar:Wine101",
    url: urlPatterns.GUIDE_101,
  },
  {
    i18nKey: "topNavbar:grapeVarieties",
    url: urlPatterns.GUIDE_GRAPE_COLOR,
  },
  {
    i18nKey: "topNavbar:regionGuides",
    url: urlPatterns.GUIDE_REGION,
  },
  {
    i18nKey: "topNavbar:tastingGuides",
    url: urlPatterns.GUIDE_TASTING,
  },
  {
    i18nKey: "topNavbar:foodWine",
    url: urlPatterns.GUIDE_MORE,
  },
];

export const NAVBAR_DESKTOP_LEARN_BLOG_ITEMS = [
  {
    i18nKey: "topNavbar:allPosts",
    url: externalUrls.BLOG,
    isExternalUrl: true,
  },
  {
    i18nKey: "topNavbar:allRecipes",
    url: BLOG_URLS.RECIPES_AND_PAIRINGS,
    isExternalUrl: true,
  },
  {
    i18nKey: "topNavbar:stories",
    url: BLOG_URLS.STORIES_AND_LIFESTYLE,
    isExternalUrl: true,
  },
  {
    i18nKey: "topNavbar:wineTips",
    url: BLOG_URLS.WINE_TIPS,
    isExternalUrl: true,
  },
];

export const NAVBAR_DESKTOP_LEARN_TASTE_PROFILE_ITEMS = [
  {
    i18nKey: "topNavbar:seeTasteProfile",
    url: urlPatterns.MY_TASTE_PROFILE,
  },
  {
    i18nKey: "topNavbar:seeMatchMaker",
    url: urlPatterns.MY_TASTE_PROFILE_MATCH_MAKER,
  },
  {
    i18nKey: "topNavbar:seeWineTypeRanking",
    url: urlPatterns.MY_TASTE_PROFILE_WINE_TYPE_RANKING,
  },
  {
    i18nKey: "topNavbar:retakeQuiz",
    url: urlPatterns.QUIZ,
  },
];

export const NAVBAR_GIFT_ITEMS = [
  {
    i18nKey: "topNavbar:giveGift",
    url: urlPatterns.GIFT_LANDING,
    icon: giveGift,
  },
  {
    i18nKey: "topNavbar:redeemGift",
    url: urlPatterns.REDEEM_GIFT,
    icon: redeemGift,
  },
  {
    i18nKey: "topNavbar:corporateGifts",
    url: urlPatterns.CORPORATE_GIFT,
    icon: corporateGift,
  },
];

export const NAVBAR_REWARD_ITEMS = [
  {
    i18nKey: "topNavbar:howRewardsWork",
    url: urlPatterns.REWARDS_HOW_IT_WORKS,
  },
  {
    i18nKey: "topNavbar:rewardStore",
    url: urlPatterns.REWARDS_STORE,
  },
];

export const NAVBAR_REWARD_LOGGED_IN_ITEMS = [
  ...NAVBAR_REWARD_ITEMS,
  {
    i18nKey: "topNavbar:myProgress",
    url: urlPatterns.MY_PROGRESS,
  },
  {
    i18nKey: "topNavbar:earnPoints",
    url: urlPatterns.MY_BADGES,
    icon: earnPoints,
  },
];

export const NAVBAR_MOBILE_ABOUT_ITEMS = {
  unsubscribed: [
    {
      i18nKey: "topNavbar:faqs",
      url: externalUrls.FAQ,
      icon: faqs,
    },
    {
      i18nKey: "topNavbar:contactUs",
      url: urlPatterns.CONTACT,
      icon: contactUs,
    },
    {
      i18nKey: "topNavbar:who",
      url: urlPatterns.ABOUT,
      icon: whoWeAre,
    },
    {
      i18nKey: "common:howItWorks",
      url: urlPatterns.LANDING,
      icon: howItWorks,
    },
    {
      i18nKey: "topNavbar:reviews",
      url: urlPatterns.REVIEWS,
      icon: reviews,
    },
  ],
  loggedIn: [
    {
      i18nKey: "topNavbar:faqs",
      url: externalUrls.FAQ,
      icon: faqs,
    },
    {
      i18nKey: "topNavbar:contactUs",
      url: urlPatterns.CONTACT,
      icon: contactUs,
    },
    {
      i18nKey: "topNavbar:who",
      url: urlPatterns.ABOUT,
      icon: whoWeAre,
    },
    {
      i18nKey: "topNavbar:reviews",
      url: urlPatterns.REVIEWS,
      icon: reviews,
    },
  ],
  loggedOut: [
    {
      i18nKey: "topNavbar:who",
      url: urlPatterns.ABOUT,
      icon: whoWeAre,
    },
    {
      i18nKey: "topNavbar:reviews",
      url: urlPatterns.REVIEWS,
      icon: reviews,
    },
    {
      i18nKey: "topNavbar:faqs",
      url: externalUrls.FAQ,
      icon: faqs,
    },
    {
      i18nKey: "topNavbar:contactUs",
      url: urlPatterns.CONTACT,
      icon: contactUs,
    },
  ],
};

export const NAVBAR_MOBILE_MEMBER_ITEMS = [
  {
    i18nKey: "topNavbar:dashboard",
    url: urlPatterns.MY_DASHBOARD,
    icon: dashboard,
  },
  {
    i18nKey: "topNavbar:nextBox",
    url: urlPatterns.MY_NEXT_WINES,
    icon: box,
  },
  {
    i18nKey: "topNavbar:rewards",
    url: urlPatterns.MY_PROGRESS,
    icon: myRewards,
  },
  {
    i18nKey: "topNavbar:pastWines",
    url: urlPatterns.MY_CELLAR,
    icon: pastWines,
  },
  {
    i18nKey: "topNavbar:savedLists",
    url: urlPatterns.MY_CELLAR_BOOKMARKED,
    icon: bookmark,
  },
  {
    i18nKey: "topNavbar:myTaste",
    url: urlPatterns.MY_TASTE_PROFILE,
    icon: lips,
  },
  {
    i18nKey: "topNavbar:settings",
    url: urlPatterns.MY_ACCOUNT,
    icon: settings,
  },
  {
    i18nKey: "topNavbar:inviteMates",
    url: urlPatterns.INVITE_FRIEND,
    icon: inviteFriends,
  },
  {
    i18nKey: "topNavbar:myOrders",
    url: urlPatterns.MY_ORDERS,
    icon: myOrders,
  },
];

export const NAVBAR_MOBILE_MEMBER_ITEMS_WITH_NON_WINES = [
  {
    i18nKey: "topNavbar:dashboard",
    url: urlPatterns.MY_DASHBOARD,
    icon: dashboard,
  },
  {
    i18nKey: "topNavbar:nextBox",
    url: urlPatterns.MY_NEXT_WINES,
    icon: box,
  },
  {
    i18nKey: "topNavbar:rewards",
    url: urlPatterns.MY_PROGRESS,
    icon: myRewards,
  },
  {
    i18nKey: "topNavbar:pastDrinks",
    url: urlPatterns.MY_CELLAR,
    icon: pastWines,
  },
  {
    i18nKey: "topNavbar:savedLists",
    url: urlPatterns.MY_CELLAR_BOOKMARKED,
    icon: bookmark,
  },
  {
    i18nKey: "topNavbar:myTaste",
    url: urlPatterns.MY_TASTE_PROFILE,
    icon: lips,
  },
  {
    i18nKey: "topNavbar:settings",
    url: urlPatterns.MY_ACCOUNT,
    icon: settings,
  },
  {
    i18nKey: "topNavbar:inviteMates",
    url: urlPatterns.INVITE_FRIEND,
    icon: inviteFriends,
  },
  {
    i18nKey: "topNavbar:myOrders",
    url: urlPatterns.MY_ORDERS,
    icon: myOrders,
  },
];

export const NAVBAR_DESKTOP_MEMBER_HOME_AND_BOX_ITEMS = [
  {
    i18nKey: "topNavbar:seeDashboard",
    url: urlPatterns.MY_DASHBOARD,
  },
  {
    i18nKey: "topNavbar:seeNextBox",
    url: urlPatterns.MY_NEXT_WINES,
  },
];

export const NAVBAR_DESKTOP_MEMBER_REWARDS_ITEMS = [
  {
    i18nKey: "topNavbar:seeProgress",
    url: urlPatterns.MY_PROGRESS,
  },
  {
    i18nKey: "topNavbar:seeBadges",
    url: urlPatterns.MY_BADGES,
  },
  {
    i18nKey: "topNavbar:seeChallenges",
    url: urlPatterns.MY_BADGES,
  },
  {
    i18nKey: "topNavbar:seeRewardStore",
    url: urlPatterns.REWARDS_STORE,
  },
  {
    i18nKey: "topNavbar:howRewardsWork",
    url: urlPatterns.REWARDS_HOW_IT_WORKS,
  },
];

export const NAVBAR_DESKTOP_MEMBER_PAST_WINES_ITEMS = [
  {
    i18nKey: "topNavbar:rateWines",
    url: urlPatterns.MY_CELLAR,
  },
  {
    i18nKey: "topNavbar:seePastWines",
    url: urlPatterns.MY_CELLAR,
  },
  {
    i18nKey: "topNavbar:seeOrders",
    url: urlPatterns.MY_ORDERS,
  },
];

export const NAVBAR_DESKTOP_MEMBER_PAST_DRINKS_ITEMS = [
  {
    i18nKey: "topNavbar:rateDrinks",
    url: urlPatterns.MY_CELLAR,
  },
  {
    i18nKey: "topNavbar:seePastDrinks",
    url: urlPatterns.MY_CELLAR,
  },
  {
    i18nKey: "topNavbar:seeOrders",
    url: urlPatterns.MY_ORDERS,
  },
];

export const NAVBAR_DESKTOP_MEMBER_MY_TASTES_ITEMS = [
  {
    i18nKey: "topNavbar:seeTasteProfile",
    url: urlPatterns.MY_TASTE_PROFILE,
  },
  {
    i18nKey: "topNavbar:retakeQuiz",
    url: urlPatterns.QUIZ,
  },
];

export const NAVBAR_DESKTOP_MEMBER_SETTINGS_ITEMS = [
  {
    i18nKey: "topNavbar:allSettings",
    url: urlPatterns.MY_ACCOUNT,
  },
  {
    i18nKey: "topNavbar:updateBoxAndSpend",
    url: urlPatterns.MY_ACCOUNT_TRAY(SETTINGS_TRAY.BOX_AND_SPEND),
    shouldOpenSideTray: true,
  },
  {
    i18nKey: "topNavbar:setGrapesToAvoid",
    url: urlPatterns.MY_ACCOUNT_TRAY(SETTINGS_TRAY.GRAPES_TO_AVOID),
    shouldOpenSideTray: true,
  },
];

export const NAVBAR_DESKTOP_MEMBER_MORE_ITEMS = [
  {
    i18nKey: "topNavbar:inviteMate",
    url: urlPatterns.INVITE_FRIEND,
    shouldNotCapitalize: true,
  },
  {
    i18nKey: "topNavbar:savedLists",
    url: urlPatterns.MY_CELLAR_BOOKMARKED,
  },
];

export const REWARDS_TABS = [
  {
    i18nKey: "topNavbar:howRewardsWork",
    url: urlPatterns.REWARDS_HOW_IT_WORKS,
  },
  {
    i18nKey: "topNavbar:rewardStore",
    url: urlPatterns.REWARDS_STORE,
  },
];

export const TERMS_AND_CONDITIONS_TABS = [
  {
    i18nKey: "topNavbar:terms",
    url: urlPatterns.TERMS,
  },
  {
    i18nKey: "topNavbar:policies",
    url: urlPatterns.PRIVACY,
  },
];

export const GIFT_LANDING_TABS = [
  {
    i18nKey: "gifts:send",
    url: urlPatterns.GIFT_LANDING,
  },
  {
    i18nKey: "gifts:redeem",
    url: urlPatterns.REDEEM_GIFT,
  },
  {
    i18nKey: "gifts:corporate.heroTitle",
    url: urlPatterns.CORPORATE_GIFT,
  },
];

export const SEND_GIFT_TABS = [
  {
    i18nKey: "gifts:send",
    url: urlPatterns.SEND_GIFT,
  },
  {
    i18nKey: "gifts:redeem",
    url: urlPatterns.REDEEM_GIFT,
  },
  {
    i18nKey: "gifts:corporate.heroTitle",
    url: urlPatterns.CORPORATE_GIFT,
  },
];

export const PROGRESS_TABS = [
  {
    i18nKey: "myProgress:progressTabs.progress",
    url: urlPatterns.MY_PROGRESS,
  },
  {
    i18nKey: "myProgress:progressTabs.earnPoints",
    url: urlPatterns.MY_BADGES,
  },
];
