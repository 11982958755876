import React from "react";

import BoxCartHeaderToggles from "../BoxCartHeaderToggles";
import BoxCartSideToggles from "../BoxCartSideToggles";
import { BoxCartTogglesWrapper } from "./styles";

interface BoxCartToggleControllerProps {
  variant: "header" | "side";
  onBoxCartToggle: (isMonthlyBox: boolean) => void;
  onCloseTray: () => void;
  isBox: boolean;
  isTrayOpen: boolean;
  boxAmountBottles: number;
  boxAmountPresents: number;
  cartAmountBottles: number;
  cartAmountPresents: number;
}

const BoxCartTogglesController = ({
  variant,
  onBoxCartToggle,
  onCloseTray,
  isBox,
  isTrayOpen,
  boxAmountBottles,
  boxAmountPresents,
  cartAmountBottles,
  cartAmountPresents,
}: BoxCartToggleControllerProps) => (
  <BoxCartTogglesWrapper>
    {variant === "header" ? (
      <BoxCartHeaderToggles
        onCloseTray={onCloseTray}
        onToggleClick={onBoxCartToggle}
        isMonthlyBoxSelected={isBox}
        isTrayOpen={isTrayOpen}
        boxAmountBottles={boxAmountBottles}
        boxAmountPresents={boxAmountPresents}
        cartAmountBottles={cartAmountBottles}
        cartAmountPresents={cartAmountPresents}
      />
    ) : (
      <BoxCartSideToggles
        onToggleClick={onBoxCartToggle}
        isMonthlyBoxSelected={isBox}
        boxAmountBottles={boxAmountBottles}
        boxAmountPresents={boxAmountPresents}
        cartAmountBottles={cartAmountBottles}
        cartAmountPresents={cartAmountPresents}
      />
    )}
  </BoxCartTogglesWrapper>
);

export default BoxCartTogglesController;
