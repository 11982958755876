import styled from "styled-components";
import winesDropdownWebpImage from "../../../assets/images/general/wines-dropdown.webp";
import winesDropdownImage from "../../../assets/images/general/wines-dropdown.png";
import exploreWines from "../../../assets/images/icons/explore-wines.svg";
import glass from "../../../assets/images/icons/glass.svg";
import priceTag from "../../../assets/images/icons/price-tag.svg";
import products from "../../../assets/images/icons/all-not-wines.svg";
import Image from "../../atoms/Image";
import { ColumnTitleIcon } from "../../organisms/Header/style";
import breakpoints from "../../../styles/breakpoints";

export const WinesDropdownWrapper = styled.div<{
  isAnyDropdownActive: boolean;
}>`
  display: ${(props) => (props.isAnyDropdownActive ? "flex" : "none")};
  height: 100%;
  flex-direction: row;
`;

export const WinesDropdownImage = styled(Image).attrs({
  src: winesDropdownImage,
  webpSrc: winesDropdownWebpImage,
  containerStyle: { display: "contents" },
})<{ dropdownHeight: number }>`
  max-width: 30%;
  object-fit: cover;
  height: ${(props) => props.dropdownHeight}px;
  ${breakpoints.lgDown} {
    max-width: 20%;
  }
`;

export const ExploreWinesIcon = styled(ColumnTitleIcon).attrs({
  src: exploreWines,
})``;

export const WineColourIcon = styled(ColumnTitleIcon).attrs({ src: glass })``;
export const WinePriceIcon = styled(ColumnTitleIcon).attrs({ src: priceTag })``;
export const ProductsIcon = styled(ColumnTitleIcon).attrs({ src: products })``;
