import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";

import { colors, fonts } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";
import { COUNTRIES } from "../../../helpers/constants";

const SelectDropdownHolder = styled.div<{
  isOpened: boolean;
}>`
  position: relative;
  z-index: ${(props) => (props.isOpened ? 155 : 0)};
  display: flex;
  ${breakpoints.mdUp} {
    justify-content: left;
  }
  ${breakpoints.mdDown} {
    justify-content: center;
  }
  margin-bottom: 7px;
`;

const DropdownArrow = styled.div`
  width: 5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${colors.black};
`;

const StyledSelectDropdown = styled.div`
  border: 1px solid ${colors.black};
  padding: 6px;
  width: 100%;
  height: 31px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
`;

const SelectDropdownLabel = styled.p`
  white-space: nowrap;
  width: 100%;
  height: 15px;
  font-family: ${fonts.fontInterstateUltraBlack};
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  padding-right: 6px;
  /* identical to box height */

  display: flex;
  align-items: center;
  color: ${colors.black};
  margin-bottom: 0px !important;
`;

const Dropdown = styled.div<{
  isOpened: boolean;
}>`
  position: absolute;
  top: 100%;
  height: 30px;
  width: 100%;
  border: 1px solid ${colors.black};
  border-top: none;
  background-color: ${colors.white};
  z-index: 20;
  display: ${(props) => (props.isOpened ? "flex" : "none")};
  align-items: center;
`;

const DropdownItem = styled.div`
  font-size: 12px;
  font-family: ${fonts.fontInterstateUltraBlack};
  text-transform: uppercase;
  padding-left: 6px;
  padding-right: 6px;
  cursor: pointer;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export interface CountryDropdownProps {
  selectedCountry: string;
  onSelectCountry: (countryId: string) => void;
  renderCountryLabel: (countryId: string, selected: boolean) => ReactNode;
}

const CountryDropdown = ({ selectedCountry, onSelectCountry, renderCountryLabel }: CountryDropdownProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const handleSelectCountry = (countryId: string) => {
    onSelectCountry(countryId);
    setIsOpened(false);
  };

  const handleToggleDropdown = () => {
    setIsOpened((prevValue) => !prevValue);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpened(false)}>
      <SelectDropdownHolder isOpened={isOpened}>
        <StyledSelectDropdown onClick={handleToggleDropdown}>
          <SelectDropdownLabel>{renderCountryLabel(selectedCountry, true)}</SelectDropdownLabel>
          <DropdownArrow />
        </StyledSelectDropdown>

        <Dropdown isOpened={isOpened}>
          {Object.values(COUNTRIES)
            .filter((country) => country !== selectedCountry)
            .map((country) => (
              <DropdownItem key={country} onClick={() => handleSelectCountry(country)}>
                {renderCountryLabel(country, false)}
              </DropdownItem>
            ))}
        </Dropdown>
      </SelectDropdownHolder>
    </OutsideClickHandler>
  );
};

export default CountryDropdown;
