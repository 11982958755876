import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(customParseFormat);

/**
 * Forces the given dateString to become a dayjs date object.
 *
 * @param {Object} date
 * @param {Number} amount to add
 * @param {String} unit to add
 * @return {Object} resulting date
 * */
const addDate = (date, amount, unit) => date.add(amount, unit);

/**
 * Returns the difference between two date-time in the specified unit.
 * with the option to return the diff with time reset to 0:00
 *
 * @param {Object} fromDate
 * @param {Object} toDate
 * @param {String} unit to return the difference
 * @param {Boolean} reset time to 0:00 or not when calculate the diff
 * @return {Number} duration
 * */
const dateDiff = (fromDate, toDate, unit = "ms", clearTime = false) => {
  if (clearTime) {
    const startOfFromDate = fromDate.startOf("d");
    const startOfToDate = toDate.startOf("d");
    return startOfFromDate.diff(startOfToDate, unit);
  }

  return fromDate.diff(toDate, unit);
};

/**
 * Returns the duration object from duration. To be used in conjunction with dateDiff function.
 *
 * @param {Object} durationInput: duration input e.g {months: 12}
 * @return {Object} duration object
 * */
const dateDuration = (durationInput) => dayjs.duration(durationInput);

/**
 * Returns a formatted date string using the given format.
 *
 * @param {Date | String | dayjs.Dayjs} date string in ISO format
 * @param {String} format - the format to return the data at
 * @param {Boolean} friendlyFormat - if true and recent, a friendly message is returned (e. g. 2 hours ago)
 * @return {String}
 * */
const formatDate = (date, format = "DD MMM", friendlyFormat = false) => {
  let dateToFormat = date;
  if (typeof date === "string" || date instanceof String || date instanceof Date) {
    dateToFormat = dayjs(date);
  }

  return friendlyFormat && dayjs().diff(dateToFormat, "d") > 0
    ? dateToFormat.fromNow()
    : dateToFormat.format(format);
};

/**
 * Forces the given dateString to become a dayjs date object.
 *
 * @param {Object} dateToCheck this is the date we test if it's after dateBase
 * @param {Object} dateBase the base date to receive the check
 * @return {Boolean} If dateToCheck is after dateBase
 * */
const isDateAfter = (dateToCheck, dateBase) => dayjs(dateToCheck).isAfter(dateBase);

/**
 * Returns the parsed date. If no date is given, we return the current date.
 *
 * @param {String} date
 * @param {String} format
 * @return {Object} date object
 * */
const parseDate = (date = null, format = null) => {
  if (date && format) {
    return dayjs(date, format);
  }

  return date ? dayjs(date) : dayjs();
};

/**
 * Forces the given dateString to become a dayjs date object.
 *
 * @param {string} date string
 * @return {Object} date object
 * */
const toDate = (date) => dayjs(date).toDate();

/**
 * Get formatted date string.
 * @params {String | Date} date
 * @returns {String}
 * */
const getMonth = (date) => formatDate(date, "MMM YYYY");

/**
 * Get the current year from today's date.
 * @returns {Number}
 * */
const getCurrentYear = () => dayjs().year();

/**
 * Returns long month name.
 * @params {number} monthNumber
 * @returns {String}
 * */
export const getLongMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber);
  return date.toLocaleString("en-US", { month: "long" });
};

/**
 * Returns masked date.
 * @params {Element} elm
 * @returns {string}
 * */
export const maskDate = (elm) => {
  const lastChar = elm.current.value[elm.current.value.length - 1];
  const isLastCharNotNumber = Number.isNaN(parseInt(lastChar, 10));
  const isLastCharSlash = lastChar === "/";
  const len = elm.current.value.length;

  let maskedString = elm.current.value;

  if (isLastCharNotNumber) {
    // If we're at a particular place, let the user type the slash
    // i.e., 12/12/1212
    if (isLastCharSlash && (len === 3 || len === 6)) {
      return maskedString;
    }
    // Otherwise remove the last character.
    return maskedString.slice(0, len - 1);
  }

  // If they don't add the slash, do it for them...
  if (len === 3 && !isLastCharSlash) {
    maskedString = `${maskedString.slice(0, 2)}/${maskedString.slice(2)}`;
  }

  // If they don't add the slash, do it for them...
  if (len === 6 && !isLastCharSlash) {
    maskedString = `${maskedString.slice(0, 5)}/${maskedString.slice(5)}`;
  }
  return maskedString;
};

export {
  addDate,
  dateDiff,
  dateDuration,
  formatDate,
  isDateAfter,
  parseDate,
  toDate,
  getMonth,
  getCurrentYear,
};
