import React, { ReactElement } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { MOBILE_NAVBAR_ACCORDIONS, UNLEASH_NEW_PRODUCT_CATEGORIES } from "../../../helpers/constants";
import {
  NAVBAR_MOBILE_MEMBER_ITEMS,
  NAVBAR_MOBILE_MEMBER_ITEMS_WITH_NON_WINES,
} from "../../../helpers/navbarProcessor";
import { NAVBAR_MEMBER_ACCORDION_HEIGHT } from "../../../styles/variables";
import AccordionItem from "../AccordionItem";
import {
  StyledHeaderUserAvatar,
  AccordionContentWrapper,
  AccordionTopPart,
  StyledChevron,
  UserAccordionWrapper,
  UserName,
} from "../MobileHeaderNavbar/style";

interface MobileUserAccordionProps {
  firstName?: string;
  openAccordion: string | undefined;
  setOpenAccordion: Function;
}

const MobileUserAccordion = ({
  firstName,
  openAccordion,
  setOpenAccordion,
}: MobileUserAccordionProps): ReactElement => {
  const isProductCollectionsEnabled = useFlag(UNLEASH_NEW_PRODUCT_CATEGORIES);
  const isThisAccordionOpen = !!(openAccordion === MOBILE_NAVBAR_ACCORDIONS.USER);
  const navbarItems = isProductCollectionsEnabled
    ? NAVBAR_MOBILE_MEMBER_ITEMS_WITH_NON_WINES
    : NAVBAR_MOBILE_MEMBER_ITEMS;
  return (
    <UserAccordionWrapper
      open={isThisAccordionOpen}
      onClick={() => setOpenAccordion(isThisAccordionOpen ? "" : MOBILE_NAVBAR_ACCORDIONS.USER)}
    >
      <AccordionTopPart isThisAccordionOpen>
        <StyledHeaderUserAvatar type="button" onClick={() => {}}>
          <span>{firstName?.charAt(0).toUpperCase()}</span>
        </StyledHeaderUserAvatar>
        <UserName>{firstName}</UserName>
        <StyledChevron open={isThisAccordionOpen} />
      </AccordionTopPart>
      <AccordionContentWrapper
        open={isThisAccordionOpen}
        shouldHideTopBorder={isThisAccordionOpen}
        height={NAVBAR_MEMBER_ACCORDION_HEIGHT}
        isFlexDirectionRow
        isMemberAccordion
      >
        {navbarItems.map((item) => (
          <AccordionItem
            i18nKey={item.i18nKey}
            url={item.url}
            icon={item.icon}
            key={item.i18nKey}
            isUserAccordion
            isFlexDirectionRow
          />
        ))}
      </AccordionContentWrapper>
    </UserAccordionWrapper>
  );
};

export default MobileUserAccordion;
