import styled, { css } from "styled-components";
import { header, transition, boxTrayToggleMobileHeight } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";

interface styleProps {
  isOpened?: boolean;
  smUpTop?: string;
  smUpHeightOffset?: string;
  mdUpTop?: string;
  mdUpHeightOffset?: string;
}

export const SmDownBackdrop = styled.div<{ isOpened: boolean }>`
  ${breakpoints.smDown} {
    background: ${(props) => (props.isOpened ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)")};
    ${(props) => !props.isOpened && "visibility: hidden;"}
    height: 100vh;
    width: 100vw;
    position: fixed;
    transition: ${transition.headerMenuBarTransition};
    pointer-events: ${(props) => (props.isOpened ? "auto" : "none")};
  }
  ${breakpoints.smUp} {
    display: none;
  }
`;

export const StyledBoxTray = styled.div`
  transition: ${transition.headerMenuBarTransition};
  position: fixed;
  right: 0;
  width: 100%;
  top: 0;
  z-index: ${(props: styleProps) => (props.isOpened ? "950" : "900")};
  ${breakpoints.smUp} {
    top: ${(props) => props.smUpTop || `${header.mobileHeight}`};
    width: 420px;
    transform: translateX(${(props) => (!props.isOpened ? "420px" : "0px")});
    height: calc(100% - ${(props) => `${props.smUpHeightOffset}`});
  }
  ${breakpoints.mdUp} {
    z-index: 6000;
    top: ${(props: styleProps) => props.mdUpTop || `${header.desktopHeight}`};
    height: calc(100% - ${(props) => `${props.mdUpHeightOffset}`});
  }
  ${breakpoints.smDown} {
    position: relative;
    margin-bottom: ${boxTrayToggleMobileHeight};
  }
`;

export const HolderInner = styled.div<{
  isOpened: boolean;
  isHeaderShown: boolean;
}>`
  ${breakpoints.smUp} {
    height: 100%;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  }
  ${breakpoints.smDown} {
    display: ${(props) => props.isOpened && "block"};
    transition: ${transition.headerMenuBarTransition};
    ${(props) =>
      css`
        transform: translateY(0);
        position: fixed;
        overflow: hidden;
        margin-top: -${boxTrayToggleMobileHeight};
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
        height: ${props.isOpened ? `calc(100dvh - ${header.mobileHeight})` : `${boxTrayToggleMobileHeight}`};
      `}
    left: 0;
    ${(props) =>
      css`
        top: ${props.isHeaderShown ? header.withTabBarMobileHeightBoxToggle : header.mobileHeight};
      `}
    z-index: 2500;
    width: 100%;
  }
`;
