import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import urlPatterns from "./urls";
import { isLoggedIn } from "./helpers/auth";
import Layout from "./components/organisms/Layout";
import Home from "./pages/Home";

// Declares lazily loaded components --> https://reactjs.org/docs/code-splitting.html#reactlazy
const Faq = React.lazy(() => import("./pages/Faq"));
const Wines = React.lazy(() => import("./pages/Wines"));
const BeerLanding = React.lazy(() => import("./pages/BeerLanding"));
const ProductCollections = React.lazy(() => import("./pages/ProductCollections"));
const ProductDetails = React.lazy(() => import("./pages/ProductDetails"));
const NextWines = React.lazy(() => import("./pages/NextWines"));
const ForceReload = React.lazy(() => import("./pages/ForceReload"));
const WineDetails = React.lazy(() => import("./pages/WineDetails"));
const WineDetailsById = React.lazy(() => import("./pages/WineDetailsById"));
const AddWineToBoxById = React.lazy(() => import("./pages/AddWineToBoxById"));
const MyOneOffRecommendations = React.lazy(() => import("./pages/MyOneOffRecommendations"));
const LogIn = React.lazy(() => import("./pages/LogIn"));
const LogOut = React.lazy(() => import("./pages/LogOut"));
const Referral = React.lazy(() => import("./pages/Referral"));
const DiscountCode = React.lazy(() => import("./pages/DiscountCode"));
const MyAccount = React.lazy(() => import("./pages/MyAccount"));
const MyCellarReceived = React.lazy(() => import("./pages/MyCellarReceived"));
const MyCellarWithDetailsTray = React.lazy(() => import("./pages/MyCellarWithDetailsTray"));
const MyCellarWithDetailsTrayById = React.lazy(() => import("./pages/MyCellarWithDetailsTrayById"));
const MyCellarBookmarked = React.lazy(() => import("./pages/MyCellarBookmarked"));
const MyOrders = React.lazy(() => import("./pages/MyOrders"));
const MyCellarDetails = React.lazy(() => import("./pages/MyCellarDetails"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const TasteChallenge = React.lazy(() => import("./pages/TasteChallenge"));
const MyBadges = React.lazy(() => import("./pages/MyBadges"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Checkout = React.lazy(() => import("./pages/Checkout"));
const ThankYou = React.lazy(() => import("./pages/ThankYou"));
const About = React.lazy(() => import("./pages/About"));
const Terms = React.lazy(() => import("./pages/Terms"));
const Privacy = React.lazy(() => import("./pages/Privacy"));
const Reviews = React.lazy(() => import("./pages/Reviews"));
const Progress = React.lazy(() => import("./pages/Progress"));
const WineList = React.lazy(() => import("./pages/WineList"));
const InviteFriend = React.lazy(() => import("./pages/InviteFriend"));
const GiftLanding = React.lazy(() => import("./pages/GiftLanding"));
const SendGift = React.lazy(() => import("./pages/SendGift"));
const RedeemGift = React.lazy(() => import("./pages/RedeemGift"));
const CorporateGifts = React.lazy(() => import("./pages/CorporateGifts"));
const RewardsHowItWorks = React.lazy(() => import("./pages/RewardsHowItWorks"));
const RewardsStore = React.lazy(() => import("./pages/RewardsStore"));
const RewardMerchDetails = React.lazy(() => import("./pages/RewardMerchDetails"));
const SpecialPacks = React.lazy(() => import("./pages/SpecialPacks"));
const SpecialPackDetails = React.lazy(() => import("./pages/SpecialPackDetails"));
const SpecialPackAdventCalendar = React.lazy(() => import("./pages/SpecialPackAdventCalendar"));
const QuizResults = React.lazy(() => import("./pages/QuizResults"));
const SignUp = React.lazy(() => import("./pages/SignUp"));
const BeerSubscriptionSignup = React.lazy(() => import("./pages/BeerSubscriptionSignup"));
const SetNewPassword = React.lazy(() => import("./pages/SetNewPassword"));
const PasswordReset = React.lazy(() => import("./pages/PasswordReset"));
const Quiz = React.lazy(() => import("./pages/Quiz"));
const SelectWelcomePack = React.lazy(() => import("./pages/SelectWelcomePack"));
const SelectFreeWineColor = React.lazy(() => import("./pages/SelectFreeWineColor"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const ApplyReactivationCredit = React.lazy(() => import("./pages/ApplyReactivationCredit"));
const GuidesTabAll = React.lazy(() => import("./pages/Guides/GuidesTabAll"));
const GuidesTabAllGlossary = React.lazy(() => import("./pages/Guides/GuidesTabAllGlossary"));
const GuidesTabAllDetails = React.lazy(() => import("./pages/Guides/GuidesTabAllDetails"));
const GuidesTabGrape = React.lazy(() => import("./pages/Guides/GuidesTabGrape"));
const GuidesTabGrapeGlossary = React.lazy(() => import("./pages/Guides/GuidesTabGrapeGlossary"));
const GuidesGrapeTypeDetails = React.lazy(() => import("./pages/Guides/GuidesGrapeTypeDetails"));
const GuidesTabRegion = React.lazy(() => import("./pages/Guides/GuidesTabRegion"));
const GuidesTabRegionGlossary = React.lazy(() => import("./pages/Guides/GuidesTabRegionGlossary"));
const GuidesTabRegionDetails = React.lazy(() => import("./pages/Guides/GuidesTabRegionDetails"));
const GuidesTabTasting = React.lazy(() => import("./pages/Guides/GuidesTabTasting"));
const GuidesTabTastingGlossary = React.lazy(() => import("./pages/Guides/GuidesTabTastingGlossary"));
const GuidesTabTastingDetails = React.lazy(() => import("./pages/Guides/GuidesTabTastingDetails"));
const GuidesTabMore = React.lazy(() => import("./pages/Guides/GuidesTabMore"));
const GuidesTabMoreGlossary = React.lazy(() => import("./pages/Guides/GuidesTabMoreGlossary"));
const GuidesTabMoreDetails = React.lazy(() => import("./pages/Guides/GuidesTabMoreDetails"));
const GuidesSearch = React.lazy(() => import("./pages/Guides/GuidesSearch"));
const WineListClass = React.lazy(() => import("./pages/WineListClass"));
const WineListType = React.lazy(() => import("./pages/WineListType"));
const WineListPriceRange = React.lazy(() => import("./pages/WineListPriceRange"));
const ChristmasCutOffDates = React.lazy(() => import("./pages/ChristmasCutOffDates"));
const MyTasteProfile = React.lazy(() => import("./pages/MyTasteProfile"));

/**
 * Protects routes (pages) that require login to be visualized, as example My Account page
 * @param ChildComponent
 * @param rest
 * @return {React.Component}: stateless component
 * */
const PrivateRoute = ({ component: ChildComponent, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn() ? (
        <ChildComponent {...props} />
      ) : (
        <Redirect
          to={{
            pathname: urlPatterns.LOGIN,
            state: {
              from: props.location,
              redirectUrl: props.location.pathname,
            },
          }}
        />
      )
    }
  />
);

/**
 * Stores routing management, this is where we map url path and pages (component container)
 * */
const Routes = () => (
  <Switch>
    <Route
      path={[
        urlPatterns.MY_ACCOUNT,
        urlPatterns.MY_ACCOUNT_TRAY(),
        urlPatterns.MY_SUBSCRIPTION,
        urlPatterns.REACTIVATE(),
        urlPatterns.INVITE_FRIEND,
        urlPatterns.MY_CELLAR_DETAILS(),
        urlPatterns.ADD_WINE_TO_BOX_BY_ID(),
        urlPatterns.MY_TASTE_PROFILE,
        urlPatterns.MY_CELLAR_BOOKMARKED,
        urlPatterns.REFERRAL(),
        urlPatterns.DISCOUNT_CODE(),
        urlPatterns.CONTACT,
        urlPatterns.MY_PROGRESS,
        urlPatterns.MY_CELLAR,
        urlPatterns.WINE_DETAILS(),
        urlPatterns.FAQ,
        urlPatterns.REDEEM_GIFT,
        urlPatterns.CORPORATE_GIFT,
        urlPatterns.REVIEWS,
        urlPatterns.MY_BADGES,
        urlPatterns.CHRISTMAS_CUT_OFF_DATES,
        urlPatterns.MY_CELLAR_WITH_DETAILS_TRAY(),
        urlPatterns.MY_CELLAR_WITH_DETAILS_TRAY_BY_ID(),
        urlPatterns.REWARDS_HOW_IT_WORKS,
        urlPatterns.REWARDS_STORE,
        urlPatterns.REWARD_MERCH_DETAILS_BY_PRODUCT_ID(),
        urlPatterns.REWARD_MERCH_DETAILS_BY_SLUG(),
        urlPatterns.PRODUCT_DETAILS_BY_SLUG(),
        urlPatterns.ONBOARDING,
        urlPatterns.TERMS,
        urlPatterns.PRIVACY,
      ]}
    >
      <Layout>
        <Switch>
          <PrivateRoute
            path={[urlPatterns.MY_ACCOUNT, urlPatterns.MY_ACCOUNT_TRAY(), urlPatterns.MY_SUBSCRIPTION]}
            component={MyAccount}
            exact
          />
          <PrivateRoute path={urlPatterns.REACTIVATE()} component={ApplyReactivationCredit} exact />
          <PrivateRoute path={urlPatterns.INVITE_FRIEND} component={InviteFriend} exact />
          <PrivateRoute path={urlPatterns.MY_CELLAR_DETAILS()} component={MyCellarDetails} exact />
          <PrivateRoute path={urlPatterns.ADD_WINE_TO_BOX_BY_ID()} component={AddWineToBoxById} exact />
          <PrivateRoute path={urlPatterns.MY_PROGRESS} component={Progress} exact />
          <PrivateRoute path={urlPatterns.MY_BADGES} component={MyBadges} exact />
          <PrivateRoute path={urlPatterns.BADGE_DETAILS()} component={MyBadges} exact />
          <PrivateRoute path={urlPatterns.CHALLENGE_DETAILS()} component={MyBadges} exact />
          <PrivateRoute path={urlPatterns.ONBOARDING} component={Dashboard} exact />
          <PrivateRoute path={urlPatterns.MY_CELLAR} component={MyCellarReceived} exact />
          <PrivateRoute path={urlPatterns.MY_CELLAR_FILTER_BY_CLASS()} component={MyCellarReceived} />
          <PrivateRoute path={urlPatterns.MY_TASTE_PROFILE} component={MyTasteProfile} exact />
          <PrivateRoute path={urlPatterns.MY_CELLAR_BOOKMARKED} component={MyCellarBookmarked} exact />
          <Route path={urlPatterns.MY_CELLAR_WITH_DETAILS_TRAY()} component={MyCellarWithDetailsTray} exact />
          <Route
            path={urlPatterns.MY_CELLAR_WITH_DETAILS_TRAY_BY_ID()}
            component={MyCellarWithDetailsTrayById}
            exact
          />
          <Route path={urlPatterns.TERMS} render={(props) => <Terms {...props} />} exact />
          <Route path={urlPatterns.PRIVACY} render={(props) => <Privacy {...props} exact />} />
          <Route path={urlPatterns.FAQ} exact render={(props) => <Faq {...props} />} />
          <Route path={urlPatterns.WINE_DETAILS()} render={(props) => <WineDetails {...props} />} />
          <Route path={urlPatterns.REDEEM_GIFT} exact render={(props) => <RedeemGift {...props} />} />
          <Route path={urlPatterns.CORPORATE_GIFT} exact render={(props) => <CorporateGifts {...props} />} />
          <Route
            path={urlPatterns.REWARDS_HOW_IT_WORKS}
            exact
            render={(props) => <RewardsHowItWorks {...props} />}
          />
          <Route path={urlPatterns.REWARDS_STORE} exact render={(props) => <RewardsStore {...props} />} />
          <Route
            path={urlPatterns.REWARD_MERCH_DETAILS_BY_PRODUCT_ID()}
            exact
            render={(props) => <RewardMerchDetails {...props} />}
          />
          <Route
            path={urlPatterns.REWARD_MERCH_DETAILS_BY_SLUG()}
            exact
            render={(props) => <RewardMerchDetails {...props} />}
          />
          <Route
            path={urlPatterns.PRODUCT_DETAILS_BY_SLUG()}
            exact
            render={(props) => <ProductDetails {...props} />}
          />
          <Route path={urlPatterns.REVIEWS} exact render={(props) => <Reviews {...props} />} />
          <Route path={urlPatterns.REFERRAL()} render={(props) => <Referral {...props} />} />
          <Route path={urlPatterns.DISCOUNT_CODE()} render={(props) => <DiscountCode {...props} />} />
          <Route path={urlPatterns.CONTACT} render={(props) => <Contact {...props} />} />
          <Route
            path={urlPatterns.CHRISTMAS_CUT_OFF_DATES}
            exact
            render={(props) => <ChristmasCutOffDates {...props} />}
          />
        </Switch>
      </Layout>
    </Route>
    {/* Renders lazily loaded components --> https://reactjs.org/docs/code-splitting.html#reactlazy */}

    {/* https://github.com/ReactTraining/react-router/issues/820#issuecomment-313794272 */}
    <Route
      exact
      strict
      path="/:url*"
      render={(props) => (
        <Redirect
          to={{
            pathname: `${props.location.pathname}/`,
            search: props.location.search,
            state: props.location.state,
          }}
        />
      )}
    />

    <Route path={[urlPatterns.ABOUT]}>
      <Layout showAnnouncementPopupForSubscribers showAnnouncementPopupForNonSubscribers>
        <Switch>
          <Route path={urlPatterns.ABOUT} render={(props) => <About {...props} />} />
        </Switch>
      </Layout>
    </Route>

    <Route path={[urlPatterns.QUIZ_RESULTS]}>
      <Layout
        showBoxAndCartTray={false}
        showAnnouncementBar
        showAnnouncementPopupForSubscribers
        showAnnouncementPopupForNonSubscribers
      >
        <Switch>
          <PrivateRoute path={urlPatterns.QUIZ_RESULTS} exact component={QuizResults} />
        </Switch>
      </Layout>
    </Route>

    <Route path={[urlPatterns.MY_DASHBOARD, urlPatterns.WINES, urlPatterns.ALL_PRODUCT_COLLECTIONS]} exact>
      <Layout showAnnouncementBar showAnnouncementPopupForSubscribers showAnnouncementPopupForNonSubscribers>
        <Switch>
          <Route path={urlPatterns.WINES} exact render={(props) => <Wines {...props} />} />
          <Route
            path={urlPatterns.ALL_PRODUCT_COLLECTIONS}
            exact
            render={(props) => <ProductCollections {...props} />}
          />
          <PrivateRoute path={urlPatterns.MY_DASHBOARD} component={Dashboard} exact />
        </Switch>
      </Layout>
    </Route>

    <Route path={[urlPatterns.GIFT_LANDING]} exact>
      <Layout
        showAnnouncementPopupForSubscribers
        showAnnouncementPopupForNonSubscribers
        showAnnouncementBar
        hasTabBarUnderHeader
      >
        <Switch>
          <Route path={urlPatterns.GIFT_LANDING} exact render={(props) => <GiftLanding {...props} />} />
        </Switch>
      </Layout>
    </Route>

    <Route
      path={[urlPatterns.HOME, urlPatterns.LANDING, urlPatterns.WINE_LANDING, urlPatterns.BEER_LANDING]}
      exact
    >
      <Layout
        showAnnouncementPopupForSubscribers
        showAnnouncementPopupForNonSubscribers
        showAnnouncementBar
        variant="home"
      >
        <Switch>
          <Route
            path={[urlPatterns.HOME, urlPatterns.LANDING, urlPatterns.WINE_LANDING]}
            exact
            render={(props) => <Home {...props} />}
          />
          <Route path={[urlPatterns.BEER_LANDING]} exact render={(props) => <BeerLanding {...props} />} />
        </Switch>
      </Layout>
    </Route>

    <Route path={[urlPatterns.MY_NEXT_WINES]} exact>
      <Layout showBoxAndCartTray={false} showAnnouncementBar>
        <Switch>
          <PrivateRoute path={urlPatterns.MY_NEXT_WINES} component={NextWines} exact />
        </Switch>
      </Layout>
    </Route>

    <Route path={[urlPatterns.SEND_GIFT]} exact>
      <Layout showAnnouncementBar hasTabBarUnderHeader>
        <Switch>
          <Route path={urlPatterns.SEND_GIFT} exact render={(props) => <SendGift {...props} />} />
        </Switch>
      </Layout>
    </Route>

    <Route
      path={[
        urlPatterns.SPECIAL_PACKS,
        urlPatterns.SPECIAL_PACK_ADVENT_CALENDAR,
        urlPatterns.SPECIAL_PACK_DETAILS(),
      ]}
      exact
    >
      <Layout showAnnouncementBar>
        <Switch>
          <Route path={urlPatterns.SPECIAL_PACKS} render={(props) => <SpecialPacks {...props} />} exact />
          <Route
            path={urlPatterns.SPECIAL_PACK_ADVENT_CALENDAR}
            render={(props) => <SpecialPackAdventCalendar {...props} />}
            exact
          />
          <Route
            path={urlPatterns.SPECIAL_PACK_DETAILS()}
            render={(props) => <SpecialPackDetails {...props} />}
            exact
          />
        </Switch>
      </Layout>
    </Route>

    <Route
      path={[
        urlPatterns.GUIDE_101,
        urlPatterns.GUIDE_101_GLOSSARY(),
        urlPatterns.GUIDE_101_POST(),
        urlPatterns.GUIDE_GRAPE_COLOR,
        urlPatterns.GUIDE_GRAPE_COLOR_GLOSSARY(),
        urlPatterns.GUIDE_GRAPE_COLOR_POST(),
        urlPatterns.GUIDE_REGION,
        urlPatterns.GUIDE_REGION_GLOSSARY(),
        urlPatterns.GUIDE_REGION_POST(),
        urlPatterns.GUIDE_TASTING,
        urlPatterns.GUIDE_TASTING_GLOSSARY(),
        urlPatterns.GUIDE_TASTING_POST(),
        urlPatterns.GUIDE_MORE,
        urlPatterns.GUIDE_MORE_GLOSSARY(),
        urlPatterns.GUIDE_MORE_POST(),
        urlPatterns.GUIDE_SEARCH_ARTICLES,
      ]}
    >
      <Layout showBoxAndCartTray={false} showAnnouncementPopupForNonSubscribers>
        <Switch>
          <Route path={urlPatterns.GUIDE_101} render={(props) => <GuidesTabAll {...props} />} exact />
          <Route
            path={urlPatterns.GUIDE_101_GLOSSARY()}
            render={(props) => <GuidesTabAllGlossary {...props} />}
            exact
          />
          <Route
            path={urlPatterns.GUIDE_101_POST()}
            render={(props) => <GuidesTabAllDetails {...props} />}
            exact
          />
          <Route
            path={urlPatterns.GUIDE_GRAPE_COLOR}
            render={(props) => <GuidesTabGrape {...props} />}
            exact
          />
          <Route
            path={urlPatterns.GUIDE_GRAPE_COLOR_GLOSSARY()}
            render={(props) => <GuidesTabGrapeGlossary {...props} />}
            exact
          />
          <Route
            path={urlPatterns.GUIDE_GRAPE_COLOR_POST()}
            render={(props) => <GuidesGrapeTypeDetails {...props} />}
            exact
          />
          <Route path={urlPatterns.GUIDE_REGION} render={(props) => <GuidesTabRegion {...props} />} exact />
          <Route
            path={urlPatterns.GUIDE_REGION_GLOSSARY()}
            render={(props) => <GuidesTabRegionGlossary {...props} />}
            exact
          />
          <Route
            path={urlPatterns.GUIDE_REGION_POST()}
            render={(props) => <GuidesTabRegionDetails {...props} />}
            exact
          />
          <Route path={urlPatterns.GUIDE_TASTING} render={(props) => <GuidesTabTasting {...props} />} exact />
          <Route
            path={urlPatterns.GUIDE_TASTING_GLOSSARY()}
            render={(props) => <GuidesTabTastingGlossary {...props} />}
            exact
          />
          <Route
            path={urlPatterns.GUIDE_TASTING_POST()}
            render={(props) => <GuidesTabTastingDetails {...props} />}
            exact
          />
          <Route path={urlPatterns.GUIDE_MORE} render={(props) => <GuidesTabMore {...props} />} exact />
          <Route
            path={urlPatterns.GUIDE_MORE_GLOSSARY()}
            render={(props) => <GuidesTabMoreGlossary {...props} />}
            exact
          />
          <Route
            path={urlPatterns.GUIDE_MORE_POST()}
            render={(props) => <GuidesTabMoreDetails {...props} />}
            exact
          />
          <Route
            path={urlPatterns.GUIDE_SEARCH_ARTICLES}
            render={(props) => <GuidesSearch {...props} />}
            exact
          />
        </Switch>
      </Layout>
    </Route>

    <Route
      path={[
        urlPatterns.MY_ONE_OFF_RECOMMENDATIONS,
        urlPatterns.CHECKOUT_THANK_YOU,
        urlPatterns.THANK_YOU_PAGE_ONBOARDING,
        urlPatterns.MY_ORDERS,
      ]}
    >
      <Layout showBoxAndCartTray={false}>
        <Switch>
          <PrivateRoute path={urlPatterns.CHECKOUT_THANK_YOU} component={ThankYou} exact />
          <PrivateRoute path={urlPatterns.THANK_YOU_PAGE_ONBOARDING} component={ThankYou} exact />
          <PrivateRoute
            path={urlPatterns.MY_ONE_OFF_RECOMMENDATIONS}
            component={MyOneOffRecommendations}
            exact
          />
          <PrivateRoute path={urlPatterns.MY_ORDERS} component={MyOrders} />
        </Switch>
      </Layout>
    </Route>

    <Route path={urlPatterns.WINE_DETAILS_BY_ID()} render={(props) => <WineDetailsById {...props} />} />

    <Route path={[urlPatterns.WINE_LIST(), urlPatterns.PRODUCT_COLLECTION()]}>
      <Layout showAnnouncementBar showAnnouncementPopupForSubscribers showAnnouncementPopupForNonSubscribers>
        <Switch>
          <Route path={urlPatterns.WINE_LIST()} render={(props) => <WineList {...props} />} />
          <Route
            path={urlPatterns.PRODUCT_COLLECTION()}
            render={(props) => <ProductCollections {...props} />}
          />
        </Switch>
      </Layout>
    </Route>

    <Route path={urlPatterns.SIGN_UP} render={(props) => <SignUp {...props} />} />
    <Route path={urlPatterns.BEER_SUBSCRIBE} render={(props) => <BeerSubscriptionSignup {...props} />} />
    <Route path={urlPatterns.PASSWORD_RESET} render={(props) => <PasswordReset {...props} />} />
    <Route path={urlPatterns.SET_NEW_PASSWORD} render={(props) => <SetNewPassword {...props} />} />
    <Route path={urlPatterns.QUIZ} exact render={(props) => <Quiz {...props} />} />

    <Route path={urlPatterns.LOGIN} render={(props) => <LogIn {...props} />} />
    <Route path={urlPatterns.LOGOUT} render={(props) => <LogOut {...props} />} />
    <Route path={urlPatterns.WINE_LIST_CLASS()} render={(props) => <WineListClass {...props} />} />
    <Route path={urlPatterns.WINE_LIST_TYPE()} render={(props) => <WineListType {...props} />} />
    <Route path={urlPatterns.WINE_LIST_PRICE_RANGE()} render={(props) => <WineListPriceRange {...props} />} />
    <Route path={urlPatterns.TASTE_CHALLENGE()} render={(props) => <TasteChallenge {...props} />} />
    <PrivateRoute path={urlPatterns.FORCE_RELOAD} component={ForceReload} exact />

    <PrivateRoute path={urlPatterns.CHECKOUT} component={Checkout} exact />

    <PrivateRoute path={urlPatterns.UPDATE_QUIZ()} component={Quiz} exact />

    <PrivateRoute path={urlPatterns.SELECT_WELCOME_PACK} component={SelectWelcomePack} exact />
    <PrivateRoute path={urlPatterns.SELECT_FREE_WINE_COLOR()} component={SelectFreeWineColor} exact />

    {/* TODO (Pending integration with new design) */}
    {/*
      <Route
        path={urlPatterns.FREE_BOX_CAMPAIGN()}
        render={props => <FreeBoxCampaign {...props} />}
        exact
      />
      */}
    <Route path={urlPatterns.NOT_FOUND} render={(props) => <NotFound {...props} />} exact />
    <Route render={(props) => <NotFound {...props} />} />
  </Switch>
);

export default Routes;
