import "../assets/fonts/BauPro/stylesheet.css";
import "../assets/fonts/Interstate/stylesheet.css";
import "../assets/fonts/Concept/stylesheet.css";

/* Colors */
export const colors = {
  white: "#ffffff",
  black: "#000000",
  gray: "#6C6C6C",
  middleGray: "#C3C3C3",
  lightgray: "#D3D3D3",
  grayBrown: "#80746D",
  darkGray: "#9E9C9C",
  burgundy: "#82314d",
  burgundyTint: "#A75671",
  success: "#94944A",
  successThin: "#D6D6A6",
  successSubtle: "#E2E1C2",
  purple: "#C7A8E0",
  purpleThin: "#E2D8EB",
  purpleThinTransparent: "rgba(226, 216, 235, 0.8)",
  purpleThinLessTransparent: "rgba(226, 216, 235, 0.85)",
  blue: "#CCE5FF",
  blueThin: "#DEEBF9",
  blueTint: "#A7D2FF",
  blueThinTransparent: "rgba(222, 235, 249, 0.8)",
  blueSoft: "#2E6ED4",
  warning: "#FFE000",
  warningThin: "#EFD880",
  warningSoft: "#F7ED90",
  warningSoftTransparent: "rgba(247, 237, 144, 0.3)",
  warningMidTransparent: "rgba(247, 237, 144, 0.5)",
  warningTransparent: "rgba(247, 237, 144, 0.6)",
  tan: "#EBAB78",
  tanThin: "#FBE9DB",
  coral: "#FF8361",
  coralGradient: "#FF9061",
  coralThin: "#FF9679",
  peach: "#FFE8D9",
  peachTint: "#FFF6EF",
  orange: "#FFF3EC",
  pink: "#FF9987",
  gold: "#FFBC26",
  desertSand: "#F3CDAE",
  pinkTint: "#F7BFCC",
  pinkSalmon: "#ff8d9b",
  pinkThinTransparent: "rgba(247, 191, 204, 0.6)",
  danger: "#E54533",
  dangerOpaque: "rgba(229,69,51, 0.1)",
  silver: "#eaeaea",
  silverSoft: "#f0f0f0",
  yellow: "#F7ED90",
  whiteOpaque: "#FFFAF7",
  whiteTransparent: "rgba(256, 256, 256, 0.5)",
  whiteLessTransparent: "rgba(255, 255, 255, 0.6)",
  blackTransparent: "rgba(0, 0, 0, 0.3)",
  blackLightTransparent: "rgba(0, 0, 0, 0.15)",
  dessert: "#72B4FA",
  orangeWine: "rgba(235, 171, 120, 0.7)",
  sakeWine: "#D6D6A6",
  transparent: "transparent",
  merchCardDefaultBackgroundColor: "#F3F3F3",
  youtubeDark: "#282828",
};

export const baseColors = {
  baseColor: colors.black,
  baseLinkColor: colors.black,
  baseLinkHoverColor: colors.black,
};

/* Typography */
export const fonts = {
  fontBauRegular: "BauPro-Regular",
  fontBauMedium: "BauPro-Medium",
  fontInterstateUltraBlack: "Interstate-UltraBlack",
  fontInterstateBlackCompressed: "Interstate-BlackCompressed",
  fontConceptRegular: "Concept-Regular",
};

export const typography = {
  baseFontSize: "14px",
  baseLineHeight: 1.4,
  baseFontFamily: fonts.fontBauRegular,
  titleLineHeight: 1.3,
  titleFontFamily: fonts.fontInterstateUltraBlack,
};

/* Transition */
export const transition = {
  baseTransition: "all 200ms ease",
  arrowNavigationContentTransition: "all 300ms",
  subMenuTransition: "all 400ms ease",
  headerMenuBarTransition: "all 300ms ease-in-out",
  headerMenuPaddingTransition: "padding 300ms ease-in-out",
  searchFilterBarTransition: "all 500ms ease-in-out",
  addWineToCartIconTransition: "all 600ms ease-in-out",
  checkoutQta: "all 750ms ease-in-out",
};

export const animationDurations = {
  sideTrayInSeconds: 0.4,
};

/* Header */
export const header = {
  desktopHeight: "80px",
  mobileHeight: "68px",
  tabBarHeight: "40px",
  withTabBarDesktopHeight: "120px",
  withTabBarMobileHeight: "108px",
  withTabBarMobileHeightBoxToggle: "136px",
  withBoxAndCartTrayMobileHeight: "120px", // Revert to 108px if we return to old box toggle
  withBoxAndCartTrayMobileHeightBoxToggle: "130px",
  withTabBarAndBoxCartTrayMobileHeight: "148px",
  withTabBarAndBoxCartTrayMobileHeightBoxToggle: "170px",
};

export const boxTrayToggleMobileHeight = "68px";

export const MOBILE_NAVBAR_IN_PX = 68;
const MOBILE_NAVBAR_ACCORDION_CLOSED_HEIGHT = 90;
const NAVBAR_WINES_FULL_HEIGHT = 730;
const NAVBAR_LEARN_FULL_HEIGHT = 574;
const NAVBAR_ABOUT_FULL_HEIGHT = 310;
const NAVBAR_GIFTS_FULL_HEIGHT = 310;
const NAVBAR_REWARDS_FULL_HEIGHT = 250;
const NAVBAR_REWARDS_LOGGED_IN_FULL_HEIGHT = 370;
const NAVBAR_ABOUT_LOGGED_IN_STATE_FULL_HEIGHT = 320;

export const NAVBAR_MEMBER_ACCORDION_HEIGHT = 320;

export const NAVBAR_WINES_HEIGHTS = {
  full: NAVBAR_WINES_FULL_HEIGHT,
  accordion: NAVBAR_WINES_FULL_HEIGHT - MOBILE_NAVBAR_ACCORDION_CLOSED_HEIGHT,
};
export const NAVBAR_LEARN_HEIGHTS = {
  full: NAVBAR_LEARN_FULL_HEIGHT,
  accordion: NAVBAR_LEARN_FULL_HEIGHT - MOBILE_NAVBAR_ACCORDION_CLOSED_HEIGHT,
};
export const NAVBAR_ABOUT_HEIGHTS = {
  full: NAVBAR_ABOUT_FULL_HEIGHT,
  accordion: NAVBAR_ABOUT_FULL_HEIGHT - MOBILE_NAVBAR_ACCORDION_CLOSED_HEIGHT,
};
export const NAVBAR_ABOUT_LOGGED_IN_STATE_HEIGHTS = {
  full: NAVBAR_ABOUT_LOGGED_IN_STATE_FULL_HEIGHT,
  accordion: NAVBAR_ABOUT_LOGGED_IN_STATE_FULL_HEIGHT - MOBILE_NAVBAR_ACCORDION_CLOSED_HEIGHT,
};
export const NAVBAR_GIFTS_HEIGHTS = {
  full: NAVBAR_GIFTS_FULL_HEIGHT,
  accordion: NAVBAR_GIFTS_FULL_HEIGHT - MOBILE_NAVBAR_ACCORDION_CLOSED_HEIGHT,
};

// TODO
export const NAVBAR_REWARDS_HEIGHTS = {
  full: NAVBAR_REWARDS_FULL_HEIGHT,
  accordion: NAVBAR_REWARDS_FULL_HEIGHT - MOBILE_NAVBAR_ACCORDION_CLOSED_HEIGHT,
};
export const NAVBAR_REWARDS_LOGGED_IN_STATE_HEIGHTS = {
  full: NAVBAR_REWARDS_LOGGED_IN_FULL_HEIGHT,
  accordion: NAVBAR_REWARDS_LOGGED_IN_FULL_HEIGHT - MOBILE_NAVBAR_ACCORDION_CLOSED_HEIGHT,
};

export const topHeroContainerHeight = 150;
export const topCellarHeroContainerHeight = 90;

export const BREAKPOINT = {
  XXS: 320,
  XS: 575,
  SM: 767,
  MD: 991,
  LG: 1199,
  XL: 1699,
};

export const CONTAINER_MAX_WIDTH = {
  XS_UP: 540,
  SM_UP: 720,
  MD_UP: 960,
  LG_UP: 1290,
};

export const confettiColors = [
  colors.coral,
  colors.tan,
  colors.warning,
  colors.blue,
  colors.pinkSalmon,
  colors.success,
  colors.successThin,
  colors.purple,
  colors.burgundyTint,
  colors.danger,
  colors.pinkTint,
];
