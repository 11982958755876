import React, { ReactElement } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { useTranslation } from "react-i18next";
import {
  I18N_NAMESPACES,
  MOBILE_NAVBAR_ACCORDIONS,
  UNLEASH_BEER_SUBSCRIPTION,
} from "../../../helpers/constants";
import urlPatterns from "../../../urls";
import AccordionItem from "../AccordionItem";
import {
  AccordionTopPart,
  StyledChevron,
  AccordionWrapper,
  AccordionContentWrapper,
} from "../MobileHeaderNavbar/style";
import { useAppContext } from "../../organisms/Layout/context";
import { MemberLayoutInfo_collections } from "../../../types/__generated__/MemberLayoutInfo";

interface MobileProductsAccordionProps {
  openAccordion: string | undefined;
  setOpenAccordion: Function;
}

const MobileProductsAccordion = ({
  openAccordion,
  setOpenAccordion,
}: MobileProductsAccordionProps): ReactElement => {
  const isThisAccordionOpen = openAccordion === MOBILE_NAVBAR_ACCORDIONS.PRODUCTS;
  const { t } = useTranslation([I18N_NAMESPACES.COMMON]);
  const isBeerSubscriptionEnabled = useFlag(UNLEASH_BEER_SUBSCRIPTION);
  const { collections } = useAppContext();

  const itemHeightInPx = 55;
  const accordionTopPartHeightInPx = 90;
  const accordionContentWrapperPaddingInPx = 32;
  const bothBordersHeightInPx = 2;

  const collectionsLength = collections?.length || 0;
  const itemsInAccordion = isBeerSubscriptionEnabled ? collectionsLength + 1 : collectionsLength;

  const minHeight =
    accordionTopPartHeightInPx + accordionContentWrapperPaddingInPx + itemHeightInPx + bothBordersHeightInPx;
  const height = minHeight + itemHeightInPx * itemsInAccordion;

  return (
    <AccordionWrapper
      open={isThisAccordionOpen}
      height={height}
      onClick={() => setOpenAccordion(isThisAccordionOpen ? "" : MOBILE_NAVBAR_ACCORDIONS.PRODUCTS)}
    >
      <AccordionTopPart isThisAccordionOpen={isThisAccordionOpen}>
        {t("common:products")}
        <StyledChevron open={isThisAccordionOpen} />
      </AccordionTopPart>
      <AccordionContentWrapper open={isThisAccordionOpen} height={height}>
        {isBeerSubscriptionEnabled && (
          <AccordionItem i18nKey={"common:beerClub"} url={urlPatterns.BEER_LANDING} />
        )}
        <AccordionItem i18nKey={"common:allProducts"} url={urlPatterns.ALL_PRODUCT_COLLECTIONS} />
        {collections?.map((collection: MemberLayoutInfo_collections | null) => (
          <AccordionItem
            i18nKey={collection?.name || ""}
            url={urlPatterns.PRODUCT_COLLECTION(collection?.slug)}
            key={collection?.slug}
          />
        ))}
      </AccordionContentWrapper>
    </AccordionWrapper>
  );
};

export default MobileProductsAccordion;
