import { gql } from "@apollo/client";

/**
 * QUERIES
 * */

export const GET_ALL_TASTE_CHALLENGE_QUESTIONS = gql`
  query AllTasteChallengeQuestions {
    allTasteChallengeQuestions {
      id
      text
      sortOrder
      tastechallengeanswerSet {
        id
        text
        description
        graphicalIdentifier
      }
    }
  }
`;

export const GET_TASTE_CHALLENGE_RESULTS = gql`
  query TasteChallengeResults($memberId: Int, $wineSlug: String!) {
    tasteChallengeResults(memberId: $memberId, wineSlug: $wineSlug) {
      id
      score
      progress
      averageScore
      numberOfAnswers
    }
  }
`;

/**
 * MUTATIONS
 * */

export const SUBMIT_TASTE_CHALLENGE_ANSWER = gql`
  mutation SubmitTasteChallengeAnswer($input: SubmitTasteChallengeAnswerInput!) {
    submitTasteChallengeAnswer(input: $input) {
      isCorrect
      correctAnswer {
        id
        description
      }
      errors {
        messages
        field
      }
    }
  }
`;
