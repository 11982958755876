import { WINE_LIST_SLUGS } from "../../../helpers/constants";
import URL_PATTERNS from "../../../urls";

export const DEFAULT_NOTIFICATION_LOCATIONS = [
  URL_PATTERNS.HOME,
  URL_PATTERNS.SEND_GIFT,
  URL_PATTERNS.GIFT_LANDING,
  URL_PATTERNS.ABOUT,
  URL_PATTERNS.QUIZ_RESULTS,
  URL_PATTERNS.WINE_LIST(WINE_LIST_SLUGS.ALL_WINES),
  URL_PATTERNS.WINE_LIST(WINE_LIST_SLUGS.NEW_RELEASES),
  URL_PATTERNS.WINES,
  URL_PATTERNS.MY_DASHBOARD,
];
