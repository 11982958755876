import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";

import { colors, header } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";
import { checkIfScrollLockInSideTrayShouldBeActive } from "../../../helpers/tools";

const StyledOffCanvasTray = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 6001;
  display: ${(props) => (props.isOffcanvasOpened ? "block" : "none")};
  ${breakpoints.mdUp} {
    ${(props) =>
      !props.isInBoxAndCartTray &&
      css`
        top: ${props.fullViewMask ? 0 : header.desktopHeight};
        height: calc(100% - ${props.fullViewMask ? 0 : header.desktopHeight});
      `};
  }
  ${breakpoints.smDown} {
    ${(props) =>
      props.isInBoxAndCartTray &&
      css`
        top: ${header.mobileHeight};
      `}
  }
`;

const viewFadingIn = keyframes`
  from { opacity: 0; }
  to { opacity: 0.5; }
`;

const OffCanvasTrayMask = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: ${(props) => (props.isOffcanvasOpened && props.isMaskVisible ? "0.5" : "0")};
  background-color: ${colors.black};
  cursor: pointer;
  ${(props) =>
    props.isOffcanvasOpened &&
    props.shouldOffCanvasTrayMaskAnimate &&
    css`
      animation: ${viewFadingIn} 0.5s ease-out;
    `}
`;

const OffCanvasTray = (props) => {
  const {
    isOffcanvasOpened,
    children,
    setOverflowHidden,
    outSideClickHandler,
    isScrollLockEnabled,
    isInBoxAndCartTray,
    fullViewMask,
    disableScrollLockInSideTray,
    shouldOffCanvasTrayMaskAnimate,
    isMaskVisible,
  } = props;

  if (setOverflowHidden) {
    if (isOffcanvasOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }

  useEffect(() => {
    const activateScrollLock = isScrollLockEnabled !== null ? isScrollLockEnabled : isOffcanvasOpened;
    const validatedActivateScrollLock = disableScrollLockInSideTray
      ? checkIfScrollLockInSideTrayShouldBeActive(activateScrollLock)
      : activateScrollLock;

    if (validatedActivateScrollLock) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "unset";
      };
    }

    return undefined;
  }, [isScrollLockEnabled, isOffcanvasOpened, disableScrollLockInSideTray]);

  return (
    <StyledOffCanvasTray
      isOffcanvasOpened={isOffcanvasOpened}
      isInBoxAndCartTray={isInBoxAndCartTray}
      fullViewMask={fullViewMask}
    >
      <OffCanvasTrayMask
        isOffcanvasOpened={isOffcanvasOpened}
        shouldOffCanvasTrayMaskAnimate={shouldOffCanvasTrayMaskAnimate}
        isMaskVisible={isMaskVisible}
      />
      <OutsideClickHandler onOutsideClick={outSideClickHandler}>{children}</OutsideClickHandler>
    </StyledOffCanvasTray>
  );
};

OffCanvasTray.propTypes = {
  children: PropTypes.node.isRequired,
  isOffcanvasOpened: PropTypes.bool,
  setOverflowHidden: PropTypes.bool,
  outSideClickHandler: PropTypes.func,
  isScrollLockEnabled: PropTypes.bool,
  isInBoxAndCartTray: PropTypes.bool,
  fullViewMask: PropTypes.bool,
  disableScrollLockInSideTray: PropTypes.bool,
  shouldOffCanvasTrayMaskAnimate: PropTypes.bool,
  isMaskVisible: PropTypes.bool,
};

OffCanvasTray.defaultProps = {
  isOffcanvasOpened: false,
  setOverflowHidden: true,
  outSideClickHandler: () => {},
  isScrollLockEnabled: null,
  isInBoxAndCartTray: false,
  fullViewMask: false,
  disableScrollLockInSideTray: false,
  shouldOffCanvasTrayMaskAnimate: false,
  isMaskVisible: true,
};

export default OffCanvasTray;
