import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { isLoggedIn } from "../../../helpers/auth";
import URL_PATTERNS from "../../../urls";
import {
  I18N_NAMESPACES,
  DB_ID_SUBSCRIPTION_STATUS_ACTIVE,
  DB_ID_SUBSCRIPTION_STATUS_SKIP,
} from "../../../helpers/constants";
import {
  NAVBAR_DESKTOP_LEARN_ABOUT_ITEMS,
  NAVBAR_DESKTOP_LEARN_ABOUT_ITEMS_FOR_UNSUBSCRIBED_MEMBERS,
  NAVBAR_DESKTOP_LEARN_BLOG_ITEMS,
  NAVBAR_DESKTOP_LEARN_GUIDES_ITEMS,
  NAVBAR_DESKTOP_LEARN_TASTE_PROFILE_ITEMS,
} from "../../../helpers/navbarProcessor";
import { useHeaderContext } from "../../organisms/Header/context";
import {
  DropdownContentWrapper,
  SectionColumn,
  ColumnTitle,
  ItemWrapperLink,
  ItemName,
  Cross,
} from "../../organisms/Header/style";
import { MemberLayoutInfo } from "../../../types/__generated__/MemberLayoutInfo";

import { MyTastesIcon } from "../DesktopMemberDropdown/style";
import { AboutIcon, BlogIcon, GuidesIcon, LearnDropdownImage, LearnDropdownWrapper } from "./style";

interface NavbarItemProps {
  i18nKey: string;
  url: string;
  isExternalUrl?: boolean;
}

interface DesktopLearnDropdownProps {
  onCrossClicked: () => void;
  isAnyDropdownActive: boolean;
  memberLayoutInfoData: MemberLayoutInfo | undefined;
}

const DesktopLearnDropdown = ({
  onCrossClicked,
  isAnyDropdownActive,
  memberLayoutInfoData,
}: DesktopLearnDropdownProps) => {
  const { t } = useTranslation([I18N_NAMESPACES.COMMON, I18N_NAMESPACES.TOP_NAVBAR]);

  const { setActiveDesktopMenuItem } = useHeaderContext();

  const isMemberNotActivated =
    !memberLayoutInfoData?.me?.subscription?.subscriptionStatus?.id ||
    ![DB_ID_SUBSCRIPTION_STATUS_SKIP, DB_ID_SUBSCRIPTION_STATUS_ACTIVE].includes(
      memberLayoutInfoData?.me?.subscription.subscriptionStatus?.id
    );

  const renderNavbarItem = ({ i18nKey, url, isExternalUrl }: NavbarItemProps): ReactElement => {
    const linkUrl = url ?? URL_PATTERNS.MY_DASHBOARD;
    return (
      <ItemWrapperLink
        to={isExternalUrl ? { pathname: linkUrl } : linkUrl}
        target={isExternalUrl ? "_blank" : ""}
        onClick={() => setActiveDesktopMenuItem("")}
        key={i18nKey}
        smooth
      >
        <ItemName>{t(i18nKey)}</ItemName>
      </ItemWrapperLink>
    );
  };

  const renderAboutSection = (): ReactElement => {
    const itemOrder = isLoggedIn()
      ? NAVBAR_DESKTOP_LEARN_ABOUT_ITEMS_FOR_UNSUBSCRIBED_MEMBERS
      : NAVBAR_DESKTOP_LEARN_ABOUT_ITEMS;
    return (
      <>
        <AboutIcon />
        <SectionColumn>
          <ColumnTitle>{t("topNavbar:about")}</ColumnTitle>
          {itemOrder.map((item) => renderNavbarItem(item))}
        </SectionColumn>
      </>
    );
  };

  const renderGuidesSection = (): ReactElement => (
    <>
      <GuidesIcon />
      <SectionColumn>
        <ColumnTitle>{t("topNavbar:wineGuides")}</ColumnTitle>
        {NAVBAR_DESKTOP_LEARN_GUIDES_ITEMS.map((item) => renderNavbarItem(item))}
      </SectionColumn>
    </>
  );

  const renderBlogSection = (): ReactElement => (
    <>
      <BlogIcon />
      <SectionColumn>
        <ColumnTitle>{t("common:blog")}</ColumnTitle>
        {NAVBAR_DESKTOP_LEARN_BLOG_ITEMS.map((item) => renderNavbarItem(item))}
      </SectionColumn>
    </>
  );

  const renderTasteProfileSection = (): ReactElement => (
    <>
      <MyTastesIcon />
      <SectionColumn>
        <ColumnTitle>{t("topNavbar:myTaste")}</ColumnTitle>
        {NAVBAR_DESKTOP_LEARN_TASTE_PROFILE_ITEMS.map((item) => renderNavbarItem(item))}
      </SectionColumn>
    </>
  );

  const shouldRenderAboutSection = !isLoggedIn() || isMemberNotActivated;
  const shouldRenderTasteProfileSection = !shouldRenderAboutSection;

  return (
    <LearnDropdownWrapper isAnyDropdownActive={isAnyDropdownActive}>
      <LearnDropdownImage />
      <DropdownContentWrapper>
        {shouldRenderAboutSection && renderAboutSection()}
        {renderGuidesSection()}
        {renderBlogSection()}
        {shouldRenderTasteProfileSection && renderTasteProfileSection()}
        <Cross onClick={() => onCrossClicked()} />
      </DropdownContentWrapper>
    </LearnDropdownWrapper>
  );
};

export default DesktopLearnDropdown;
