import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";
import { boxTrayToggleMobileHeight } from "../../../styles/variables";

export const BoxCartTogglesWrapper = styled.div`
  ${breakpoints.smDown} {
    position: relative;
    height: ${boxTrayToggleMobileHeight};
    z-index: 1;
  }
`;
