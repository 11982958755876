import { getRestAPIURL } from "./urls.helper";
import { logError } from "./logging";

const baseURL = getRestAPIURL();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EMPTY_PARAM_DATA: any = {
  params: null,
  data: null,
  responseType: null,
};

/**
 * Executes ajax call using fetch.
 *
 * @param httpMethod
 * @param urlPath
 * @param data
 * @return {Object}
 * */
const executeRestApi = async (httpMethod: string, urlPath: string, { data = null } = EMPTY_PARAM_DATA) => {
  try {
    let response;
    if (data) {
      response = await fetch(baseURL + urlPath, {
        method: httpMethod,
        body: JSON.stringify(data),
      });
    } else {
      response = await fetch(baseURL + urlPath, { method: httpMethod });
    }
    const jsonData = await response.json();
    if (!response.ok) {
      throw jsonData;
    }
    return jsonData;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    logError(error);

    // Throws error which should be handled in the caller function.
    throw error;
  }
};

export default executeRestApi;
