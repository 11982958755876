import styled from "styled-components";
import learnDropdownWebpImage from "../../../assets/images/general/learn-dropdown.webp";
import learnDropdownImage from "../../../assets/images/general/learn-dropdown.png";
import aboutIcon from "../../../assets/images/icons/about-transparent.svg";
import blogIcon from "../../../assets/images/icons/blog-transparent.svg";
import bookImage from "../../../assets/images/icons/book-transparent.svg";
import Image from "../../atoms/Image";
import { ColumnTitleIcon } from "../../organisms/Header/style";

export const LearnDropdownWrapper = styled.div<{
  isAnyDropdownActive: boolean;
}>`
  display: ${(props) => (props.isAnyDropdownActive ? "flex" : "none")};
  height: 100%;
  flex-direction: row;
`;

export const LearnDropdownImage = styled(Image).attrs({
  src: learnDropdownImage,
  webpSrc: learnDropdownWebpImage,
  containerStyle: { display: "contents" },
})`
  max-width: 30%;
  object-fit: cover;
  height: 380px;
`;

export const AboutIcon = styled(ColumnTitleIcon).attrs({ src: aboutIcon })``;

export const BlogIcon = styled(ColumnTitleIcon).attrs({ src: blogIcon })``;

export const GuidesIcon = styled(ColumnTitleIcon).attrs({ src: bookImage })``;
