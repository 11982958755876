import { createContext, useContext } from "react";

interface HeaderContextProps {
  setIsMobileMenuOpen: (isOpen: boolean) => void;
  setActiveDesktopMenuItem: (activeDesktopMenuItem: string) => void;
}

const initialState: HeaderContextProps = {
  setIsMobileMenuOpen: () => {},
  setActiveDesktopMenuItem: () => {},
};

export const HeaderContext = createContext<HeaderContextProps>(initialState);

export const useHeaderContext = () => useContext(HeaderContext);
