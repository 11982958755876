import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const StyledImage = styled.img`
  width: ${(props) => props.full && "100%"};
  max-width: ${(props) => props.maxFull && "100%"};
  display: inline-block;
  vertical-align: middle;
  object-fit: ${(props) => props.cover && "cover"};
  height: ${(props) => props.cover && "100%"};
  ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `};
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `};
`;

const Image = ({ full, maxFull, cover, webpSrc, containerStyle, ...attrs }) => {
  if (!webpSrc || !attrs.src) {
    return <StyledImage {...attrs} full={full} maxFull={maxFull} cover={cover} />;
  }

  const originalSrc = attrs.src;
  const legacySourceType = originalSrc.split(".").pop() === "png" ? "image/png" : "image/jpeg";

  return (
    <picture style={containerStyle}>
      <source type="image/webp" srcSet={webpSrc} />
      <source type={legacySourceType} srcSet={originalSrc} />
      <StyledImage {...attrs} full={full} maxFull={maxFull} cover={cover} />
    </picture>
  );
};

Image.propTypes = {
  full: PropTypes.bool,
  maxFull: PropTypes.bool,
  cover: PropTypes.bool,
  webpSrc: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Image.defaultProps = {
  full: false,
  maxFull: false,
  cover: false,
  webpSrc: null,
  height: null,
  width: null,
};

export default Image;
