import React from "react";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACES } from "../../../helpers/constants";
import BoxCartToggleIcons from "../BoxCartToggleIcons";
import {
  BoxCartHeaderToggleWrapper,
  BoxCartHeaderToggleInnerWrapper,
  Title,
  StyledArrowHeadDown,
} from "./styles";

interface BoxCartHeaderToggleProps {
  isMonthlyBox: boolean;
  isSelected: boolean;
  isOpen: boolean;
  onToggleClick: () => void;
  amountBottles: number;
  amountPresents: number;
}

const BoxCartHeaderToggle = ({
  isMonthlyBox,
  isSelected,
  onToggleClick,
  isOpen,
  amountBottles,
  amountPresents,
}: BoxCartHeaderToggleProps) => {
  const { t } = useTranslation([I18N_NAMESPACES.COMMON]);
  return (
    <BoxCartHeaderToggleWrapper isSelected={isSelected} onClick={onToggleClick}>
      <BoxCartHeaderToggleInnerWrapper>
        <Title>{isMonthlyBox ? t("common:monthlyBox") : t("common:oneOffBox")}</Title>
        <StyledArrowHeadDown isOpen={isOpen} isSelected={isSelected} />
        <BoxCartToggleIcons
          variant={"header"}
          isSelected={isSelected}
          amountBottles={amountBottles}
          amountPresents={amountPresents}
        />
      </BoxCartHeaderToggleInnerWrapper>
    </BoxCartHeaderToggleWrapper>
  );
};

export default BoxCartHeaderToggle;
