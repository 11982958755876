import React, { useEffect } from "react";

import styled, { keyframes } from "styled-components";

import { useMutation, useQuery } from "@apollo/client";
import { colors, fonts } from "../../../styles/variables";
import { MARK_NOTIFICATIONS_AS_READ } from "../../../graphql/resolvers/member";

import SideTray from "../../atoms/SideTray";
import Notification from "../../atoms/Notification";
import Loading from "../../atoms/Loading";
import { GET_MEMBER_RECENT_NOTIFICATIONS } from "../../../graphql/queries";

import cross from "../../../assets/images/icons/cross.svg";
import { MemberRecentNotifications } from "../../../types/__generated__/MemberRecentNotifications";
import { MarkNotificationAsRead } from "../../../types/__generated__/MarkNotificationAsRead";
import breakpoints from "../../../styles/breakpoints";

const trayDown = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const trayUp = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const Cross = styled.img.attrs({
  src: cross,
})`
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 20px;
  position: absolute;
  left: 0;
  top: 0;
`;

const NotificationHeader = styled.div`
  font-family: ${fonts.fontInterstateUltraBlack};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
`;

const StyledSideTray = styled(SideTray)<{ isOpened?: boolean }>`
  text-align: left;
  padding-bottom: 80px;
  cursor: default;
  height: 100vh;
  position: fixed;
  top: 80px;
  ${breakpoints.smDown} {
    animation: ${(props) => (!props.isOpened ? trayDown : trayUp)};
    top: 0;
  }
`;

interface NotificationTrayProps {
  isOpened: boolean;
  onClose: () => void;
}

const NotificationTray = (props: NotificationTrayProps) => {
  const { isOpened, onClose } = props;
  const {
    loading: notificationDataLoading,
    data: notificationData,
    refetch: refetchNotificationData,
  } = useQuery<MemberRecentNotifications>(GET_MEMBER_RECENT_NOTIFICATIONS, {
    skip: !isOpened,
  });
  const [markNotificationsAsRead] = useMutation<MarkNotificationAsRead>(MARK_NOTIFICATIONS_AS_READ);

  useEffect(() => {
    const memberId = notificationData?.me?.id;
    const unreadNotificationIds = notificationData?.me?.recentNotifications
      ?.filter((x) => !x?.isNotificationRead)
      .map((x) => x?.id);

    if (unreadNotificationIds?.length) {
      markNotificationsAsRead({
        variables: {
          input: {
            memberId,
            activityIds: unreadNotificationIds,
          },
        },
      });
    }
  }, [notificationDataLoading]);

  useEffect(() => {
    isOpened && refetchNotificationData();
  }, [isOpened]);

  return (
    <StyledSideTray isOpened={isOpened} color={colors.white} onClickBackdrop={onClose} shouldAnimate>
      <NotificationHeader>
        <Cross onClick={onClose} />
        Notifications
      </NotificationHeader>
      {!notificationDataLoading &&
        notificationData?.me?.recentNotifications?.map(
          (notification) => notification !== null && <Notification key={notification?.id} {...notification} />
        )}
      {notificationDataLoading && <Loading />}
    </StyledSideTray>
  );
};

export default NotificationTray;
