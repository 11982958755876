import React, { ReactElement } from "react";

import styled from "styled-components";

import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import {
  InfoPanel,
  InfoPanelItem,
  InfoPanelHeader,
  InfoPanelHeaderIcon,
  InfoPanelHeaderTitle,
} from "../../atoms/InfoPanel";

import infoPanelIconPause from "../../../assets/images/temp/home_pause.svg";
import infoPanelIconNoBad from "../../../assets/images/temp/home_nobadbottles.svg";
import infoPanelIconSettings from "../../../assets/images/temp/home_settings.svg";
import infoPanelIconRewards from "../../../assets/images/temp/home_rewards.svg";
import infoPanelIconShipping from "../../../assets/images/temp/home_shipping.svg";
import InfoPanelIconShippingUK from "../../../assets/images/temp/UK_black_fill.svg";
import infoPanelIconFees from "../../../assets/images/temp/home_fees.svg";
import scrollbarThumbIcon from "../../../assets/images/icons/arrows.svg";
import line from "../../../assets/images/icons/line.svg";
import breakpoints from "../../../styles/breakpoints";
import { I18N_NAMESPACES } from "../../../helpers/constants";
import { getCountrySettings } from "../../../helpers/tools";
import { useAppContext } from "../../organisms/Layout/context";

const StyledScrollbar = styled.div`
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-track {
    display: auto !important;
    height: 50px !important;
    -webkit-appearance: auto;
  }

  // Thumb styles, with white background, black border, and double arrow in middle.
  ::-webkit-scrollbar-thumb {
    border: 2px solid black;
    background-position: 50% 50% !important;
    background-size: 24px !important;
    background-image: url(${scrollbarThumbIcon}) !important;
    background-repeat: no-repeat !important;
    background-color: white;
  }

  // track styles with line in center.
  ${breakpoints.mdDown} {
    ::-webkit-scrollbar-track {
      height: 2px;
      background-position: 50% 50% !important;
      background-image: url(${line}) !important;
      background-repeat: repeat-x !important;
      transform: translateY(50%);
    }
  }
`;

const SellingPoints = () => {
  const { t } = useTranslation(I18N_NAMESPACES.HOME);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { IS_COUNTRY_UK }: any = getCountrySettings();
  const { minBottlesForFreeShipping } = useAppContext();

  const rewardSellingPointTitle = "Points & rewards";
  const rewardSellingPointText =
    "Earn points for trying new wines. Redeem for exclusive merch and pre-release wines.";

  const renderNoSubscriptionSellingPoint = (): ReactElement => (
    <InfoPanelItem>
      <InfoPanelHeader>
        <LazyLoad once offset={500} height={100}>
          <InfoPanelHeaderIcon src={infoPanelIconFees} alt="No subscription fees" />
        </LazyLoad>
        <InfoPanelHeaderTitle>No subscription fees</InfoPanelHeaderTitle>
      </InfoPanelHeader>
      <p>You just pay for the bottles you receive.</p>
    </InfoPanelItem>
  );

  const renderRewardSellingPoint = (): ReactElement => (
    <InfoPanelItem>
      <InfoPanelHeader>
        <LazyLoad once offset={500} height={100}>
          <InfoPanelHeaderIcon src={infoPanelIconRewards} alt={rewardSellingPointTitle} />
        </LazyLoad>
        <InfoPanelHeaderTitle>{rewardSellingPointTitle}</InfoPanelHeaderTitle>
      </InfoPanelHeader>
      <p>{rewardSellingPointText}</p>
    </InfoPanelItem>
  );

  return (
    <>
      <StyledScrollbar>
        <InfoPanel>
          <InfoPanelItem>
            <InfoPanelHeader>
              <LazyLoad once offset={500} height={100}>
                <InfoPanelHeaderIcon
                  src={IS_COUNTRY_UK ? InfoPanelIconShippingUK : infoPanelIconShipping}
                  alt={IS_COUNTRY_UK ? "United Kingdom" : "Australia"}
                />
              </LazyLoad>
              <InfoPanelHeaderTitle>{t("home:freeShippingTitle")}</InfoPanelHeaderTitle>
            </InfoPanelHeader>
            <p>
              {t("home:freeShippingHeader", {
                minBottlesForFreeShipping,
              })}
            </p>
          </InfoPanelItem>
          {renderRewardSellingPoint()}
          <InfoPanelItem>
            <InfoPanelHeader>
              <LazyLoad once offset={500} height={100}>
                <InfoPanelHeaderIcon src={infoPanelIconNoBad} alt="Happiness Promise" />
              </LazyLoad>
              <InfoPanelHeaderTitle>Happiness promise</InfoPanelHeaderTitle>
            </InfoPanelHeader>
            <p>Don’t love a wine? We’ll add a free wine to your next box. No questions asked.</p>
          </InfoPanelItem>
          <InfoPanelItem>
            <InfoPanelHeader>
              <LazyLoad once offset={500} height={100}>
                <InfoPanelHeaderIcon src={infoPanelIconPause} alt="Cancel anytime" />
              </LazyLoad>
              <InfoPanelHeaderTitle>Cancel anytime</InfoPanelHeaderTitle>
            </InfoPanelHeader>
            <p>Cancel, skip or change delivery frequency whenever you like. You’re never locked in.</p>
          </InfoPanelItem>
          <InfoPanelItem>
            <InfoPanelHeader>
              <LazyLoad once offset={500} height={100}>
                <InfoPanelHeaderIcon src={infoPanelIconSettings} alt="Go your own way" />
              </LazyLoad>
              <InfoPanelHeaderTitle>Go your own way</InfoPanelHeaderTitle>
            </InfoPanelHeader>
            <p>Customise your preferences, grapes to avoid, and change your settings anytime.</p>
          </InfoPanelItem>
          {renderNoSubscriptionSellingPoint()}
        </InfoPanel>
      </StyledScrollbar>
    </>
  );
};

export default SellingPoints;
