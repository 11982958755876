import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { colors, fonts } from "../../../styles/variables";
import { I18N_NAMESPACES } from "../../../helpers/constants";
import breakpoints from "../../../styles/breakpoints";
import Loading from "../Loading";

export const PointsWrapper = styled(Link)<{ variant?: "default" | "header"; isClickable: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.warningSoft};
  height: 100%;

  ${(props) =>
    !props.isClickable &&
    css`
      pointer-events: none;
      cursor: default;
    `}

  ${(props) =>
    props.variant === "default" &&
    css`
      padding: 8px 16px;
      border-radius: 10px;
      ${breakpoints.smDown} {
        padding: 7px 8px;
      }
    `}
  
  ${(props) =>
    props.variant === "header" &&
    css`
      padding: 4px 18px;
      border-radius: 99px;
      margin-right: 16px;
      ${breakpoints.smDown} {
        padding: 8px 4px;
        border-radius: 10px;
        margin-right: 0px;
      }
    `}
`;

export const PointsNumber = styled.div<{ variant?: "default" | "header" }>`
  ${(props) =>
    props.variant === "default" &&
    css`
      font-size: 40px;
      line-height: 40px;
      letter-spacing: 2px;
      font-family: ${fonts.fontInterstateBlackCompressed};
      ${breakpoints.smDown} {
        font-size: 25px;
        line-height: 25px;
      }
    `}
  ${(props) =>
    props.variant === "header" &&
    css`
      font-size: 12px;
      line-height: 17px;
      font-family: ${fonts.fontBauMedium};
      ${breakpoints.smDown} {
        font-size: 11px;
        line-height: 16px;
      }
    `}
`;

export const PointsText = styled.div<{ variant?: "default" | "header" }>`
  font-family: ${fonts.fontBauMedium};
  text-transform: uppercase;
  ${(props) =>
    props.variant === "default" &&
    css`
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 1.1px;
    `}
  ${(props) =>
    props.variant === "header" &&
    css`
      font-size: 8px;
      line-height: 10px;
      ${breakpoints.smDown} {
        font-size: 7px;
        line-height: 10px;
        letter-spacing: 1.05px;
      }
    `}
`;

export const PointsBalanceWithLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  padding: 8px 14px;
  ${PointsWrapper} {
    background-color: ${colors.warningTransparent};
  }
  ${PointsNumber} {
    font-size: 25px;
    line-height: 25px;
  }
`;

export const PointsBalanceLabel = styled.span`
  font-size: 25px;
  font-family: ${fonts.fontInterstateBlackCompressed};
  margin-right: 5px;
  padding-left: 6px;
`;

export interface MemberPointsBalanceProps {
  points: number | null | undefined;
  variant?: "default" | "header";
  link?: string;
}

const MemberPointsBalance = ({ points, variant = "default", link }: MemberPointsBalanceProps) => {
  const { t } = useTranslation([I18N_NAMESPACES.COMMON]);

  const isHeader = variant === "header";

  const loadingStyles = css`
    width: 10px;
    height: 10px;
    position: relative;
  `;

  const isPointsLoading = points === null || points === undefined;

  return (
    <PointsWrapper variant={variant} to={link || ""} isClickable={!!link}>
      {isPointsLoading ? (
        isHeader && <Loading spinnerStyle={loadingStyles} />
      ) : (
        <PointsNumber variant={variant}>{points}</PointsNumber>
      )}
      <PointsText variant={variant}>{t("common:points")}</PointsText>
    </PointsWrapper>
  );
};

export const MemberPointsBalanceWithLabel = ({
  points,
  variant = "default",
  link,
}: MemberPointsBalanceProps) => {
  const { t } = useTranslation([I18N_NAMESPACES.COMMON]);

  return (
    <PointsBalanceWithLabelWrapper>
      <PointsBalanceLabel>{t("common:pointsBalance")}</PointsBalanceLabel>
      <MemberPointsBalance points={points} variant={variant} link={link} />
    </PointsBalanceWithLabelWrapper>
  );
};

export default MemberPointsBalance;
