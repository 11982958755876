import React from "react";
import { Helmet } from "react-helmet";

export interface GenericJsonDataProps {
  /**
   * The name of the Item. E.g. if the schema is of type Article this will be the name of the article.
   * If the schema is of type WebPage this will be the name of the WebPage.
   */
  name: string;

  /**
   * A description of the item. It can be a sentence or two and include information about the of the item
   * and/or data what is displayed to the user.
   */
  description: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const JsonLdGeneric = (props: any) => {
  const { jsonData } = props;
  return (
    <Helmet>
      <script className="structured-data-list" type="application/ld+json">
        {JSON.stringify(jsonData)}
      </script>
    </Helmet>
  );
};

export default JsonLdGeneric;
