import React from "react";
import PropTypes from "prop-types";

import styled, { css } from "styled-components";

import { formatDate } from "../../../helpers/dates";

import gift from "../../../assets/images/icons/gift.svg";
import { colors } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";

const NotificationWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  ${(props) =>
    !props.isRead &&
    css`
      background-color: ${colors.peachTint};
    `};
  & > :first-child {
    flex: 1 1 auto;
    margin-right: 15px;
  }
`;

const Icon = styled.img`
  width: 30px;
  object-fit: scale-down;
`;

const UnreadMark = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: ${colors.coral};
  border-radius: 10px;
  margin: 10px;
`;

const Date = styled.div`
  font-size: 12px;
`;

const MessageWrapper = styled.div`
  max-width: 90%;
  ${(props) =>
    !props.isRead &&
    css`
      max-width: 80%;
    `};

  ${breakpoints.smDown} {
    max-width: 85%;

    ${(props) =>
      !props.isRead &&
      css`
        max-width: 75%;
      `};
  }
`;

function NotificationItem(props) {
  const { children, isRead, icon, createdDate } = props;

  const date = formatDate(createdDate, "DD MMMM [at] h:MMa", true);

  return (
    <NotificationWrapper isRead={isRead}>
      <MessageWrapper isRead={isRead}>
        {children}
        <Date>{date}</Date>
      </MessageWrapper>
      <div>
        <Icon src={icon || gift} />
        {!isRead && <UnreadMark />}
      </div>
    </NotificationWrapper>
  );
}

NotificationItem.propTypes = {
  isRead: PropTypes.bool,
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  createdDate: PropTypes.string.isRequired,
};

NotificationItem.defaultProps = {
  isRead: true,
  icon: undefined,
};

export default NotificationItem;
