import styled from "styled-components";

export default styled.img`
  position: absolute;
  left: 25px;
  top: 25px;
  width: 15px;
  height: 15px;
  cursor: pointer;
`;
