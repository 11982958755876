import React from "react";
import styled from "styled-components";

import PropTypes from "prop-types";
import { colors, fonts } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";

const H1 = styled.h1`
  font-size: 50px;
  line-height: 0.9;
  color: ${(props) => props.white && colors.white};
  font-family: ${(props) =>
    props.fontSecondary ? fonts.fontInterstateBlackCompressed : fonts.fontInterstateUltraBlack};
  text-transform: ${(props) => props.uppercase && "uppercase"};
  text-align: ${(props) => props.center && "center"};
  margin-bottom: ${(props) =>
    (props.mbSmall && "10px !important") ||
    (props.mbMedium && "20px !important") ||
    (props.mb0 && "0px !important") ||
    "35px"};
  word-break: break-word;
  ${breakpoints.smUp} {
    margin-bottom: ${(props) => props.mbSmOnly && "0px !important"};
  }
  ${breakpoints.mdDown} {
    margin-bottom: ${(props) => props.mbMd && "20px !important"};
  }
  ${breakpoints.smDown} {
    font-size: 45px;
    margin-bottom: ${(props) => props.mbSm && "20px !important"};
    display: ${(props) => props.hiddenSm && "none !important"};
  }
  ${breakpoints.xsDown} {
    font-size: 40px;
  }
`;

const H2 = styled.h2`
  font-size: 50px;
  line-height: 0.9;
  color: ${(props) => props.white && colors.white};
  font-family: ${(props) =>
    props.fontSecondary ? fonts.fontInterstateBlackCompressed : fonts.fontInterstateUltraBlack};
  word-break: break-word;
  text-transform: ${(props) => props.uppercase && "uppercase"};
  text-align: ${(props) => props.center && "center"};
  margin-bottom: ${(props) =>
    (props.mbSmall && "10px !important") ||
    (props.mbMedium && "20px !important") ||
    (props.mb0 && "0px !important") ||
    "35px"};
  ${breakpoints.smUp} {
    margin-bottom: ${(props) => props.mbSmOnly && "0px !important"};
  }
  ${breakpoints.mdDown} {
    margin-bottom: ${(props) => props.mbMd && "20px !important"};
  }
  ${breakpoints.smDown} {
    font-size: 45px;
    text-align: ${(props) => props.centerSm && "center"};
    margin-bottom: ${(props) => props.mbSm && "20px !important"};
    display: ${(props) => props.hiddenSm && "none !important"};
  }
`;

const H3 = styled.h3`
  font-size: 25px;
  line-height: ${(props) => (props.tightLineHeight ? "0.9" : "1.2")};
  color: ${(props) => props.white && colors.white};
  font-family: ${(props) =>
    props.fontSecondary ? fonts.fontInterstateBlackCompressed : fonts.fontInterstateUltraBlack};
  word-break: break-word;
  text-transform: ${(props) => props.uppercase && "uppercase"};
  text-align: ${(props) => props.center && "center"};
  margin-bottom: ${(props) =>
    (props.mbSmall && "10px !important") ||
    (props.mbMedium && "20px !important") ||
    (props.mb0 && "0px !important") ||
    "35px"};
  ${breakpoints.smUp} {
    margin-bottom: ${(props) => props.mbSmOnly && "0px !important"};
  }
  ${breakpoints.mdDown} {
    margin-bottom: ${(props) => props.mbMd && "20px !important"};
  }
  ${breakpoints.smDown} {
    text-align: ${(props) => props.centerSm && "center"};
    margin-bottom: ${(props) => props.mbSm && "20px !important"};
    display: ${(props) => props.hiddenSm && "none !important"};
  }
`;

const H5 = styled.h5`
  font-size: 20px;
  color: ${(props) => props.white && colors.white};
  font-family: ${(props) =>
    props.fontSecondary ? fonts.fontInterstateBlackCompressed : fonts.fontInterstateUltraBlack};
  text-transform: ${(props) => props.uppercase && "uppercase"};
  text-align: ${(props) => props.center && "center"};
  margin-bottom: ${(props) =>
    (props.mbSmall && "10px !important") ||
    (props.mbMedium && "20px !important") ||
    (props.mb0 && "0px !important") ||
    "20px"};
  word-break: break-word;
  ${breakpoints.smUp} {
    margin-bottom: ${(props) => props.mbSmOnly && "0px !important"};
  }
  ${breakpoints.mdDown} {
    margin-bottom: ${(props) => props.mbMd && "20px !important"};
  }
  ${breakpoints.smDown} {
    margin-bottom: ${(props) => props.mbSm && "20px !important"};
    display: ${(props) => props.hiddenSm && "none !important"};
  }
  line-height: ${(props) => (props.tightLineHeight ? "0.9" : "normal")};
`;

const H6 = styled.h6`
  font-size: 11px;
  color: ${(props) => props.white && colors.white};
  font-family: ${(props) =>
    props.fontSecondary ? fonts.fontInterstateBlackCompressed : fonts.fontInterstateUltraBlack};
  text-transform: ${(props) => props.uppercase && "uppercase"};
  text-align: ${(props) => props.center && "center"};
  margin-bottom: ${(props) =>
    (props.mbSmall && "10px !important") ||
    (props.mbMedium && "20px !important") ||
    (props.mb0 && "0px !important") ||
    "20px"};
  word-break: break-word;
  ${breakpoints.smUp} {
    margin-bottom: ${(props) => props.mbSmOnly && "0px !important"};
  }
  ${breakpoints.mdDown} {
    margin-bottom: ${(props) => props.mbMd && "20px !important"};
  }
  ${breakpoints.smDown} {
    margin-bottom: ${(props) => props.mbSm && "20px !important"};
    display: ${(props) => props.hiddenSm && "none !important"};
  }
  line-height: ${(props) => (props.tightLineHeight ? "0.9" : "normal")};
`;

const Title = ({
  tag,
  children,
  uppercase,
  fontSecondary,
  center,
  centerSm,
  mbSmall,
  mbMedium,
  mbMd,
  mbSm,
  mbSmOnly,
  mb0,
  hiddenSm,
  white,
  tightLineHeight,
}) => {
  if (tag === 2) {
    return (
      <H2
        uppercase={uppercase}
        fontSecondary={fontSecondary}
        center={center}
        centerSm={centerSm}
        mbSmall={mbSmall}
        mbMedium={mbMedium}
        mbMd={mbMd}
        mbSm={mbSm}
        mbSmOnly={mbSmOnly}
        mb0={mb0}
        hiddenSm={hiddenSm}
        white={white}
      >
        {children}
      </H2>
    );
  }
  if (tag === 3) {
    return (
      <H3
        uppercase={uppercase}
        fontSecondary={fontSecondary}
        center={center}
        centerSm={centerSm}
        mbSmall={mbSmall}
        mbMedium={mbMedium}
        mbMd={mbMd}
        mbSm={mbSm}
        mbSmOnly={mbSmOnly}
        mb0={mb0}
        hiddenSm={hiddenSm}
        white={white}
        tightLineHeight={tightLineHeight}
      >
        {children}
      </H3>
    );
  }
  if (tag === 5) {
    return (
      <H5
        uppercase={uppercase}
        fontSecondary={fontSecondary}
        center={center}
        mbSmall={mbSmall}
        mbMedium={mbMedium}
        mbMd={mbMd}
        mbSm={mbSm}
        mbSmOnly={mbSmOnly}
        mb0={mb0}
        hiddenSm={hiddenSm}
        white={white}
        tightLineHeight={tightLineHeight}
      >
        {children}
      </H5>
    );
  }
  if (tag === 6) {
    return (
      <H6
        uppercase={uppercase}
        fontSecondary={fontSecondary}
        center={center}
        mbSmall={mbSmall}
        mbMedium={mbMedium}
        mbMd={mbMd}
        mbSm={mbSm}
        mbSmOnly={mbSmOnly}
        mb0={mb0}
        hiddenSm={hiddenSm}
        white={white}
        tightLineHeight={tightLineHeight}
      >
        {children}
      </H6>
    );
  }
  return (
    <H1
      uppercase={uppercase}
      fontSecondary={fontSecondary}
      center={center}
      mbSmall={mbSmall}
      mbMedium={mbMedium}
      mbMd={mbMd}
      mbSm={mbSm}
      mbSmOnly={mbSmOnly}
      mb0={mb0}
      hiddenSm={hiddenSm}
      white={white}
    >
      {children}
    </H1>
  );
};

Title.propTypes = {
  tag: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  uppercase: PropTypes.bool,
  fontSecondary: PropTypes.bool,
  center: PropTypes.bool,
  centerSm: PropTypes.bool,
  mbSmall: PropTypes.bool,
  mbMedium: PropTypes.bool,
  mbMd: PropTypes.bool,
  mbSm: PropTypes.bool,
  mbSmOnly: PropTypes.bool,
  mb0: PropTypes.bool,
  hiddenSm: PropTypes.bool,
  white: PropTypes.bool,
  tightLineHeight: PropTypes.bool,
};

Title.defaultProps = {
  uppercase: false,
  fontSecondary: false,
  center: false,
  centerSm: false,
  mbSmall: false,
  mbMedium: false,
  mbMd: false,
  mbSm: false,
  mbSmOnly: false,
  mb0: false,
  hiddenSm: false,
  white: false,
  tightLineHeight: false,
};

export default Title;
