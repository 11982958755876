import React, { Component } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { ToastContainer, Slide } from "react-toastify";

import { colors, transition, fonts } from "../../../styles/variables";
import { showSuccessAlert, showErrorAlert, showInfoAlert } from "../../../helpers/alerts";
import breakpoints from "../../../styles/breakpoints";
import iconClose from "../../../assets/images/icons/cross.svg";

import "react-toastify/dist/ReactToastify.css";

const StyledToastContainer = styled((props) => <ToastContainer {...props} />).attrs(() => ({
  className: "toast-container",
  toastClassName: "toast",
  bodyClassName: "body",
  progressClassName: "progress",

  hideProgressBar: true,
  position: "bottom-left",
  autoClose: 4000,
  transition: Slide,
  pauseOnFocusLoss: false,
}))`
  /* .toast-container */
  .Toastify__toast--success {
    background-color: ${colors.warning};
  }
  .Toastify__toast--error {
    background-color: ${colors.peach};
  }
  .Toastify__toast-body {
    white-space: pre-wrap;
    background: url(${iconClose}) no-repeat;
    background-position: bottom 50% right 10px;
    padding-right: 35px;
    background-size: 15px;
  }
  .Toastify__toast--info {
    background-color: ${colors.warning};
  }
  .Toastify__close-button {
    display: none; // default "x" is hidden, since GPD cross icon is used in .Toastify__toast-body.
  }
  .toast {
    transition: ${transition.baseTransition} !important;
    color: ${colors.black};
    font-size: 12px;
    font-family: ${fonts.fontBauRegular};
  }
  button[aria-label="close"] {
    color: ${colors.black};
  }
  ${breakpoints.xsUp} {
    width: 390px !important;
  }
`;

class GlobalAlert extends Component {
  componentDidMount() {
    const { message, type, permanent, onClose } = this.props;

    const options = { onClose };
    if (permanent) options.autoClose = false;

    switch (type) {
      case "success":
        showSuccessAlert(message, options);
        break;

      case "error":
        showErrorAlert(message, options);
        break;

      default:
        showInfoAlert(message, options);
    }
  }

  render() {
    return null;
  }
}

GlobalAlert.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(["success", "warning", "error", "info"]),
  permanent: PropTypes.bool,
  onClose: PropTypes.func,
};

GlobalAlert.defaultProps = {
  message: "",
  type: "",
  permanent: false,
  onClose: () => {},
};

export { GlobalAlert, StyledToastContainer };
