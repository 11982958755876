import React, { useState } from "react";
import styled from "styled-components";

import externalUrls from "../../../helpers/externalUrls";

import youtubeDarkIcon from "../../../assets/images/icons/youtube_social_icon_dark.png";
import youtubeLightIcon from "../../../assets/images/icons/youtube_icon_mono_dark.png";
import { ReactComponent as facebook } from "../../../assets/images/icons/facebook.svg";
import { ReactComponent as instagram } from "../../../assets/images/icons/instagram.svg";

import { colors, transition } from "../../../styles/variables";

const socialListData = [
  {
    id: "youtube",
    title: "youtube",
    href: externalUrls.YOU_TUBE,
    pngSrc: youtubeDarkIcon,
    pngSrcOnHover: youtubeLightIcon,
  },
  {
    id: "facebook",
    title: "facebook",
    href: externalUrls.FACEBOOK,
    svgSrc: facebook,
  },
  {
    id: "instagram",
    title: "instagram",
    href: externalUrls.INSTAGRAM,
    svgSrc: instagram,
  },
];

const StyledSocialList = styled.ul`
  list-style: none;
`;

const StyledSocialListItem = styled.li`
  display: inline-block;
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const StyledSocialListLink = styled.a`
  display: inline-block;
`;

const StyledSocialListIcon = styled.div`
  width: 100%;
  max-width: 30px;

  svg path {
    fill: ${colors.youtubeDark}; /* Set your default color here */
    transition: ${transition.baseTransition};
  }

  &:hover svg path {
    fill: ${colors.white};
  }
`;

const StyledSocialListIconImage = styled.img`
  width: 100%;
  max-width: 30px;
`;

const SocialList = () => {
  const [isYoutubeIconHovered, setIsYoutubeIconHovered] = useState(false);

  return socialListData.length > 0 ? (
    <StyledSocialList>
      {socialListData.map((socialListItem) => (
        <StyledSocialListItem key={socialListItem.id}>
          <StyledSocialListLink target="_blank" href={socialListItem.href} rel="noopener">
            {socialListItem?.pngSrc ? (
              <StyledSocialListIconImage
                onMouseEnter={() => setIsYoutubeIconHovered(true)}
                onMouseLeave={() => setIsYoutubeIconHovered(false)}
                src={isYoutubeIconHovered ? socialListItem?.pngSrcOnHover : socialListItem?.pngSrc}
                alt={socialListItem.title}
              />
            ) : (
              <StyledSocialListIcon>
                <socialListItem.svgSrc alt={socialListItem.title} />
              </StyledSocialListIcon>
            )}
          </StyledSocialListLink>
        </StyledSocialListItem>
      ))}
    </StyledSocialList>
  ) : null;
};

export default SocialList;
