import React from "react";
import styled from "styled-components";

import { colors, fonts } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";

import { Container } from "../../atoms/Container";
import FooterLogo from "../../atoms/FooterLogo";
import FooterContent from "../../molecules/FooterContent";

const StyledFooter = styled.footer`
  background-color: ${colors.coral};
  padding: 64px 0;
  flex-shrink: 0;
  ${breakpoints.mdDown} {
    padding: 20px 0 80px 0;
  }
`;

const StyledFooterContainer = styled((props) => <Container {...props} />)`
  ${breakpoints.mdUp} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const FooterLogoWrapper = styled.div`
  padding-bottom: 85px;
  ${breakpoints.mdDown} {
    display: none;
  }
`;

const StyledFooterCopyrightText = styled.p`
  ${breakpoints.mdUp} {
    display: none;
  }
  text-align: center;
  margin-top: 50px;
  font-size: 10px;
  font-family: ${fonts.fontBauMedium};
`;

const Footer = () => (
  <StyledFooter>
    <StyledFooterContainer>
      <FooterLogoWrapper>
        <FooterLogo />
      </FooterLogoWrapper>

      <FooterContent />

      <StyledFooterCopyrightText>© Good Pair Days. All rights reserved.</StyledFooterCopyrightText>
    </StyledFooterContainer>
  </StyledFooter>
);

export default Footer;
