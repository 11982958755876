import URL_PATTERNS from "../urls";
import { WINE_LIST_SLUGS } from "./constants";

export const WINES_NAVBAR_ITEM_URL_PATTERNS = {
  ALL_WINES: URL_PATTERNS.ALL_WINES,
  WINES: URL_PATTERNS.WINES,
  WINE_DETAILS: URL_PATTERNS.WINE_DETAILS,
  NEW_RELEASES: URL_PATTERNS.WINE_LIST(WINE_LIST_SLUGS.NEW_RELEASES),
  WINE_LIST: URL_PATTERNS.WINE_LIST,
  WINE_LIST_CLASS: URL_PATTERNS.WINE_LIST_CLASS,
  WINE_LIST_TYPE: URL_PATTERNS.WINE_LIST_TYPE,
  WINE_DETAILS_BY_ID: URL_PATTERNS.WINE_DETAILS_BY_ID,
  ADD_WINE_TO_BOX_BY_ID: URL_PATTERNS.ADD_WINE_TO_BOX_BY_ID,
  MY_ONE_OFF_RECOMMENDATIONS: URL_PATTERNS.MY_ONE_OFF_RECOMMENDATIONS,
  SPECIAL_PACKS: URL_PATTERNS.SPECIAL_PACKS,
  SPECIAL_PACK_DETAILS: URL_PATTERNS.SPECIAL_PACK_DETAILS,
};

export const LEARN_NAVBAR_ITEM_URL_PATTERNS = {
  ABOUT: URL_PATTERNS.ABOUT,
  REVIEWS: URL_PATTERNS.REVIEWS,
  GUIDE_101: URL_PATTERNS.GUIDE_101,
  GUIDE_GRAPE_COLOR: URL_PATTERNS.GUIDE_GRAPE_COLOR,
  GUIDE_REGION: URL_PATTERNS.GUIDE_REGION,
  GUIDE_TASTING: URL_PATTERNS.GUIDE_TASTING,
  GUIDE_MORE: URL_PATTERNS.GUIDE_MORE,
  GUIDE_101_GLOSSARY: URL_PATTERNS.GUIDE_101_GLOSSARY,
  GUIDE_101_POST: URL_PATTERNS.GUIDE_101_GLOSSARY,
  GUIDE_GRAPE_COLOR_GLOSSARY: URL_PATTERNS.GUIDE_GRAPE_COLOR_GLOSSARY,
  GUIDE_GRAPE_COLOR_POST: URL_PATTERNS.GUIDE_GRAPE_COLOR_POST,
  GUIDE_REGION_GLOSSARY: URL_PATTERNS.GUIDE_REGION_GLOSSARY,
  GUIDE_REGION_POST: URL_PATTERNS.GUIDE_REGION_POST,
  GUIDE_TASTING_GLOSSARY: URL_PATTERNS.GUIDE_TASTING_GLOSSARY,
  GUIDE_TASTING_POST: URL_PATTERNS.GUIDE_TASTING_POST,
  GUIDE_MORE_GLOSSARY: URL_PATTERNS.GUIDE_MORE_GLOSSARY,
  GUIDE_MORE_POST: URL_PATTERNS.GUIDE_MORE_POST,
  GUIDE_SEARCH_ARTICLES: URL_PATTERNS.GUIDE_SEARCH_ARTICLES,
};

export const GIFTS_NAVBAR_ITEM_URL_PATTERNS = {
  GIFT_LANDING: URL_PATTERNS.GIFT_LANDING,
  SEND_GIFT: URL_PATTERNS.SEND_GIFT,
  REDEEM_GIFT: URL_PATTERNS.REDEEM_GIFT,
};

export const REWARDS_NAVBAR_ITEM_URL_PATTERNS = {
  REWARDS_HOW_IT_WORKS: URL_PATTERNS.REWARDS_HOW_IT_WORKS,
  REWARDS_STORE: URL_PATTERNS.REWARDS_STORE,
  MY_PROGRESS: URL_PATTERNS.MY_PROGRESS,
};

export const NAVBAR_URL_PATTERNS_WITHOUT_BOX_SHADOW = {
  ...GIFTS_NAVBAR_ITEM_URL_PATTERNS,
  PRIVACY: URL_PATTERNS.PRIVACY,
  TERMS: URL_PATTERNS.TERMS,
  REWARDS_HOW_IT_WORKS: URL_PATTERNS.REWARDS_HOW_IT_WORKS,
  REWARDS_STORE: URL_PATTERNS.REWARDS_STORE,
};

export const URL_PATTERNS_SHOW_CHRISTMAS_CUT_OFF = {
  ...GIFTS_NAVBAR_ITEM_URL_PATTERNS,
  DASHBOARD: URL_PATTERNS.MY_DASHBOARD,
  WINES: URL_PATTERNS.ALL_WINES,
};
