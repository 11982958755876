import React, { Component } from "react";
import PropTypes from "prop-types";
import { Mutation } from "@apollo/client/react/components";

import { logError } from "../../../helpers/logging";
import { showErrorAlert } from "../../../helpers/alerts";
import { forceLogOut } from "../../../helpers/auth";
import { ERROR_MESSAGE_NOT_AUTHENTICATED } from "../../../helpers/constants";

/**
 * Renders ButtonMutation component.
 * Generic component for a button with mutation.
 * React.Component: https://reactjs.org/docs/react-component.html
 * */
class ButtonMutation extends Component {
  static propTypes = {
    input: PropTypes.shape({}),
    label: PropTypes.node.isRequired,
    className: PropTypes.string,
    mutationProp: PropTypes.shape({}).isRequired,
    reFetchQueriesProp: PropTypes.arrayOf(PropTypes.shape({})),
    awaitRefetchQueries: PropTypes.bool,
    disabled: PropTypes.bool,
    mutationPayloadName: PropTypes.string,
    handleShowErrors: PropTypes.func,
    onClick: PropTypes.func,
    callbackBeforeMutation: PropTypes.func,
    handleResponse: PropTypes.func,
    ButtonComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.shape({})]),
    submittingLabel: PropTypes.string,
    confirmationDialogMessage: PropTypes.string,
  };

  static defaultProps = {
    input: undefined,
    className: "",
    reFetchQueriesProp: [],
    awaitRefetchQueries: false,
    disabled: false,
    mutationPayloadName: null,
    handleShowErrors: null,
    onClick: null,
    handleResponse: null,
    callbackBeforeMutation: () => {},
    ButtonComponent: "button",
    submittingLabel: "Submitting...",
    confirmationDialogMessage: "",
  };

  /**
   * Triggers mutation passing "input" from props.
   * @param {Function} mutationMethod: mutation client
   * @param {Object} event: click button event
   * */
  handleButtonClicked = (event, mutationMethod) => {
    const { input, mutationPayloadName, handleShowErrors, onClick, handleResponse, callbackBeforeMutation } =
      this.props;
    const inputData = input ? { variables: { input } } : undefined;

    callbackBeforeMutation();

    mutationMethod(inputData)
      .then((data) => {
        // Shows friendly errors from GraphQL's response
        if (
          handleShowErrors &&
          mutationPayloadName &&
          data.data[mutationPayloadName] &&
          data.data[mutationPayloadName].errors &&
          data.data[mutationPayloadName].errors.length
        ) {
          data.data[mutationPayloadName].errors.map((error) => handleShowErrors(error.messages[0]));
        }

        // Sends response back to parent component independent of success or failure
        handleResponse && handleResponse(data);
      })
      .catch((error) => {
        const { message } = error || {};
        if (typeof message === "string" && message.includes(ERROR_MESSAGE_NOT_AUTHENTICATED)) {
          forceLogOut().then();
        } else {
          logError(error);
        }
      });

    // Triggers event when for when user clicks the button good to simulates button clicked
    !!onClick && onClick(event);
  };

  /**
   * Triggers confirmation dialog to confirm the mutation.
   * @param {Object} event: click button event
   * @param {Function} mutationMethod: mutation client
   * */
  showDialog = (event, mutationMethod) => {
    const { confirmationDialogMessage } = this.props;
    const isConfirmed = window.confirm(`${confirmationDialogMessage}`);
    if (isConfirmed) {
      this.handleButtonClicked(event, mutationMethod);
    }
  };

  render() {
    const {
      reFetchQueriesProp,
      awaitRefetchQueries,
      mutationProp,
      label,
      disabled,
      className,
      ButtonComponent,
      submittingLabel,
      input,
      mutationPayloadName,
      handleShowErrors,
      handleResponse,
      onClick,
      callbackBeforeMutation,
      children,
      confirmationDialogMessage,
      ...otherProps
    } = this.props;

    return (
      <Mutation
        mutation={mutationProp}
        refetchQueries={() => reFetchQueriesProp}
        awaitRefetchQueries={awaitRefetchQueries}
      >
        {(mutationMethod, { loading, error }) => {
          if (error) {
            showErrorAlert("Sorry, something went wrong!", {
              autoClose: 10000,
            });
            error.graphQLErrors.map((err) => err && logError(err.message));
          }

          return (
            <ButtonComponent
              type="button"
              onClick={(event) =>
                confirmationDialogMessage === ""
                  ? this.handleButtonClicked(event, mutationMethod)
                  : this.showDialog(event, mutationMethod)
              }
              className={className}
              disabled={disabled || loading}
              {...otherProps}
            >
              {loading ? submittingLabel : label}
              {children}
            </ButtonComponent>
          );
        }}
      </Mutation>
    );
  }
}

export default ButtonMutation;
