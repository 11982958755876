/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import subBox from "../assets/images/icons/sub_box.svg";
import points from "../assets/images/icons/points.svg";

// import inviteFriend from '../assets/images/icons/invite-friend.svg';
// import comment from '../assets/images/icons/comment.svg';
// import unlock from '../assets/images/icons/unlock.svg';
// import gift from '../assets/images/icons/gift.svg';
import { fonts } from "../styles/variables";
import urlPatterns from "../urls";
import {
  DB_ID_ACTIVITY_TYPE_MONTHLY_RECOMMENDATIONS,
  DB_ID_ACTIVITY_TYPE_ORDERED_SUBSCRIPTION,
  DB_ID_ACTIVITY_TYPE_EARNED_BADGE,
  DB_ID_ACTIVITY_TYPE_RECEIVED_CREDIT,
  DB_ID_ACTIVITY_TYPE_EXTERNAL_ACTION,
  DB_ID_ACTIVITY_TYPE_AUTO_RATE_BOTTLES,
  DB_ID_ACTIVITY_TYPE_AUTO_TASTES,
  DB_ID_ACTIVITY_TYPE_WINE_RUNNING_OUT_OF_STOCK,
  BADGE_CATEGORY_IDS,

  // DB_ID_ACTIVITY_TYPE_ADDED_AS_FRIEND,
  // DB_ID_ACTIVITY_TYPE_RECEIVED_COMMENT_ON_ACTIVITY,
  // DB_ID_ACTIVITY_TYPE_AUTO_FREE_SHIPPING,
  // DB_ID_ACTIVITY_TYPE_AUTO_CONNECT_TO_FRIENDS,
  // DB_ID_ACTIVITY_TYPE_AUTO_GIVE_A_BOX,
  // DB_ID_ACTIVITY_TYPE_AUTO_6_MONTHS,
  // DB_ID_ACTIVITY_TYPE_AUTO_FEEDBACK,
  // DB_ID_ACTIVITY_TYPE_AUTO_12_MONTHS,
} from "./constants";
import { logError } from "./logging";

const Button = styled(Link).attrs((props) => ({ to: props.to || "#" }))`
  // font-family: ${fonts.fontInterstateUltraBlack};
  text-transform: uppercase;
  font-size: 11px;
  display: inline-block;
  margin-bottom: 10px;
  letter-spacing: 1.5px;
`;
const StrongButton = styled(Button)`
  font-family: ${fonts.fontInterstateUltraBlack};
  text-transform: uppercase;
  letter-spacing: unset;
`;

// const StyledAnchor = styled.a`
//   text-transform: uppercase;
//   font-size: 11px;
//   display: inline-block;
//   margin-bottom: 10px;
//   font-family: ${fonts.fontInterstateUltraBlack};
// `;

const Card = styled.div`
  > p {
    margin-bottom: 10px;
  }
`;

/**
 * Returns the string to render for notification when a badge is earned.
 *
 * @param {String} badgeName
 * @param {Number} badgeCategoryId
 * @param {String} badgeShortDescription
 * @returns {String}
 * */
const getEarnedBadgeNotification = (badgeName, badgeCategoryId, badgeShortDescription) => {
  if (
    badgeShortDescription &&
    badgeCategoryId === BADGE_CATEGORY_IDS.DB_ID_BADGE_CATEGORY_RANDOM_VARIABLE_REWARDS
  ) {
    return badgeShortDescription;
  }
  return `You earned the badge: ${badgeName}.`;
};

const TEMPLATES = {
  [DB_ID_ACTIVITY_TYPE_MONTHLY_RECOMMENDATIONS]: ({ wines }) => ({
    body: (
      <Card>
        <p>{`We've recommended your monthly wines. They are:${wines.map((wine) => ` ${wine.name}`)}.`}</p>
        <StrongButton to={urlPatterns.MY_NEXT_WINES}>Go to my next box</StrongButton>
      </Card>
    ),
    icon: subBox,
  }),
  [DB_ID_ACTIVITY_TYPE_ORDERED_SUBSCRIPTION]: ({ wines }) => ({
    body: (
      <Card>
        <p>{`You just locked in this month's wine selection:${wines.map((wine) => ` ${wine.name}`)}.`}</p>
        <StrongButton to={urlPatterns.MY_NEXT_WINES}>Go to my next box</StrongButton>
      </Card>
    ),
    icon: subBox,
  }),
  [DB_ID_ACTIVITY_TYPE_EARNED_BADGE]: ({ badgeName, badgeCategoryId, badgeShortDescription }) => ({
    body: (
      <Card>
        <p>{getEarnedBadgeNotification(badgeName, badgeCategoryId, badgeShortDescription)}</p>
        <StrongButton to={urlPatterns.MY_PROGRESS}>Go to my progress</StrongButton>
      </Card>
    ),
    icon: points,
  }),
  [DB_ID_ACTIVITY_TYPE_RECEIVED_CREDIT]: () => ({
    body: (
      <Card>
        <p>
          {`You've just won a gift! You're earning points for trying new wines, rating bottles and
               generally being amazing. You can see all your rewards and progress here.`}
        </p>
        <StrongButton to={urlPatterns.MY_PROGRESS}>Go to my progress</StrongButton>
      </Card>
    ),
    icon: points,
  }),
  [DB_ID_ACTIVITY_TYPE_EXTERNAL_ACTION]: ({ externalActionName }) => ({
    body: (
      <Card>
        <p>{`You ${externalActionName}`.replace("You has", "You have")}</p>
      </Card>
    ),
    icon: subBox,
  }),
  [DB_ID_ACTIVITY_TYPE_AUTO_RATE_BOTTLES]: () => ({
    body: (
      <Card>
        <p>
          {`Hey there, we want to keep learning about your special taste buds to make sure we pick
        bottles that you'll love. Make sure to rate your wines as you go, and you'll earn points
        to use towards free bottles as well as chances to win extra prizes in your next box.`}
        </p>
        <StrongButton to={urlPatterns.MY_CELLAR}>Go to past wines</StrongButton>
      </Card>
    ),
    icon: subBox,
  }),
  [DB_ID_ACTIVITY_TYPE_AUTO_TASTES]: () => ({
    body: (
      <Card>
        <p>
          {`Are we on the right track with your unique taste buds? If you're tough nut to crack and
        we're missing the mark, you can always update your wine preferences.`}
        </p>
        <StrongButton to={urlPatterns.MY_ACCOUNT}>Go to my settings</StrongButton>
      </Card>
    ),
    icon: subBox,
  }),
  [DB_ID_ACTIVITY_TYPE_WINE_RUNNING_OUT_OF_STOCK]: ({ wineName, wineSlug }) => ({
    body: (
      <Card>
        <p>{`You rated the wine ${wineName} as 5 before and it is almost running out of stock.`}</p>
        {wineSlug ? (
          <StrongButton to={urlPatterns.MY_CELLAR_DETAILS(wineSlug)}>Go to wine</StrongButton>
        ) : (
          <StrongButton to={urlPatterns.MY_CELLAR}>Go to past wines</StrongButton>
        )}
      </Card>
    ),
    icon: subBox,
  }),

  // [DB_ID_ACTIVITY_TYPE_ADDED_AS_FRIEND]: ({ fromMemberName }) => (
  //   {
  //     body: (
  //       <Card>
  //         <p>{`${fromMemberName} just added you as a wine friend.`}</p>
  //         <Button>See your friends</Button>
  //       </Card>
  //     ),
  //     icon: inviteFriend,
  //   }
  // ),
  // [DB_ID_ACTIVITY_TYPE_RECEIVED_COMMENT_ON_ACTIVITY]: ({ fromMemberName, activity }) => (
  //   {
  //     body: (
  //       <Card>
  //         <p>{`${fromMemberName} just wrote a comment on your ${activity}.`}</p>
  //         <Button>See comments</Button>
  //       </Card>
  //     ),
  //     icon: comment,
  //   }
  // ),
  // [DB_ID_ACTIVITY_TYPE_AUTO_FREE_SHIPPING]: () => (
  //   {
  //     body: (
  //       <Card>
  //         <p>
  //           {`You've unlocked your next wine level. You can now get free-shipping! Head to settings
  //      to find out how.`}
  //         </p>
  //         <StrongButton to={urlPatterns.MY_ACCOUNT}>Go to my settings</StrongButton>
  //       </Card>
  //     ),
  //     icon: unlock,
  //   }
  // ),
  // [DB_ID_ACTIVITY_TYPE_AUTO_CONNECT_TO_FRIENDS]: () => null,
  // (
  //   {
  //     body:
  // <Card>
  //   <p>
  //     <a href={dashboardUrl} target="_parent">Connect</a>
  //     {` your Facebook account to follow all your friend's wine adventures. Including their favourite
  //       bottles, ratings, reviews and more!`}
  //   </p>
  //   <Button>See friends</Button>
  // </Card>,
  //     icon: inviteFriend,
  //   }
  // ),
  // [DB_ID_ACTIVITY_TYPE_AUTO_GIVE_A_BOX]: ({ freeBoxUrl }) => (
  //   {
  //     body:
  // <Card>
  //   <p>
  //     {'You\'ve just earned a very '}
  //     <a href={freeBoxUrl} target="_parent">special invite</a>
  //     {'! You can share the love and give out one free box to your wine loving bestie.'}
  //   </p>
  //   <StrongButton>See Invite</StrongButton>
  // </Card>,
  //     icon: gift,
  //   }
  // ),
  // [DB_ID_ACTIVITY_TYPE_AUTO_6_MONTHS]: () => (
  //   {
  //     body:
  // <Card>
  //   <p>
  //     {`It's a big day, you've been with us for 6 months. Woo Hoo.
  //       Keep an eye our for a special thank you in your next box.`}
  //   </p>
  // </Card>,
  //     icon: unlock,
  //   }
  // ),
  //
  // [DB_ID_ACTIVITY_TYPE_AUTO_FEEDBACK]: ({ feedbackUrl }) => (
  //   {
  //     body:
  // <Card>
  //   <p>
  //     {`You're one of our top winesquad members! So we want to know what you think.
  //       Would you mind helping us out by sharing your 2 cents about our service?`}
  //   </p>
  //   <StyledAnchor href={feedbackUrl} target="_parent">Go to feedback form</StyledAnchor>
  // </Card>,
  //     icon: comment,
  //   }
  // ),
  // [DB_ID_ACTIVITY_TYPE_AUTO_12_MONTHS]: () => (
  //   {
  //     body: (
  //       <Card>
  //         <p>
  //           {`What a happy day, you've been with us for 12 months.
  //             Amongst other things it means you have our undying love!`}
  //         </p>
  //       </Card>
  //     ),
  //     icon: unlock,
  //   }
  // ),
};

/**
 * @typedef {Object} NotificationObject
 * @property {*} body - Notification body as react node
 * @property {string} icon - Notification icon
 */

/**
 * Returns HTML with notification.
 *
 * @param activityTypeId - ID required for choosing the right template
 * @param context - data to be used to fill a template
 * @returns {NotificationObject}
 */
const notificationProcessor = (activityTypeId, context) => {
  if (TEMPLATES[activityTypeId]) {
    return TEMPLATES[activityTypeId](context);
  }

  logError(`Unregistered notification type: ${activityTypeId} with context: ${context}`);
  return undefined;
};

export default notificationProcessor;
