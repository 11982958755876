import styled, { FlattenSimpleInterpolation } from "styled-components";

import { colors, fonts } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";

export interface ButtonProps {
  disabled?: boolean;
  outline?: boolean;
  ghost?: boolean;
  coral?: boolean;
  warning?: boolean;
  onClick?: Function;
  lg?: boolean;
  long?: boolean;
  noBorder?: boolean;
  full?: boolean;
  fullMobile?: boolean;
  whiteBgOnHover?: boolean;
  between?: boolean;
  hiddenSmUp?: boolean;
  css?: FlattenSimpleInterpolation;
}

const Button = styled.button<ButtonProps>`
  display: ${(props) => (props.between ? "inline-flex" : "inline-block")};
  ${(props) => props.hidden && "display: none;"}
  justify-content: ${(props) => props.between && "space-between"};
  align-items: ${(props) => props.between && "center"};
  width: ${(props) => props.full && "100%"};
  text-align: center;
  user-select: none;
  background-color: ${(props) =>
    (props.outline && colors.white) ||
    (props.ghost && "transparent") ||
    (props.coral && colors.coral) ||
    (props.warning && colors.warning) ||
    colors.black};
  color: ${(props) =>
    (props.outline && colors.black) ||
    (props.ghost && colors.black) ||
    (props.coral && colors.white) ||
    (props.warning && colors.black) ||
    colors.white};
  border: ${(props) =>
    (props.noBorder && "none") ||
    (props.coral && `1.5px solid ${colors.coral}`) ||
    (props.warning && `1.5px solid ${colors.warning}`) ||
    `1.5px solid ${colors.black}`};
  border-radius: ${(props) => (props.coral ? "5px" : "0")};
  padding: ${(props) => (props.lg ? "21.5px" : "11.5px")} ${(props) => (props.long ? "90px" : "15px")};
  font-size: 10px;
  line-height: 1.5;
  text-transform: uppercase;
  font-family: ${fonts.fontBauMedium};
  font-weight: bold;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${breakpoints.smUp} {
    display: ${(props) => props.hiddenSmUp && "none !important"};
  }
  ${breakpoints.smDown} {
    padding-left: ${(props) => (props.long && "45px") || (props.fullMobile && "15px")};
    padding-right: ${(props) => (props.long && "45px") || (props.fullMobile && "15px")};
    width: ${(props) => props.fullMobile && "100%"};
  }

  &:hover,
  &:focus {
    border-color: ${(props) =>
      (props.coral && colors.coral) || (props.warning && colors.black) || colors.black};
    background-color: ${(props) =>
      (props.outline && colors.black) ||
      ((props.ghost || props.whiteBgOnHover) && colors.white) ||
      (props.coral && colors.coralThin) ||
      "transparent"};
    color: ${(props) =>
      (props.outline && colors.white) ||
      ((props.ghost || props.whiteBgOnHover) && colors.black) ||
      (props.coral && colors.white) ||
      colors.black};
  }
  &:disabled {
    border-color: ${colors.black};
    color: ${colors.white} !important;
    background-color: ${colors.black};
    &:hover,
    &:focus {
      color: ${colors.white} !important;
      background-color: ${colors.black};
    }
  }
  ${(props) => props.css}
`;

export default Button;
