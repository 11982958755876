import styled from "styled-components";
import { Row, Col } from "react-flexbox-grid";

import breakpoints from "../../../styles/breakpoints";

export const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  flex: 1;
  padding: 0 !important;
  margin: 0 !important;
`;

export const StyledCol = styled(Col)`
  padding: 0 !important;
`;

export const BrandGalleryHolder = styled.div`
  padding: 20px 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex: 1;
  ${breakpoints.xsUp} {
    height: ${(props) => props.maxHeight + 40}px;
    width: ${(props) => props.maxWidth + 60}px !important;
  }
  img {
    height: ${(props) => props.maxHeight}px !important;
    width: ${(props) => props.maxWidth}px !important;
    ${breakpoints.xsDown} {
      max-width: 120px;
      max-height: auto;
    }
  }
  ${breakpoints.smDown} {
    padding: 0 30px;
  }
`;
