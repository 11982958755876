import React, { ReactElement } from "react";
import {
  NAVIGATION_MEMBER,
  NAVIGATION_WINES,
  NAVIGATION_LEARN,
  NAVIGATION_GIFTS,
  NAVIGATION_REWARDS,
} from "../../../helpers/constants";
import { MemberLayoutInfo } from "../../../types/__generated__/MemberLayoutInfo";
import { DesktopDropdownWrapper, DropdownsContainer } from "../../organisms/Header/style";
import DesktopGiftsDropdown from "../DesktopGiftsDropdown";
import DesktopWinesDropdown from "../DesktopWinesDropdown";
import DesktopLearnDropdown from "../DesktopLearnDropdown";
import DesktopMemberDropdown from "../DesktopMemberDropdown";
import DesktopRewardsDropdown from "../DesktopRewardsDropdown";

interface DesktopWinesDropdownsProps {
  onCrossClicked: () => void;
  activeDropdown: string;
  memberLayoutInfoData: MemberLayoutInfo | undefined;
}

const DesktopNavbarDropdowns = ({
  onCrossClicked,
  activeDropdown,
  memberLayoutInfoData,
}: DesktopWinesDropdownsProps): ReactElement => {
  const isMemberDropdownActive = !!(activeDropdown === NAVIGATION_MEMBER);
  const isWinesDropdownActive = !!(activeDropdown === NAVIGATION_WINES);
  const isLearnDropdownActive = !!(activeDropdown === NAVIGATION_LEARN);
  const isGiftsDropdownActive = !!(activeDropdown === NAVIGATION_GIFTS);
  const isRewardsDropdownActive = !!(activeDropdown === NAVIGATION_REWARDS);
  return (
    <DropdownsContainer>
      <DesktopDropdownWrapper
        isThisDropdownActive={isMemberDropdownActive}
        isAnyDropdownActive={!!activeDropdown}
      >
        <DesktopMemberDropdown onCrossClicked={onCrossClicked} isAnyDropDownActive={!!activeDropdown} />
      </DesktopDropdownWrapper>
      <DesktopDropdownWrapper
        isThisDropdownActive={isWinesDropdownActive}
        isAnyDropdownActive={!!activeDropdown}
      >
        <DesktopWinesDropdown onCrossClicked={onCrossClicked} isAnyDropdownActive={!!activeDropdown} />
      </DesktopDropdownWrapper>
      <DesktopDropdownWrapper
        isThisDropdownActive={isLearnDropdownActive}
        isAnyDropdownActive={!!activeDropdown}
      >
        <DesktopLearnDropdown
          onCrossClicked={onCrossClicked}
          isAnyDropdownActive={!!activeDropdown}
          memberLayoutInfoData={memberLayoutInfoData}
        />
      </DesktopDropdownWrapper>
      <DesktopDropdownWrapper
        isThisDropdownActive={isGiftsDropdownActive}
        isAnyDropdownActive={!!activeDropdown}
      >
        <DesktopGiftsDropdown onCrossClicked={onCrossClicked} isAnyDropdownActive={!!activeDropdown} />
      </DesktopDropdownWrapper>
      <DesktopDropdownWrapper
        isThisDropdownActive={isRewardsDropdownActive}
        isAnyDropdownActive={!!activeDropdown}
      >
        <DesktopRewardsDropdown onCrossClicked={onCrossClicked} isAnyDropdownActive={!!activeDropdown} />
      </DesktopDropdownWrapper>
    </DropdownsContainer>
  );
};

export default DesktopNavbarDropdowns;
