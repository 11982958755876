import styled from "styled-components";

import { Container } from "../../atoms/Container";

import { fonts, colors, transition } from "../../../styles/variables";

export const Title = styled.h1`
  font-family: ${fonts.fontInterstateBlackCompressed};
  font-size: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  line-height: 1;
  ${(props) => props.css}
`;

export const StyledContainer = styled(Container)`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const ModalDiv = styled.div`
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : colors.white)};
  transition: ${transition.baseTransition};
  position: fixed;
  left: ${(props) => (props.fullCenter ? "50%" : `${props.leftPosition}px`)};
  top: ${(props) => (props.fullCenter ? "50%" : "20%")};
  transform: translate(-50%, -50%);
  z-index: 2200;
  min-height: 150px;
  width: ${(props) => props.width}px;
  max-width: 100%;
  border-radius: ${(props) => (props.borderRadius ? "20px" : 0)};
  padding: 10px;
`;
