import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import compose from "lodash.flowright";
import { Query } from "@apollo/client/react/components";
import { withRouter } from "react-router-dom";

import iconClose from "../../../assets/images/icons/cross.svg";
import { colors, fonts, transition } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";

import Button from "../../atoms/Button";
import { GET_AUTH } from "../../../graphql/resolvers/auth";
import { FETCH_POLICY_CACHE_ONLY } from "../../../helpers/constants";
import { trackingEvents, useTrackEvent } from "../../../helpers/Analytics";
import { GET_ANNOUNCEMENT } from "../../../graphql/resolvers/announcement";
import { logError } from "../../../helpers/logging";
import { getAnnouncementsWithImages } from "../../../helpers/tools";
import urlPatterns from "../../../urls";

const FullScreenMask = styled.div`
  cursor: pointer;
  z-index: 7500;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: 0.5;
`;

const ModalCoreDiv = styled.div`
  padding: 0 !important;
  background-color: ${colors.peach};
  transition: ${transition.baseTransition};
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 7501;
  min-height: 150px;
  width: 472px;
  padding: 10px;
  ${breakpoints.smDown} {
    width: 335px;
    max-height: calc(100% - 10px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
`;

const AnnouncementWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 29.37px;
  padding: 0 28px 0 30px;
  ${breakpoints.smDown} {
    padding: 0 20px;
    margin-top: 20.5px;
  }
`;

const CloseAnnouncementWrapper = styled.header`
  width: 100%;
  margin-bottom: 10.37px;
  ${breakpoints.smDown} {
    margin-bottom: 9.5px;
  }
`;

const CloseAnnouncement = styled.button`
  border: none !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  width: 20px;
  height: 20px;
  background: url(${iconClose}) no-repeat;
  background-size: 11.5px 11.5px;
  ${breakpoints.smDown} {
    background-size: 15px 15px;
  }
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-family: ${fonts.fontBauRegular};
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 25px;
    ${breakpoints.smDown} {
      margin-bottom: 20px;
      text-align: center;
    }
  }
  a,
  div {
    width: 100%;
    margin-bottom: 25px;
    ${breakpoints.smDown} {
      margin-bottom: 30px;
    }
  }
`;

const AnnouncementImage = styled.img`
  margin-bottom: 20px;
  max-width: 414px;
  ${breakpoints.smDown} {
    display: none;
  }
`;

const AnnouncementImageMobile = styled.img`
  margin-bottom: 14px;
  max-width: 295px;
  ${breakpoints.smUp} {
    display: none;
  }
`;

const CTAButton = ({ buttonLabel, onClick }) => (
  <Button lg long full onClick={onClick}>
    {buttonLabel}
  </Button>
);

CTAButton.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const AnnouncementPopupWrapped = ({ closeAnnouncementHandler, latestAnnouncement, location }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const trackEvent = useTrackEvent();

  const popupTimer = useRef(null);
  const bodySelector = document.querySelector("body");
  const scrollPosition = useRef(0);

  const currentURL = window.location.href;
  const isCurrentPageQuizResults = location && location.pathname === urlPatterns.QUIZ_RESULTS;

  // get the id of the most recent announcement to store it locally if users close announcement
  const { id, shouldHideFromNonSubscribers, shouldHideFromSubscribers } = latestAnnouncement || {};

  const enableScrollLock = () => {
    scrollPosition.current = window.pageYOffset;
    bodySelector.style.overflow = "hidden";
    bodySelector.style.position = "fixed";
    bodySelector.style.top = `-${scrollPosition.current}px`;
    bodySelector.style.width = "100%";
  };

  const disableScrollLock = () => {
    bodySelector.style.removeProperty("overflow");
    bodySelector.style.removeProperty("position");
    bodySelector.style.removeProperty("top");
    bodySelector.style.removeProperty("width");
    window.scrollTo(0, scrollPosition.current);
  };

  const handleCloseAnnouncement = () => {
    window.localStorage.setItem("maxIndexOfImageAnnouncementClosed", id);

    clearTimeout(popupTimer.current);
    disableScrollLock();

    closeAnnouncementHandler();
  };

  const handleTrackAndCloseAnnouncement = () => {
    trackEvent(trackingEvents.TRACKING_EVENT_CLICKED_POPUP_CTA_BUTTON, {
      announcement_id: id,
      click_origin_url: currentURL,
      was_hidden_from_non_subscribers: !!shouldHideFromNonSubscribers,
      was_hidden_from_subscribers: !!shouldHideFromSubscribers,
    });

    handleCloseAnnouncement();
  };

  // if previously closed announceID is equal to current latest announcement id, dont show announcements
  const closedId = window.localStorage.getItem("maxIndexOfImageAnnouncementClosed");
  const userHasSeenthePopup = closedId && id && closedId.toString() === id.toString();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (latestAnnouncement && !userHasSeenthePopup && popupTimer.current === null) {
      popupTimer.current = setTimeout(() => {
        setOpenPopup(true);
        enableScrollLock();
      }, 15000);
    }

    if (popupTimer.current) {
      return () => clearTimeout(popupTimer.current);
    }
  }, []);

  if (userHasSeenthePopup) return null;

  if (openPopup) {
    return (
      <>
        <FullScreenMask onClick={handleCloseAnnouncement} />
        <ModalCoreDiv>
          <AnnouncementWrapper id="announcementModal">
            <CloseAnnouncementWrapper>
              <CloseAnnouncement onClick={handleCloseAnnouncement} />
            </CloseAnnouncementWrapper>
            <PopupContent>
              <AnnouncementImage
                src={latestAnnouncement.imageMedium}
                alt={latestAnnouncement.imageSeoTitle}
              />
              <AnnouncementImageMobile
                src={latestAnnouncement.imageSmall}
                alt={latestAnnouncement.imageSeoTitle}
              />
              <p>{latestAnnouncement.message}</p>
              {isCurrentPageQuizResults ? (
                <div>
                  <CTAButton buttonLabel="Sounds great" onClick={handleTrackAndCloseAnnouncement} />
                </div>
              ) : (
                <a href={latestAnnouncement.url}>
                  <CTAButton
                    buttonLabel={latestAnnouncement.buttonLabel}
                    onClick={handleTrackAndCloseAnnouncement}
                  />
                </a>
              )}
            </PopupContent>
          </AnnouncementWrapper>
        </ModalCoreDiv>
      </>
    );
  }

  return null;
};

AnnouncementPopupWrapped.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  closeAnnouncementHandler: PropTypes.func.isRequired,
  latestAnnouncement: PropTypes.shape({}).isRequired,
};

const AnnouncementPopup = ({ closeAnnouncementHandler, location }) => (
  <Query query={GET_AUTH} fetchPolicy={FETCH_POLICY_CACHE_ONLY}>
    {({ loading: loadingAuth, data: authData }) => {
      if (loadingAuth) return null;

      const memberId = authData && authData.auth && authData.auth.memberId;
      return (
        <Query query={GET_ANNOUNCEMENT} variables={{ memberId }} partialRefetch>
          {({ loading, error, data }) => {
            if (loading) return null;
            if (error) logError(error, { location: "AnnouncementPopup", action: "GET_ANNOUNCEMENT" });

            const announcements = getAnnouncementsWithImages(data);
            const latestAnnouncement = announcements[0];
            if (latestAnnouncement) {
              return (
                <AnnouncementPopupWrapped
                  location={location}
                  latestAnnouncement={latestAnnouncement}
                  closeAnnouncementHandler={closeAnnouncementHandler}
                />
              );
            }
            return null;
          }}
        </Query>
      );
    }}
  </Query>
);

AnnouncementPopup.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  closeAnnouncementHandler: PropTypes.func.isRequired,
};

export default compose(withRouter)(AnnouncementPopup);
