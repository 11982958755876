import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFlag } from "@unleash/proxy-client-react";
import { I18N_NAMESPACES, UNLEASH_EARN_POINTS_V3 } from "../../../helpers/constants";
import { WINES_NAVBAR_ITEM_URL_PATTERNS } from "../../../helpers/urls.constants";
import { checkIfUrlIsInUrlGroup } from "../../../helpers/urls.helper";
import URL_PATTERNS from "../../../urls";
import { useHeaderContext } from "../../organisms/Header/context";
import {
  AccordionItemIcon,
  AccordionItemIconWrapper,
  AccordionItemWrapper,
} from "../MobileHeaderNavbar/style";

interface AccordionItemProps {
  isUserAccordion?: boolean;
  isFlexDirectionRow?: boolean;
  icon?: string | undefined;
  i18nKey: string;
  url?: string;
}

const AccordionItem = ({
  isUserAccordion,
  isFlexDirectionRow,
  icon,
  i18nKey,
  url,
}: AccordionItemProps): ReactElement => {
  const history = useHistory();
  const isExternalUrl = url?.includes("https");
  const { t } = useTranslation([I18N_NAMESPACES.TOP_NAVBAR]);
  const { setIsMobileMenuOpen } = useHeaderContext();
  const isEarnPointsV3Enabled = useFlag(UNLEASH_EARN_POINTS_V3);

  // We should not highlight any of the wine submenuItems as white.
  const isUrlThisPage = url ? url === history?.location?.pathname : false;
  const isWinesNavbarItemUrl = checkIfUrlIsInUrlGroup(url ?? "", WINES_NAVBAR_ITEM_URL_PATTERNS);
  const shouldShowItemAsWhite = isWinesNavbarItemUrl ? false : isUrlThisPage;

  const linkUrl = url ?? URL_PATTERNS.MY_DASHBOARD;

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <AccordionItemWrapper
      to={isExternalUrl ? { pathname: linkUrl } : linkUrl}
      target={isExternalUrl ? "_blank" : ""}
      onClick={() => handleLinkClick()}
      isFlexDirectionRow={isFlexDirectionRow}
      isUserAccordion={isUserAccordion}
      shouldShowItemAsWhite={shouldShowItemAsWhite}
      smooth
    >
      {!!icon && (
        <AccordionItemIconWrapper>
          <AccordionItemIcon src={icon} />
        </AccordionItemIconWrapper>
      )}
      {t(i18nKey, {
        context: isEarnPointsV3Enabled ? "v3" : "default",
        interpolation: { escapeValue: false },
      })}
    </AccordionItemWrapper>
  );
};

export default AccordionItem;
