import React from "react";
import styled from "styled-components";

import { transition } from "../../../styles/variables";

import AppStore from "../../../assets/images/icons/app-store-transparent.svg";
import AppStoreHover from "../../../assets/images/icons/app-store-black-background.svg";
import PlayStore from "../../../assets/images/icons/play-store-transparent.svg";
import PlayStoreHover from "../../../assets/images/icons/play-store-black-background.svg";
import { getCountrySettings } from "../../../helpers/tools";

const StyledAppList = styled.ul`
  list-style: none;
`;

const StyledAppListItem = styled.li`
  display: inline-block;
  transition: all 500ms ease-in-out;
  &:not(:first-child) {
    margin-left: 20px;
  }
`;

const StyledAppListLink = styled.a`
  display: inline-block;
`;

const LogoIcon = styled.img`
  display: inline-block;
  transition: ${transition.baseTransition};
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
`;

const LogoIconHover = styled.img`
  position: absolute;
  top: 1px;
  left: 0;
  // opacity: 0 creates a pseudo element in iOS that affects the link click
  opacity: 0.001;
  transition: ${transition.baseTransition};
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
`;

const LogoIconWrapper = styled.div`
  display: inline-block;
  position: relative;
  @media (hover: hover) {
    &:hover {
      ${LogoIcon} {
        opacity: 0.001;
      }
      ${LogoIconHover} {
        opacity: 1;
      }
    }
  }
  @media not all and (hover: hover) {
    &:active {
      ${LogoIcon} {
        opacity: 0.001;
      }
      ${LogoIconHover} {
        opacity: 1;
      }
    }
  }
`;

const AppList = () => {
  const { APP_STORE_URL, PLAY_STORE_URL } = getCountrySettings();
  return (
    <StyledAppList>
      <StyledAppListItem>
        <StyledAppListLink id="appListAppStoreDownloadButton" target="_blank" href={APP_STORE_URL}>
          <LogoIconWrapper>
            <LogoIcon src={AppStore} alt="App Store logo" height={33} width={102} />
            <LogoIconHover src={AppStoreHover} alt="App Store logo" height={33} width={102} />
          </LogoIconWrapper>
        </StyledAppListLink>
      </StyledAppListItem>
      <StyledAppListItem>
        <StyledAppListLink id="appListPlayStoreDownloadButton" target="_blank" href={PLAY_STORE_URL}>
          <LogoIconWrapper>
            <LogoIcon src={PlayStore} alt="Play Store logo" height={33} width={117} />
            <LogoIconHover src={PlayStoreHover} alt="Play Store logo" height={33} width={117} />
          </LogoIconWrapper>
        </StyledAppListLink>
      </StyledAppListItem>
    </StyledAppList>
  );
};

export default AppList;
