import jwt_decode from "jwt-decode";

import { client, persistent } from "../graphql/client";
import {
  ADD_SHOPPING_CART_ITEM,
  RESET_LOCAL_SHOPPING_CART,
  SET_REFERRAL_DISCOUNT,
  SUBMIT_QUIZ,
  UPDATE_SHOPPING_CART_ITEM,
  APPLY_DISCOUNT_CODE,
  SET_GUEST_PROMO_CODE_DISCOUNT,
  APPLY_REFERRAL_CODE,
} from "../graphql/mutations";
import {
  GET_LOCAL_SHOPPING_CART,
  GET_MEMBER_INFO_ON_LOGIN,
  GET_MEMBER_SHOPPING_CART,
  GET_REFERRAL_DISCOUNT,
  GET_MEMBER_QUIZ_DATA,
  GET_GUEST_PROMO_CODE_DISCOUNT,
} from "../graphql/queries";
import { SET_MEMBER_AUTH } from "../graphql/resolvers/auth";
import URL_PATTERNS from "../urls";
import { identifyIterableUser } from "./Analytics";
import { FETCH_POLICY_CACHE_ONLY, HTTP_METHODS, ACCESS_TOKEN_EXPIRE_SECONDS } from "./constants";
import { logError } from "./logging";
import { addDate, isDateAfter, parseDate } from "./dates";
import executeRestApi from "./rest";
import { formatExtraInfoOnLocalShoppingCartSync, setCountryIdOfLoggedInMemberInCache } from "./tools";
import { getCountryIdFromUrl } from "./urls.helper";

/**
 * Logs user out, removed all data that they have collected.
 *
 * @param {string} [redirectUrl='']
 * @returns {Promise<void>}
 */
const executeLogOut = async (redirectUrl = "") => {
  if (localStorage.getItem(process.env.REACT_APP_STORE_LOCAL_STORAGE)) {
    // Removes all data collected by the user in the localStorage.
    client.resetStore();
    await persistent.purge();

    // Cleans the localStorage for safety, it should be redundant if the commands above worked as expected.
    localStorage.removeItem(process.env.REACT_APP_AUTH_LOCAL_STORAGE);
    localStorage.removeItem(process.env.REACT_APP_STORE_LOCAL_STORAGE);

    if (redirectUrl) window.location = `${process.env.REACT_APP_BASE_URL}${redirectUrl}`;
  }
};

/**
 * Forces log out of the current Member. Used when Member is logged out without their intention/action.
 *
 * @returns {Promise<void>}
 */
const forceLogOut = async (redirectUrl = URL_PATTERNS.HOME) => {
  await executeLogOut(redirectUrl);
};

/**
 * Gets auth information to browser local storage and decodes information.
 * @return {Object}
 * */
const getLocalStorageToken = () => {
  // Creates token object with null properties in case of error or `localStorage` is undefined
  let tokenObject = { accessToken: null, refreshToken: null, email: null };
  const localStorageContent = localStorage.getItem(process.env.REACT_APP_AUTH_LOCAL_STORAGE);

  if (localStorageContent) {
    try {
      tokenObject = JSON.parse(localStorageContent);
    } catch (error) {
      // Token is not a valid JSON string
      try {
        // Check if the token is a legacy signed jwt token instead
        // We can drop support for legacy token and remove the following lines
        // after July 2022 (PLA-463)
        tokenObject = jwt_decode(localStorageContent);
      } catch (decodeError) {
        // Token is not a signed jwt token either
        logError(decodeError);
      }
    }
  }

  return tokenObject;
};

/**
 * Sets encoded auth information to browser local storage.
 * @param {string} accessToken
 * @param {string} refreshToken
 * @param {string} email
 * @param {object} expiresAt
 * */
const setLocalStorageToken = (accessToken, refreshToken, email, expiresAt) => {
  localStorage.setItem(
    process.env.REACT_APP_AUTH_LOCAL_STORAGE,
    JSON.stringify({
      accessToken,
      refreshToken,
      email,
      expiresAt,
    })
  );
};

/**
 * Checks if the browser has an access token store in localStorage.
 * If checkExpiration is set to true then expiration of the token is checked as well.
 *
 * @param {boolean} checkExpiration
 * @return {boolean}
 * */
const isLoggedIn = (checkExpiration = false) => {
  const localStorageTokenObject = getLocalStorageToken();
  const localStorageContent = localStorage.getItem(process.env.REACT_APP_AUTH_LOCAL_STORAGE);

  const doTokensExist = Boolean(localStorageContent && localStorageTokenObject);

  // Used for components (eg. Header) to avoid presenting that Member is logged out when token is being
  // refreshed. The same applies for components that redirect Member in case they're not logged in.
  // If refreshing a token failed Member will be logged out anyway by handleError in refreshTokenLink.
  if (!checkExpiration) {
    return doTokensExist;
  }

  return Boolean(doTokensExist && isDateAfter(parseDate(localStorageTokenObject.expiresAt), parseDate()));
};

/**
 * Synchronises Products from the local state (added when user was not authenticated) with the database.
 *
 * @param {number} memberId
 * @param {array} shoppingCartItemSet
 * @returns {Promise<void>}
 */
const synchroniseShoppingCart = async (memberId, shoppingCartItemSet) => {
  const localShoppingCartQuery = await client.query({
    query: GET_LOCAL_SHOPPING_CART,
    options: { fetchPolicy: FETCH_POLICY_CACHE_ONLY },
  });

  const shoppingCart = localShoppingCartQuery.data && localShoppingCartQuery.data.localShoppingCart;

  // Checks if user has added items to local shopping cart
  if (shoppingCart && shoppingCart.items.length > 0) {
    const shoppingCartItemsId =
      (await shoppingCartItemSet) &&
      shoppingCartItemSet.length &&
      shoppingCartItemSet.map(async (item) => item.product.id);

    // Creates shopping cart in database with local cart item
    (await shoppingCart.items.length) &&
      shoppingCart.items.forEach(async (item) => {
        // Skips if the wine is already on the DB shopping cart
        if (
          shoppingCartItemsId &&
          shoppingCartItemsId.length &&
          shoppingCartItemsId.includes(item.product.id)
        ) {
          await client.mutate({
            mutation: UPDATE_SHOPPING_CART_ITEM,
            refetchQueries: () => [{ query: GET_MEMBER_SHOPPING_CART }],
            variables: {
              input: {
                memberId,
                productId: item.product.id,
                quantity: item.quantity,
              },
            },
          });
          await client.mutate({
            mutation: RESET_LOCAL_SHOPPING_CART,
          });
        } else {
          // Creates an array (signUpInput) removing unnecessary info
          const { __typename, ...cleanedExtraInfo } = item.extraInfo || {};
          const formattedExtraInfo = formatExtraInfoOnLocalShoppingCartSync(cleanedExtraInfo);
          await client.mutate({
            mutation: ADD_SHOPPING_CART_ITEM,
            refetchQueries: () => [{ query: GET_MEMBER_SHOPPING_CART }],
            variables: {
              input: {
                memberId,
                productId: item.product.id,
                quantity: item.quantity,
                extraInfo: formattedExtraInfo,
              },
            },
          });
          await client.mutate({
            mutation: RESET_LOCAL_SHOPPING_CART,
          });
        }
      });
  }
};

/**
 * Synchronises Promo Code from the local state (added before user signed up/ logged in) with the database.
 *
 * @param {number} memberId
 * @returns {Promise<void>}
 */
const synchronisePromoCodeDiscount = async (memberId) =>
  client
    .query({
      query: GET_GUEST_PROMO_CODE_DISCOUNT,
      options: { fetchPolicy: FETCH_POLICY_CACHE_ONLY },
    })
    .then((promoCodeQuery) => {
      const { data } = promoCodeQuery;

      if (data === null) {
        // Sometimes 'data' is null, it shouldn't be the case as defaults are set for
        // this query (possible bug)
        logError(["Promo Code query empty.", promoCodeQuery]);
        return Promise.resolve();
      }

      const { promoCode, discountValue } = data.guestPromoCodeDiscount;

      if (discountValue) {
        return client
          .mutate({
            mutation: APPLY_DISCOUNT_CODE,
            variables: {
              input: {
                memberId,
                name: promoCode,
              },
            },
          })
          .then(() =>
            // Resets Promo Code discount once it's applied in the database
            client.mutate({
              mutation: SET_GUEST_PROMO_CODE_DISCOUNT,
              variables: {
                promoCode: null,
                discountValue: 0,
              },
            })
          );
      }
      return Promise.resolve();
    });

/**
 * Synchronises Referral Code from the local state (added before user signed up/ logged in) with the database.
 *
 * @param memberId
 * @returns {Promise<void>}
 */
const synchroniseReferralDiscount = async (memberId) =>
  client
    .query({
      query: GET_REFERRAL_DISCOUNT,
      options: { fetchPolicy: FETCH_POLICY_CACHE_ONLY },
    })
    .then((referralDiscountQuery) => {
      const { data } = referralDiscountQuery;

      const { referralCode } = data.referralDiscount;

      if (referralCode) {
        return client
          .mutate({
            mutation: APPLY_REFERRAL_CODE,
            variables: {
              input: {
                memberId,
                referralCode,
              },
            },
          })
          .then(() =>
            // Resets Referral Code once it's applied in the database
            client.mutate({
              mutation: SET_REFERRAL_DISCOUNT,
              variables: {
                referralCode: null,
                giveawayCode: null,
              },
            })
          );
      }
      return Promise.resolve();
    });

/**
 * Refreshes auth local cache used by apollo client.
 *
 * * @param memberId
 * */
const refreshAuthTokensInApolloCache = async (memberId) => {
  await client.mutate({
    mutation: SET_MEMBER_AUTH,
    variables: {
      memberId,
      token: localStorage.getItem(process.env.REACT_APP_AUTH_LOCAL_STORAGE),
    },
  });
};

/**
 * Saves Member ID to the local state and synchronises the local shopping cart (of unauthenticated user)
 * with the database.
 *
 * @param {number} memberId - id from member
 * @param {Array} shoppingCartItemSet - shopping cart items
 * */
const handleUpdatesOnLogin = async (memberId, shoppingCartItemSet) => {
  // WARNING: client.mutate and client.query seem to not return a Promise, thus 'await' seems to take
  // no effect. Even if they were a Promise there would be a risk the don't handle refetchQueries in scope
  // of the same Promise.

  // Sets values related to member's Auth
  await refreshAuthTokensInApolloCache(memberId);

  await synchronisePromoCodeDiscount(memberId);
  await synchroniseReferralDiscount(memberId);
  await synchroniseShoppingCart(memberId, shoppingCartItemSet);
};

const executeRefreshTokenRequest = async () => {
  // Creates arguments to pass into Login API request
  const urlPath = `${process.env.REACT_APP_REST_AUTH_PATH}`;
  const localStorageTokenObject = getLocalStorageToken();
  const data = {
    grant_type: "refresh_token",
    refresh_token: localStorageTokenObject.refreshToken,
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  };

  // gets a new access token using refresh token
  const refreshTokenResponse = await executeRestApi(HTTP_METHODS.POST, urlPath, { data });

  await setLocalStorageToken(
    refreshTokenResponse.access_token,
    refreshTokenResponse.refresh_token,
    localStorageTokenObject.email,
    addDate(parseDate(), refreshTokenResponse.expires_in, "second")
  );

  // Needed for compatibility with TokenRefetchLink
  refreshTokenResponse.text = () => new Promise((resolve) => resolve(JSON.stringify(refreshTokenResponse)));
  return refreshTokenResponse;
};

/**
 * This function is called when user logs in,
 * and deletes all locally saved quiz data.
 * */
const removeSavedQuizDataFromLocalStorage = () => {
  window.localStorage.removeItem("nonMemberQuizAnswersSelected");
  window.localStorage.removeItem("nonMemberQuizVersion");
  window.localStorage.removeItem("nonMemberQuizQuestion");
  window.localStorage.removeItem("nonMemberSavedName");
  window.localStorage.removeItem("nonMemberQuizWelcomePackId");
  window.localStorage.removeItem("nonMemberQuizFreeWineColorId");
  window.localStorage.removeItem("redBottles");
  window.localStorage.removeItem("whiteBottles");
  window.localStorage.removeItem("roseBottles");
  window.localStorage.removeItem("sparklingBottles");
};

/**
 * compares the users country id with the URLs country id.
 * if they're the same, store the id in local store
 * else, force log out the user.
 *
 * @param {object} userCountry
 * */
const validateUserCountryIdAndLogoutIfFails = (userCountry) => {
  const { id } = userCountry || { id: 0 };
  const urlCountryId = getCountryIdFromUrl();

  // If there is no country in the URL, don't set country ID in cache and don't force logout user.
  if (!urlCountryId) return;
  if (id === urlCountryId) {
    setCountryIdOfLoggedInMemberInCache(id);
  } else {
    forceLogOut();
  }
};

/**
 * Sets up local cache right after logging in such as auth cache, synchronising local cart cache with
 * remote cart data fetched from backend, setups user profile.
 *
 * @param {string} email
 * @param {object} tokenObj
 * @param {object} memberData
 * @returns {int|null}
 * */
const setupLocalAccountAndCleanUpPostLogin = async (email, tokenObj, memberData) => {
  if (!tokenObj) return null;

  // eslint-disable-next-line camelcase
  const { access_token, refresh_token, expires_in } = tokenObj;
  // eslint-disable-next-line camelcase
  const expiresAt = addDate(parseDate(), expires_in || ACCESS_TOKEN_EXPIRE_SECONDS, "second");

  // Encodes and Stores tokens in localStorage --> https://www.npmjs.com/package/jsonwebtoken
  await setLocalStorageToken(access_token, refresh_token, email, expiresAt);

  // Member details that need to be saved to the local state
  const { id, shoppingCart } = memberData || {};
  if (memberData && memberData.externalUniqueId) {
    await identifyIterableUser({
      email,
      id: memberData.externalUniqueId,
      firstName: memberData.firstName,
      lastName: memberData.lastName,
      name: `${memberData.firstName} ${memberData.lastName}`,
    });

    // Save urlCountryId as part of the sign up process.
    // This will be used later to force log out the user if he tries to switch country.
    const urlCountryId = getCountryIdFromUrl();
    setCountryIdOfLoggedInMemberInCache(urlCountryId);
  }

  // If Member data is not specified gets basic info from the API
  let memberId = id;
  let memberShoppingCart = shoppingCart;
  if (!memberId) {
    const memberInfoQuery = await client.query({
      query: GET_MEMBER_INFO_ON_LOGIN,
      options: { partialRefetch: true },
    });
    if (memberInfoQuery.data) {
      const { me } = memberInfoQuery.data;
      memberId = me && me.id;
      memberShoppingCart = me && me.shoppingCart;
      const subscriptionId = me && me.subscription && me.subscription.id;
      const { country, firstName, lastName, externalUniqueId } = me || {};
      await identifyIterableUser({
        email,
        id: externalUniqueId,
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        subscription_id: subscriptionId,
      });
      validateUserCountryIdAndLogoutIfFails(country);
    }
  }

  // Setups local cache and syncs cart data between backend and local cache
  const shoppingcartitemSet = memberShoppingCart ? memberShoppingCart.shoppingcartitemSet : [];
  await handleUpdatesOnLogin(memberId, shoppingcartitemSet);

  removeSavedQuizDataFromLocalStorage();

  return memberId;
};

/**
 * Executes Login api request and save token details.
 * @param {string} email
 * @param {string} password
 * @param {object} memberData
 * @return {object}
 * */
const executeLogInRequest = async (email, password, memberData = null) => {
  // Creates arguments to pass into Login API request
  const urlPath = `${process.env.REACT_APP_REST_AUTH_PATH}`;
  const data = {
    password,
    username: email,
    grant_type: "password",
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  };

  // Logs user in, if sign up is successful
  const loginResponse = await executeRestApi(HTTP_METHODS.POST, urlPath, {
    data,
  });
  await setupLocalAccountAndCleanUpPostLogin(email, loginResponse, memberData);

  return loginResponse;
};

/**
 * Sends a stand-alone login mutation to fetch new tokens and refresh local auth cache in cases such as
 * after password is updated which renders existing tokens void immediately.
 *
 * @param {string} email
 * @param {string} password
 * @param {string} memberId
 * @return {object}
 * */
const refreshTokensAfterPasswordUpdate = async (email, password, memberId) => {
  // Creates arguments to pass into Login API request
  const urlPath = `${process.env.REACT_APP_REST_AUTH_PATH}`;
  const data = {
    password,
    username: email,
    grant_type: "password",
    client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  };

  // Logs user in, if sign up is successful
  const loginResponse = await executeRestApi(HTTP_METHODS.POST, urlPath, {
    data,
  });
  if (loginResponse) {
    // eslint-disable-next-line camelcase
    const { access_token, refresh_token, expires_in } = loginResponse;
    const expiresIn = addDate(parseDate(), expires_in, "second");
    await setLocalStorageToken(access_token, refresh_token, email, expiresIn);

    await refreshAuthTokensInApolloCache(memberId);
  }

  return loginResponse;
};

/**
 * Sends sign up request to GraphQL mutation and log results in the browser
 * On successful sign up it saves local shopping cart item to database
 * @param {Object} form
 * @param {Function} signUp
 * @param {Function} addShoppingCart
 * @param {Function} setMemberAuth
 * @param {Function} resetLocalShoppingCart
 * @param {Function} localShoppingCart
 * @return {Promise<void>} response object built with responses from all promises.
 * */
const executeSignUpRequest = async (
  form,
  signUp,
  addShoppingCart,
  setMemberAuth,
  resetLocalShoppingCart,
  localShoppingCart
) => {
  const response = {};
  const shoppingCart = localShoppingCart;

  // Creates an array (signUpInput) removing unnecessary info
  const { __typename, confirmPassword, ...signUpInput } = form;

  // Builds input depending on which page the user is accessing
  const input = { ...signUpInput, hasUpdatedPassword: true };

  if (confirmPassword === signUpInput.password) {
    // Saves new member (signUp)
    await signUp({ variables: { input } }).then((member) => {
      response.member = member;
    });

    // Only executes login in case SignUp mutation returns no error
    if (!response.member.data.signUp.errors) {
      // Saves Member ID to the state
      await setMemberAuth({
        variables: {
          memberId: response.member.data.signUp.id,
          token: null, // token not available yet
        },
      });

      // Executes Login and redirects user to MyAccount page
      try {
        await executeLogInRequest(form.email, form.password);
      } catch (errorData) {
        const { error_description: errorMessage } = errorData || {};

        // Catches error from server (if login unsuccessful) and show message in the form
        // Stores error from server's response in state variables
        response.form = {
          ...form,
          error: [errorMessage || "Sorry, something went wrong!"],
        };
      }
      if (shoppingCart && shoppingCart.items.length > 0) {
        // Creates shopping cart in database with local storage item
        await shoppingCart.items.forEach((item) =>
          addShoppingCart({
            variables: {
              input: {
                memberId: response.member.data.signUp.id,
                productId: item.product.id,
                quantity: item.quantity,
              },
            },
          })
        );
        resetLocalShoppingCart();
      }
    }
  } else {
    response.errors = ["Sorry, your passwords do not match."];
  }
  return response;
};

const runSubmitQuizMutation = async ({
  answersIds,
  firstName,
  email,
  redBottles,
  whiteBottles,
  roseBottles,
  sparklingBottles,
  shouldWinesBeAddedToSubscription,
  welcomePackId,
  preferredWineClassId,
}) => {
  // Auth data required for user authentication
  const authData = {
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
  };

  let mutationResponse = null;

  await client
    .mutate({
      mutation: SUBMIT_QUIZ,
      variables: {
        input: {
          answersIds,
          firstName,
          email,
          redBottles,
          whiteBottles,
          roseBottles,
          sparklingBottles,
          shouldWinesBeAddedToSubscription,
          welcomePackId,
          preferredWineClassId,
          ...authData,
        },
      },
      refetchQueries: isLoggedIn() ? () => [{ query: GET_MEMBER_QUIZ_DATA }] : [],
    })
    .then((response) => {
      mutationResponse = response;
    })
    .catch((error) => {
      logError(error);
    });

  return mutationResponse;
};

export {
  executeRefreshTokenRequest,
  executeLogInRequest,
  refreshTokensAfterPasswordUpdate,
  executeSignUpRequest,
  getLocalStorageToken,
  isLoggedIn,
  forceLogOut,
  executeLogOut,
  runSubmitQuizMutation,
  setupLocalAccountAndCleanUpPostLogin,
};
