import styled from "styled-components";
import { colors, fonts } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";
import { ReactComponent as ArrowHeadDown } from "../../../assets/images/icons/arrowhead-down.svg";

export const BoxCartHeaderToggleWrapper = styled.div<{
  isSelected: boolean;
}>`
  position: relative;
  display: flex;
  cursor: pointer;
  color: ${(props) => (props.isSelected ? colors.white : colors.black)};
  width: 100%;
  border-radius: 99px;
  flex-direction: row;
  padding: 12px 12px;
  ${breakpoints.mdUp} {
    padding: 10px 16px;
  }
  ${breakpoints.xsDown} {
    padding: 12px 4px;
  }
  ${breakpoints.xxsDown} {
    padding: 8px 4px;
  }
`;

export const BoxCartHeaderToggleInnerWrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

export const StyledArrowHeadDown = styled(ArrowHeadDown)<{ isOpen: boolean; isSelected: boolean }>`
  width: 8px;
  height: 5px;
  filter: ${(props) => (props.isSelected ? "invert(100%)" : "invert(0%)")};
  transition: transform 200ms ease;
  stroke-width: 1.5px;
  transform: rotate(${(props) => (props.isOpen && props.isSelected ? 180 : 0)}deg);
  ${breakpoints.mdUp} {
    display: none;
    margin-right: 4px;
  }
`;

export const Title = styled.p`
  margin: 0;
  user-select: none;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  font-family: ${fonts.fontBauMedium};
  text-transform: capitalize;
  max-width: 100%;
  ${breakpoints.mdUp} {
    font-size: 14px;
    line-height: 20px;
  }
  ${breakpoints.smDown} {
    margin-right: 4px;
  }
  ${breakpoints.xxsDown} {
    margin-right: 2px;
    font-size: 10px;
    line-height: 15px;
  }
`;
