import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";
import { boxTrayToggleMobileHeight, colors, transition } from "../../../styles/variables";
import arrowRight from "../../../assets/images/icons/arrow-right-straight.svg";

export const BoxCartHeaderTogglesWrapper = styled.div<{
  isMonthlyBoxSelected: boolean;
  isTrayOpen: boolean;
}>`
  width: 100%;
  padding: 10px 8px 16px 10px;
  background-color: ${colors.coral};

  ${breakpoints.smUp} {
    height: 80px;
    background-color: ${(props) => (props.isMonthlyBoxSelected ? colors.warning : colors.blue)};
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0 20px;
  }

  ${breakpoints.smDown} {
    position: ${(props) => (props.isTrayOpen ? "fixed" : "relative")};
    height: ${boxTrayToggleMobileHeight};
    flex-direction: row;
    display: flex;
  }
`;

export const BoxCartHeaderTogglesInnerWrapper = styled.div`
  display: flex;
  background-color: ${colors.white};
  border-radius: 99px;
  flex-direction: row;
  width: 100%;
  position: relative;
  margin-right: 8px;
`;

export const HideOnSmUp = styled.div`
  ${breakpoints.smUp} {
    display: none;
  }
`;

export const BoxCartHeaderTablet = styled.div<{ isMonthlyBox: boolean }>`
  display: flex;
  background-color: ${colors.black};
  border-radius: 99px;
  flex-direction: row;
  width: 50%;
  height: 100%;
  position: absolute;
  left: ${(props) => (props.isMonthlyBox ? "0" : "50%")};
  transition: ${transition.baseTransition};
`;

export const BackButton = styled.div<{ isMonthlyBox: boolean }>`
  border-radius: 5px 0px 0px 5px;
  transform: translateX(-100%);
  display: flex;
  background-color: ${(props) => (props.isMonthlyBox ? colors.warning : colors.blue)};
  height: 80px;
  width: 80px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: url(${arrowRight}) no-repeat 50% 50% / 20px;
  }
  ${breakpoints.smDown} {
    display: none;
  }
`;
