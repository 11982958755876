import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import Button from "../Button";

interface ButtonLinkProps {
  type?: "button" | "submit" | "reset" | undefined;
  to: string | {};
  target?: string;
  children: ReactNode[] | ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  between?: boolean;
  lg?: boolean;
  full?: boolean;
  fsMd?: boolean;
  whiteBgOnHover?: boolean;
  disabled?: boolean;
  long?: boolean;
  fullMobile?: boolean;
  ghost?: boolean;
  outline?: boolean;
}

const ButtonLink = ({ to, target, children, ...otherProps }: ButtonLinkProps) => (
  <Link to={to} target={target}>
    <Button {...otherProps}>{children}</Button>
  </Link>
);

export default ButtonLink;
