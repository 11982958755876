import styled from "styled-components";

import { colors, transition } from "../../../styles/variables";

export const HamburgerMenu = styled.div`
  height: 15px;
  width: 18px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
`;

export const Line = styled.div`
  position: relative;
  height: 2px;
  width: 18px;
  border-radius: 2px;
  background-color: ${colors.black};
`;

export const Line1 = styled(Line)<{ isMobileMenuOpen: boolean }>`
  transform-origin: 0% 0%;
  transition: ${transition.headerMenuBarTransition};
  transform: ${(props) => (props.isMobileMenuOpen ? "rotate(45deg) scaleX(1.06)" : "rotate(0deg) scaleX(1)")};
`;

export const Line2 = styled(Line)<{ isMobileMenuOpen: boolean }>`
  transition: ${transition.headerMenuBarTransition};
  transform: ${(props) => (props.isMobileMenuOpen ? "scaleY(0)" : "scaleY(1)")};
`;

export const Line3 = styled(Line)<{ isMobileMenuOpen: boolean }>`
  transform-origin: 0% 100%;
  transition: ${transition.headerMenuBarTransition};
  transform: ${(props) =>
    props.isMobileMenuOpen ? "rotate(-45deg) scaleX(1.06)" : "rotate(0deg) scaleX(1)"};
`;

export const HeaderToggleMenuButtonInner = styled.div<{
  isMobileMenuOpen: boolean;
}>`
  transition: ${transition.baseTransition};
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 2px;
  background-color: ${(props) => (!props.isMobileMenuOpen ? colors.black : "transparent")};
  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 14px;
    height: 2px;
    background-color: ${colors.black};
    transition: ${transition.baseTransition};
  }
  &:before {
    top: ${(props) => (!props.isMobileMenuOpen ? "-4px" : "50%")};
    transform: rotate(${(props) => (!props.isMobileMenuOpen ? "0deg" : "-45deg")})
      translateY(${(props) => (!props.isMobileMenuOpen ? "0px" : "-50%")});
  }
  &:after {
    bottom: ${(props) => (!props.isMobileMenuOpen ? "-4px" : "auto")};
    top: ${(props) => (!props.isMobileMenuOpen ? "auto" : "50%")};
    left: ${(props) => (!props.isMobileMenuOpen ? "0" : "-1px")};
    transform: rotate(${(props) => (!props.isMobileMenuOpen ? "0deg" : "45deg")})
      translateY(${(props) => (!props.isMobileMenuOpen ? "0px" : "-50%")});
  }
`;
