import styled, { css } from "styled-components";
import { colors, fonts, transition } from "../../../styles/variables";

export const BoxCartSideToggleWrapper = styled.div<{
  isMonthlyBox: boolean;
  isSelected: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 5px;
  justify-content: space-between;
  transition: ${transition.baseTransition};
  width: ${(props) => (props.isSelected ? "104px" : "80px")};
  height: 80px;
  box-shadow: ${(props) => (props.isSelected ? "0 5px 10px 0" : "0 2px 4px 0")} rgba(0, 0, 0, 0.1);
  z-index: ${(props) => (props.isSelected ? "2" : "1")};
  border-radius: 10px 0 0 10px;
  cursor: pointer;

  &:hover {
    width: 104px;
  }
  
  ${(props) =>
    props.isMonthlyBox
      ? css`
          background-color: ${props.isSelected ? colors.warning : colors.silver};
          transform: translateY(${props.isSelected ? "0" : "100%"});
        `
      : css`
          background-color: ${props.isSelected ? colors.blue : colors.silver};
          transform: translateY(${props.isSelected ? "-100%" : "0"});
        `}
}`;

export const Title = styled.p`
  margin: 0;
  user-select: none;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  font-family: ${fonts.fontBauMedium};
  text-transform: uppercase;
  max-width: 80px;
`;
