import React from "react";
import { BoxCartToggleIconsWrapper, BottleIcon, PresentIcon, CircleWithNumber, IconWrapper } from "./styles";

interface BoxCartToggleIconsProps {
  variant: "header" | "side";
  isSelected: boolean;
  amountBottles: number;
  amountPresents: number;
}

const BoxCartToggleIcons = ({
  variant,
  isSelected,
  amountBottles,
  amountPresents,
}: BoxCartToggleIconsProps) => {
  const hasBottles = amountBottles > 0;
  const hasPresents = amountPresents > 0;
  return (
    <BoxCartToggleIconsWrapper>
      <IconWrapper reduceRightMargin={!hasBottles}>
        <BottleIcon isSelected={isSelected} variant={variant} />
        {hasBottles && <CircleWithNumber number={amountBottles} isSelected={isSelected} variant={variant} />}
      </IconWrapper>
      <IconWrapper reduceRightMargin={!hasPresents}>
        <PresentIcon isSelected={isSelected} variant={variant} />
        {hasPresents && (
          <CircleWithNumber number={amountPresents} isSelected={isSelected} variant={variant} />
        )}
      </IconWrapper>
    </BoxCartToggleIconsWrapper>
  );
};

export default BoxCartToggleIcons;
