import { useQuery } from "@apollo/client";
import { useFlagsStatus, useUnleashClient, useUnleashContext } from "@unleash/proxy-client-react";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";

import { GET_MEMBER_LAYOUT_INFO } from "../graphql/queries";
import { BREAKPOINT } from "../styles/variables";
import { MemberLayoutInfo } from "../types/__generated__/MemberLayoutInfo";
import { isLoggedIn } from "./auth";
import { PRESELECTED_WELCOME_PACK_ID_COOKIE } from "./constants";
import { logError } from "./logging";
import { getCountry } from "./urls.helper";

/**
 * React hook that stores the current window innerWidth.
 *
 * @returns {Number}
 */
const useWindowWidthSize = () => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      // Set window width/height to state
      setWindowWidth(window.innerWidth);
    };

    // DOM window listener
    window.addEventListener("resize", handleResize);

    // Remove listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
};

/**
 * Returns an object with booleans for device size based on window width and breakpoints.
 */
const useDeviceSize = (): {
  isExtraSmall: boolean;
  isSmall: boolean;
  isMedium: boolean;
  isSmallOrMedium: boolean;
} => {
  const windowWidth = useWindowWidthSize();
  const isExtraSmall = windowWidth <= BREAKPOINT.XS;
  const isSmall = windowWidth <= BREAKPOINT.SM;
  const isSmallOrMedium = windowWidth <= BREAKPOINT.MD;
  const isMedium = isSmallOrMedium && !isSmall;
  return { isExtraSmall, isSmall, isMedium, isSmallOrMedium };
};

/**
 * Use this hook in a page component to scroll to the element corresponding to the URL hash on page load.
 */
export default function useScrollToHash(deps: React.DependencyList | undefined = []) {
  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) element.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, deps);
}

/**
 * React hook that initializes the unleash client
 */
const useInitUnleashClient = (): { isInitialized: boolean } => {
  const country = getCountry();
  const updateContext = useUnleashContext();
  const { flagsReady } = useFlagsStatus();
  const unleashClient = useUnleashClient();
  const userIsLoggedIn = isLoggedIn();
  const [isInitialized, setIsInitialized] = useState(false);

  const { data, loading: memberLayoutInfoDataLoading } = useQuery<MemberLayoutInfo>(GET_MEMBER_LAYOUT_INFO, {
    skip: !userIsLoggedIn,
  });

  const { externalUniqueId, subscription, createdDate } = data?.me || {};

  useEffect(() => {
    async function updateUnleashContextAndStartClient() {
      const defaultProperties = {
        countryCode: country,
      };
      let userProperties = {};
      if (externalUniqueId) {
        userProperties = {
          memberCreatedTimestamp: Math.round(new Date(createdDate).getTime() / 1000),
          subscriptionStatus: subscription?.subscriptionStatus?.name,
          subscriptionMonth: subscription?.monthCount,
          subscriptionFrequency: subscription?.monthFrequency,
          loggedIn: true,
        };
      } else {
        userProperties = {
          loggedIn: false,
        };
      }

      // Wait for the new flags to pull in from the different context
      await updateContext({
        userId: externalUniqueId ?? undefined,
        properties: {
          ...defaultProperties,
          ...userProperties,
        },
      });

      await unleashClient.start();
      setIsInitialized(true);
    }

    if (memberLayoutInfoDataLoading) return;

    // flagsReady == true means user context has been updated and client already started
    if (flagsReady) {
      setIsInitialized(true);
      return;
    }

    updateUnleashContextAndStartClient().catch((error) => {
      logError(error);
      setIsInitialized(true);
    });
  }, [externalUniqueId, subscription, createdDate, flagsReady, memberLayoutInfoDataLoading]);

  return { isInitialized };
};

/**
 * Custom hook for handling 'welcome-pack' URL parameter in the URL and setting it in a cookie.
 */
const useWelcomePackCookie = () => {
  useEffect(() => {
    // Get the URL parameter value
    const urlParams = new URLSearchParams(window.location.search);
    const preselectedWelcomePackId = urlParams.get("welcome-pack");

    if (preselectedWelcomePackId) {
      // Set the value in a cookie with a a day expiration time
      Cookies.set(PRESELECTED_WELCOME_PACK_ID_COOKIE, preselectedWelcomePackId, { expires: 1 });
    }
  }, []);
};

export { useDeviceSize, useInitUnleashClient, useScrollToHash, useWelcomePackCookie, useWindowWidthSize };
