import { BREAKPOINT, CONTAINER_MAX_WIDTH } from "./variables";

const breakpoints = {
  xxsDown: `@media (max-width: ${BREAKPOINT.XXS}px)`,
  xsDown: `@media (max-width: ${BREAKPOINT.XS}px)`,
  smDown: `@media (max-width: ${BREAKPOINT.SM}px)`,
  mdDown: `@media (max-width: ${BREAKPOINT.MD}px)`,
  lgDown: `@media (max-width: ${BREAKPOINT.LG}px)`,
  xlDown: `@media (max-width: ${BREAKPOINT.XL}px)`,

  xsUp: `@media (min-width: ${BREAKPOINT.XS + 1}px)`,
  smUp: `@media (min-width: ${BREAKPOINT.SM + 1}px)`,
  mdUp: `@media (min-width: ${BREAKPOINT.MD + 1}px)`,
  lgUp: `@media (min-width: ${BREAKPOINT.LG + 1}px)`,
  xlUp: `@media (min-width: ${BREAKPOINT.XL + 1}px)`,

  md: `@media (max-width: ${BREAKPOINT.MD}px) and (min-width: ${BREAKPOINT.SM + 1}px)`,

  containerLgDown: `@media (max-width: ${CONTAINER_MAX_WIDTH.LG_UP}px)`,
  containerLgUp: `@media (min-width: ${CONTAINER_MAX_WIDTH.LG_UP + 1}px)`,

  iphone5: "@media screen and (device-aspect-ratio: 40/71)",
  iphone678:
    "@media only screen" +
    " and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2)",
  iphone678plus:
    "@media only screen" +
    " and (device-width : 414px) and (device-height : 736px) and (-webkit-device-pixel-ratio : 3)",
  iphoneX:
    "@media only screen" +
    " and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3)",
  iphoneXsMax:
    "@media only screen and (min-device-width : 414px) and (max-device-height : 896px) " +
    "and (-webkit-device-pixel-ratio : 3)",
  iphoneXr:
    "@media only screen and (min-device-width : 414px) and (max-device-height : 896px) " +
    "and (-webkit-device-pixel-ratio : 2)",
};

export default breakpoints;
