import styled from "styled-components";
import { ColumnTitleIcon } from "../../organisms/Header/style";
import nextBox from "../../../assets/images/icons/box-transparent.svg";
import rewards from "../../../assets/images/icons/rewards-transparent.svg";
import pastWines from "../../../assets/images/icons/past-wines-transparent.svg";
import more from "../../../assets/images/icons/circle-more.svg";
import myTastes from "../../../assets/images/icons/lips-transparent.svg";
import settings from "../../../assets/images/icons/settings.svg";
import inviteFriends from "../../../assets/images/icons/invite-friends-transparent.svg";
import megaMenuDropdownImage from "../../../assets/images/general/mega-menu-dropdown.png";
import megaMenuDropdownWebpImage from "../../../assets/images/general/mega-menu-dropdown.webp";
import Image from "../../atoms/Image";
import breakpoints from "../../../styles/breakpoints";

export const MenuImageWrapper = styled.div`
  ${breakpoints.smDown} {
    display: none;
  }
`;

export const MemberDropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MemberMenuDropdownWrapper = styled.div<{
  isAnyDropdownActive: boolean;
}>`
  display: ${(props) => (props.isAnyDropdownActive ? "flex" : "none")};
  flex-direction: column;
  flex: auto;
  padding: 52px 138px 50px 130px;
  ${breakpoints.xlDown} {
    padding: 40px 70px 24px;
  }
  ${breakpoints.lgDown} {
    padding: 30px 20px;
  }
`;

export const MemberDropdownRow = styled.div<{
  increasedMarginBottom?: boolean;
}>`
  display: flex;
  flex-direction: row;
  margin-bottom: ${(props) => (props.increasedMarginBottom ? "24px" : "")};
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 33%;
`;

export const MenuDropdownImage = styled(Image).attrs({
  src: megaMenuDropdownImage,
  webpSrc: megaMenuDropdownWebpImage,
})`
  object-fit: cover;
  height: 100%;
  width: 487px;
`;

export const NextBoxIcon = styled(ColumnTitleIcon).attrs({ src: nextBox })``;
export const MyRewardsIcon = styled(ColumnTitleIcon).attrs({ src: rewards })``;
export const PastWinesIcon = styled(ColumnTitleIcon).attrs({
  src: pastWines,
})``;
export const MoreIcon = styled(ColumnTitleIcon).attrs({ src: more })``;
export const MyTastesIcon = styled(ColumnTitleIcon).attrs({ src: myTastes })``;
export const InviteMatesIcon = styled(ColumnTitleIcon).attrs({
  src: inviteFriends,
})``;
export const SettingsIcon = styled(ColumnTitleIcon).attrs({ src: settings })`
  padding: 5px;
`;
