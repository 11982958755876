import React from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import compose from "lodash.flowright";
import { withTranslation } from "react-i18next";
import Loading from "../../atoms/Loading";
import { TOGGLE_SHOPPING_CART_SUBSCRIPTION } from "../../../graphql/mutations";
import {
  GET_MEMBER_SHOPPING_CART,
  GET_MEMBER_SUBSCRIPTION,
  GET_ANNOUNCEMENT,
} from "../../../graphql/queries";
import { GET_AUTH } from "../../../graphql/resolvers/auth";
import { I18N_NAMESPACES } from "../../../helpers/constants";

import urlPatterns from "../../../urls";
import { isLoggedIn } from "../../../helpers/auth";
import { getAnnouncementsWithImages } from "../../../helpers/tools";
import {
  WelcomePackWarningContainer,
  ImageContainer,
  SwitchToSubscriptionButton,
  QuizButton,
  CheckoutButton,
  WarningText,
  ButtonsContainer,
  OptionsButtonWrapper,
  BuyTextWrapper,
  GenericButtonWrapper,
} from "./styles";

const WelcomePackWarning = (props) => {
  const { getAnnouncementsQuery, authQuery, history, toggleWelcomePackWarning, t } = props;

  if (getAnnouncementsQuery.loading || authQuery.loading) {
    return <Loading />;
  }

  const announcementsWithImages = getAnnouncementsWithImages(getAnnouncementsQuery);
  const latestAnnouncement = announcementsWithImages[0];

  const { imageMedium, imageSeoTitle } = latestAnnouncement || {};

  const { memberId } = authQuery.auth;

  const onClickSwitchToSubscription = () => {
    toggleWelcomePackWarning();
    if (!isLoggedIn()) {
      history.push(urlPatterns.QUIZ);
    }
  };

  return (
    <WelcomePackWarningContainer>
      <ImageContainer>
        <img src={imageMedium} alt={imageSeoTitle} />
      </ImageContainer>
      <WarningText>
        {t("welcomePack:welcomePackWarning:warningText1")}
        {t("welcomePack:welcomePackWarning:warningText2")}
      </WarningText>
      <ButtonsContainer>
        <GenericButtonWrapper>
          {isLoggedIn() ? (
            <SwitchToSubscriptionButton
              input={{ memberId }}
              mutationProp={TOGGLE_SHOPPING_CART_SUBSCRIPTION}
              reFetchQueriesProp={[{ query: GET_MEMBER_SHOPPING_CART }, { query: GET_MEMBER_SUBSCRIPTION }]}
              label=""
              submittingLabel=""
              callbackBeforeMutation={() => onClickSwitchToSubscription()}
              awaitRefetchQueries
            >
              <OptionsButtonWrapper>
                <p>{t("welcomePack:welcomePackWarning:subscriptionButtonText")}</p>
              </OptionsButtonWrapper>
            </SwitchToSubscriptionButton>
          ) : (
            <QuizButton type="submit" to={urlPatterns.QUIZ} between full fsMd>
              <BuyTextWrapper>
                <p>{t("welcomePack:welcomePackWarning:subscriptionButtonText")}</p>
              </BuyTextWrapper>
            </QuizButton>
          )}
        </GenericButtonWrapper>
        <GenericButtonWrapper>
          <CheckoutButton type="submit" to={urlPatterns.CHECKOUT} between full fsMd whiteBgOnHover>
            <BuyTextWrapper>
              <span>{t("welcomePack:welcomePackWarning:checkoutButtonText")}</span>
            </BuyTextWrapper>
          </CheckoutButton>
        </GenericButtonWrapper>
      </ButtonsContainer>
    </WelcomePackWarningContainer>
  );
};

WelcomePackWarning.propTypes = {
  getAnnouncementsQuery: PropTypes.shape({}).isRequired,
  authQuery: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
  toggleWelcomePackWarning: PropTypes.func.isRequired,
};

WelcomePackWarning.defaultProps = {
  history: {},
};

export default compose(
  graphql(GET_AUTH, { name: "authQuery" }),
  graphql(GET_ANNOUNCEMENT, {
    name: "getAnnouncementsQuery",
    options: {
      variables: {
        memberId: null,
      },
    },
  }),
  withTranslation(I18N_NAMESPACES.WELCOME_PACK)
)(WelcomePackWarning);
