import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { FlagProvider, UnleashClient } from "@unleash/proxy-client-react";
import { init } from "@sentry/browser";
import { PostHogProvider } from "posthog-js/react";
import "core-js/features/promise";

import App from "./App";
import ScrollToTop from "./components/atoms/ScrollToTop";
import {
  ERROR_TYPE_ONUNHANDLEDREJECTION,
  ERROR_MESSAGE_PROMISE_REJECTION_EMPTY_VALUE,
  ERROR_MESSAGE_PROMISE_REJECTION_NULL_VALUE,
  ERROR_MESSAGE_PROMISE_REJECTION_VALUE_WITH_ID,
} from "./helpers/constants";
import { isUnSupportedBrowser, getUnleashConfig } from "./helpers/tools";
import "./helpers/i18n";
import BrowserNotSupported from "./pages/BrowserNotSupported";

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const isBrowserNotSupported = isUnSupportedBrowser();

if (SENTRY_DSN && !isBrowserNotSupported) {
  init({
    dsn: SENTRY_DSN,
    normalizeDepth: 6, // Default value: 3
    beforeSend(event) {
      const { exception } = event || {};
      const { values } = exception || {};
      const [errorValueItem] = values || [];
      const { mechanism, value } = errorValueItem || {};
      const { type } = mechanism || {};
      if (
        type &&
        type === ERROR_TYPE_ONUNHANDLEDREJECTION &&
        value &&
        (`${value}` === ERROR_MESSAGE_PROMISE_REJECTION_EMPTY_VALUE ||
          `${value}` === ERROR_MESSAGE_PROMISE_REJECTION_NULL_VALUE ||
          value.match(RegExp(ERROR_MESSAGE_PROMISE_REJECTION_VALUE_WITH_ID, "i")))
      ) {
        // Before sending an error event to Sentry,
        // if the error's mechanism type is 'onunhandledrejection',
        // and the error message has specific promise rejection values defined in constants.js,
        // then, we do not log this event to Sentry.
        // https://github.com/getsentry/sentry-javascript/issues/3440
        return null;
      }
      return event;
    },
  });
}

// Adds support for Object.values to older browsers
if (!Object.values) Object.values = (obj) => Object.keys(obj).map((key) => obj[key]);

const history = createBrowserHistory();

const unleashClient = new UnleashClient(getUnleashConfig());

const postHogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

ReactDOM.render(
  isBrowserNotSupported ? (
    <BrowserNotSupported />
  ) : (
    <Router history={history}>
      <ScrollToTop>
        {/* Defer starting of client to until after loading user context in Layout */}
        <FlagProvider unleashClient={unleashClient} startClient={false}>
          <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={postHogOptions}>
            <App history={history} />
          </PostHogProvider>
        </FlagProvider>
      </ScrollToTop>
    </Router>
  ),
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

// Allows hot module replacement (HMR) for create-react-app
if (module.hot && !isBrowserNotSupported) {
  module.hot.accept("./App", () => {
    const NextApp = import("./App").default;
    ReactDOM.render(
      <Router history={history}>
        <ScrollToTop>
          <NextApp />
        </ScrollToTop>
      </Router>,
      document.getElementById("root")
    );
  });
}
