import { captureMessage, captureException } from "@sentry/browser";
import {
  ERROR_MESSAGE_NOT_AUTHENTICATED,
  ERROR_MESSAGE_ACCESS_TOKEN_UNAUTHORIZED,
  ERROR_MESSAGE_INVALID_GRANT,
} from "./constants";

const WHITE_LISTED_ERROR_MESSAGES = [
  ERROR_MESSAGE_INVALID_GRANT,
  ERROR_MESSAGE_NOT_AUTHENTICATED,
  ERROR_MESSAGE_ACCESS_TOKEN_UNAUTHORIZED,

  // Errors from stripe payment card from
  "Your card's security code is incomplete.",
  "Your card number is incomplete.",
  "Your card's expiration year is in the past.",
  "Sorry, the payment wasn't approved.",

  // Errors from checkout discount code
  "Sorry, this code is no longer valid",
];

const isIncorrectPasswordError = (error) => {
  const { error: errorMsg } = error || {};
  return errorMsg === ERROR_MESSAGE_INVALID_GRANT;
};

/**
 * Helper function that logs an error to the console and to Sentry.
 *
 * @param
 * {Object|String} error - Error object or string message to be logged
 * {Object} extra - Extra info
 *  .e.g.
 *  - location: where the error occurs
 */
const logError = (error, extra = {}) => {
  if (isIncorrectPasswordError(error)) return;

  const { message } = error || {};
  if (typeof message === "string" && WHITE_LISTED_ERROR_MESSAGES.includes(message)) {
    return;
  }

  // eslint-disable-next-line no-console
  console.error(error instanceof Error ? error : JSON.stringify(error));
  if (typeof error === "string" || error instanceof String) {
    if (WHITE_LISTED_ERROR_MESSAGES.includes(error)) {
      return;
    }

    captureMessage(error, { extra });
  } else {
    captureException(error, { extra });
  }
};

export { logError };
