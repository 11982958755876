import { gql } from "@apollo/client";

/**
 * QUERIES
 * */

export const BADGE_FRAGMENT = gql`
  fragment BadgeFragment on BadgeObject {
    id
    slug
    name
    badgeNameWhenEarned
    level
    isEarned
    points
    badgePointsWhenEarned
    imageSmallUrl
    memberBadge {
      badgeNameWhenEarned
      badgePointsWhenEarned
    }
  }
`;

export const BADGE_DETAILS_FRAGMENT = gql`
  fragment BadgeDetailsFragment on BadgeObject {
    id
    slug
    name
    badgeNameWhenEarned
    level
    isEarned
    dateTimeEarned
    points
    badgePointsWhenEarned
    imageSmallUrl
    shortDescription
    externalActionQuestion
    hasWineCharacteristicBadgeRule
    shouldHideWineRecommendation
    badgerulesSet {
      id
      quantity
      contentType {
        id
        appLabel
        model
      }
      objectId
    }
    memberBadge {
      badgeNameWhenEarned
      badgePointsWhenEarned
    }
  }
`;

// Query for Earn Points V2
const GET_MEMBER_BADGES_BY_CATEGORY = gql`
  ${BADGE_DETAILS_FRAGMENT}
  query MemberBadgesByCategory(
    $memberId: Int
    $badgeCategoryId: Int!
    $limit: Int
    $offset: Int
    $orderBy: MemberBadgeByCategoryOrderField
    $name: String
  ) {
    memberBadgesByCategory(
      memberId: $memberId
      badgeCategoryId: $badgeCategoryId
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      name: $name
    ) {
      ...BadgeDetailsFragment
    }
  }
`;

// Query for Earn Points V3
export const BADGES_BY_CATEGORY_WITH_COUNT = gql`
  ${BADGE_FRAGMENT}
  query GetBadgesWithCount($badgeCategoryId: Int!, $limit: Int, $offset: Int, $badgeStatus: BadgeStatusEnum) {
    badgesByCategory(
      badgeCategoryId: $badgeCategoryId
      limit: $limit
      offset: $offset
      badgeStatus: $badgeStatus
    ) {
      ...BadgeFragment
    }
    badgesByCategoryCount(badgeCategoryId: $badgeCategoryId, badgeStatus: $badgeStatus)
  }
`;

// Query for Earn Points V3
export const FETCH_MORE_BADGES_BY_CATEGORY = gql`
  ${BADGE_FRAGMENT}
  query FetchMoreBadges($badgeCategoryId: Int!, $limit: Int, $offset: Int, $badgeStatus: BadgeStatusEnum) {
    badgesByCategory(
      badgeCategoryId: $badgeCategoryId
      limit: $limit
      offset: $offset
      badgeStatus: $badgeStatus
    ) {
      ...BadgeFragment
    }
  }
`;

export const GET_BADGE = gql`
  ${BADGE_DETAILS_FRAGMENT}
  query GetBadge($badgeSlug: String!) {
    badge(badgeSlug: $badgeSlug) {
      ...BadgeDetailsFragment
    }
  }
`;

export const GET_EARNED_BADGES_COUNT = gql`
  query GetEarnedBadgesCount($badgeCategoryId: Int!) {
    badgesByCategoryCount(badgeCategoryId: $badgeCategoryId, badgeStatus: EARNED)
  }
`;

const GET_MEMBER_BADGES_BY_CATEGORY_WITH_STOCK_CHECK = gql`
  query MemberBadgesByCategoryWithStockCheck(
    $memberId: Int
    $badgeCategoryId: Int!
    $limit: Int
    $offset: Int
  ) {
    memberBadgesByCategory(
      memberId: $memberId
      badgeCategoryId: $badgeCategoryId
      limit: $limit
      offset: $offset
    ) {
      id
      name
      level
      isEarned
      points
      imageSmallUrl
      shortDescription
      externalActionQuestion
      hasWineCharacteristicBadgeRule
      badgeCategory {
        id
      }
      relatedWineHasStock
    }
  }
`;

export const CHALLENGE_BADGE_DETAILS_FRAGMENT = gql`
  fragment ChallengeBadgeDetailsFragment on BadgeObject {
    id
    name
    slug
    level
    points
    pointsRequired
    shortDescription
    challengeKeyBenefit
    challengeGameplayTitle1
    challengeGameplayDescription1
    challengeGameplayTitle2
    challengeGameplayDescription2
    challengeGameplayTitle3
    challengeGameplayDescription3
    badgeNameWhenEarned
    badgePointsWhenEarned
    isEarned
    dateTimeEarned
    relatedWineHasStock
    imageSmallUrl
    badgeRuleQuantity
    hasWineCharacteristicBadgeRule
    shouldHideWineRecommendation
    badgeCategory {
      id
      name
    }
    challengeProgress {
      id
      challengeStatus {
        name
        id
      }
      numberOfTotalBadges
      numberOfEarnedBadges
      dateTimeStarted
    }
    badgerulesSet {
      id
      quantity
      contentType {
        id
        appLabel
        model
      }
      objectId
    }
  }
`;

export const CHALLENGE_BADGE_FRAGMENT = gql`
  fragment ChallengeBadgeFragment on BadgeObject {
    id
    name
    slug
    level
    points
    pointsRequired
    isEarned
    dateTimeEarned
    imageSmallUrl
    badgeRuleQuantity
    badgeCategory {
      id
      name
    }
    challengeProgress {
      id
      challengeStatus {
        name
        id
      }
      numberOfTotalBadges
      numberOfEarnedBadges
      dateTimeStarted
    }
    memberBadge {
      badgeNameWhenEarned
      badgePointsWhenEarned
    }
  }
`;

export const GET_CHALLENGE_BADGE = gql`
  ${CHALLENGE_BADGE_DETAILS_FRAGMENT}
  query GetChallengeBadge($badgeSlug: String!) {
    badge(badgeSlug: $badgeSlug) {
      ...ChallengeBadgeDetailsFragment
      memberBadge {
        badgeNameWhenEarned
        badgePointsWhenEarned
      }
    }
  }
`;

export const GET_SINGLE_BADGE = gql`
  ${CHALLENGE_BADGE_DETAILS_FRAGMENT}
  query GetSingleBadge($badgeSlug: String!) {
    badge(badgeSlug: $badgeSlug) {
      ...ChallengeBadgeDetailsFragment
      externalActionQuestion
      memberBadge {
        badgeNameWhenEarned
        badgePointsWhenEarned
      }
    }
  }
`;

const GET_MEMBER_CHALLENGE_BADGES = gql`
  ${CHALLENGE_BADGE_DETAILS_FRAGMENT}
  query ChallengeBadges(
    $memberId: Int
    $badgeCategoryId: Int!
    $limit: Int
    $offset: Int
    $orderBy: MemberBadgeByCategoryOrderField
    $name: String
  ) {
    memberBadgesByCategory(
      memberId: $memberId
      badgeCategoryId: $badgeCategoryId
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      name: $name
    ) {
      ...ChallengeBadgeDetailsFragment
    }
  }
`;

export const GET_CHALLENGE_BADGES = gql`
  ${CHALLENGE_BADGE_FRAGMENT}
  query GetChallengeBadgesWithCount(
    $badgeCategoryId: Int!
    $limit: Int
    $offset: Int
    $badgeStatus: BadgeStatusEnum
  ) {
    badgesByCategory(
      badgeCategoryId: $badgeCategoryId
      limit: $limit
      offset: $offset
      badgeStatus: $badgeStatus
    ) {
      ...ChallengeBadgeFragment
    }
    badgesByCategoryCount(badgeCategoryId: $badgeCategoryId, badgeStatus: $badgeStatus)
  }
`;

export const FETCH_MORE_CHALLENGE_BADGES = gql`
  ${CHALLENGE_BADGE_FRAGMENT}
  query FetchMoreChallengeBadges(
    $badgeCategoryId: Int!
    $limit: Int
    $offset: Int
    $badgeStatus: BadgeStatusEnum
  ) {
    badgesByCategory(
      badgeCategoryId: $badgeCategoryId
      limit: $limit
      offset: $offset
      badgeStatus: $badgeStatus
    ) {
      ...ChallengeBadgeFragment
    }
  }
`;

const GET_CHILD_BADGES_FROM_CHALLENGE_BADGES = gql`
  query ChildChallengeBadges($memberId: Int, $challengeBadgeId: Int!) {
    memberChildBadgesFromChallengeBadge(memberId: $memberId, challengeBadgeId: $challengeBadgeId) {
      id
      name
      points
      pointsRequired
      shortDescription
      badgeNameWhenEarned
      badgePointsWhenEarned
      isEarned
      relatedWineHasStock
      imageSmallUrl
      hasWineCharacteristicBadgeRule
      shouldHideWineRecommendation
      badgeCategory {
        id
        name
      }
      badgeRuleQuantity
      challengeProgress {
        id
        challengeStatus {
          name
          id
        }
        numberOfTotalBadges
        numberOfEarnedBadges
      }
      badgerulesSet {
        id
        quantity
        contentType {
          id
          appLabel
          model
        }
        objectId
      }
    }
  }
`;

const GET_MEMBER_BADGES_COUNT = gql`
  query MemberBadgesCount($memberId: Int, $badgeCategoryIds: [Int], $name: String) {
    memberBadgesByCategoryCount(memberId: $memberId, badgeCategoryIds: $badgeCategoryIds, name: $name)
  }
`;

const GET_MEMBER_POINTS_BADGES = gql`
  query MemberPointsBadges($memberId: Int) {
    memberPointsBadges(memberId: $memberId) {
      id
      name
      level
      isEarned
      badgeNameWhenEarned
      badgePointsWhenEarned
      dateTimeEarned
      points
      pointsRequired
      imageSmallUrl
      shortDescription
      coverPhotoLargeUrl
      shouldHideWineRecommendation
      freebiesEarned {
        id
        product {
          id
          name
        }
        imageMedium
      }
      isFreebiesInNextBox
      badgebenefitSet {
        creditType {
          id
        }
        freebie {
          imageMedium
          product {
            id
            name
          }
        }
      }
    }
  }
`;

const GET_MEMBER_NEXT_BADGE_SUGGESTIONS = gql`
  query MemberBadgeSuggestions($memberId: Int) {
    memberNextBadgeSuggestions(memberId: $memberId) {
      id
      displayTitle
      sortOrder
      frontEndRoute {
        pathWeb
      }
      badge {
        id
        points
      }
    }
  }
`;

const GET_BADGE_RELATED_WINES = gql`
  query BadgeRelatedWines($badgeId: Int!) {
    badgeRelatedWines(badgeId: $badgeId) {
      numberOfWinesTried
      averageRating
      badgeRecommendedWines {
        id
        hasStock
        hasOtherVintageStock
        country {
          name
        }
        product {
          id
          name
          slug
          sellingPrice
          coverPhotoSmall
          productPhotos {
            photoSmall
          }
          productType {
            id
          }
          lastOrderByMember {
            createdDate
          }
        }
        wineType {
          id
          name
          wineClass {
            id
            name
          }
        }
        country {
          name
        }
        wineRegion {
          id
          name
        }
        memberLikelihood
        memberWineRatingScore
      }
      badgeAlreadyDrunkWines {
        id
        hasStock
        hasOtherVintageStock
        country {
          name
        }
        product {
          id
          name
          slug
          sellingPrice
          coverPhotoSmall
          productPhotos {
            photoSmall
          }
          productType {
            id
          }
          lastOrderByMember {
            createdDate
          }
        }
        wineType {
          id
          name
          wineClass {
            id
            name
          }
        }
        country {
          name
        }
        wineRegion {
          id
          name
        }
        memberLikelihood
        memberWineRatingScore
      }
    }
  }
`;

/**
 * MUTATIONS
 * */

const CREATE_EXTERNAL_ACTION = gql`
  mutation CreateExternalAction($input: ExternalActionInput!) {
    createExternalAction(input: $input) {
      externalAction {
        id
        quantity
        description
        externalAction {
          id
          name
        }
      }
      errors {
        messages
        field
      }
    }
  }
`;

const UPDATE_CHALLENGE_PROGRESS_STATUS = gql`
  mutation UpdateChallengeProgressStatus($input: UpdateChallengeProgressInput!) {
    updateChallengeProgressStatus(input: $input) {
      errors {
        field
        messages
      }
      isSuccessful
      challengeProgress {
        id
        numberOfTotalBadges
        numberOfEarnedBadges
        challengeStatus {
          name
          id
        }
      }
    }
  }
`;

export {
  GET_MEMBER_BADGES_BY_CATEGORY,
  GET_MEMBER_BADGES_BY_CATEGORY_WITH_STOCK_CHECK,
  GET_MEMBER_POINTS_BADGES,
  CREATE_EXTERNAL_ACTION,
  GET_MEMBER_NEXT_BADGE_SUGGESTIONS,
  GET_MEMBER_CHALLENGE_BADGES,
  GET_CHILD_BADGES_FROM_CHALLENGE_BADGES,
  GET_BADGE_RELATED_WINES,
  UPDATE_CHALLENGE_PROGRESS_STATUS,
  GET_MEMBER_BADGES_COUNT,
};
