import React from "react";

import HeaderUserName from "../HeaderUserName";
import { StyledHeaderUserAvatarHolder, AvatarAndNamePointContainer } from "./style";

interface HeaderUserProps {
  isMemberDropDownOpen: boolean;
  firstName: string;
}

const HeaderUser = ({ isMemberDropDownOpen, firstName }: HeaderUserProps) => (
  <StyledHeaderUserAvatarHolder isMemberDropDownOpen={isMemberDropDownOpen}>
    <AvatarAndNamePointContainer>
      <HeaderUserName isMemberDropDownOpen={isMemberDropDownOpen} firstName={firstName} />
    </AvatarAndNamePointContainer>
  </StyledHeaderUserAvatarHolder>
);

export default HeaderUser;
