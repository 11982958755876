import React, { useState, useEffect } from "react";
import LazyLoad from "react-lazyload";

import Image from "../../atoms/Image";
import { ReactComponent as ToggleArrow } from "../../../assets/images/icons/slider-toggle-arrow.svg";
import homePreviewImg1 from "../../../assets/images/general/good-pair-days-app-screen-my-cellar.png";
import homePreviewImg1Global from "../../../assets/images/general/good-pair-days-app-screen-my-cellar-global.png";
import homePreviewImg1Alt from "../../../assets/images/general/good-pair-days-app-screen-my-cellar.webp";
import homePreviewImg1AltGlobal from "../../../assets/images/general/good-pair-days-app-screen-my-cellar-global.webp";
import homePreviewImg2 from "../../../assets/images/general/good-pair-days-app-screen-rate-and-review.png";
import homePreviewImg2Alt from "../../../assets/images/general/good-pair-days-app-screen-rate-and-review.webp";
import homePreviewImg3 from "../../../assets/images/general/good-pair-days-app-screen-points-and-rewards.png";
import homePreviewImg3Alt from "../../../assets/images/general/good-pair-days-app-screen-points-and-rewards.webp";
import homePreviewImg4 from "../../../assets/images/general/good-pair-days-app-screen-taste-test.png";
import homePreviewImg4Alt from "../../../assets/images/general/good-pair-days-app-screen-taste-test.webp";
import homePreviewImg5 from "../../../assets/images/general/good-pair-days-app-screen-wine-guides.png";
import homePreviewImg5Alt from "../../../assets/images/general/good-pair-days-app-screen-wine-guides.webp";
import homePreviewImg6 from "../../../assets/images/general/good-pair-days-app-screen-wine-discount-top-ups.png";
import homePreviewImg6Global from "../../../assets/images/general/good-pair-days-app-screen-wine-discount-top-ups-global.png";
import homePreviewImg6Alt from "../../../assets/images/general/good-pair-days-app-screen-wine-discount-top-ups.webp";
import homePreviewImg6AltGlobal from "../../../assets/images/general/good-pair-days-app-screen-wine-discount-top-ups-global.webp";
import homePreviewImg7 from "../../../assets/images/general/good-pair-days-app-screen-wine-grapes-to-avoid.png";
import homePreviewImg7Alt from "../../../assets/images/general/good-pair-days-app-screen-wine-grapes-to-avoid.webp";
import homePreviewImg8 from "../../../assets/images/general/good-pair-days-app-screen-box-and-spend.png";
import homePreviewImg8Global from "../../../assets/images/general/good-pair-days-app-screen-box-and-spend-global.png";
import homePreviewImg8Alt from "../../../assets/images/general/good-pair-days-app-screen-box-and-spend.webp";
import homePreviewImg8AltGlobal from "../../../assets/images/general/good-pair-days-app-screen-box-and-spend-global.webp";
import catPreviewImg from "../../../assets/images/temp/TasteChallengeImage3.svg";
import lemonPreviewImg from "../../../assets/images/temp/TasteChallengeImage1.svg";
import { ReactComponent as SliderActive } from "../../../assets/images/icons/slider-tab-active.svg";
import { ReactComponent as SliderInactive } from "../../../assets/images/icons/slider-tab-inactive.svg";
import { AU_COUNTRY_CODE, getCountry } from "../../../helpers/urls.helper";
import {
  SectionPreviewLeftBottom,
  PreviewList,
  PreviewListItem,
  PreviewSliderWrapper,
  PreviewSlideTabWrapper,
  CurrentPreviewTextWrapper,
  PreviewHolder,
  ToggleArrowWrapper,
  CatImageWrapper,
  LemonImageWrapper,
  TabPanel,
} from "./style";

const APP_TABS = {
  MY_CELLAR: "myCellar",
  RATE_AND_REVIEW: "rateAndReview",
  POINTS_AND_REWARDS: "pointsAndRewards",
  TASTE_TESTS: "tasteTests",
  WINE_GUIDES: "wineGuides",
  DISCOUNT: "discount",
  GRAPES_TO_AVOID: "grapesToAvoid",
  BOX_AND_SPEND: "boxAndSpend",
};
const APP_TABS_TEXT = {
  MY_CELLAR: "my cellar",
  RATE_AND_REVIEW: "rate and review",
  POINTS_AND_REWARDS: "points and rewards",
  TASTE_TESTS: "taste test quizzes",
  WINE_GUIDES: "wine guides",
  DISCOUNT: "discount top-ups",
  GRAPES_TO_AVOID: "grapes to avoid",
  BOX_AND_SPEND: "box and spend",
};

const ScrollingAppImages = () => {
  const country = getCountry();
  const [activeTab, setActiveTab] = useState(APP_TABS.MY_CELLAR);

  const scrollAppPreviewReverse = () => {
    const appTabKeys = Object.keys(APP_TABS);
    const currentActiveTabKey = appTabKeys.find((key) => APP_TABS[key] === activeTab);
    const currentActivePosition = appTabKeys.findIndex((el) => el === currentActiveTabKey);

    if (currentActivePosition - 1 < 0) {
      setActiveTab(APP_TABS[appTabKeys[appTabKeys.length - 1]]);
    } else {
      setActiveTab(APP_TABS[appTabKeys[currentActivePosition - 1]]);
    }
  };

  const scrollAppPreviewForward = () => {
    const appTabKeys = Object.keys(APP_TABS);
    const currentActiveTabKey = appTabKeys.find((key) => APP_TABS[key] === activeTab);
    const currentActivePosition = appTabKeys.findIndex((el) => el === currentActiveTabKey);

    if (currentActivePosition + 1 >= appTabKeys.length) {
      setActiveTab(APP_TABS[appTabKeys[0]]);
    } else {
      setActiveTab(APP_TABS[appTabKeys[currentActivePosition + 1]]);
    }
  };

  useEffect(() => {
    const displayPreviewTimer = setTimeout(() => scrollAppPreviewForward(), 4000);

    // Unmount/cleanup
    return () => {
      clearTimeout(displayPreviewTimer);
    };
  }, [activeTab]);

  return (
    <>
      <SectionPreviewLeftBottom>
        <PreviewList>
          <PreviewListItem
            isActive={activeTab === APP_TABS.MY_CELLAR}
            onClick={() => setActiveTab(APP_TABS.MY_CELLAR)}
          >
            <span>&#183;</span>
            {APP_TABS_TEXT.MY_CELLAR}
          </PreviewListItem>

          <PreviewListItem
            isActive={activeTab === APP_TABS.RATE_AND_REVIEW}
            onClick={() => setActiveTab(APP_TABS.RATE_AND_REVIEW)}
          >
            <span>&#183;</span>
            {APP_TABS_TEXT.RATE_AND_REVIEW}
          </PreviewListItem>

          <PreviewListItem
            isActive={activeTab === APP_TABS.POINTS_AND_REWARDS}
            onClick={() => setActiveTab(APP_TABS.POINTS_AND_REWARDS)}
          >
            <span>&#183;</span>
            {APP_TABS_TEXT.POINTS_AND_REWARDS}
          </PreviewListItem>

          <PreviewListItem
            isActive={activeTab === APP_TABS.TASTE_TESTS}
            onClick={() => setActiveTab(APP_TABS.TASTE_TESTS)}
          >
            <span>&#183;</span>
            {APP_TABS_TEXT.TASTE_TESTS}
          </PreviewListItem>

          <PreviewListItem
            isActive={activeTab === APP_TABS.WINE_GUIDES}
            onClick={() => setActiveTab(APP_TABS.WINE_GUIDES)}
          >
            <span>&#183;</span>
            {APP_TABS_TEXT.WINE_GUIDES}
          </PreviewListItem>

          <PreviewListItem
            isActive={activeTab === APP_TABS.DISCOUNT}
            onClick={() => setActiveTab(APP_TABS.DISCOUNT)}
          >
            <span>&#183;</span>
            {APP_TABS_TEXT.DISCOUNT}
          </PreviewListItem>

          <PreviewListItem
            isActive={activeTab === APP_TABS.GRAPES_TO_AVOID}
            onClick={() => setActiveTab(APP_TABS.GRAPES_TO_AVOID)}
          >
            <span>&#183;</span>
            {APP_TABS_TEXT.GRAPES_TO_AVOID}
          </PreviewListItem>

          <PreviewListItem
            isActive={activeTab === APP_TABS.BOX_AND_SPEND}
            onClick={() => setActiveTab(APP_TABS.BOX_AND_SPEND)}
          >
            <span>&#183;</span>
            {APP_TABS_TEXT.BOX_AND_SPEND}
          </PreviewListItem>
        </PreviewList>

        <PreviewHolder>
          <ToggleArrowWrapper direction="left" onClick={scrollAppPreviewReverse}>
            <ToggleArrow />
          </ToggleArrowWrapper>

          <CatImageWrapper>
            <LazyLoad once offset={500} height={93}>
              <img src={catPreviewImg} alt="Outline of cat" />
            </LazyLoad>
          </CatImageWrapper>

          <LazyLoad once offset={500} height={492}>
            <TabPanel isShown={activeTab === APP_TABS.MY_CELLAR}>
              <Image
                alt="Browse wines through our Good Pair Days app"
                src={country === AU_COUNTRY_CODE ? homePreviewImg1 : homePreviewImg1Global}
                full
                webpSrc={country === AU_COUNTRY_CODE ? homePreviewImg1Alt : homePreviewImg1AltGlobal}
              />
            </TabPanel>

            <TabPanel isShown={activeTab === APP_TABS.RATE_AND_REVIEW}>
              <Image
                alt="Rate drunk wines through our Good Pair Days app"
                src={homePreviewImg2}
                full
                webpSrc={homePreviewImg2Alt}
              />
            </TabPanel>

            <TabPanel isShown={activeTab === APP_TABS.POINTS_AND_REWARDS}>
              <Image
                alt="Pre-select wines through our Good Pair Days app"
                src={homePreviewImg3}
                full
                webpSrc={homePreviewImg3Alt}
              />
            </TabPanel>

            <TabPanel isShown={activeTab === APP_TABS.TASTE_TESTS}>
              <Image
                alt="Test your wine knowledge through our Good Pair Days app"
                src={homePreviewImg4}
                full
                webpSrc={homePreviewImg4Alt}
              />
            </TabPanel>

            <TabPanel isShown={activeTab === APP_TABS.WINE_GUIDES}>
              <Image
                alt="Test your wine knowledge through our Good Pair Days app"
                src={homePreviewImg5}
                full
                webpSrc={homePreviewImg5Alt}
              />
            </TabPanel>

            <TabPanel isShown={activeTab === APP_TABS.DISCOUNT}>
              <Image
                alt="Test your wine knowledge through our Good Pair Days app"
                src={country === AU_COUNTRY_CODE ? homePreviewImg6 : homePreviewImg6Global}
                full
                webpSrc={country === AU_COUNTRY_CODE ? homePreviewImg6Alt : homePreviewImg6AltGlobal}
              />
            </TabPanel>

            <TabPanel isShown={activeTab === APP_TABS.GRAPES_TO_AVOID}>
              <Image
                alt="Test your wine knowledge through our Good Pair Days app"
                src={homePreviewImg7}
                full
                webpSrc={homePreviewImg7Alt}
              />
            </TabPanel>

            <TabPanel isShown={activeTab === APP_TABS.BOX_AND_SPEND}>
              <Image
                alt="Test your wine knowledge through our Good Pair Days app"
                src={country === AU_COUNTRY_CODE ? homePreviewImg8 : homePreviewImg8Global}
                full
                webpSrc={country === AU_COUNTRY_CODE ? homePreviewImg8Alt : homePreviewImg8AltGlobal}
              />
            </TabPanel>
          </LazyLoad>
          <ToggleArrowWrapper direction="right" onClick={scrollAppPreviewForward}>
            <ToggleArrow />
          </ToggleArrowWrapper>

          <LemonImageWrapper>
            <LazyLoad once offset={500} height={40}>
              <img src={lemonPreviewImg} alt="Lemon cartoon" height={40} width={40} />
            </LazyLoad>
          </LemonImageWrapper>
        </PreviewHolder>
      </SectionPreviewLeftBottom>

      <CurrentPreviewTextWrapper>
        {APP_TABS_TEXT[Object.keys(APP_TABS).find((key) => APP_TABS[key] === activeTab)]}
      </CurrentPreviewTextWrapper>

      <PreviewSliderWrapper>
        {Object.keys(APP_TABS).map((el) => (
          <PreviewSlideTabWrapper
            key={`preview-slider-${el}`}
            onClick={() => {
              setActiveTab(APP_TABS[el]);
            }}
          >
            {activeTab === APP_TABS[el] ? (
              <SliderActive key={`preview-slider-tab-${el}`} />
            ) : (
              <SliderInactive key={`preview-slider-tab-${el}`} />
            )}
          </PreviewSlideTabWrapper>
        ))}
      </PreviewSliderWrapper>
    </>
  );
};

export default ScrollingAppImages;
