import { gql } from "@apollo/client";

/**
 * QUERIES
 * */

export const GET_ALL_SPECIAL_PACK_EDITIONS = gql`
  query AllSpecialPackEditions($limit: Int, $offset: Int, $availableOnly: Boolean) {
    allSpecialPackEditions(limit: $limit, offset: $offset, availableOnly: $availableOnly) {
      id
      name
      listingDescription
      listingImageUrl
      slug
      specialpackoptionSet {
        id
        displayName
        product {
          id
          name
          isEnabled
          sellingPrice
          coverPhotoSmall
          slug
          productType {
            id
          }
        }
      }
    }
  }
`;

export const SPECIAL_PACK_EDITION_DETAILS = gql`
  fragment SpecialPackEditionDetails on SpecialPackEditionObject {
    id
    name
    slug
    endDate
    heroTopImageLargeUrl
    listingImageUrl
    buyButtonText
    heroTopTitle
    heroTopSubTitle
    heroTopTheme
    heroTopTextPosition
    heroDisabledText
    section1Title
    section1Text
    section2Title
    section2Text
    section3Title
    section3Text
    heroBottomTitle
    heroBottomSubTitle
    heroBottomTheme
    seoTitle
    seoDescription
    heroBottomImageLargeUrl
    section1ImageLargeUrl
    section2ImageLargeUrl
    section3ImageLargeUrl
    isAvailable
    specialPackType {
      id
    }
    specialpackoptionSet {
      id
      displayName
      sortOrder
      product {
        id
        name
        isEnabled
        productType {
          id
          isEnabled
          name
        }
        coverPhotoLarge
        slug
        skuCode
        sellingPrice
        productPhotos {
          id
          photoLarge
          photoSmall
        }
        wine {
          id
          wineMaker {
            name
            id
          }
          product {
            name
            id
            productType {
              id
              name
            }
            productPhotos {
              id
              photoLarge
            }
            sellingPrice
          }
          wineType {
            name
            id
          }
          wineRegion {
            name
            id
          }
          country {
            name
            id
            code
          }
          wineBody {
            name
            id
          }
          wineTannin {
            name
            id
          }
          wineAcidity {
            name
            id
          }
          wineSweetness {
            name
            id
          }
          wineBarrelType {
            name
            id
          }
          wineCellarPeriod {
            name
            id
          }
          wineProductionMethods {
            name
            id
          }
          fullDescription
          country {
            id
            name
            code
          }
          food {
            name
            id
          }
          moods {
            name
            id
          }
          tastes {
            name
            id
          }
          year
          alcoholPercentage
          sommelierNotes
          pairingsDescription
          oakAged
          memberLikelihood
        }
      }
    }
  }
`;

export const GET_SPECIAL_PACK_EDITION_DETAILS = gql`
  ${SPECIAL_PACK_EDITION_DETAILS}
  query SpecialPackEdition($slug: String!) {
    specialPackEdition(slug: $slug) {
      ...SpecialPackEditionDetails
    }
  }
`;

export const GET_ACTIVE_ADVENT_CALENDAR = gql`
  query ActiveAdventCalendar {
    getActiveAdventCalendar {
      id
    }
  }
`;

/**
 * MUTATIONS
 * */
export const ADD_SPECIAL_PACK_INTEREST = gql`
  mutation RegisterSpecialPackInterest($input: RegisterSpecialPackInterestInput!) {
    registerSpecialPackInterest(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;
