import { gql } from "@apollo/client";
import { WINE_LIST_TYPE_IDS } from "../../helpers/constants";

export const GET_WINE_FILTERS = gql`
  query WineFilters($memberId: Int) {
    allWineLists(memberId: $memberId, excludeEmpty: true) {
      id
      slug
      name
    }
    allWineCountries {
      id
      name
    }
    allWineRegions {
      id
      name
    }
    allWinePriceRanges {
      id
    }
    allWineClasses {
      id
      name
    }
    allWineProductionMethods {
      id
      name
    }
    allWineTypes {
      id
      name
    }
    allWineSweetnesses {
      id
      name
    }
    allWineTannins {
      id
      name
    }
    allWineBodies {
      id
      name
      shortName
    }
    allWineAcidities {
      id
      name
    }
    allWineOakies {
      id
      name
    }
    allWineFruitDrivens {
      id
      name
    }
    allCuisines {
      id
      name
    }
  }
`;

export const GET_WINE_PRICE_RANGES_FRAGMENT = gql`
  fragment WinePriceRangesFragment on WinePriceRangeObject {
    id
    isEnabled
    isEnabledForWineQuantity
    winePriceRangeValue {
      name
      fromPrice
      toPrice
    }
  }
`;

export const GET_WINE_PRICE_RANGES = gql`
  ${GET_WINE_PRICE_RANGES_FRAGMENT}
  query WinePriceRanges {
    allWinePriceRanges {
      ...WinePriceRangesFragment
    }
  }
`;

export const GET_WINE_BODIES = gql`
  query WineBodies {
    allWineBodies {
      id
      name
      level
      shortName
    }
  }
`;

export const GET_WINE_TANNINS = gql`
  query WineTannins {
    allWineTannins {
      id
      name
      level
      shortName
    }
  }
`;

export const GET_WINE_SWEETNESSES = gql`
  query WineSweetnesses {
    allWineSweetnesses {
      id
      name
      level
      shortName
    }
  }
`;

export const GET_WINE_ACIDITIES = gql`
  query WineAcidities {
    allWineAcidities {
      id
      name
      level
      shortName
    }
  }
`;

export const GET_WINE_FRUIT_DRIVENS = gql`
  query WineFruitDrivens {
    allWineFruitDrivens {
      id
      name
      level
      shortName
    }
  }
`;

export const GET_WINE_OAKIES = gql`
  query WineOakies {
    allWineOakies {
      id
      name
      level
      shortName
    }
  }
`;

export const GET_WINE_TASTING_RANGES = gql`
  query WineTastingRanges {
    allWineBodies {
      id
      name
      level
      shortName
    }
    allWineTannins {
      id
      name
      level
      shortName
    }
    allWineSweetnesses {
      id
      name
      level
      shortName
    }
    allWineAcidities {
      id
      name
      level
      shortName
    }
    allWineFruitDrivens {
      id
      name
      level
      shortName
    }
    allWineOakies {
      id
      name
      level
      shortName
    }
  }
`;

export const GET_WINE_SLUG = gql`
  query GetWineById($id: Int!) {
    wine(id: $id) {
      product {
        id
        slug
      }
    }
  }
`;

export const GET_WINE = gql`
  query GetWine($slug: String!, $memberId: Int) {
    wine(slug: $slug, memberId: $memberId) {
      id
      hasStock
      hasStock6
      hasStock12
      hasOtherVintageStock
      stockNotificationRequested
      memberWineRatingScore
      memberLikelihood
      shortDescription
      fullDescription
      sommelierNotes
      pairingsDescription
      alcoholPercentage
      videoAspectRatio
      recipes {
        id
        url
      }
      year
      oakAged
      isBookmarked
      product {
        id
        name
        slug
        productType {
          id
        }
        sellingPrice
        skuCode
        coverPhotoLarge
        coverPhotoSmall
        productPhotos {
          id
          photoLarge
        }
      }
      country {
        id
        name
        code
      }
      wineSeries {
        id
        name
      }
      wineRegion {
        id
        name
        state
        fullDescription
        mapUrl
      }
      wineType {
        id
        name
        pronunciation
        wineClass {
          id
          name
        }
      }
      wineMaker {
        id
        name
      }
      wineSweetness {
        id
        level
        name
      }
      wineBody {
        id
        level
        name
      }
      wineTannin {
        id
        level
        name
      }
      wineAcidity {
        id
        level
        name
      }
      wineFruitDriven {
        id
        level
        name
      }
      wineOaky {
        id
        level
        name
      }
      wineCellarPeriod {
        id
        name
        shortDescription
      }
      wineProductionMethods {
        id
        name
      }
      wineBarrelType {
        name
        id
      }
      fruits {
        id
        name
        imageSmallUrl
      }
      flavours {
        id
        name
        imageSmallUrl
      }
      tastes {
        id
        name
        imageSmallUrl
      }
      food {
        id
        name
        imageSmallUrl
      }
      seasons {
        id
        name
      }
      moods {
        id
        name
        imageSmallUrl
      }
      bottleClosure {
        id
        name
      }
      servingTemperature
      wineServingTemperature {
        name
        minTemperature
        maxTemperature
      }
      wineLists {
        id
        name
        slug
      }
    }
  }
`;

export const GET_WINE_NAME_AND_LARGE_PHOTO = gql`
  query GetWinePhotos($slug: String!, $memberId: Int) {
    wine(slug: $slug, memberId: $memberId) {
      id
      hasStock
      hasStock6
      hasStock12
      hasOtherVintageStock
      stockNotificationRequested
      isBookmarked
      memberWineRatingScore
      wineType {
        id
        name
        wineClass {
          id
          name
        }
      }
      product {
        id
        name
        slug
        productType {
          id
        }
        productPhotos {
          id
          photoLarge
        }
      }
      country {
        id
        name
      }
      wineRegion {
        id
        name
        state
      }
      wineMaker {
        id
        name
      }
      wineSeries {
        id
        name
      }
    }
  }
`;

export const GET_WINES = gql`
  query GetWines(
    $year: String
    $wineClassId: Int
    $winePriceRangeId: Int
    $wineTypeId: Int
    $wineBodyId: Int
    $wineSweetnessId: Int
    $wineTanninId: Int
    $wineStyleId: Int
    $wineProductionMethodId: Int
    $seasonId: Int
    $tasteId: Int
    $foodId: Int
    $countryId: Int
    $order: AllWinesOrderField
    $memberId: Int
  ) {
    allWines(
      year: $year
      winePriceRangeId: $winePriceRangeId
      wineClassId: $wineClassId
      wineTypeId: $wineTypeId
      wineBodyId: $wineBodyId
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineStyleId: $wineStyleId
      wineProductionMethodId: $wineProductionMethodId
      seasonId: $seasonId
      tasteId: $tasteId
      foodId: $foodId
      countryId: $countryId
      order: $order
      memberId: $memberId
    ) {
      id
      memberLikelihood
      year
      country {
        id
        name
      }
      product {
        id
        name
        slug
        sellingPrice
        productType {
          id
        }
        coverPhotoLarge
        coverPhotoSmall
        productPhotos {
          id
          photoSmall
          photoLarge
        }
      }
      wineType {
        id
        name
        wineClass {
          id
          name
        }
      }
      wineRegion {
        id
        name
      }
    }
  }
`;

export const GET_WINE_LIST = gql`
  query WineList($id: Int, $slug: String, $memberId: Int) {
    wineList(id: $id, slug: $slug, memberId: $memberId) {
      id
      name
      description
    }
  }
`;

export const GET_ALL_WINE_LISTS_CONTROLLED_BY_MEMBER = gql`
  query AllWineLists (
    $memberId: Int
  ) {
    allWineLists (
      memberId: $memberId
      excludeEmpty: false
      wineListTypeIds: [
        ${WINE_LIST_TYPE_IDS.DB_ID_WINE_LIST_TYPE_MEMBER_FAVOURITES},
        ${WINE_LIST_TYPE_IDS.DB_ID_WINE_LIST_TYPE_MEMBER_WISHLIST},
        ${WINE_LIST_TYPE_IDS.DB_ID_WINE_LIST_TYPE_MEMBER_CUSTOM}
      ]
    ) {
      id
      name
      sortOrder
      isPublic
      wineListType {
        id
      }
      wines {
        id
        memberLikelihood
        wineType {
          id
          name
          wineClass {
            id
          }
        }
        product {
          id
          slug
          sellingPrice
          name
          coverPhotoLarge
          coverPhotoSmall
          productPhotos {
            id
            photoSmall
            photoLarge
          }
        }
      }
    }
  }
`;

export const GET_ALL_WINE_LISTS_WINES_PAGE = gql`
  query AllWineListsWinesPage($memberId: Int) {
    allWineLists(memberId: $memberId, excludeEmpty: true) {
      id
      slug
      name
      description
    }
  }
`;

export const GET_ALL_WINES_BY_WINE_LIST = gql`
  query AllWinesByWineList(
    $memberId: Int
    $wineListId: Int
    $wineListSlug: String
    $orderBy: AllWinesByWineListOrderField
    $name: String
    $limit: Int
    $offset: Int
    $wineBodyId: Int
    $wineAcidityId: Int
    $wineFruitDrivenId: Int
    $winePriceRangeId: Int
    $winePriceRangeIds: [Int]
    $wineProductionMethodId: Int
    $wineProductionMethodIds: [Int]
    $wineClassId: Int
    $wineClassIds: [Int]
    $cuisineIds: [Int]
    $countryId: Int
    $countryIds: [Int]
    $wineRegionId: Int
    $wineRegionIds: [Int]
    $wineTypeId: Int
    $wineTypeIds: [Int]
    $wineAlcoholRange: [Int]
    $wineSweetnessId: Int
    $wineTanninId: Int
    $availableOnly: Boolean
  ) {
    allWinesByWineList(
      memberId: $memberId
      wineListSlug: $wineListSlug
      orderBy: $orderBy
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      cuisineIds: $cuisineIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineAlcoholRange: $wineAlcoholRange
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      limit: $limit
      offset: $offset
      availableOnly: $availableOnly
    ) @connection(key: "allWinesByWineList", filter: ["memberId", "wineListId"]) {
      id
      shortDescription
      fullDescription
      memberLikelihood
      memberWineRatingScore
      isBookmarked
      isLowStock
      hasStock
      memberTasteChallenge {
        score
        numberOfAnswers
      }
      wineType {
        id
        name
        wineClass {
          id
          name
        }
      }
      wineMaker {
        id
        name
      }
      wineSweetness {
        id
        level
      }
      wineBody {
        id
        level
      }
      wineAcidity {
        id
        level
      }
      wineTannin {
        id
        level
      }
      country {
        id
        name
      }
      wineRegion {
        id
        name
        state
      }
      winePriceRange {
        id
        isEnabled
      }
      product {
        id
        slug
        name
        sellingPrice
        coverPhotoSmall
        coverPhotoMedium
        lastOrderByMember {
          createdDate
        }
        productType {
          id
        }
        productPhotos {
          id
          photoLarge
        }
      }
    }
  }
`;

export const GET_ALL_WINES_BY_WINE_LIST_WITH_COUNT = gql`
  query AllWinesByWineListWithCount(
    $wineListId: Int
    $wineListSlug: String
    $name: String
    $orderBy: AllWinesByWineListOrderField
    $wineBodyId: Int
    $wineAcidityId: Int
    $wineOakyId: Int
    $wineFruitDrivenId: Int
    $winePriceRangeId: Int
    $winePriceRangeIds: [Int]
    $wineProductionMethodId: Int
    $wineProductionMethodIds: [Int]
    $wineClassId: Int
    $wineClassIds: [Int]
    $cuisineIds: [Int]
    $countryId: Int
    $countryIds: [Int]
    $wineRegionId: Int
    $wineRegionIds: [Int]
    $wineTypeId: Int
    $wineTypeIds: [Int]
    $wineAlcoholRange: [Int]
    $wineSweetnessId: Int
    $wineTanninId: Int
    $limit: Int
    $offset: Int
    $availableOnly: Boolean
  ) {
    allWinesByWineListCount(
      wineListSlug: $wineListSlug
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineOakyId: $wineOakyId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      cuisineIds: $cuisineIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineAlcoholRange: $wineAlcoholRange
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      availableOnly: $availableOnly
    )
    allWinesByWineList(
      wineListSlug: $wineListSlug
      name: $name
      orderBy: $orderBy
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineOakyId: $wineOakyId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      cuisineIds: $cuisineIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineAlcoholRange: $wineAlcoholRange
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      limit: $limit
      offset: $offset
      availableOnly: $availableOnly
    ) {
      id
      memberLikelihood
      memberWineRatingScore
      isBookmarked
      isLowStock
      wineType {
        id
        name
        wineClass {
          id
          name
        }
      }
      wineMaker {
        id
        name
      }
      country {
        name
      }
      wineRegion {
        name
        state
      }
      product {
        id
        slug
        name
        sellingPrice
        coverPhotoSmall
        coverPhotoSmallPng
        productType {
          id
        }
        productPhotos {
          id
          photoLarge
        }
      }
    }
  }
`;

export const FETCH_MORE_ALL_WINES_BY_WINE_LIST = gql`
  query FetchMoreAllWinesByWineList(
    $wineListId: Int
    $wineListSlug: String
    $name: String
    $orderBy: AllWinesByWineListOrderField
    $wineBodyId: Int
    $wineAcidityId: Int
    $wineOakyId: Int
    $wineFruitDrivenId: Int
    $winePriceRangeId: Int
    $winePriceRangeIds: [Int]
    $wineProductionMethodId: Int
    $wineProductionMethodIds: [Int]
    $wineClassId: Int
    $wineClassIds: [Int]
    $cuisineIds: [Int]
    $countryId: Int
    $countryIds: [Int]
    $wineRegionId: Int
    $wineRegionIds: [Int]
    $wineTypeId: Int
    $wineTypeIds: [Int]
    $wineAlcoholRange: [Int]
    $wineSweetnessId: Int
    $wineTanninId: Int
    $limit: Int
    $offset: Int
    $availableOnly: Boolean
  ) {
    allWinesByWineList(
      wineListSlug: $wineListSlug
      name: $name
      orderBy: $orderBy
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineOakyId: $wineOakyId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineAlcoholRange: $wineAlcoholRange
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      cuisineIds: $cuisineIds
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      limit: $limit
      offset: $offset
      availableOnly: $availableOnly
    ) {
      id
      memberLikelihood
      memberWineRatingScore
      isBookmarked
      isLowStock
      wineType {
        id
        name
        wineClass {
          id
          name
        }
      }
      wineMaker {
        id
        name
      }
      country {
        name
      }
      wineRegion {
        name
        state
      }
      product {
        id
        slug
        name
        sellingPrice
        coverPhotoSmall
        coverPhotoSmallPng
        productType {
          id
        }
        productPhotos {
          id
          photoLarge
        }
      }
    }
  }
`;

export const GET_WINE_RATING = gql`
  query GetWineRating($wineId: Int!, $memberId: Int) {
    wineRating(wineId: $wineId, memberId: $memberId) {
      id
      score
      liked
      note
    }
    me {
      id
      level {
        id
        pointsPerBottleRated
      }
    }
  }
`;

export const REQUEST_WINE_STOCK = gql`
  mutation RequestWineStock($input: WineRequestInput!) {
    requestWineStock(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const GET_WINE_CHARACTERISTICS_INFO = gql`
  query WineCharacteristicsInfo {
    allWineCharacteristicsInfo {
      id
      name
      fullDescriptionHtml
    }
  }
`;

export const GET_ALL_WINES_BY_WINE_LIST_MATCH_COUNT = gql`
  query AllWinesByWineListMatchCount(
    $wineListId: Int
    $wineListSlug: String
    $name: String
    $wineBodyId: Int
    $wineAcidityId: Int
    $wineFruitDrivenId: Int
    $winePriceRangeId: Int
    $winePriceRangeIds: [Int]
    $wineProductionMethodId: Int
    $wineProductionMethodIds: [Int]
    $wineClassId: Int
    $wineClassIds: [Int]
    $countryId: Int
    $countryIds: [Int]
    $wineRegionId: Int
    $wineRegionIds: [Int]
    $wineTypeId: Int
    $wineTypeIds: [Int]
    $wineSweetnessId: Int
    $wineTanninId: Int
    $wineAlcoholRange: [Int]
    $wineOakyId: Int
    $cuisineIds: [Int]
    $availableOnly: Boolean
  ) {
    allWinesByWineListRegionCount(
      wineListSlug: $wineListSlug
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineAlcoholRange: $wineAlcoholRange
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      availableOnly: $availableOnly
    ) {
      id
      matchCount
    }
    allWinesByWineListTypeCount(
      wineListSlug: $wineListSlug
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineAlcoholRange: $wineAlcoholRange
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      availableOnly: $availableOnly
    ) {
      id
      matchCount
    }
    allWinesByWineListClassCount(
      wineListSlug: $wineListSlug
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineAlcoholRange: $wineAlcoholRange
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      availableOnly: $availableOnly
    ) {
      id
      matchCount
    }
    allWinesByWineListCountryCount(
      wineListSlug: $wineListSlug
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineAlcoholRange: $wineAlcoholRange
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      availableOnly: $availableOnly
    ) {
      id
      matchCount
    }
    allWinesByWineListSweetnessCount(
      wineListSlug: $wineListSlug
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineAlcoholRange: $wineAlcoholRange
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      availableOnly: $availableOnly
    ) {
      id
      matchCount
    }
    allWinesByWineListTanninCount(
      wineListSlug: $wineListSlug
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineAlcoholRange: $wineAlcoholRange
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      availableOnly: $availableOnly
    ) {
      id
      matchCount
    }
    allWinesByWineListBodyCount(
      wineListSlug: $wineListSlug
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineAlcoholRange: $wineAlcoholRange
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      availableOnly: $availableOnly
    ) {
      id
      matchCount
    }
    allWinesByWineListFruitDrivenCount(
      wineListSlug: $wineListSlug
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineAlcoholRange: $wineAlcoholRange
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      availableOnly: $availableOnly
    ) {
      id
      matchCount
    }
    allWinesByWineListAcidityCount(
      wineListSlug: $wineListSlug
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineAlcoholRange: $wineAlcoholRange
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      availableOnly: $availableOnly
    ) {
      id
      matchCount
    }
    allWinesByWineListWineListCount(
      wineListSlug: $wineListSlug
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineAlcoholRange: $wineAlcoholRange
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      availableOnly: $availableOnly
    ) {
      id
      matchCount
    }
    allWinesByWineListProductionMethodCount(
      wineListSlug: $wineListSlug
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineAlcoholRange: $wineAlcoholRange
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      availableOnly: $availableOnly
    ) {
      id
      matchCount
    }
    allWinesByWineListCuisineCount(
      wineListSlug: $wineListSlug
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineAlcoholRange: $wineAlcoholRange
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      availableOnly: $availableOnly
    ) {
      id
      matchCount
    }
    allWinesByWineListOakyCount(
      wineListSlug: $wineListSlug
      name: $name
      wineListId: $wineListId
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineFruitDrivenId: $wineFruitDrivenId
      winePriceRangeId: $winePriceRangeId
      winePriceRangeIds: $winePriceRangeIds
      wineProductionMethodId: $wineProductionMethodId
      wineProductionMethodIds: $wineProductionMethodIds
      wineClassId: $wineClassId
      wineClassIds: $wineClassIds
      wineTypeId: $wineTypeId
      wineTypeIds: $wineTypeIds
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineAlcoholRange: $wineAlcoholRange
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      countryId: $countryId
      countryIds: $countryIds
      wineRegionId: $wineRegionId
      wineRegionIds: $wineRegionIds
      availableOnly: $availableOnly
    ) {
      id
      matchCount
    }
  }
`;

export const GET_WINE_SUMMARY = gql`
  query GetWineSummary($slug: String!, $memberId: Int) {
    wine(slug: $slug, memberId: $memberId) {
      wineSummary {
        tastingNote {
          wineBody
          wineSweetness
          wineAcidity
          wineTannin
          wineOaky
          wineAlcoholPercentage
          wineFruitDriven
        }
        grapeType {
          name
          triedWinesCount
          triedWinesAvgRating
          topRatedWine
          ratingPosition
        }
        region {
          name
          triedWinesCount
          triedWinesAvgRating
          topRatedWine
          ratingPosition
        }
      }
    }
  }
`;

export const WINE_RELATED_BADGES = gql`
  query GetWineRelatedBadges($slug: String!, $earnedBadgeFilter: Boolean) {
    wine(slug: $slug) {
      id
      relatedBadges(earnedBadgeFilter: $earnedBadgeFilter) {
        id
        slug
        name
        level
        isEarned
        dateTimeEarned
        points
        imageSmallUrl
        shortDescription
        externalActionQuestion
        hasWineCharacteristicBadgeRule
        shouldHideWineRecommendation
        challengeKeyBenefit
        challengeGameplayTitle1
        challengeGameplayDescription1
        challengeGameplayTitle2
        challengeGameplayDescription2
        challengeGameplayTitle3
        challengeGameplayDescription3
        badgeNameWhenEarned
        badgePointsWhenEarned
        relatedWineHasStock
        badgerulesSet {
          id
          quantity
          contentType {
            id
            appLabel
            model
          }
          objectId
        }
        badgeCategory {
          id
          name
        }
        challengeProgress {
          id
          challengeStatus {
            name
            id
          }
          numberOfTotalBadges
          numberOfEarnedBadges
          dateTimeStarted
        }
      }
    }
  }
`;

export const WINE_RELATED_WINES = gql`
  query GetWineRelatedWines($slug: String!, $limit: Int) {
    wine(slug: $slug) {
      similarWines(limit: $limit) {
        id
        shortDescription
        fullDescription
        memberLikelihood
        memberWineRatingScore
        isBookmarked
        isLowStock
        hasStock
        wineType {
          id
          name
          wineClass {
            id
            name
          }
        }
        wineMaker {
          id
          name
        }
        wineSweetness {
          id
          level
        }
        wineBody {
          id
          level
        }
        wineAcidity {
          id
          level
        }
        wineTannin {
          id
          level
        }
        country {
          id
          name
        }
        wineRegion {
          id
          name
          state
        }
        wineSeries {
          id
          name
        }
        product {
          id
          slug
          name
          sellingPrice
          coverPhotoMedium
          lastOrderByMember {
            createdDate
          }
          productType {
            id
          }
          productPhotos {
            id
            photoLarge
          }
        }
      }
    }
  }
`;

export const GET_LATEST_VINTAGE = gql`
  query GetLatestVintage($wineSeriesId: Int!) {
    latestVintage(wineSeriesId: $wineSeriesId) {
      id
      shortDescription
      fullDescription
      memberLikelihood
      memberWineRatingScore
      isBookmarked
      isLowStock
      hasStock
      hasStock6
      hasStock12
      wineType {
        id
        name
        wineClass {
          id
          name
        }
      }
      wineMaker {
        id
        name
      }
      country {
        id
        name
        code
      }
      wineRegion {
        id
        name
        state
      }
      product {
        id
        name
        slug
        productPhotos {
          id
          photoSmall
          photoLarge
        }
      }
    }
  }
`;
