import styled from "styled-components";

import breakpoints from "../../../styles/breakpoints";

const AlignLeft = styled.div`
  text-align: left !important;
`;
const AlignCenter = styled.div`
  text-align: center !important;
`;
const AlignRight = styled.div`
  text-align: right !important;
`;

const AlignMdLeft = styled.div`
  ${breakpoints.mdDown} {
    text-align: left !important;
  }
`;
const AlignMdCenter = styled.div`
  ${breakpoints.mdDown} {
    text-align: center !important;
  }
`;
const AlignMdRight = styled.div`
  ${breakpoints.mdDown} {
    text-align: right !important;
  }
`;

const AlignMdUpLeft = styled.div`
  ${breakpoints.mdUp} {
    text-align: left !important;
  }
`;
const AlignMdUpCenter = styled.div`
  ${breakpoints.mdUp} {
    text-align: center !important;
  }
`;
const AlignMdUpRight = styled.div`
  ${breakpoints.mdUp} {
    text-align: right !important;
  }
`;

const AlignSmLeft = styled.div`
  ${breakpoints.smDown} {
    text-align: left !important;
  }
`;
const AlignSmCenter = styled.div`
  ${breakpoints.smDown} {
    text-align: center !important;
  }
`;
const AlignSmRight = styled.div`
  ${breakpoints.smDown} {
    text-align: right !important;
  }
`;

const AlignSmUpLeft = styled.div`
  ${breakpoints.smUp} {
    text-align: left !important;
  }
`;
const AlignSmUpCenter = styled.div`
  ${breakpoints.smUp} {
    text-align: center !important;
  }
`;
const AlignSmUpRight = styled.div`
  ${breakpoints.smUp} {
    text-align: right !important;
  }
`;

export {
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignMdLeft,
  AlignMdCenter,
  AlignMdRight,
  AlignMdUpLeft,
  AlignMdUpCenter,
  AlignMdUpRight,
  AlignSmLeft,
  AlignSmCenter,
  AlignSmRight,
  AlignSmUpLeft,
  AlignSmUpCenter,
  AlignSmUpRight,
};
