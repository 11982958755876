import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACES, MOBILE_NAVBAR_ACCORDIONS } from "../../../helpers/constants";
import { NAVBAR_GIFT_ITEMS } from "../../../helpers/navbarProcessor";
import { NAVBAR_GIFTS_HEIGHTS } from "../../../styles/variables";
import AccordionItem from "../AccordionItem";
import {
  AccordionTopPart,
  StyledChevron,
  AccordionWrapper,
  AccordionContentWrapper,
} from "../MobileHeaderNavbar/style";

interface MobileGiftsAccordionProps {
  openAccordion: string | undefined;
  setOpenAccordion: Function;
}

const MobileGiftsAccordion = ({
  openAccordion,
  setOpenAccordion,
}: MobileGiftsAccordionProps): ReactElement => {
  const isThisAccordionOpen = !!(openAccordion === MOBILE_NAVBAR_ACCORDIONS.GIFTS);
  const { t } = useTranslation([I18N_NAMESPACES.COMMON]);
  return (
    <AccordionWrapper
      height={NAVBAR_GIFTS_HEIGHTS.full}
      open={isThisAccordionOpen}
      onClick={() => setOpenAccordion(isThisAccordionOpen ? "" : MOBILE_NAVBAR_ACCORDIONS.GIFTS)}
    >
      <AccordionTopPart isThisAccordionOpen={isThisAccordionOpen}>
        {t("common:gifts")}
        <StyledChevron open={isThisAccordionOpen} />
      </AccordionTopPart>
      <AccordionContentWrapper open={isThisAccordionOpen} height={NAVBAR_GIFTS_HEIGHTS.accordion}>
        {NAVBAR_GIFT_ITEMS.map((item) => (
          <AccordionItem i18nKey={item.i18nKey} url={item.url} key={item.i18nKey} icon={item.icon} />
        ))}
      </AccordionContentWrapper>
    </AccordionWrapper>
  );
};

export default MobileGiftsAccordion;
