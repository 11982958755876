import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import { colors, fonts, transition } from "../../../styles/variables";
import { ReactComponent as Chevron } from "../../../assets/images/icons/chevron-transparent.svg";
import Button from "../Button";
import ButtonLink from "../ButtonLink";
import breakpoints from "../../../styles/breakpoints";

export const MobileHeaderNavbarWrapper = styled.div`
  border-top: 1px solid ${colors.black};
  display: flex;
  flex-direction: column;
`;

export const AccordionWrapper = styled.div<{ open?: boolean; height?: number }>`
  max-height: ${(props) => (props.open ? `${props.height}px` : "72px")};
  overflow: hidden;
  transition: ${transition.headerMenuBarTransition};
  font-family: ${fonts.fontBauRegular};
  font-size: 18px;
  line-height: 26px;
  color: ${(props) => (props.open ? colors.white : colors.black)};
`;

export const UserAccordionWrapper = styled.div<{
  open: boolean;
  isMobileMenuOpen?: boolean;
}>`
  max-height: ${(props) => (props.open ? "464px" : "104px")};
  min-height: ${(props) => (props.open ? "464px" : "104px")};
  overflow: hidden;
  transition: ${transition.headerMenuBarTransition};
  text-transform: uppercase;
  border-bottom: 1px solid ${colors.black};
  margin-bottom: 12px;
`;

export const AccordionTopPart = styled.div<{ isThisAccordionOpen?: boolean }>`
  padding: ${(props) => (props.isThisAccordionOpen ? "32px 30px" : "23px 30px")};
  transition: ${transition.headerMenuPaddingTransition};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  ${breakpoints.smDown} {
    padding: ${(props) => (props.isThisAccordionOpen ? "32px 15px" : "23px 15px")};
  }
`;

export const UserName = styled.div`
  font-family: ${fonts.fontBauMedium};
  font-size: 14px;
  line-height: 20px;
  margin-left: 12px;
`;

export const StyledChevron = styled(Chevron)<{ open: boolean }>`
  margin-left: auto;
  margin-right: 5px;
  transition: ${transition.headerMenuBarTransition};
  transform: rotate(${(props) => (props.open ? 0 : 180)}deg);
  stroke: ${colors.black};
`;

export const AccordionContentWrapper = styled.div<{
  open: boolean;
  isFlexDirectionRow?: boolean;
  shouldHideTopBorder?: boolean;
  isMemberAccordion?: boolean;
  height: number;
}>`
  display: flex;
  max-height: ${(props) => (props.open ? `${props.height}px` : "0px")};
  transition: ${transition.headerMenuBarTransition};
  flex-flow: ${(props) => (props.isFlexDirectionRow ? "row wrap" : "column")};
  border-top: ${(props) => (props.isMemberAccordion ? "" : `1px solid ${colors.black}`)};
  border-bottom: ${(props) => (props.isMemberAccordion ? "" : `1px solid ${colors.black}`)};
  padding: 16px 20px;
  ${breakpoints.smUp} {
    padding: 16px 30px;
  }
`;

export const AccordionItemWrapper = styled(Link)<{
  shouldShowItemAsWhite: boolean;
  isFlexDirectionRow?: boolean;
  isUserAccordion?: boolean;
}>`
  color: ${(props) => (props.shouldShowItemAsWhite ? colors.white : colors.black)};
  width: ${(props) => (props.isFlexDirectionRow ? "50%" : "")};
  font-size: 16px;
  line-height: 23px;
  display: flex;
  flex-direction: row;
  padding: ${(props) => (props.isUserAccordion ? "0 0 40px 0" : "16px 0")};
  align-items: center;
  text-transform: capitalize;
`;

export const AccordionItemIconWrapper = styled.div`
  width: 32px;
  margin-right: 20px;
  display: flex;
  justify-content: center;

  ${breakpoints.xxsDown} {
    margin-right: 11px;
  }
`;

export const AccordionItemIcon = styled.img<{ src: string | undefined }>``;

export const LogOutButton = styled.button<{
  shouldIncreaseTopPadding: boolean;
}>`
  text-transform: capitalize;
  text-align: left;
  color: ${colors.black};
  font-size: 18px;
  line-height: 26px;
  padding: ${(props) => (props.shouldIncreaseTopPadding ? "40px 15px 32px 15px" : "20px 15px 32px 15px")};
  ${breakpoints.smUp} {
    padding: ${(props) => (props.shouldIncreaseTopPadding ? "40px 30px 32px 30px" : "20px 30px 32px 30px")};
  }
`;

export const StyledLoginButton = styled(Button)`
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 500;
`;

export const GetStartedButton = styled(ButtonLink).attrs({
  full: true,
})`
  font-size: 12px;
  margin-bottom: 18px;
  font-weight: 500;
`;

export const StyledHeaderSignInBox = styled.div<{
  shouldIncreaseTopPadding: boolean;
}>`
  display: flex;
  flex-direction: column
  padding: 20px 30px;
  ${breakpoints.smDown} {
    padding: 20px 15px;
  }
  padding-top: ${(props) => (props.shouldIncreaseTopPadding ? "40px" : "20px")};
`;

export const StyledHeaderUserAvatar = styled.button`
  color: ${colors.black};
  ${breakpoints.mdUp} {
    margin-right: 10px;
  }
  display: inline;
  text-align: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${colors.blue};
  font-family: ${fonts.fontInterstateUltraBlack};
  border: none;
  text-transform: uppercase;
  padding: 0;
  font-size: 16px;
`;
