import { gql } from "@apollo/client";

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on ProductObject {
    id
    name
    skuCode
    hasStock
    sellingPrice
    slug
    coverPhotoSmallPng
    coverPhotoLargePng
    backgroundColor
    descriptionMarkdown
    productionCountry {
      id
      name
    }
    productType {
      id
      name
      isEnabled
    }
    wine {
      id
      product {
        id
        name
        productType {
          id
        }
      }
      country {
        id
        name
      }
      wineRegion {
        id
        name
        state
      }
      wineType {
        id
        name
        wineClass {
          id
          name
        }
      }
      wineMaker {
        id
        name
      }
    }
  }
`;

export const CELLAR_PRODUCT_FRAGMENT = gql`
  fragment CellarProductFragment on ProductObject {
    id
    name
    skuCode
    sellingPrice
    coverPhotoSmallPng
    coverPhotoSmall
    coverPhotoLargePng
    productionCountry {
      id
      name
    }
    hasStock
    slug
    backgroundColor
    lastOrderByMember {
      createdDate
    }
    productType {
      id
      name
      isEnabled
    }
    productRating {
      score
      note
    }
    productPhotos {
      photoSmall
    }
    wine {
      hasStock
      hasOtherVintageStock
      id
      country {
        id
        name
      }
      wineRegion {
        id
        name
        state
      }
      wineType {
        id
        name
        wineClass {
          id
          name
        }
      }
      wineMaker {
        id
        name
      }
    }
  }
`;

export const COLLECTION_FRAGMENT = gql`
  fragment CollectionFragment on CollectionObject {
    id
    name
    description
    slug
    products(search: $search, limit: $productLimit, offset: $productOffset) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const COLLECTION_FRAGMENT_WITH_PRODUCT_COUNT = gql`
  fragment CollectionFragmentWithProductCount on CollectionObject {
    id
    name
    description
    slug
    productCount(search: $search)
    products(search: $search, limit: $productLimit, offset: $productOffset) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCT_COLLECTIONS_WITH_COUNT = gql`
  query GetProductCollectionsWithCount(
    $limit: Int
    $offset: Int
    $search: String
    $productLimit: Int
    $productOffset: Int
  ) {
    collectionsCount(search: $search, excludeEmptyCollections: true)
    collections(limit: $limit, offset: $offset, search: $search, excludeEmptyCollections: true) {
      ...CollectionFragment
    }
  }
  ${COLLECTION_FRAGMENT}
`;

export const FETCH_MORE_PRODUCT_COLLECTIONS = gql`
  query FetchMoreProductCollections(
    $limit: Int
    $offset: Int
    $search: String
    $productLimit: Int
    $productOffset: Int
  ) {
    collections(limit: $limit, offset: $offset, search: $search, excludeEmptyCollections: true) {
      ...CollectionFragment
    }
  }
  ${COLLECTION_FRAGMENT}
`;

export const GET_COLLECTION_BY_SLUG_WITH_PRODUCT_COUNT = gql`
  query GetCollectionBySlugWithProductCount(
    $slug: String!
    $search: String
    $productLimit: Int
    $productOffset: Int
  ) {
    collectionBySlug(slug: $slug) {
      ...CollectionFragmentWithProductCount
    }
  }
  ${COLLECTION_FRAGMENT_WITH_PRODUCT_COUNT}
`;

export const FETCH_MORE_PRODUCTS_BY_COLLECTION_SLUG = gql`
  query FetchMoreProductsByCollectionSlug(
    $slug: String!
    $search: String
    $productLimit: Int
    $productOffset: Int
  ) {
    collectionBySlug(slug: $slug) {
      ...CollectionFragment
    }
  }
  ${COLLECTION_FRAGMENT}
`;

export const ADD_PRODUCT_TO_CART = gql`
  mutation AddProductToCart($input: AddShoppingCartItemInput!) {
    addShoppingCartItem(input: $input) {
      shoppingCart {
        id
        totalNonSubscriptionProductsCost
        amountPresents
        shoppingcartitemSet {
          quantity
          product {
            id
            hasStock
            name
            skuCode
            sellingPrice
            backgroundColor
            coverPhotoSmallPng
            productType {
              id
              name
            }
            productionCountry {
              id
              name
            }
          }
        }
      }
      errors {
        messages
      }
    }
  }
`;

export const ADD_PRODUCT_TO_BOX = gql`
  mutation AddProductToBox($productId: Int!, $quantity: Int!, $redeemWithPoints: Boolean!) {
    addProductToNextBox(productId: $productId, quantity: $quantity, redeemWithPoints: $redeemWithPoints) {
      errors {
        messages
      }
      subscription {
        id
        amountPresents
        subscriptionnextboxproductSet {
          id
          quantity
          isRedeemedWithPoints
          product {
            id
            name
            slug
            sellingPrice
            pointCost
            coverPhotoSmall
            hasStock
            backgroundColor
            productType {
              id
              name
            }
            productionCountry {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_COLLECTIONS_PRODUCT_ITEM_BY_SLUG = gql`
  query CollectionsProductItemById($slug: String!) {
    productBySlug(slug: $slug) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCT_ITEM_BY_SLUG = gql`
  query ProductItemBySlug($slug: String!) {
    productBySlug(slug: $slug) {
      ...ProductFragment
      coverPhotoSmall
      productRating {
        score
        note
      }
      lastOrderByMember {
        createdDate
      }
      productPhotos {
        id
        photoSmall
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const UPDATE_PRODUCT_RATING = gql`
  mutation UpdateProductRating($input: ProductRatingInput!) {
    updateProductRating(input: $input) {
      product {
        ...CellarProductFragment
      }
      pointsEarned
      randomVariableRewardPoints
      errors {
        field
        messages
      }
    }
  }
  ${CELLAR_PRODUCT_FRAGMENT}
`;
