import React from "react";
import LazyLoad from "react-lazyload";
import BrandGallery from "../../molecules/BrandGallery";
import { UK_COUNTRY_CODE, AU_COUNTRY_CODE, getCountry } from "../../../helpers/urls.helper";
import Image from "../../atoms/Image";

import featuredLogoHalliday from "../../../assets/images/featured/halliday.png";
import featuredLogoGourmetTraveller from "../../../assets/images/featured/gourmet-traveller.png";
import featuredLogoPedestrian from "../../../assets/images/featured/pedestrian.png";
import featuredLogoTheAge from "../../../assets/images/featured/the-age.png";
import featuredLogoSydneyMorningHerald from "../../../assets/images/featured/sydney-morning-herald.png";
import featuredLogoUrbanList from "../../../assets/images/featured/urbanlist.png";
import featuredLogoRetailDrinksAustralia from "../../../assets/images/featured/retail-drinks-australia.png";
import featuredLogoGoodFood from "../../../assets/images/featured/goodfood.png";
import featuredLogoBroadsheet from "../../../assets/images/featured/broadsheet.png";
import featuredLogoGQ from "../../../assets/images/featured/gq.png";
import featuredLogoVogue from "../../../assets/images/featured/vogue.png";
import featuredLogoElle from "../../../assets/images/featured/elle.png";
import featuredHello from "../../../assets/images/featured/hello.png";

import { StyledRow as Row, StyledCol as Col, BrandGalleryHolder } from "./style";

const renderAuBrandGallery = () => (
  <BrandGallery>
    <BrandGalleryHolder maxHeight={25} maxWidth={233}>
      <LazyLoad once offset={500} height={25}>
        <Image alt="Sydney Morning Herald" src={featuredLogoSydneyMorningHerald} height={25} width={233} />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={37} maxWidth={139}>
      <LazyLoad once offset={500} height={37}>
        <Image alt="Vogue" src={featuredLogoVogue} height={37} width={139} />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={40} maxWidth={187}>
      <LazyLoad once offset={500} height={40}>
        <Image alt="The Age" src={featuredLogoTheAge} height={40} width={187} />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={54} maxWidth={170}>
      <LazyLoad once offset={500} height={54}>
        <Image alt="Broadsheet" src={featuredLogoBroadsheet} height={54} width={170} />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={36} maxWidth={167}>
      <LazyLoad once offset={500} height={36}>
        <Image alt="Good Food" src={featuredLogoGoodFood} height={36} width={167} />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={45} maxWidth={108}>
      <LazyLoad once offset={500} height={45}>
        <Image alt="Urban List" src={featuredLogoUrbanList} height={45} width={108} />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={48} maxWidth={215}>
      <LazyLoad once offset={500} height={48}>
        <Image
          alt="Retail Drinks Australia"
          src={featuredLogoRetailDrinksAustralia}
          height={48}
          width={215}
        />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={100} maxWidth={141}>
      <LazyLoad once offset={500} height={100}>
        <Image alt="Halliday" src={featuredLogoHalliday} height={100} width={141} />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={36} maxWidth={136}>
      <LazyLoad once offset={500} height={36}>
        <Image alt="Gourmet Traveller" src={featuredLogoGourmetTraveller} height={36} width={136} />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={37} maxWidth={222}>
      <LazyLoad once offset={500} height={37}>
        <Image alt="Pedestrian" src={featuredLogoPedestrian} height={37} width={222} />
      </LazyLoad>
    </BrandGalleryHolder>
  </BrandGallery>
);

const renderUkBrandGallery = () => (
  <BrandGallery>
    <BrandGalleryHolder maxHeight={37} maxWidth={139}>
      <LazyLoad once offset={500} height={37}>
        <Image alt="Vogue" src={featuredLogoVogue} height={37} width={139} />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={45} maxWidth={100}>
      <LazyLoad once offset={500} height={45}>
        <Image alt="GQ" src={featuredLogoGQ} height={45} width={100} />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={54} maxWidth={170}>
      <LazyLoad once offset={500} height={54}>
        <Image alt="Broadsheet" src={featuredLogoBroadsheet} height={54} width={170} />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={36} maxWidth={167}>
      <LazyLoad once offset={500} height={36}>
        <Image alt="Good Food" src={featuredLogoGoodFood} height={36} width={167} />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={40} maxWidth={167}>
      <LazyLoad once offset={500} height={40}>
        <Image alt="Elle" src={featuredLogoElle} height={40} width={167} />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={36} maxWidth={136}>
      <LazyLoad once offset={500} height={36}>
        <Image alt="Gourmet Traveller" src={featuredLogoGourmetTraveller} height={36} width={136} />
      </LazyLoad>
    </BrandGalleryHolder>
    <BrandGalleryHolder maxHeight={40} maxWidth={160}>
      <LazyLoad once offset={500} height={40}>
        <Image alt="Hello" src={featuredHello} height={40} width={160} />
      </LazyLoad>
    </BrandGalleryHolder>
  </BrandGallery>
);

const HomePageBrandGallery = () => {
  const country = getCountry();
  return (
    <Row middle="md">
      <Col md={12} lg={12}>
        {country === AU_COUNTRY_CODE && renderAuBrandGallery()}
        {country === UK_COUNTRY_CODE && renderUkBrandGallery()}
      </Col>
    </Row>
  );
};

export default HomePageBrandGallery;
