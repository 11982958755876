import React, { Component } from "react";

import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import { getWithExpiry, setWithExpiry } from "../../../helpers/storage";
import urlPatterns from "../../../urls";

class AppErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.shape({}),
  };

  static defaultProps = {
    children: null,
    history: {},
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    const { hasError, error } = this.state;
    const { children, history } = this.props;
    const { location } = history || {};
    const { pathname } = location || {};
    if (hasError) {
      // Handles failed lazy loading of a JS/CSS chunk.
      // https://mitchgavan.com/code-splitting-react-safely/
      const chunkFailedMessage = /Loading chunk [\d]+ failed/;

      if (
        error &&
        error.message &&
        chunkFailedMessage.test(error.message) &&
        !getWithExpiry("chunk_failed")
      ) {
        // This flag is used to avoid reloading the page if it was already reloaded in the last 10 seconds
        // because of a missing chunk. This is to avoid an infinite reload loop in the case were a chunk is
        // legitimately missing (not just missing because of a new deployment)
        setWithExpiry("chunk_failed", true, 10000);
        window.location.reload();
      } else if (pathname !== urlPatterns.NOT_FOUND) {
        history.push({
          pathname: urlPatterns.NOT_FOUND,
          state: { isOldVersion: true },
        });
        return (
          <Redirect
            to={{
              pathname: urlPatterns.NOT_FOUND,
              state: { isOldVersion: true },
            }}
          />
        );
      }
    }

    return children;
  }
}

export default AppErrorBoundary;
