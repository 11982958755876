import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { colors, fonts } from "../../../styles/variables";

const CardFeedbackBodyText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-size: 14px;
  line-height: 18px;
  max-height: 90px;
  min-height: 90px;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
`;

const CardFeedbackReadMore = styled(Link)`
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 10px;
  font-family: ${fonts.fontBauMedium};
`;

const CardFeedbackAuthor = styled.p`
  font-size: 12px;
  font-family: ${fonts.fontConceptRegular};
`;

const CardFeedback = (props) => {
  const { children } = props;
  const CardFeedbackHolder = styled.div`
    padding: 20px;
    background-color: ${colors.white};
    height: 300px;
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
  `;
  return <CardFeedbackHolder>{children}</CardFeedbackHolder>;
};

CardFeedback.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CardFeedback, CardFeedbackBodyText, CardFeedbackReadMore, CardFeedbackAuthor };
