import { gql } from "@apollo/client";

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($input: SubscriptionInput!) {
    updateSubscription(input: $input) {
      subscription {
        id
        billingDay
        subscriptionStatus {
          id
          name
        }
        holdUntilDate
        monthFrequency
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const GET_ALL_SUBSCRIPTIONS_STATUS_REASONS = gql`
  query AllSubscriptionStatusReasons {
    allSubscriptionStatusReasons {
      id
      name
      sortOrder
      subscriptionStatus {
        id
        name
      }
    }
  }
`;

export const GET_MEMBER_WINE_BLACKLIST = gql`
  query MemberWineBlacklist {
    me {
      id
      wineblacklistSet {
        id
        objectId
        wineBlacklistType {
          id
          name
        }
      }
      subscription {
        id
        maximumBoxSpend
      }
      winequantitySet {
        id
        numberOfBottles
        wineClass {
          id
          name
        }
        winePriceRange {
          id
          isEnabled
        }
      }
    }
  }
`;

export const CAN_CREATE_SUBSCRIPTION_ORDER = gql`
  query CanCreateSubscriptionOrder($memberId: Int) {
    canCreateSubscriptionOrder(memberId: $memberId)
  }
`;

export const DELETE_WINE_FROM_NEXT_BOX = gql`
  mutation DeleteWineFromNextBox($input: SpecificSubscriptionWineInput!) {
    deleteWineFromNextBox(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

export const ADD_WINE_TO_NEXT_BOX = gql`
  mutation AddWineToNextBox($input: GenericSubscriptionWineInput!) {
    addWineToNextBox(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const RECOMMEND_MISSING_WINES = gql`
  mutation RecommendMissingWines($input: RecommendMissingWinesInput!) {
    recommendMissingWines(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

export const RECOMMEND_MISSING_CHOOSE_FREE_BOTTLE_CREDIT_WINES = gql`
  mutation RecommendMissingChooseFreeBottleCreditWines(
    $input: RecommendMissingChooseFreeBottleCreditWinesMutationInput!
  ) {
    recommendMissingChooseFreeBottleCreditWines(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const GET_RECOMMENDATIONS_NOW = gql`
  mutation GetRecommendationsNow($input: GetRecommendationsNowInput!) {
    getRecommendationsNow(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

export const REFRESH_RECOMMENDATION = gql`
  mutation RefreshRecommendation($input: RefreshRecommendationInput!) {
    refreshRecommendation(input: $input) {
      errors {
        field
        messages
      }
      subscriptionWine {
        id
        quantity
        wine {
          id
          winePriceRange {
            id
          }
          wineType {
            id
            name
            quizJustification
            wineClass {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const CANCEL_PENDING_BILLING_ORDER = gql`
  mutation CancelPendingBillingOrder($input: CancelPendingBillingOrderInput!) {
    cancelPendingBillingOrder(input: $input) {
      isSuccessful
      errors {
        messages
        field
      }
    }
  }
`;

export const DELETE_PRODUCT_FROM_NEXT_BOX = gql`
  mutation DeleteProductFromNextBox($subscriptionNextBoxProductId: Int!) {
    deleteProductFromNextBox(subscriptionNextBoxProductId: $subscriptionNextBoxProductId) {
      errors {
        messages
        field
      }
    }
  }
`;

export const ADD_ON_FRAGMENT = gql`
  fragment AddOnFragment on AddOnObject {
    id
    name
    shortName
    color
    productMinPrice
    productMaxPrice
    addToBoxTitle
    addToBoxSubtitle
    addsBottleThatCountsTowardsFreeShippingThreshold
    nextBoxImageUrl
    isStandalone
  }
`;

export const UPDATE_SUBSCRIPTION_ADD_ON = gql`
  mutation UpdateSubscriptionAddOn($input: UpdateSubscriptionAddOnInput!) {
    updateSubscriptionAddOn(input: $input) {
      subscription {
        id
        billingDay
        subscriptionStatus {
          id
          name
        }
        holdUntilDate
        monthFrequency
        activeAddOns: subscribedAddons(status: ACTIVE) {
          ...AddOnFragment
        }
        skippedAddOns: subscribedAddons(status: SKIP) {
          ...AddOnFragment
        }
      }
      errors {
        field
        messages
      }
    }
  }
  ${ADD_ON_FRAGMENT}
`;
