import React from "react";
import styled, { FlattenSimpleInterpolation, keyframes } from "styled-components";
import loader from "../../../assets/images/icons/loader.svg";

const rotate = keyframes`
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(360deg);
  }
`;

const LoadingSpinner = styled.div<{ css?: FlattenSimpleInterpolation }>`
  position: absolute;
  display: inline-block;
  height: 30px;
  width: 30px;
  background-image: url("${loader}");
  animation: linear ${rotate} 1s infinite;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  ${({ css }) => css};
`;

const LoadingWrapper = styled.div`
  text-align: center;
  ${(props: LoadingWrapperProps) => props.css}
`;

interface LoadingWrapperProps {
  css?: FlattenSimpleInterpolation;
}

interface LoadingProps {
  style?: FlattenSimpleInterpolation;
  spinnerStyle?: FlattenSimpleInterpolation;
}

const Loading = ({ style, spinnerStyle }: LoadingProps) => (
  <LoadingWrapper css={style}>
    <LoadingSpinner css={spinnerStyle} />
  </LoadingWrapper>
);

export default Loading;
