import { gql } from "@apollo/client";
import { CELLAR_PRODUCT_FRAGMENT } from "./product";

/**
 * QUERIES
 * */

export const GET_FREE_BOX_CAMPAIGN = gql`
  query FreeBoxCampaign($seoCampaignName: String!) {
    freeBoxCampaign(seoCampaignName: $seoCampaignName) {
      id
      isAvailable
      totalBoxesAvailable
      seoTitle
      seoDescription
      seoCampaignName
      buyButtonText
      heroTopTheme
      heroTopImageLargeUrl
      heroTopTextPosition
      heroTopTitle
      heroTopSubTitle
      heroMonthlyLimitReachedText
      heroBottomTheme
      heroBottomImageLargeUrl
      heroBottomTitle
      heroBottomSubTitle
      section1ImageLargeUrl
      section1Title
      section1Text
      section2ImageLargeUrl
      section2Title
      section2Text
      section3ImageLargeUrl
      section3Title
      section3Text
    }
  }
`;

export const GET_TOP_UP_INFO = gql`
  query TopUpInfo($memberId: Int, $wineId: Int!, $quantity: Int!) {
    topupSummary(memberId: $memberId, wineId: $wineId, quantity: $quantity) {
      totalPrice
      discountValue
      shippingFee
      hasStock
    }
  }
`;

export const GET_GUEST_FREE_BOX_CAMPAIGN_DISCOUNT = gql`
  query GuestFreeBoxCampaignDiscount {
    guestFreeBoxCampaignDiscount @client {
      freeBoxCampaignId
    }
  }
`;

export const GET_GUEST_PROMO_CODE_DISCOUNT = gql`
  query GuestPromoCodeDiscount {
    guestPromoCodeDiscount @client {
      promoCode
      discountValue
    }
  }
`;

export const GET_MEMBER_CHARGED_ORDERS = gql`
  query MemberChargedOrders($memberId: Int, $orderCategoryIds: [Int], $limit: Int, $offset: Int) {
    memberChargedOrders(
      memberId: $memberId
      orderCategoryIds: $orderCategoryIds
      limit: $limit
      offset: $offset
    ) {
      id
      netTotal
      grossTotal
      displayDeliveryStatusName
      invoiceUrl
      chargeDate
      description
      giftTrackingUrl
      orderStatus {
        id
        name
      }
      orderCategory {
        id
        name
      }
      delivery {
        id
        trackingCode
        trackingUrl
      }
      orderitemSet {
        id
        productName
        quantityOrdered
        product {
          id
          slug
          name
          coverPhotoSmall
          coverPhotoSmallPng
          backgroundColor
          wine {
            id
            recipes {
              id
              url
            }
            wineType {
              id
              wineClass {
                id
              }
            }
          }
          freebie {
            id
            imageMedium
          }
          productPhotos {
            id
            photoSmall
          }
          productType {
            id
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_RECIPES_CHARGED_ORDERS = gql`
  query MemberRecipesChargedOrders($memberId: Int, $orderCategoryIds: [Int], $limit: Int, $offset: Int) {
    memberChargedOrders(
      memberId: $memberId
      orderCategoryIds: $orderCategoryIds
      limit: $limit
      offset: $offset
    ) {
      id
      chargeDate
      orderitemSet {
        id
        productName
        product {
          id
          slug
          name
          coverPhotoSmall
          wine {
            id
            recipes {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_BILLING_FAILED_SUBSCRIPTION_ORDER = gql`
  query MemberBillingFailedSubscriptionOrder($memberId: Int) {
    memberBillingFailedSubscriptionOrder(memberId: $memberId) {
      id
      isOpenForChanges
      invoiceUrl
      grossTotal
      netTotal
      chargeDate
      latestFailedChargeDate
      holdChargeDate
      paymentApiErrorCode
      orderStatus {
        id
      }
      orderitemSet {
        id
        productName
        quantityOrdered
        product {
          id
          name
          sellingPrice
          coverPhotoSmall
          coverPhotoSmallPng
          backgroundColor
          slug
          wine {
            id
            wineType {
              id
              wineClass {
                id
              }
            }
          }
          productPhotos {
            id
            photoSmall
          }
          productType {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_ORDER_ITEMS_WITH_RATINGS = gql`
  query MemberOrderItemsWithRatings(
    $memberId: Int
    $winePriceRangeIds: [Int]
    $wineClassIds: [Int]
    $wineProductionMethodIds: [Int]
    $countryIds: [Int]
    $wineRegionIds: [Int]
    $wineTypeIds: [Int]
    $wineAlcoholRange: [Int]
    $wineBodyId: Int
    $wineAcidityId: Int
    $wineOakyId: Int
    $cuisineIds: [Int]
    $wineFruitDrivenId: Int
    $wineSweetnessId: Int
    $wineTanninId: Int
    $hasDrunk: Boolean
    $orderBy: MemberOrderItemsOrderField
    $limit: Int
    $offset: Int
    $name: String
  ) {
    memberOrderItemsWithRating(
      memberId: $memberId
      winePriceRangeIds: $winePriceRangeIds
      wineClassIds: $wineClassIds
      wineProductionMethodIds: $wineProductionMethodIds
      countryIds: $countryIds
      wineRegionIds: $wineRegionIds
      wineTypeIds: $wineTypeIds
      wineAlcoholRange: $wineAlcoholRange
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      wineFruitDrivenId: $wineFruitDrivenId
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      hasDrunk: $hasDrunk
      orderBy: $orderBy
      limit: $limit
      offset: $offset
      name: $name
    ) {
      id
      createdDate
      memberWineRatingScore
      product {
        id
        name
        slug
        sellingPrice
        coverPhotoSmall
        productPhotos {
          id
          photoSmall
        }
        wine {
          id
          hasStock
          hasStock6
          hasStock12
          hasOtherVintageStock
          wineType {
            id
            name
            wineClass {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_ORDER_ITEMS_WITH_RATINGS_COUNT = gql`
  query MemberOrderItemsWithRatingsCount(
    $memberId: Int
    $winePriceRangeIds: [Int]
    $wineClassIds: [Int]
    $wineProductionMethodIds: [Int]
    $countryIds: [Int]
    $wineRegionIds: [Int]
    $wineTypeIds: [Int]
    $wineAlcoholRange: [Int]
    $wineBodyId: Int
    $wineAcidityId: Int
    $wineOakyId: Int
    $cuisineIds: [Int]
    $wineFruitDrivenId: Int
    $wineSweetnessId: Int
    $wineTanninId: Int
    $hasDrunk: Boolean
    $name: String
  ) {
    memberOrderItemsWithRatingCount(
      memberId: $memberId
      winePriceRangeIds: $winePriceRangeIds
      wineClassIds: $wineClassIds
      wineProductionMethodIds: $wineProductionMethodIds
      countryIds: $countryIds
      wineRegionIds: $wineRegionIds
      wineTypeIds: $wineTypeIds
      wineAlcoholRange: $wineAlcoholRange
      wineBodyId: $wineBodyId
      wineAcidityId: $wineAcidityId
      wineOakyId: $wineOakyId
      cuisineIds: $cuisineIds
      wineFruitDrivenId: $wineFruitDrivenId
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      hasDrunk: $hasDrunk
      name: $name
    )
  }
`;

export const GET_SIGNUP_PROMO_CODE = gql`
  query SignupPromoCode {
    signupPromoCode {
      promoCode {
        name
        discountValue
        promotionImageCheckout
        promotionImageCheckoutMobile
        promotionImageQuizResults
        promotionDescriptionMarkdown
        promotionValue
        quizResultsLabel
        promocodefreebieSet {
          freebie {
            product {
              name
            }
          }
          quantity
        }
        winePriceRange {
          id
        }
      }
      message
    }
  }
`;

export const GET_WELCOME_PACKS = gql`
  query AllWelcomePacks {
    allSignupPromoCodes {
      id
      promoCode {
        name
        isFreeWineForAYearPromo
        promocodefreebieSet {
          freebie {
            product {
              name
            }
          }
          quantity
        }
      }
      promotionLabel
      promotionImageQuiz
      promotionImageQuizWebMobileView
      isSoldOut
      descriptionMarkdown
    }
  }
`;

/**
 *  MUTATIONS
 * */

export const APPLY_DISCOUNT_CODE = gql`
  mutation AddPromoCodeInfo($input: PromoCodeInfoInput!) {
    addPromoCodeInfo(input: $input) {
      name
      discountTypeId
      discountValue
      message
      promotionImageCheckout
      promotionImageCheckoutMobile
      promotionImageQuizResults
      promotionDescriptionMarkdown
      promotionValue
      checkoutWelcomeOfferLabel
      quizResultsLabel
      freebies {
        freebie {
          product {
            name
          }
        }
        quantity
      }
      referralCodeBenefits {
        creditType {
          id
        }
        freeWineValue
        freebie {
          product {
            name
          }
        }
        quantity
      }
      winePriceRange {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const VALIDATE_DISCOUNT_CODE = gql`
  mutation validatePromoCode($input: ValidatePromoCodeInput!) {
    validatePromoCode(input: $input) {
      discountValue
      code
      promotionImageCheckout
      promotionImageCheckoutMobile
      promotionImageQuizResults
      promotionDescriptionMarkdown
      promotionValue
      checkoutWelcomeOfferLabel
      quizResultsLabel
      isFreeWineForAYearPromo
      freebies {
        freebie {
          product {
            name
          }
        }
        quantity
      }
      winePriceRange {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const APPLY_DISCOUNT_FROM_FREE_BOX_CAMPAIGN = gql`
  mutation ApplyDiscountFromFreeBoxCampaign($input: ApplyDiscountFromFreeBoxCampaignInput!) {
    applyDiscountFromFreeBoxCampaign(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

export const SET_GUEST_FREE_BOX_CAMPAIGN_DISCOUNT = gql`
  mutation SetGuestFreeBoxCampaignDiscount($freeBoxCampaignId: Int) {
    setGuestFreeBoxCampaignDiscount(freeBoxCampaignId: $freeBoxCampaignId) @client {
      guestFreeBoxCampaignDiscount @client {
        freeBoxCampaignId
      }
    }
  }
`;

export const SET_GUEST_PROMO_CODE_DISCOUNT = gql`
  mutation SetGuestPromoCodeDiscount($promoCode: String!, $discountValue: Int!) {
    setGuestPromoCodeDiscount(promoCode: $promoCode, discountValue: $discountValue) @client {
      guestPromoCodeDiscount @client {
        promoCode
        discountValue
      }
    }
  }
`;

export const ADD_FREE_BOX_CAMPAIGN_INTEREST = gql`
  mutation RegisterFreeBoxCampaignInterest($input: RegisterFreeBoxCampaignInterestInput!) {
    registerFreeBoxCampaignInterest(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

/**
 * Resolvers, Defaults & Type definitions
 * https://www.apollographql.com/docs/link/links/state
 * */
export const resolverGuestFreeBoxCampaignDiscount = {
  defaults: {
    guestFreeBoxCampaignDiscount: {
      freeBoxCampaignId: null,
      __typename: "GuestFreeBoxCampaignDiscountObjectStore",
    },
    guestPromoCodeDiscount: {
      promoCode: null,
      discountValue: 0,
      __typename: "GuestPromoCodeDiscountObjectStore",
    },
  },
  resolvers: {
    Mutation: {
      setGuestFreeBoxCampaignDiscount: async (obj, args, { cache }) => {
        const data = {
          __typename: "Store",
          guestFreeBoxCampaignDiscount: {
            __typename: "GuestFreeBoxCampaignDiscountObjectStore",
            ...args,
          },
        };
        await cache.writeQuery({
          query: GET_GUEST_FREE_BOX_CAMPAIGN_DISCOUNT,
          data,
        });

        return data;
      },
      setGuestPromoCodeDiscount: async (obj, args, { cache }) => {
        const data = {
          __typename: "Store",
          guestPromoCodeDiscount: {
            __typename: "GuestPromoCodeDiscountObjectStore",
            ...args,
          },
        };
        await cache.writeQuery({
          query: GET_GUEST_PROMO_CODE_DISCOUNT,
          data,
        });

        return data;
      },
    },
  },
};

export const CHARGE_AND_DISPATCH_ORDER_NOW = gql`
  mutation ChargeAndDispatchOrderNow($input: ChargeAndDispatchOrderNowInput!) {
    chargeAndDispatchOrderNow(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

export const RETRY_PAYMENT_FOR_CHARGE_FAILED_ORDER = gql`
  mutation RetryPaymentForChargeFailedOrder($input: RetryPaymentForChargeFailedOrderInput!) {
    retryPaymentForChargeFailedOrder(input: $input) {
      isSuccessful
      errors {
        field
        messages
      }
    }
  }
`;

export const CREATE_PAYMENT_INTENT_FOR_CHARGE_FAILED_ORDER = gql`
  mutation CreatePaymentIntentForChargeFailedOrder($input: CreatePaymentIntentForChargeFailedOrderInput!) {
    createPaymentIntentForChargeFailedOrder(input: $input) {
      clientSecret
      errors {
        field
        messages
      }
    }
  }
`;

export const CREATE_PAYMENT_INTENT_FOR_CHARGE_AND_DISPATCH_ORDER_NOW = gql`
  mutation CreatePaymentIntentForChargeAndDispatchOrderNow(
    $input: CreatePaymentIntentForChargeAndDispatchOrderNowInput!
  ) {
    createPaymentIntentForChargeAndDispatchOrderNow(input: $input) {
      clientSecret
      errors {
        field
        messages
      }
    }
  }
`;

export const GET_MEMBER_CELLAR = gql`
  query GetMemberCellar(
    $hasDrunk: Boolean
    $search: String
    $winePriceRangeIds: [Int]
    $wineClassIds: [Int]
    $wineTypeIds: [Int]
    $wineBodyId: Int
    $wineSweetnessId: Int
    $wineTanninId: Int
    $wineFruitDrivenId: Int
    $wineAcidityId: Int
    $wineOakyId: Int
    $wineProductionMethodIds: [Int]
    $wineRegionIds: [Int]
    $wineAlcoholRange: [Int]
    $cuisineIds: [Int]
    $countryIds: [Int]
    $orderBy: CellarProductsOrderField
    $limit: Int
    $offset: Int
  ) {
    memberCellar(
      hasDrunk: $hasDrunk
      search: $search
      winePriceRangeIds: $winePriceRangeIds
      wineClassIds: $wineClassIds
      wineTypeIds: $wineTypeIds
      wineBodyId: $wineBodyId
      wineSweetnessId: $wineSweetnessId
      wineTanninId: $wineTanninId
      wineFruitDrivenId: $wineFruitDrivenId
      wineAcidityId: $wineAcidityId
      wineOakyId: $wineOakyId
      wineProductionMethodIds: $wineProductionMethodIds
      wineRegionIds: $wineRegionIds
      wineAlcoholRange: $wineAlcoholRange
      cuisineIds: $cuisineIds
      countryIds: $countryIds
    ) {
      productsCount
      products(orderBy: $orderBy, limit: $limit, offset: $offset) {
        ...CellarProductFragment
      }
    }
  }
  ${CELLAR_PRODUCT_FRAGMENT}
`;

export const GET_TO_RATE_CELLAR_ITEMS = gql`
  query GetItemsToRate {
    ratedProducts: memberCellar(hasDrunk: true) {
      products(limit: 5, orderBy: MEMBER_RATING_SCORE_DESC) {
        ...CellarProductFragment
      }
      productsCount
    }
    notRatedProducts: memberCellar(hasDrunk: false) {
      products(limit: 12) {
        ...CellarProductFragment
      }
      productsCount
    }
  }
  ${CELLAR_PRODUCT_FRAGMENT}
`;
