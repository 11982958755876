import styled from "styled-components";
import { colors, transition } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";

export default styled.div`
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : colors.white)};
  transition: ${transition.baseTransition};
  position: fixed;
  right: 0;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 700px;
  top: 0;
  padding-top: 40px;
  padding-left: ${(props) => (props.sidePadding ? `${props.sidePadding}px` : "15px")};
  padding-right: ${(props) => (props.sidePadding ? `${props.sidePadding}px` : "15px")};
  z-index: 2200;
  overflow-y: ${(props) => (props.overflowY ? props.overflowY : "scroll")};
  ${breakpoints.mdUp} {
    top: 52.5%;
    height: ${(props) => (props.isMdUpHeightAuto ? "auto" : "620px")};
    right: 50%;
    padding: 60px 40px 40px;
    transform: translate(50%, -50%);
  }
`;
