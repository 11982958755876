import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACES, MOBILE_NAVBAR_ACCORDIONS } from "../../../helpers/constants";
import { NAVBAR_MOBILE_LEARN_ITEMS } from "../../../helpers/navbarProcessor";
import { NAVBAR_LEARN_HEIGHTS } from "../../../styles/variables";
import AccordionItem from "../AccordionItem";
import {
  AccordionTopPart,
  StyledChevron,
  AccordionWrapper,
  AccordionContentWrapper,
} from "../MobileHeaderNavbar/style";

interface MobileLearnAccordionProps {
  openAccordion: string | undefined;
  setOpenAccordion: Function;
}

const MobileLearnAccordion = ({
  openAccordion,
  setOpenAccordion,
}: MobileLearnAccordionProps): ReactElement => {
  const isThisAccordionOpen = !!(openAccordion === MOBILE_NAVBAR_ACCORDIONS.LEARN);
  const { t } = useTranslation([I18N_NAMESPACES.TOP_NAVBAR]);
  return (
    <AccordionWrapper
      height={NAVBAR_LEARN_HEIGHTS.full}
      open={isThisAccordionOpen}
      onClick={() => setOpenAccordion(isThisAccordionOpen ? "" : MOBILE_NAVBAR_ACCORDIONS.LEARN)}
    >
      <AccordionTopPart isThisAccordionOpen={isThisAccordionOpen}>
        {t("topNavbar:learn")}
        <StyledChevron open={isThisAccordionOpen} />
      </AccordionTopPart>
      <AccordionContentWrapper open={isThisAccordionOpen} height={NAVBAR_LEARN_HEIGHTS.accordion}>
        {NAVBAR_MOBILE_LEARN_ITEMS.map((item) => (
          <AccordionItem i18nKey={item.i18nKey} url={item.url} icon={item.icon} key={item.i18nKey} />
        ))}
      </AccordionContentWrapper>
    </AccordionWrapper>
  );
};

export default MobileLearnAccordion;
