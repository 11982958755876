import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { colors } from "../../../styles/variables";

import NotificationTray from "../NotificationsTray";
import notificationsRead from "../../../assets/images/icons/notifications-read.svg";
import notificationsUnread from "../../../assets/images/icons/notifications-unread.svg";
import breakpoints from "../../../styles/breakpoints";

const StyledHeaderNotificationBtn = styled.button`
  color: ${colors.black}
  display: inline-block;
  width: 31px;
  flex-shrink: 0;
  border: none;
  padding: 8px;
  background: transparent;
  ${breakpoints.mdUp} {
    margin-right: 16px;
  }
`;

const StyledHeaderNotificationBtnImage = styled.img`
  display: block;
  width: 100%;
`;

interface NotificationButtonProps {
  hasUnreadRecentNotifications: boolean;
  refetchMemberLayoutInfoData: () => {};
  toggleNotifications?: () => void;
  isAnyDropDownOpen: boolean;
}

const NotificationButton = (props: NotificationButtonProps) => {
  const {
    hasUnreadRecentNotifications,
    refetchMemberLayoutInfoData,
    toggleNotifications,
    isAnyDropDownOpen,
  } = props;
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (isAnyDropDownOpen) {
      setShowModal(false);
    }
  }, [isAnyDropDownOpen]);

  const closeTray = () => {
    refetchMemberLayoutInfoData();
    setShowModal(false);
    toggleNotifications && toggleNotifications();
  };

  const toggleTray = () => {
    !showModal && refetchMemberLayoutInfoData();
    setShowModal(!showModal);
    toggleNotifications && toggleNotifications();
  };

  return (
    <StyledHeaderNotificationBtn onClick={toggleTray}>
      <StyledHeaderNotificationBtnImage
        src={hasUnreadRecentNotifications ? notificationsUnread : notificationsRead}
        alt="notifications read"
      />
      <NotificationTray isOpened={showModal} onClose={closeTray} />
    </StyledHeaderNotificationBtn>
  );
};

NotificationButton.defaultProps = {
  toggleNotifications: () => {},
};

export default NotificationButton;
