import styled from "styled-components";

import { fonts } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";

export const InfoPanel = styled.div`
  display: flex;
  ${breakpoints.mdUp} {
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
  }
  ${breakpoints.smUp} {
    font-size: 18px;
  }
`;

export const InfoPanelItem = styled.div`
  padding: 0px 5px 30px 5px;
  text-align: center;
  width: 355px;
  ${breakpoints.lgDown} {
    flex: 1;
    padding: 10px 20px;
    width: 260px;
    min-width: 260px;
    margin-bottom: 0px;
    margin-right: 25px;
    flex-shrink: 0;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const InfoPanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 25px;
`;

export const InfoPanelHeaderIcon = styled.img<{ maxWidth?: number }>`
  height: 100px; //max-height of the tallest selling point image.
  flex-shrink: 0;
  max-width: ${(props) => props.maxWidth || 60}px;
  object-fit: contain;
`;

export const InfoPanelHeaderTitle = styled.h3`
  width: 100%;
  font-size: 35px;
  line-height: 1;
  font-family: ${fonts.fontInterstateBlackCompressed};
`;
