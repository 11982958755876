import styled from "styled-components";

export const BoxCartSideTogglesWrapper = styled.div`
  position: absolute;
  transform: translateX(-100%);
`;

export const BoxCartSideTogglesInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
