import React from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { Col, Row } from "react-flexbox-grid";

import LazyLoad from "react-lazyload";
import breakpoints from "../../../styles/breakpoints";
import { fonts } from "../../../styles/variables";
import urlPatterns from "../../../urls";

import { Container } from "../../atoms/Container";
import { AlignCenter } from "../../atoms/Aligned";
import { DisplayNoneSmDown, DisplayNoneSmUp } from "../../atoms/Display";
import Image from "../../atoms/Image";
import Title from "../../atoms/Title";
import ButtonLink from "../../atoms/ButtonLink";
import ReviewsRating from "../../atoms/ReviewsRating";
import FeedbackRating from "../../atoms/FeedbackRating";
import FeedbackGallery from "../FeedbackGallery";
import {
  CardFeedback,
  CardFeedbackBodyText,
  CardFeedbackReadMore,
  CardFeedbackAuthor,
} from "../CardFeedback";

import poweredByLogo from "../../../assets/images/temp/yotpo-seeklogo.svg";
import homeBathtub from "../../../assets/images/elements/home_bathtub.svg";

const StyledPoweredBy = styled.p`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  text-transform: uppercase;
  font-family: ${fonts.fontBauMedium};
`;

const StyledPoweredByDesktop = styled(StyledPoweredBy)`
  margin-bottom: 20px;
  ${breakpoints.smDown} {
    display: none;
  }
`;

const StyledPoweredByMobile = styled(StyledPoweredBy)`
  margin-top: 25px;
  margin-bottom: 20px;
  ${breakpoints.smUp} {
    display: none;
  }
`;

const StyledPoweredByLogo = styled.img`
  width: 26px;
  height: 24px;
  flex-shrink: 0;
  margin-left: 10px;
`;

const ImageBath = styled(Image)`
  margin-bottom: -5px;
`;

const ReviewsWidget = (props) => {
  const { showImage, isBeerLandingPage } = props;

  return (
    <Container>
      <Row>
        <Col md={5} lg={4}>
          <AlignCenter>
            <Title tag={2} uppercase center>
              <Title tag={2} uppercase center>
                <Trans
                  i18nKey={isBeerLandingPage ? "common:reviewsTitleBeer" : "common:reviewsTitleWine"}
                  components={{ br: <br /> }}
                />
              </Title>
            </Title>
            <ReviewsRating center rating={5} />

            <ButtonLink id="reviewsWidgetCtaButton" to={urlPatterns.REVIEWS} ghost>
              Read all reviews
            </ButtonLink>

            <StyledPoweredByDesktop>
              Powered by
              <StyledPoweredByLogo src={poweredByLogo} alt="poweredByLogo" />
            </StyledPoweredByDesktop>

            {showImage && (
              <DisplayNoneSmDown>
                <LazyLoad once offset={500} height={145}>
                  <ImageBath alt="Good Pair Days by drinking wine in the bathtub" src={homeBathtub} maxFull />
                </LazyLoad>
              </DisplayNoneSmDown>
            )}
          </AlignCenter>
        </Col>
      </Row>

      <FeedbackGallery>
        <CardFeedback>
          <FeedbackRating rating={5} />
          <Title tag={5} fontSecondary mbSmall>
            No more bottleshop stress
          </Title>
          <CardFeedbackBodyText>
            I’ve been with Good Pair Days (Wine Gallery back in the day) since 2017 and it has been one of the
            best decisions. I’ve gotten to experience wine from all over the world and as time has gone on the
            wine has gotten better and better since they know my taste so well. No more stress of trying to
            choose something at the bottleo and worrying I won’t like it. There wines are brilliant,
            customisable on price and type and their team is fantastic and so helpful!
          </CardFeedbackBodyText>
          <CardFeedbackReadMore to={urlPatterns.REVIEWS}>Read more</CardFeedbackReadMore>
          <CardFeedbackAuthor>03/06/21 by Sal</CardFeedbackAuthor>
        </CardFeedback>

        <CardFeedback>
          <FeedbackRating rating={5} />
          <Title tag={5} fontSecondary mbSmall>
            Choosing wine made easy
          </Title>
          <CardFeedbackBodyText>
            I love GPD. As someone who doesn’t know anything about wine, GPD makes the learning process so
            easy. Each wine comes with its own information sheet and a great pairing recipe (which I
            absolutely love). GPD removes the what’s what about choosing wine. I also love how I have complete
            control over my box...I can choose the wine, I can let GPD choose...
          </CardFeedbackBodyText>
          <CardFeedbackReadMore to={urlPatterns.REVIEWS}>Read more</CardFeedbackReadMore>
          <CardFeedbackAuthor>09/06/21 by Dmdoolan</CardFeedbackAuthor>
        </CardFeedback>

        <CardFeedback>
          <FeedbackRating rating={5} />
          <Title tag={5} fontSecondary mbSmall>
            Just to my taste
          </Title>
          <CardFeedbackBodyText>
            Have you ever walked into a bottle-o and just wished there was a way you could know what random
            wine to choose that you will like? Good Pair Days is the go to. Wine recommendations are based on
            my taste and rarely ever wrong. Great way to get great wines for you with little effort.
          </CardFeedbackBodyText>
          <CardFeedbackReadMore to={urlPatterns.REVIEWS}>Read more</CardFeedbackReadMore>
          <CardFeedbackAuthor>07/05/21 by Kirsten</CardFeedbackAuthor>
        </CardFeedback>

        <CardFeedback>
          <FeedbackRating rating={5} />
          <Title tag={5} fontSecondary mbSmall>
            Amazing concept!
          </Title>
          <CardFeedbackBodyText>
            No more wasting time in the bottle shop and coming home with a wine I don’t like! The grape
            information and recipes are a great way to appreciate the wines more.
          </CardFeedbackBodyText>
          <CardFeedbackReadMore to={urlPatterns.REVIEWS}>Read more</CardFeedbackReadMore>
          <CardFeedbackAuthor>28/04/21 by Dorien</CardFeedbackAuthor>
        </CardFeedback>

        <CardFeedback>
          <FeedbackRating rating={5} />
          <Title tag={5} fontSecondary mbSmall>
            They just.. get me
          </Title>
          <CardFeedbackBodyText>
            I filled out the quiz and they sent me 4 bottles of wine... AND THEY WERE ALL PERFECT! They
            understand my palate better than I do. The price points are great, the special gifts and of course
            the rewards point system has me hooked. Also a shoutout to their customer service team - super
            quick responses and feels like I’m talking with a human, not a pre-written response machine!
          </CardFeedbackBodyText>
          <CardFeedbackReadMore to={urlPatterns.REVIEWS}>Read more</CardFeedbackReadMore>
          <CardFeedbackAuthor>09/06/21 by Natalie</CardFeedbackAuthor>
        </CardFeedback>

        <CardFeedback>
          <FeedbackRating rating={5} />
          <Title tag={5} fontSecondary mbSmall>
            The most amazing wine subscription program for any wine lover!
          </Title>
          <CardFeedbackBodyText>
            It doesn’t matter if you are new to wine or have always loved and known your wines. Good Pair Days
            is all about sage, fun, quality, and discovery. Finding those wines, you never thought of trying,
            rating them, learning, having fun, and even caring for the environment. If you are a wine lover
            like myself, I would look no further!
          </CardFeedbackBodyText>
          <CardFeedbackReadMore to={urlPatterns.REVIEWS}>Read more</CardFeedbackReadMore>
          <CardFeedbackAuthor>10/05/21 by Adriana R.</CardFeedbackAuthor>
        </CardFeedback>

        <CardFeedback>
          <FeedbackRating rating={5} />
          <Title tag={5} fontSecondary mbSmall>
            Great wine, excellent customer service
          </Title>
          <CardFeedbackBodyText>
            I’m now on my 4th box with Good Pair Days and I’ve only had one bottle that didn’t meet
            expectation. With that one bottle the team reached out and asked for a bit more explanation. They
            then sent out a replacement bottle as a gesture of goodwill in my next box. Great work team!
          </CardFeedbackBodyText>
          <CardFeedbackReadMore to={urlPatterns.REVIEWS}>Read more</CardFeedbackReadMore>
          <CardFeedbackAuthor>20/05/21 by Claire W.</CardFeedbackAuthor>
        </CardFeedback>
      </FeedbackGallery>

      <StyledPoweredByMobile>
        Powered by
        <StyledPoweredByLogo src={poweredByLogo} alt="poweredByLogo" />
      </StyledPoweredByMobile>

      {showImage && (
        <DisplayNoneSmUp>
          <AlignCenter>
            <LazyLoad once offset={500} height={145}>
              <ImageBath alt="Good Pair Days by drinking wine in the bathtub" src={homeBathtub} maxFull />
            </LazyLoad>
          </AlignCenter>
        </DisplayNoneSmUp>
      )}
    </Container>
  );
};

ReviewsWidget.propTypes = {
  showImage: PropTypes.bool,
  isBeerLandingPage: PropTypes.bool,
};

ReviewsWidget.defaultProps = {
  showImage: false,
  isBeerLandingPage: false,
};

export default ReviewsWidget;
