import { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**
 * Component used with react-router-dom that scrolls to the top when navigating to a different page.
 */
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const { props } = this;
    const currentLocation = props.location;
    if (currentLocation.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ScrollToTop.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(ScrollToTop);
