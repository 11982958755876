import React from "react";
import { useQuery } from "@apollo/client";
import { GET_MEMBER_POINTS_BALANCE } from "../../../graphql/resolvers/member";
import { FETCH_POLICY_CACHE_AND_NETWORK } from "../../../helpers/constants";
import BoxCartHeaderToggle from "../../atoms/BoxCartHeaderToggle";
import MemberPointsBalance from "../../atoms/MemberPointsBalance";
import { MemberPointsBalanceInfo } from "../../../types/__generated__/MemberPointsBalanceInfo";
import URL_PATTERNS from "../../../urls";
import { isLoggedIn } from "../../../helpers/auth";
import {
  BoxCartHeaderTogglesWrapper,
  BoxCartHeaderTogglesInnerWrapper,
  BackButton,
  BoxCartHeaderTablet,
  HideOnSmUp,
} from "./styles";

interface BoxCartHeaderTogglesProps {
  onToggleClick: (isMonthlyBox: boolean) => void;
  onCloseTray: () => void;
  isTrayOpen: boolean;
  isMonthlyBoxSelected: boolean;
  boxAmountBottles: number;
  boxAmountPresents: number;
  cartAmountBottles: number;
  cartAmountPresents: number;
}

const BoxCartHeaderToggles = ({
  onToggleClick,
  onCloseTray,
  isTrayOpen,
  isMonthlyBoxSelected,
  boxAmountBottles,
  boxAmountPresents,
  cartAmountBottles,
  cartAmountPresents,
}: BoxCartHeaderTogglesProps) => {
  const { data: memberPointsBalance } = useQuery<MemberPointsBalanceInfo>(GET_MEMBER_POINTS_BALANCE, {
    fetchPolicy: FETCH_POLICY_CACHE_AND_NETWORK,
  });
  const isUserLoggedIn = isLoggedIn();
  const points = memberPointsBalance?.me?.pointsBalance;
  return (
    <BoxCartHeaderTogglesWrapper isMonthlyBoxSelected={isMonthlyBoxSelected} isTrayOpen={isTrayOpen}>
      {isTrayOpen && <BackButton onClick={() => onCloseTray()} isMonthlyBox={isMonthlyBoxSelected} />}
      <BoxCartHeaderTogglesInnerWrapper>
        <BoxCartHeaderTablet isMonthlyBox={isMonthlyBoxSelected} />
        <BoxCartHeaderToggle
          isMonthlyBox
          isSelected={isMonthlyBoxSelected}
          isOpen={isTrayOpen}
          onToggleClick={() => onToggleClick(true)}
          amountBottles={boxAmountBottles}
          amountPresents={boxAmountPresents}
        />
        <BoxCartHeaderToggle
          isMonthlyBox={false}
          isSelected={!isMonthlyBoxSelected}
          isOpen={isTrayOpen}
          onToggleClick={() => onToggleClick(false)}
          amountBottles={cartAmountBottles}
          amountPresents={cartAmountPresents}
        />
      </BoxCartHeaderTogglesInnerWrapper>
      <HideOnSmUp>
        {isUserLoggedIn && (
          <MemberPointsBalance points={points} variant="header" link={URL_PATTERNS.MY_PROGRESS} />
        )}
      </HideOnSmUp>
    </BoxCartHeaderTogglesWrapper>
  );
};
export default BoxCartHeaderToggles;
