import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { colors } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";

const StyledBoxTrayHolder = styled.div`
  height: 100%;
  background-color: ${colors.peach};
  ${breakpoints.smUp} {
    width: 420px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }

  ${breakpoints.smDown} {
    // 186px is the height of the header, mobile bottom section, and snackbar
    height: calc(100dvh - 186px);
  }
`;

const BoxTrayHolder = (props) => {
  const { children } = props;

  return <StyledBoxTrayHolder>{children}</StyledBoxTrayHolder>;
};

BoxTrayHolder.propTypes = {
  children: PropTypes.node,
};

BoxTrayHolder.defaultProps = {
  children: "",
};

export default BoxTrayHolder;
