import styled from "styled-components";

import breakpoints from "../../../styles/breakpoints";

import { colors } from "../../../styles/variables";

const Section = styled.section<{ xs?: boolean; xl?: boolean; xxs?: boolean; bgColor?: string }>`
  position: relative;
  padding: ${(props) => (props.xs && "40px 0") || (props.xl && "160px 0") || (props.xxs && "0") || "80px 0"};
  background-color: ${(props) => props.bgColor && props.bgColor};
  ${breakpoints.smDown} {
    padding: ${(props) => (props.xxs && "0") || "50px 0"};
  }
`;

const SectionPeach = styled(Section)`
  background-color: ${colors.peach};
`;

const SectionPeachTint = styled(Section)`
  background-color: ${colors.peachTint};
`;

const SectionBlue = styled(Section)`
  background-color: ${colors.blue};
`;

export { Section, SectionPeach, SectionPeachTint, SectionBlue };
