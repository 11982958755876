import { InMemoryCache } from "@apollo/client/cache";

/**
 * merge: true, Short for options.mergeObjects(existing, incoming).
 * merge: false, Short for always preferring incoming over existing data. (default behaviour)
 * More info: https://www.notion.so/goodpairdays/Upgrade-to-Apollo-client-v3-e435b2989a7d46bf8d75ad4d3b81d944
 * */
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        referralDiscount: {
          merge: true,
        },
        localShoppingCart: {
          merge: true,
        },
        auth: {
          merge: true,
        },
        guestPromoCodeDiscount: {
          merge: true,
        },
        guestFreeBoxCampaignDiscount: {
          merge: true,
        },
        giftFlow: {
          merge: true,
        },
        allWinesByWineList: {
          // all filter fields from graphql query except offset and limit which is for pagination
          keyArgs: [
            "memberId",
            "wineListId",
            "wineListSlug",
            "name",
            "wineBodyId",
            "wineAcidityId",
            "wineFruitDrivenId",
            "winePriceRangeId",
            "winePriceRangeIds",
            "wineProductionMethodId",
            "wineProductionMethodIds",
            "wineClassId",
            "wineClassIds",
            "countryId",
            "countryIds",
            "wineRegionId",
            "wineRegionIds",
            "wineTypeId",
            "wineTypeIds",
            "wineSweetnessId",
            "wineTanninId",
            "availableOnly",
            "orderBy",
          ],

          // if offset exists and > 0, it will keep expanding current list, if offset becomes zero,
          // means filters have changed, so start a new result list
          merge: (existing = [], incoming, { args }) => (args.offset ? [...existing, ...incoming] : incoming),
        },
        allSpecialPackEditions: {
          keyArgs: ["availableOnly"],
          merge: (existing = [], incoming, { args }) => (args.offset ? [...existing, ...incoming] : incoming),
        },
        memberOrderItemsWithRating: {
          keyArgs: [
            "memberId",
            "winePriceRangeIds",
            "wineClassIds",
            "wineProductionMethodIds",
            "countryIds",
            "wineRegionIds",
            "wineTypeIds",
            "wineBodyId",
            "wineAcidityId",
            "wineFruitDrivenId",
            "wineSweetnessId",
            "wineTanninId",
            "orderBy",
            "hasDrunk",
          ],
          merge: (existing = [], incoming, { args }) => (args.offset ? [...existing, ...incoming] : incoming),
        },
        memberBadgesByCategory: {
          keyArgs: ["memberId", "badgeCategoryId"],
          merge: (existing = [], incoming, { args }) => (args.offset ? [...existing, ...incoming] : incoming),
        },
        badgesByCategory: {
          keyArgs: ["badgeStatus", "badgeCategoryId"],
          merge: (existing = [], incoming, { args }) => (args.offset ? [...existing, ...incoming] : incoming),
        },
        collections: {
          keyArgs: ["isShownInMenu"],
          merge: (existing = [], incoming, { args }) => (args.offset ? [...existing, ...incoming] : incoming),
        },
      },
    },

    // Apollo will throw a warning if there is no unique id in the cache object and not sure what to do.
    // for example, the array objects in cache like shoppingcartitemSet needs explicit declaration otherwise
    // it will throw a warning to declare a custom merge function.
    SubscriptionObject: {
      fields: {
        subscriptionwineSet: { merge: false },
        freebieCredits: { merge: false },
        freeBottleCredits: { merge: false },
      },
    },
    ShoppingCartObject: {
      fields: {
        shoppingcartitemSet: { merge: false },
      },
    },
    MemberObject: {
      fields: {
        winequantitySet: { merge: false },
        paymentmethodSet: { merge: false },
        wineblacklistSet: { merge: false },
        contactpreferenceSet: { merge: false },
        quizAnswers: { merge: false },
      },
    },
    WineListObject: {
      fields: {
        wines: { merge: false },
      },
    },
    MemberCellarObject: {
      fields: {
        productsCount: { merge: false },
        products: {
          keyArgs: false,
          merge: (existing = [], incoming, { args }) => (args.offset ? [...existing, ...incoming] : incoming),
        },
      },
    },
    CollectionObject: {
      fields: {
        products: {
          keyArgs: false,
          merge: (existing = [], incoming, { args }) => (args.offset ? [...existing, ...incoming] : incoming),
        },
      },
    },
  },
});

export default cache;
