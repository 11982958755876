import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled, { keyframes, css } from "styled-components";
import StyledLoading from "../../atoms/Loading/StyledLoading";
import { colors } from "../../../styles/variables";

const LogIn = React.lazy(() => import("../../../pages/LogIn"));

const sheetAppearing = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const sheetDisappearing = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(200%);
  }
`;

const PanelContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.blue};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  animation: ${(props) =>
    props.startAppearAnimate
      ? css`
          ${sheetAppearing} .5s ease
        `
      : css`
          ${sheetDisappearing} .5s ease
        `};
  overflow-y: scroll;
`;

class LogInPanel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      startAppearAnimate: true,
    };
  }

  /**
   * Sets a timeout so the panel can animate while disappearing.
   * */
  handleClosePanel = () => {
    const { loginPanelHandler } = this.props;
    this.setState({ startAppearAnimate: false });
    setTimeout(() => loginPanelHandler(false), 300);
  };

  render() {
    const { location } = this.props;
    const { startAppearAnimate } = this.state;

    return (
      <PanelContainer startAppearAnimate={startAppearAnimate}>
        <React.Suspense fallback={<StyledLoading />}>
          <LogIn location={location} handleClosePanel={() => this.handleClosePanel()} />
        </React.Suspense>
      </PanelContainer>
    );
  }
}

LogInPanel.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string,
      quiz: PropTypes.bool,
      quizData: PropTypes.shape({}),
      isShoppingCart: PropTypes.bool,
      isGiftRedeem: PropTypes.bool,
    }),
  }),
  loginPanelHandler: PropTypes.func.isRequired,
};

LogInPanel.defaultProps = {
  location: {
    state: {},
  },
};

export default LogInPanel;
