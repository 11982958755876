import React from "react";
import styled from "styled-components";

import PropTypes from "prop-types";
import starFill from "../../../assets/images/icons/start-fill.svg";
import starEmpty from "../../../assets/images/icons/start-fill-white.svg";

const ReviewsRatingItem = styled.span`
  display: inline-block;
  margin: 0px 2px;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
`;

const ReviewsRatingItemFill = styled(ReviewsRatingItem)`
  background-image: url(${starFill});
`;

const ReviewsRatingItemEmpty = styled(ReviewsRatingItem)`
  background-image: url(${starEmpty});
`;

const ReviewsRating = (props) => {
  const { center, rating } = props;
  const ReviewsRatingList = styled.div`
    width: 100%;
    text-align: ${center && "center"};
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  `;
  if (rating === 5.5) {
    return (
      <ReviewsRatingList>
        <ReviewsRatingItemFill />
        <ReviewsRatingItemFill />
        <ReviewsRatingItemFill />
        <ReviewsRatingItemFill />
        <ReviewsRatingItemFill />
      </ReviewsRatingList>
    );
  }
  if (rating === 4.5) {
    return (
      <ReviewsRatingList>
        <ReviewsRatingItemFill />
        <ReviewsRatingItemFill />
        <ReviewsRatingItemFill />
        <ReviewsRatingItemFill />
        <ReviewsRatingItemEmpty />
      </ReviewsRatingList>
    );
  }
  if (rating === 3.5) {
    return (
      <ReviewsRatingList>
        <ReviewsRatingItemFill />
        <ReviewsRatingItemFill />
        <ReviewsRatingItemFill />
        <ReviewsRatingItemEmpty />
        <ReviewsRatingItemEmpty />
      </ReviewsRatingList>
    );
  }
  if (rating === 2.5) {
    return (
      <ReviewsRatingList>
        <ReviewsRatingItemFill />
        <ReviewsRatingItemFill />
        <ReviewsRatingItemEmpty />
        <ReviewsRatingItemEmpty />
        <ReviewsRatingItemEmpty />
      </ReviewsRatingList>
    );
  }
  if (rating === 1.5) {
    return (
      <ReviewsRatingList>
        <ReviewsRatingItemFill />
        <ReviewsRatingItemEmpty />
        <ReviewsRatingItemEmpty />
        <ReviewsRatingItemEmpty />
        <ReviewsRatingItemEmpty />
      </ReviewsRatingList>
    );
  }
  if (rating === 0.5) {
    return (
      <ReviewsRatingList>
        <ReviewsRatingItemEmpty />
        <ReviewsRatingItemEmpty />
        <ReviewsRatingItemEmpty />
        <ReviewsRatingItemEmpty />
        <ReviewsRatingItemEmpty />
      </ReviewsRatingList>
    );
  }
  return (
    <ReviewsRatingList>
      <ReviewsRatingItemFill />
      <ReviewsRatingItemFill />
      <ReviewsRatingItemFill />
      <ReviewsRatingItemFill />
      <ReviewsRatingItemFill />
    </ReviewsRatingList>
  );
};

ReviewsRating.propTypes = {
  center: PropTypes.bool,
  rating: PropTypes.number,
};

ReviewsRating.defaultProps = {
  center: false,
  rating: null,
};

export default ReviewsRating;
