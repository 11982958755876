// TODO: [PRO-320] Implement usage of unused variables
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFlag } from "@unleash/proxy-client-react";

import {
  I18N_NAMESPACES,
  NAVIGATION_GIFTS,
  NAVIGATION_LEARN,
  NAVIGATION_WINES,
  NAVIGATION_REWARDS,
  UNLEASH_NEW_PRODUCT_CATEGORIES,
} from "../../../helpers/constants";
import { useWindowWidthSize } from "../../../helpers/hooks";
import { detectActiveNavbarItem } from "../../../helpers/tools";
import { StyledHeaderMenuList, MenuItemsRow, StyledHeaderMenuItem, LineBelowMenuItem } from "./style";

interface DesktopHeaderNavbarProps {
  setActiveMenuItem: Function;
  activeMenuItem: string;
}

const DesktopHeaderNavbar = ({ setActiveMenuItem, activeMenuItem }: DesktopHeaderNavbarProps) => {
  const { t } = useTranslation([I18N_NAMESPACES.TOP_NAVBAR, I18N_NAMESPACES.COMMON]);
  const history = useHistory();
  const [showLineAsWhite, setShowLineAsWhite] = useState<boolean>(false);
  const [lineWidth, setLineWidth] = useState<number>();
  const [lineOffsetLeft, setLineOffsetLeft] = useState<number>();
  const selectedMenuItemRef = useRef<HTMLDivElement>(null);
  const windowWidth: number = useWindowWidthSize();
  const isNewProductCategoriesEnabled = useFlag(UNLEASH_NEW_PRODUCT_CATEGORIES);

  const isWinesMenuItemSelected = !!(activeMenuItem === NAVIGATION_WINES);
  const isLearnMenuItemSelected = !!(activeMenuItem === NAVIGATION_LEARN);
  const isGiftsMenuItemSelected = !!(activeMenuItem === NAVIGATION_GIFTS);
  const isRewardsMenuItemSelected = !!(activeMenuItem === NAVIGATION_REWARDS);
  const shouldShowLineBelowMenuItem =
    isWinesMenuItemSelected ||
    isLearnMenuItemSelected ||
    isGiftsMenuItemSelected ||
    isRewardsMenuItemSelected;

  const activeNavbarItem = useMemo(
    () => detectActiveNavbarItem(history?.location?.pathname),
    [history?.location?.pathname]
  );

  useEffect(() => {
    if (!activeMenuItem) {
      return;
    }
    const { current } = selectedMenuItemRef;
    setLineWidth(current?.offsetWidth);
    setLineOffsetLeft(current?.offsetLeft);
  }, [activeMenuItem, windowWidth]);

  const onMenuItemClick = (clickedMenuItem: string) => {
    if (activeMenuItem === clickedMenuItem) {
      setActiveMenuItem("");
      return;
    }
    setShowLineAsWhite(true);
    setActiveMenuItem(clickedMenuItem);
  };

  return (
    <StyledHeaderMenuList>
      <MenuItemsRow shouldAdjustTopMargin={shouldShowLineBelowMenuItem}>
        <StyledHeaderMenuItem
          onClick={() => onMenuItemClick(NAVIGATION_WINES)}
          onMouseEnter={() => setShowLineAsWhite(isWinesMenuItemSelected)}
          onMouseLeave={() => setShowLineAsWhite(false)}
          shouldBeWhite={activeNavbarItem === NAVIGATION_WINES && !isWinesMenuItemSelected}
          ref={isWinesMenuItemSelected ? selectedMenuItemRef : null}
        >
          {isNewProductCategoriesEnabled ? t("topNavbar:winesAndMore") : t("topNavbar:wines")}
        </StyledHeaderMenuItem>
        <StyledHeaderMenuItem
          onClick={() => onMenuItemClick(NAVIGATION_LEARN)}
          onMouseEnter={() => setShowLineAsWhite(isLearnMenuItemSelected)}
          onMouseLeave={() => setShowLineAsWhite(false)}
          shouldBeWhite={activeNavbarItem === NAVIGATION_LEARN && !isLearnMenuItemSelected}
          ref={isLearnMenuItemSelected ? selectedMenuItemRef : null}
        >
          {t("topNavbar:learn")}
        </StyledHeaderMenuItem>
        <StyledHeaderMenuItem
          onClick={() => onMenuItemClick(NAVIGATION_GIFTS)}
          onMouseEnter={() => setShowLineAsWhite(isGiftsMenuItemSelected)}
          onMouseLeave={() => setShowLineAsWhite(false)}
          shouldBeWhite={activeNavbarItem === NAVIGATION_GIFTS && !isGiftsMenuItemSelected}
          ref={isGiftsMenuItemSelected ? selectedMenuItemRef : null}
        >
          {t("common:gifts")}
        </StyledHeaderMenuItem>
        <StyledHeaderMenuItem
          onClick={() => onMenuItemClick(NAVIGATION_REWARDS)}
          onMouseEnter={() => setShowLineAsWhite(isRewardsMenuItemSelected)}
          onMouseLeave={() => setShowLineAsWhite(false)}
          shouldBeWhite={activeNavbarItem === NAVIGATION_REWARDS && !isRewardsMenuItemSelected}
          ref={isRewardsMenuItemSelected ? selectedMenuItemRef : null}
        >
          {t("topNavbar:rewards")}
        </StyledHeaderMenuItem>
      </MenuItemsRow>
      {shouldShowLineBelowMenuItem && (
        <LineBelowMenuItem width={lineWidth} left={lineOffsetLeft} showLineAsWhite={showLineAsWhite} />
      )}
    </StyledHeaderMenuList>
  );
};

export default DesktopHeaderNavbar;
