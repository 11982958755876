import React from "react";
import { css } from "styled-components";

import Loading from ".";

const loadingStyle = css`
  min-width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoading = () => <Loading style={loadingStyle} />;

export default StyledLoading;
