import styled, { keyframes } from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import ScrollLock, { TouchScrollable } from "react-scrolllock";
import { colors, header, animationDurations } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";

const StyledSideTrayHolder = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6001;
  height: 100vh;
  width: ${(props) => (props.isOpened ? props.width : "0px")};
  ${breakpoints.smDown} {
    width: ${(props) => (props.isOpened ? "100%" : "0px")};
    overflow-y: auto;
  }
  ${breakpoints.smUp} {
    top: ${header.desktopHeight};
  }
`;

const Backdrop = styled.div`
  ${breakpoints.smUp} {
    content: "";
    position: fixed;
    top: ${header.mobileHeight};
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: ${colors.black};
    visibility: ${(props) => (props.isOpened ? "visible" : "hidden")};
    opacity: ${(props) => (props.isOpened ? "0.5" : "0")};
    cursor: ${(props) => (props.haveOnClickListener ? "pointer" : "unset")};
  }
  ${breakpoints.mdUp} {
    top: ${header.desktopHeight};
  }
`;

const sheetApearing = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const sheetDisappearing = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

const StyledSideTrayContent = styled.div`
  ${breakpoints.smUp} {
    width: ${(props) => props.width};
    position: fixed;
    top: ${header.mobileHeight};
  }
  position: static;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${(props) => props.color};
  display: ${(props) => (props.isOpened ? "block" : "none")};
  ${breakpoints.mdUp} {
    top: ${header.desktopHeight};
  }
`;

const AnimatingSideTrayContent = styled(StyledSideTrayContent)`
  animation: ${(props) => (!props.isOpened ? sheetDisappearing : sheetApearing)};
  animation-duration: ${animationDurations.sideTrayInSeconds}s;
`;

const SideTray = (props) => {
  const { children, isOpened, color, className, onClickBackdrop, shouldAnimate, variant } = props;
  const isLarge = variant === "large";
  const trayWidth = isLarge ? "700px" : "500px";

  return (
    <StyledSideTrayHolder isOpened={isOpened} width={trayWidth}>
      <Backdrop
        isOpened={isOpened}
        haveOnClickListener={!!onClickBackdrop}
        onClick={() => onClickBackdrop && onClickBackdrop()}
      />
      <ScrollLock isActive={isOpened}>
        <TouchScrollable>
          {shouldAnimate ? (
            <AnimatingSideTrayContent
              className={className}
              isOpened={isOpened}
              color={color}
              width={trayWidth}
            >
              {children}
            </AnimatingSideTrayContent>
          ) : (
            <StyledSideTrayContent className={className} isOpened={isOpened} color={color} width={trayWidth}>
              {children}
            </StyledSideTrayContent>
          )}
        </TouchScrollable>
      </ScrollLock>
    </StyledSideTrayHolder>
  );
};

SideTray.propTypes = {
  children: PropTypes.node.isRequired,
  isOpened: PropTypes.bool.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  onClickBackdrop: PropTypes.func,
  shouldAnimate: PropTypes.bool,
  variant: PropTypes.oneOf(["default", "large"]),
};

SideTray.defaultProps = {
  color: colors.blue,
  className: undefined,
  onClickBackdrop: () => {},
  shouldAnimate: false,
  variant: "default",
};

export default SideTray;
