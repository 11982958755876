import styled from "styled-components";
import giftsDropdownWebpImage from "../../../assets/images/general/gifts-dropdown.webp";
import giftsDropdownImage from "../../../assets/images/general/gifts-dropdown.png";
import Image from "../../atoms/Image";
import { ReactComponent as Gift } from "../../../assets/images/icons/gift-transparent.svg";
import { colors } from "../../../styles/variables";

export const GiftsDropdownWrapper = styled.div<{
  isAnyDropdownActive: boolean;
}>`
  display: ${(props) => (props.isAnyDropdownActive ? "flex" : "none")};
  height: 100%;
  flex-direction: row;
`;

export const GiftsDropdownImage = styled(Image).attrs({
  src: giftsDropdownImage,
  webpSrc: giftsDropdownWebpImage,
  containerStyle: { display: "contents" },
})`
  max-width: 30%;
  object-fit: cover;
  height: 280px;
`;

export const GiftIcon = styled(Gift)`
  margin-top: -4px;
  margin-right: 18px;
  height: 32px !important;
  width: 32px;
  stroke: ${colors.black};
  stroke-width: 1.33px;
`;
