const BLOG = "https://blog.goodpairdays.com";

export default {
  BLOG,
  FAQ: "https://help.goodpairdays.com",
  SHIPPING_INFO: "https://help.goodpairdays.com/en-US/articles/shipping-delivery-103203",
  GOOGLE_MAPS_DIRECTIONS: "https://goo.gl/maps/j3DozABYLjpSGC4y9",
  JOBS: "https://blog.goodpairdays.com/tag/wineroles",
  WINE_101: "https://blog.goodpairdays.com/wine-101-e-course/",
  INSTAGRAM: "https://www.instagram.com/goodpairdays/",
  YOU_TUBE: "https://www.youtube.com/channel/UC0OdDoYozCJIg-U7xjJvaPg",
  FACEBOOK: "https://www.facebook.com/goodpairdays",
  FACEBOOK_GROUP_AU: "https://www.facebook.com/groups/1064928004891745/",
  FACEBOOK_GROUP_UK: "https://www.facebook.com/groups/3248521402113527/",
  FEEDBACK: "https://thewinegallery.typeform.com/to/W7WaSXLG",
  YOUTUBE: "https://www.youtube.com/c/GoodPairDays",
};

export const BLOG_URLS = {
  RECIPES_AND_PAIRINGS: `${BLOG}/tag/recipes-pairings`,
  WINE_TIPS: `${BLOG}/tag/wine-tips-education`,
  STORIES_AND_LIFESTYLE: `${BLOG}/tag/stories-lifestyle`,
};
