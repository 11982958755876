import { toast } from "react-toastify";

/**
 * Shows a popup with an error message.
 *
 * @param message
 * @param options
 */
export const showErrorAlert = (message, options = {}) => {
  toast.error(message, options);
};

/**
 * Shows a popup with a success message.
 *
 * @param message
 * @param options
 */
export const showSuccessAlert = (message, options = {}) => {
  toast.success(message, options);
};

/**
 * Shows a popup with an info message.
 *
 * @param message
 * @param options
 */
export const showInfoAlert = (message, options = {}) => {
  toast.info(message, options);
};

/**
 * Shows a popup with a custom info component.
 *
 * @param {Component} customComponent
 * @param {Object} options
 */
export const showInfoAlertWithComponent = (customComponent, options) => {
  const toastId = toast.info("", options);
  toast.update(toastId, {
    type: toast.TYPE.INFO,
    render: customComponent,
  });
};
