import React from "react";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACES } from "../../../helpers/constants";
import { getCashAmountLabel } from "../../../helpers/i18n";
import { BoxAndCartTrayMobileBottomSectionWrapper, CloseIconAndStringWrapper, CloseIcon } from "./styles";

interface BoxAndCartTrayMobileBottomSectionProps {
  onCloseTray: () => void;
  isBox: boolean;
  price: number;
}

const BoxAndCartTrayMobileBottomSection = ({
  onCloseTray,
  isBox,
  price,
}: BoxAndCartTrayMobileBottomSectionProps) => {
  const { t } = useTranslation([I18N_NAMESPACES.COMMON]);
  return (
    <BoxAndCartTrayMobileBottomSectionWrapper onClick={onCloseTray}>
      <CloseIconAndStringWrapper>
        <CloseIcon />
        {isBox ? t("common:closeMonthlyBox") : t("common:closeOneOffBox")}
      </CloseIconAndStringWrapper>
      <span>{getCashAmountLabel(price)}</span>
    </BoxAndCartTrayMobileBottomSectionWrapper>
  );
};

export default BoxAndCartTrayMobileBottomSection;
