import styled from "styled-components";
import rewardsDropdownWepbImage from "../../../assets/images/general/rewards-dropdown.webp";
import rewardsDropdownImage from "../../../assets/images/general/rewards-dropdown.png";
import rewardIcon from "../../../assets/images/icons/rewards-transparent.svg";
import Image from "../../atoms/Image";
import { ColumnTitleIcon } from "../../organisms/Header/style";

export const DropdownWrapper = styled.div<{ isAnyDropdownActive: boolean }>`
  display: ${(props) => (props.isAnyDropdownActive ? "flex" : "none")};
  height: 100%;
  flex-direction: row;
`;

export const DropdownImage = styled(Image).attrs({
  src: rewardsDropdownImage,
  webpSrc: rewardsDropdownWepbImage,
  containerStyle: { display: "contents" },
})`
  max-width: 30%;
  object-fit: cover;
  height: 280px;
`;

export const Icon = styled(ColumnTitleIcon).attrs({ src: rewardIcon })`
  height: 100%;
`;
