import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { isLoggedIn } from "../../../helpers/auth";
import {
  I18N_NAMESPACES,
  MOBILE_NAVBAR_ACCORDIONS,
  DB_ID_SUBSCRIPTION_STATUS_ACTIVE,
  DB_ID_SUBSCRIPTION_STATUS_SKIP,
  FETCH_POLICY_CACHE_FIRST,
} from "../../../helpers/constants";
import { NAVBAR_MOBILE_ABOUT_ITEMS } from "../../../helpers/navbarProcessor";
import { logError } from "../../../helpers/logging";
import { NAVBAR_ABOUT_HEIGHTS, NAVBAR_ABOUT_LOGGED_IN_STATE_HEIGHTS } from "../../../styles/variables";
import { GET_MEMBER_DASHBOARD_BASIC_INFO } from "../../../graphql/resolvers/member";
import AccordionItem from "../AccordionItem";
import {
  AccordionTopPart,
  StyledChevron,
  AccordionWrapper,
  AccordionContentWrapper,
} from "../MobileHeaderNavbar/style";

interface MobileAboutAccordionProps {
  openAccordion: string | undefined;
  setOpenAccordion: Function;
}

const MobileAboutAccordion = ({
  openAccordion,
  setOpenAccordion,
}: MobileAboutAccordionProps): ReactElement => {
  const { data: memberInfo, error: memberInfoQueryError } = useQuery(GET_MEMBER_DASHBOARD_BASIC_INFO, {
    fetchPolicy: FETCH_POLICY_CACHE_FIRST,
  });

  if (memberInfoQueryError) {
    logError(memberInfoQueryError, {
      location: "MobileAboutAccordion",
      action: "GET_MEMBER_DASHBOARD_BASIC_INFO",
    });
  }

  const isMemberNotActivated =
    !memberInfo?.me?.subscription ||
    ![DB_ID_SUBSCRIPTION_STATUS_SKIP, DB_ID_SUBSCRIPTION_STATUS_ACTIVE].includes(
      memberInfo?.me?.subscription.subscriptionStatus?.id
    );

  const isThisAccordionOpen = !!(openAccordion === MOBILE_NAVBAR_ACCORDIONS.ABOUT);
  const loggedInStateItems = () =>
    isMemberNotActivated ? NAVBAR_MOBILE_ABOUT_ITEMS.unsubscribed : NAVBAR_MOBILE_ABOUT_ITEMS.loggedIn;
  const itemOrder = isLoggedIn() ? loggedInStateItems() : NAVBAR_MOBILE_ABOUT_ITEMS.loggedOut;
  const navbarHeight = isLoggedIn() ? NAVBAR_ABOUT_LOGGED_IN_STATE_HEIGHTS : NAVBAR_ABOUT_HEIGHTS;
  const { t } = useTranslation([I18N_NAMESPACES.TOP_NAVBAR]);

  return (
    <AccordionWrapper
      height={navbarHeight.full}
      open={isThisAccordionOpen}
      onClick={() => setOpenAccordion(isThisAccordionOpen ? "" : MOBILE_NAVBAR_ACCORDIONS.ABOUT)}
    >
      <AccordionTopPart isThisAccordionOpen={isThisAccordionOpen}>
        {t("topNavbar:about")}
        <StyledChevron open={isThisAccordionOpen} />
      </AccordionTopPart>
      <AccordionContentWrapper open={isThisAccordionOpen} height={navbarHeight.accordion} isFlexDirectionRow>
        {itemOrder.map((item) => (
          <AccordionItem
            i18nKey={item.i18nKey}
            url={item.url}
            icon={item.icon}
            isFlexDirectionRow
            key={item.i18nKey}
          />
        ))}
      </AccordionContentWrapper>
    </AccordionWrapper>
  );
};

export default MobileAboutAccordion;
