import React from "react";
import PropTypes from "prop-types";

import OffCanvasTray from "../../organisms/OffCanvasTray";

import StyledBoxTray from "../../atoms/MyOrder/StyledBoxTray";
import CloseButton from "../../atoms/MyOrder/CloseButton";
import closeIcon from "../../../assets/images/icons/cross.svg";
import { colors } from "../../../styles/variables";
import { extractNumberFromString } from "../../../helpers/tools";

import { Title, StyledContainer, ModalDiv } from "./styles";

const ModalContainer = (props) => {
  const {
    isOpened,
    outsideClickHandler,
    isScrollLockEnabled,
    title,
    titleStyle,
    children,
    trayModal,
    nonTrayModalWidth,
    isOffCanvasAtAbsoluteTop,
    fullViewMask,
    fullCenter,
    borderRadius,
    backgroundColor,
    modalSidePadding,
    isModalMdUpHeightAuto,
  } = props;

  const windowWidth = extractNumberFromString(`${window.innerWidth}`);
  const leftPosition = (windowWidth - nonTrayModalWidth) / 2; // <ModalDiv> is 320px wide by default.

  return (
    <OffCanvasTray
      setOverflowHidden={false}
      isOffcanvasOpened={isOpened}
      outSideClickHandler={outsideClickHandler}
      isScrollLockEnabled={isScrollLockEnabled}
      isInBoxAndCartTray={isOffCanvasAtAbsoluteTop}
      fullViewMask={fullViewMask}
    >
      {trayModal ? (
        <StyledBoxTray
          backgroundColor={backgroundColor}
          overflowY="auto"
          isMdUpHeightAuto={isModalMdUpHeightAuto}
          sidePadding={modalSidePadding}
        >
          <StyledContainer>
            <CloseButton onClick={outsideClickHandler} src={closeIcon} alt="Close" />
            {title && <Title css={titleStyle}>{title}</Title>}
            {children}
          </StyledContainer>
        </StyledBoxTray>
      ) : (
        <ModalDiv
          leftPosition={leftPosition}
          width={`${nonTrayModalWidth}`}
          borderRadius={borderRadius}
          backgroundColor={backgroundColor}
          fullCenter={fullCenter}
        >
          {children}
        </ModalDiv>
      )}
    </OffCanvasTray>
  );
};

ModalContainer.propTypes = {
  isOpened: PropTypes.bool,
  isScrollLockEnabled: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  titleStyle: PropTypes.arrayOf(PropTypes.string),
  outsideClickHandler: PropTypes.func.isRequired,
  trayModal: PropTypes.bool,
  nonTrayModalWidth: PropTypes.number,
  isOffCanvasAtAbsoluteTop: PropTypes.bool,
  fullViewMask: PropTypes.bool,
  fullCenter: PropTypes.bool,
  borderRadius: PropTypes.bool,
  backgroundColor: PropTypes.string,
  modalSidePadding: PropTypes.number,
  isModalMdUpHeightAuto: PropTypes.bool,
};

ModalContainer.defaultProps = {
  isOpened: false,
  isScrollLockEnabled: false,
  children: null,
  title: null,
  titleStyle: [],
  trayModal: true,
  nonTrayModalWidth: 320,
  isOffCanvasAtAbsoluteTop: false,
  fullViewMask: false,
  fullCenter: false,
  modalSidePadding: 15,
  borderRadius: true,
  backgroundColor: colors.white,
  isModalMdUpHeightAuto: false,
};

export default ModalContainer;
