import React from "react";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACES } from "../../../helpers/constants";
import BoxCartToggleIcons from "../BoxCartToggleIcons";
import { BoxCartSideToggleWrapper, Title } from "./styles";

interface BoxCartSideToggleProps {
  isMonthlyBox: boolean;
  isSelected: boolean;
  onToggleClick: () => void;
  amountBottles: number;
  amountPresents: number;
}

const BoxCartSideToggle = ({
  isMonthlyBox,
  isSelected,
  onToggleClick,
  amountBottles,
  amountPresents,
}: BoxCartSideToggleProps) => {
  const { t } = useTranslation([I18N_NAMESPACES.COMMON]);
  return (
    <BoxCartSideToggleWrapper isSelected={isSelected} isMonthlyBox={isMonthlyBox} onClick={onToggleClick}>
      <Title>{isMonthlyBox ? t("common:monthlyBox") : t("common:oneOffBox")}</Title>
      <BoxCartToggleIcons
        variant={"side"}
        isSelected={isSelected}
        amountBottles={amountBottles}
        amountPresents={amountPresents}
      />
    </BoxCartSideToggleWrapper>
  );
};

export default BoxCartSideToggle;
