import styled from "styled-components";

import breakpoints from "../../../styles/breakpoints";
import { colors, fonts } from "../../../styles/variables";

export const StyledHeaderUserAvatarHolder = styled.div<{
  isMemberDropDownOpen: boolean;
}>`
  ${breakpoints.smUp} {
    font-size: 14px;
    font-family: ${fonts.fontBauMedium} !important;
    line-height: 20px;
    padding: 10px 18px;
    border: 1px solid ${colors.black};
    border-radius: 38px;
    box-shadow: ${(props) => (props.isMemberDropDownOpen ? "0px 3px 5px rgba(0, 0, 0, 0.3);" : "")};
    &:hover {
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    }
  }
`;

export const AvatarAndNamePointContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;
