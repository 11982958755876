import styled, { css } from "styled-components";
import breakpoints from "../../../styles/breakpoints";
import { colors, fonts, transition } from "../../../styles/variables";

export const PreviewSliderWrapper = styled.div`
  display: inline-flex;
`;

export const PreviewSlideTabWrapper = styled.div`
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 4px;
  }
`;

export const SectionPreviewLeftBottom = styled.div`
  ${breakpoints.smUp} {
    display: flex;
    justify-content: center;
  }
  position: relative;
  z-index: 60;
  width: 100%;
`;

export const PreviewList = styled.div`
  cursor: pointer;
  width: 278px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  margin-top: 140px;
  margin-right: -278px;
  order: 10;
  ${breakpoints.xlDown} {
    display: none;
  }
`;

export const PreviewListItem = styled.div`
  text-align: left;
  text-transform: uppercase;
  font-family: ${fonts.fontInterstateUltraBlack};
  color: ${(props) => (props.isActive ? colors.black : colors.white)};
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.5px;
  position: relative;
  padding-left: 10px;
  transition: ${transition.baseTransition};
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  span {
    display: ${(props) => (props.isActive ? "none" : "inline")};
    margin-right: 10px;
  }
  ${breakpoints.smUp} {
    ${(props) =>
      props.isActive &&
      css`
        transform: translateX(25px);
        &:before {
          opacity: 1;
        }
      `};
  }
  &:before {
    content: "";
    position: absolute;
    height: 2px;
    ${breakpoints.smUp} {
      width: 78px;
      top: 50%;
      left: -10px;
      transform: translate(-100%, -50%);
      background-color: ${(props) => (props.isActive ? colors.black : "transparent")};
    }
  }
`;

export const CurrentPreviewTextWrapper = styled.div`
  display: none;
  ${breakpoints.xlDown} {
    display: inline;
    font-family: ${fonts.fontInterstateUltraBlack};
    font-size: 20px;
    text-transform: uppercase;
  }
`;

export const PreviewHolder = styled.div`
  width: 300px;
  padding: 35px 15px 10px;
  display: flex;
  ${breakpoints.xlDown} {
    align-items: center;
    margin-bottom: 50px;
    margin: 0 auto;
    width: 350px;
    padding: 20px 10px 10px;
  }
`;

export const ToggleArrowWrapper = styled.div`
  cursor: pointer;
  display: flex;
  transform: ${(props) => (props.direction === "right" ? "rotate(180deg)" : 0)};
  margin-right: ${(props) => (props.direction === "right" ? 0 : "23px")};
  margin-left: ${(props) => (props.direction === "left" ? 0 : "23px")};

  ${breakpoints.xlUp} {
    display: none;
  }
`;

export const CatImageWrapper = styled.div`
  position: relative;
  right: 20px;
  display: flex;
  align-self: flex-end;
  margin-left: -75px;
  flex-shrink: 0;
  img {
    width: 75px;
  }
  ${breakpoints.smDown} {
    margin-left: -54px;
    img {
      width: 54px;
    }
  }
`;

export const LemonImageWrapper = styled.div`
  position: relative;
  right: 40px;
  display: flex;
  align-self: flex-end;
  margin-right: -99px;
  flex-shrink: 0;
  img {
    width: 99px;
  }
  ${breakpoints.mdDown} {
    right: 70px;
  }
  ${breakpoints.smDown} {
    right: 65px;
    width: 57px;
    margin-right: -57px;
    img {
      width: 57px;
    }
  }
`;

export const TabPanel = styled.div`
  display: ${(props) => (props.isShown ? "block" : "none")};
`;
