import React, { ReactElement, useState } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { useTranslation } from "react-i18next";
import { executeLogOut, isLoggedIn } from "../../../helpers/auth";
import {
  I18N_NAMESPACES,
  MOBILE_NAVBAR_ACCORDIONS,
  UNLEASH_NEW_PRODUCT_CATEGORIES,
} from "../../../helpers/constants";
import URL_PATTERNS from "../../../urls";
import MobileAboutAccordion from "../MobileAboutAccordion";
import MobileGiftsAccordion from "../MobileGiftsAccordion";
import MobileLearnAccordion from "../MobileLearnAccordion";
import MobileUserAccordion from "../MobileUserAccordion";
import MobileWinesAccordion from "../MobileWinesAccordion";
import MobileProductsAccordion from "../MobileProductsAccordion";
import MobileRewardsAccordion from "../MobileRewardsAccordion";
import {
  MobileHeaderNavbarWrapper,
  StyledHeaderSignInBox,
  LogOutButton,
  StyledLoginButton,
  GetStartedButton,
} from "./style";

interface MobileAccordionsProps {
  onClickSignIn: Function;
  firstName?: string;
  isMobileMenuOpen: boolean;
}

const MobileAccordions = ({
  onClickSignIn,
  firstName,
  isMobileMenuOpen,
}: MobileAccordionsProps): ReactElement | null => {
  const isUserLoggedIn = !!isLoggedIn();
  const [openAccordion, setOpenAccordion] = useState<string>("");
  const { t } = useTranslation([I18N_NAMESPACES.TOP_NAVBAR, I18N_NAMESPACES.COMMON]);
  const isNewProductCategoriesEnabled = useFlag(UNLEASH_NEW_PRODUCT_CATEGORIES);

  if (!isMobileMenuOpen) {
    return null;
  }

  const renderLogOutButton = (): ReactElement => (
    <LogOutButton
      onClick={() => executeLogOut(URL_PATTERNS.HOME)}
      shouldIncreaseTopPadding={!!(openAccordion === MOBILE_NAVBAR_ACCORDIONS.ABOUT)}
    >
      {t("topNavbar:logout")}
    </LogOutButton>
  );

  const renderLoggedInContent = (): ReactElement => (
    <>
      <MobileUserAccordion
        firstName={firstName}
        setOpenAccordion={setOpenAccordion}
        openAccordion={openAccordion}
      />
      <MobileWinesAccordion setOpenAccordion={setOpenAccordion} openAccordion={openAccordion} />
      {isNewProductCategoriesEnabled && (
        <MobileProductsAccordion setOpenAccordion={setOpenAccordion} openAccordion={openAccordion} />
      )}
      <MobileLearnAccordion setOpenAccordion={setOpenAccordion} openAccordion={openAccordion} />
      <MobileGiftsAccordion setOpenAccordion={setOpenAccordion} openAccordion={openAccordion} />
      <MobileRewardsAccordion setOpenAccordion={setOpenAccordion} openAccordion={openAccordion} />
      <MobileAboutAccordion setOpenAccordion={setOpenAccordion} openAccordion={openAccordion} />
      {renderLogOutButton()}
    </>
  );

  const renderSignInBox = (): ReactElement => (
    <StyledHeaderSignInBox shouldIncreaseTopPadding={!!(openAccordion === MOBILE_NAVBAR_ACCORDIONS.LEARN)}>
      <GetStartedButton to={URL_PATTERNS.QUIZ} lg>
        {t("common:getStarted")}
      </GetStartedButton>
      <StyledLoginButton onClick={() => onClickSignIn()} ghost lg>
        {t("topNavbar:login")}
      </StyledLoginButton>
    </StyledHeaderSignInBox>
  );

  const renderLoggedOutContent = (): ReactElement => (
    <>
      <MobileWinesAccordion setOpenAccordion={setOpenAccordion} openAccordion={openAccordion} />
      {isNewProductCategoriesEnabled && (
        <MobileProductsAccordion setOpenAccordion={setOpenAccordion} openAccordion={openAccordion} />
      )}
      <MobileGiftsAccordion setOpenAccordion={setOpenAccordion} openAccordion={openAccordion} />
      <MobileAboutAccordion setOpenAccordion={setOpenAccordion} openAccordion={openAccordion} />
      <MobileLearnAccordion setOpenAccordion={setOpenAccordion} openAccordion={openAccordion} />
      <MobileRewardsAccordion setOpenAccordion={setOpenAccordion} openAccordion={openAccordion} />
      {renderSignInBox()}
    </>
  );

  return (
    <MobileHeaderNavbarWrapper>
      {isUserLoggedIn ? renderLoggedInContent() : renderLoggedOutContent()}
    </MobileHeaderNavbarWrapper>
  );
};

export default MobileAccordions;
