import styled from "styled-components";

import breakpoints from "../../../styles/breakpoints";

const ColSpacer = styled.div`
  height: ${(props) => (props.large ? "50px" : "25px")};
  ${breakpoints.smUp} {
    display: ${(props) => props.sm && "none"};
  }
  ${breakpoints.mdUp} {
    display: ${(props) => props.md && "none"};
  }
`;

export default ColSpacer;
