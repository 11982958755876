import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import breakpoints from "../../../styles/breakpoints";
import galleryArrowLeft from "../../../assets/images/icons/arrow-left.svg";
import galleryArrowRight from "../../../assets/images/icons/arrow-right.svg";

const StyledGallery = styled.div`
  ${breakpoints.xsUp} {
    margin-left: -10px;
    margin-right: -10px;
  }
  ${breakpoints.smUp} {
    width: 100%;
    max-width: 525px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: -100px;
  }
  ${breakpoints.mdUp} {
    max-width: 800px;
    right: -160px;
  }
  ${breakpoints.lgUp} {
    max-width: 60%;
    right: -160px;
  }
  ${breakpoints.smDown} {
    margin-top: 30px;
  }
  .slick-slide {
    ${breakpoints.smUp} {
      /* min-width: 263px; */
    }
    ${breakpoints.smDown} {
      /* width: 100%;
      min-width: 240px; */
    }
    padding-left: 10px;
    padding-right: 10px;
  }
  .slick-track {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
  }
  .slick-list {
    ${breakpoints.xsDown} {
      padding: 0px 50px 0px 0px !important;
    }
  }
  .slick-arrow {
    opacity: 1;
    z-index: 20;
    width: 24px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: 24px 12px;
    background-position: 50% 50%;
    transform: translate(0, 0%);
    bottom: -55px;
    top: auto;
    &:before {
      content: none;
    }
  }
  .slick-prev {
    left: 0px;
    background-image: url(${galleryArrowLeft});
    &:hover,
    &:focus {
      background: url(${galleryArrowLeft});
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
  }
  .slick-next {
    left: 60px;
    background-image: url(${galleryArrowRight});
    &:hover,
    &:focus {
      background: url(${galleryArrowRight});
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
  }
`;

const FeedbackGallery = (props) => {
  const { children } = props;

  const settings = {
    autoplay: true,
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <StyledGallery>
      <Slider {...settings}>{children}</Slider>
    </StyledGallery>
  );
};

FeedbackGallery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FeedbackGallery;
