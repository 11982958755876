import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { I18N_NAMESPACES, MOBILE_NAVBAR_ACCORDIONS } from "../../../helpers/constants";
import {
  NAVBAR_MOBILE_REWARDS_ITEMS,
  NAVBAR_MOBILE_REWARDS_ITEMS_LOGGED_IN,
} from "../../../helpers/navbarProcessor";
import { isLoggedIn } from "../../../helpers/auth";
import { NAVBAR_REWARDS_HEIGHTS, NAVBAR_REWARDS_LOGGED_IN_STATE_HEIGHTS } from "../../../styles/variables";
import AccordionItem from "../AccordionItem";
import {
  AccordionTopPart,
  StyledChevron,
  AccordionWrapper,
  AccordionContentWrapper,
} from "../MobileHeaderNavbar/style";

interface MobileRewardsAccordionProps {
  openAccordion: string | undefined;
  setOpenAccordion: Function;
}

const MobileRewardsAccordion = ({
  openAccordion,
  setOpenAccordion,
}: MobileRewardsAccordionProps): ReactElement => {
  const isThisAccordionOpen = openAccordion === MOBILE_NAVBAR_ACCORDIONS.REWARDS;
  const { t } = useTranslation([I18N_NAMESPACES.COMMON]);

  const loggedIn = isLoggedIn();
  const navbarItems = loggedIn ? NAVBAR_MOBILE_REWARDS_ITEMS_LOGGED_IN : NAVBAR_MOBILE_REWARDS_ITEMS;
  const navbarHeight = loggedIn ? NAVBAR_REWARDS_LOGGED_IN_STATE_HEIGHTS : NAVBAR_REWARDS_HEIGHTS;

  return (
    <AccordionWrapper
      open={isThisAccordionOpen}
      height={navbarHeight.full}
      onClick={() => setOpenAccordion(isThisAccordionOpen ? "" : MOBILE_NAVBAR_ACCORDIONS.REWARDS)}
    >
      <AccordionTopPart isThisAccordionOpen={isThisAccordionOpen}>
        {t("topNavbar:rewards")}
        <StyledChevron open={isThisAccordionOpen} />
      </AccordionTopPart>
      <AccordionContentWrapper
        open={isThisAccordionOpen}
        height={navbarHeight.accordion}
        isFlexDirectionRow={loggedIn}
      >
        {navbarItems.map((item) => (
          <AccordionItem
            i18nKey={item.i18nKey}
            url={item.url}
            key={item.i18nKey}
            icon={item.icon}
            isFlexDirectionRow={loggedIn}
          />
        ))}
      </AccordionContentWrapper>
    </AccordionWrapper>
  );
};

export default MobileRewardsAccordion;
