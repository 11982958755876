import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";
import cross from "../../../assets/images/icons/cross.svg";
import breakpoints from "../../../styles/breakpoints";

import { colors, fonts, header, transition } from "../../../styles/variables";
import { Container } from "../../atoms/Container";

export const StyledDesktopHeader = styled.header<{
  isScrollingDown: boolean;
  isHeaderTransparent: boolean;
  isScrolling: boolean;
  shouldHideNavbarBoxShadow: boolean;
  shouldIncreaseZIndex: boolean;
}>`
  padding: 0;
  background-color: ${colors.coral};
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: ${(props) => (props.shouldIncreaseZIndex ? "6001" : "6000")};
  transition: ${transition.headerMenuBarTransition};
  background-color: ${(props) => (props.isHeaderTransparent ? "transparent" : colors.coral)};
  background-color: ${(props) => props.isScrolling && colors.coral};
  box-shadow: ${(props) => (props.isHeaderTransparent ? "" : "0px 0px 10px rgba(0, 0, 0, 0.3)")};
  box-shadow: ${(props) => (props.isScrolling ? "0px 0px 10px rgba(0, 0, 0, 0.3)" : "")};
  box-shadow: ${(props) => (props.shouldHideNavbarBoxShadow ? "0px 0px 0px rgba(0, 0, 0, 0)" : "")};
  transform: ${(props) => (props.isScrollingDown ? "translateY(-100%)" : "translateY(0)")};
`;

export const StyledMobileHeaderWrapper = styled.header<{
  isScrollingDown: boolean;
  isNotificationsOpen: boolean;
  isMobileMenuOpen: boolean;
}>`
  padding: 0;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 6000;
  position: sticky;
  height: ${header.mobileHeight};
  transition: ${transition.headerMenuBarTransition};
  transform: ${(props) =>
    props.isScrollingDown && !props.isMobileMenuOpen && !props.isNotificationsOpen
      ? "translateY(-100%)"
      : "translateY(0)"};
`;

export const StyledMobileHeader = styled.header<{
  isNotificationsOpen: boolean;
  isMobileMenuOpen: boolean;
  isChangingPage: boolean;
}>`
  width: 100%;
  position: absolute;
  background-color: ${colors.coral};
  overflow-y: ${(props) => (props.isMobileMenuOpen ? "scroll" : "initial")};
  min-height: ${(props) => (props.isMobileMenuOpen ? "420px" : "68px")};
  max-height: ${(props) => (props.isMobileMenuOpen ? "100vh" : "68px")};
  ${(props) => props.isNotificationsOpen && "min-height: 100vh"};
  ${(props) => props.isNotificationsOpen && "max-height: 100vh"};
  border-radius: ${(props) => (props.isMobileMenuOpen ? "0px 0px 10px 10px" : "")};
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-track {
    display: none;
  }
  transition: ${(props) => (props.isChangingPage ? "" : transition.headerMenuBarTransition)};
`;

export const Topbar = styled(Container)`
  padding: 14px 30px 14px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  ${breakpoints.mdDown} {
    min-height: 68px;
    max-width: 100vw;
  }
  ${breakpoints.smDown} {
    padding: 14px 15px;
  }
`;

export const OpacityLayer = styled.div<{ isActive: boolean }>`
  margin-top 80px;
  background: ${(props) => (props.isActive ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)")};
  z-index: ${(props) => (props.isActive ? "2000" : "-10")};
  height: 100vh;
  width: 100vw;
  position: fixed;
  transition: ${transition.headerMenuBarTransition};
`;

export const DropdownsContainer = styled.div``;

export const DesktopDropdownWrapper = styled.div<{
  isThisDropdownActive: boolean;
  isAnyDropdownActive: boolean;
}>`
  opacity: ${(props) => (props.isThisDropdownActive ? 1 : 0.2)};
  visibility: ${(props) => (props.isThisDropdownActive ? "visible" : "hidden")};
  min-height: ${(props) => (props.isAnyDropdownActive ? "280px" : "0px")};
  max-height: ${(props) => (props.isAnyDropdownActive ? "600px" : "0px")};
  width: 100%;
  background-color: ${colors.peach};
  border-radius: 0px 0px 10px 10px;
  transition: ${transition.baseTransition};
  overflow: hidden;
  position: absolute;
  filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.3));
  max-height: calc(100vh - ${header.desktopHeight});
  overflow-y: auto;
`;

export const DropdownContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 52px 90px 50px 52px;
  height: 0px;
  width: 100%;
  ${breakpoints.xlDown} {
    padding: 30px 20px;
  }
`;

export const SectionColumn = styled.div<{ noMarginRight?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => (props.noMarginRight ? "" : "80px")};
  ${breakpoints.xlDown} {
    margin-right: ${(props) => (props.noMarginRight ? "" : "40px")};
  }
  ${breakpoints.lgDown} {
    margin-right: ${(props) => (props.noMarginRight ? "" : "20px")};
  }
`;

export const ColumnTitle = styled.div`
  text-transform: uppercase;
  font-family: ${fonts.fontInterstateUltraBlack};
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

const ColumnLink = `
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: center;
  &:hover {
    color: ${colors.coral};
  }
`;

export const ItemWrapper = styled.div`
  ${ColumnLink}
  cursor: pointer;
`;

export const ItemWrapperLink = styled(Link)`
  ${ColumnLink}
`;

export const ColorCircle = styled.div<{ color: string }>`
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background-color: ${(props) => (props.color ? props.color : "")};
  margin-right: 16px;
`;

export const ItemName = styled.div<{ shouldNotCapitalize?: boolean }>`
  font-family: ${fonts.fontBauRegular};
  font-size: 18px;
  line-height: 26px;
  text-transform: ${(props) => (props.shouldNotCapitalize ? "" : "capitalize")};
`;

export const Cross = styled.img.attrs({ src: cross })`
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 0;
  margin-top: 3px;
  &:hover {
    filter: invert(78%) sepia(29%) saturate(5649%) hue-rotate(317deg) brightness(99%) contrast(105%);
  }
`;

export const ColumnTitleIcon = styled.img`
  margin-top: -4px;
  margin-right: 18px;
  height: 32px !important;
  width: 32px;
`;
