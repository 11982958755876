import Cookies from "js-cookie";
import { track, updateUser } from "@iterable/web-sdk";
import { TrackableEventNameEnum } from "react-use-facebook-pixel";
import { useAppContext } from "../components/organisms/Layout/context";
import {
  WEBSITE_VARIANT,
  CANCELLATION_CLOSE_TRAY,
  WINE_LIST_TYPE_IDS,
  FACEBOOK_TRACKING_CURRENCY,
  ERROR_CODE_ITERABLE_GDPR_FORGOTTEN,
  RESPONSE_CODE_ITERABLE_SUCCESS,
  ERROR_MESSAGE_ITERABLE_USER_UNAUTHORIZED,
  IMPACT_SIGNUP_EVENT_TYPE_ID,
  IMPACT_CAMPAIGN_ID,
  ERROR_MESSAGE_ITERABLE_CONFLICT,
  ERROR_MESSAGE_ITERABLE_BAD_REQUEST,
  ERROR_CODE_ITERABLE_FORGOTTEN_USER,
} from "./constants";
import { isLoggedIn } from "./auth";
import { logError } from "./logging";

const trackingEvents = {
  TRACKING_EVENT_VISITED_PAGE: "visited_page",
  TRACKING_EVENT_STARTED_QUIZ: "started_quiz",
  TRACKING_EVENT_ANSWERED_QUIZ_QUESTION: "answered_quiz_question",
  TRACKING_EVENT_COMPLETED_QUIZ: "completed_quiz",
  TRACKING_EVENT_RETOOK_QUIZ: "retook_quiz",
  TRACKING_EVENT_STARTED_CHECKOUT: "started_checkout",
  TRACKING_EVENT_PLACED_ORDER: "placed_order",
  TRACKING_EVENT_SELECTED_GIFT_OPTION: "selected_gift_option",
  TRACKING_EVENT_COPIED_REFERRAL_LINK: "copied_referral_link",
  TRACKING_EVENT_CLICKED_WINE_SHARE_BUTTON: "clicked_wine_share_button",
  TRACKING_EVENT_COPIED_WINE_SHARE_LINK: "copied_wine_share_link",
  TRACKING_EVENT_STARTED_CANCELLATION: "started_cancellation_wizard",
  TRACKING_EVENT_DROPPED_CANCELLATION: "dropped_cancellation_wizard",
  TRACKING_EVENT_OPENED_SAVED_LISTS: "opened_saved_lists",
  TRACKING_EVENT_VIEWED_SAVED_LIST_DETAILS: "viewed_saved_list_details",
  TRACKING_EVENT_CLICKED_POPUP_CTA_BUTTON: "clicked_popup_cta_button",
  TRACKING_EVENT_UPDATED_BOX_AND_SPEND: "updated_box_and_spend",
  TRACKING_EVENT_UPDATED_BILLING_DATE: "updated_billing_date",
  TRACKING_EVENT_UPDATED_DELIVERY_FREQUENCY: "updated_delivery_frequency",
  TRACKING_EVENT_UPDATED_PERSONAL_INFORMATION: "updated_personal_information",
  TRACKING_EVENT_UPDATED_GRAPES_TO_AVOID: "updated_grapes_to_avoid",
  TRACKING_EVENT_CONTACTED_SUPPORT_TEAM: "contacted_support_team",
  TRACKING_EVENT_SEARCHED_WINES: "searched_wines",
  TRACKING_EVENT_SORTED_WINES: "sorted_wines",
  TRACKING_EVENT_FILTERED_WINES: "filtered_wines",
  TRACKING_EVENT_CLICKED_TAG_ON_WINE_CARD: "clicked_tag_on_wine_card",
  TRACKING_EVENT_CLICKED_TAG_ON_PRODUCT_CARD: "clicked_tag_on_product_card",
  TRACKING_EVENT_VIEWED_WINE_DETAILS: "viewed_wine_details",
  TRACKING_EVENT_VIEWED_FAQ: "viewed_faq",
  TRACKING_EVENT_COMPLETED_TASTE_TEST_QUIZ: "completed_taste_test_quiz",
  TRACKING_EVENT_CLICKED_FEEDBACK_SURVEY: "clicked_feedback_survey",
  TRACKING_EVENT_CLICKED_WINE_101_COURSE_SIGNUP: "clicked_wine_101_course_signup",
  TRACKING_EVENT_DOWNLOADED_CORPORATE_ORDER_FORM: "downloaded_corporate_order_form",
  TRACKING_EVENT_SUBMITTED_CORPORATE_ORDER_FORM: "submitted_corporate_order_form",
  TRACKING_EVENT_FILTERED_TASTE_PROFILE_YOUR_PALATE_DATA: "filtered_taste_profile_your_palate_data",
  TRACKING_EVENT_CLICKED_TASTE_PROFILE_GUIDE_CHARACTERISTIC: "clicked_taste_profile_guide_characteristic",
  TRACKING_EVENT_CLICKED_UNLOCK_TASTE_PROFILE: "clicked_unlock_taste_profile",
  TRACKING_EVENT_RECEIVED_NO_RESULTS_FROM_RECOMMEND_SIMILAR_WINE:
    "received_no_results_from_recommend_similar_wine",
  TRACKING_EVENT_VIEWED_PREVIOUS_BOX_FROM_MY_NEXT_BOX: "viewed_previous_box_from_my_next_box",
  TRACKING_EVENT_CLICKED_BUILD_YOUR_OWN_BOX: "clicked_build_your_own_box",
  TRACKING_EVENT_CLICKED_PICK_YOUR_FREE_BOTTLE: "clicked_pick_your_free_bottle",
  TRACKING_EVENT_CLICKED_REWARD_YOURSELF: "clicked_reward_yourself",
  TRACKING_EVENT_CLICKED_FULL_ORDER_HISTORY: "clicked_full_order_history",
  TRACKING_EVENT_REFRESHED_RECOMMENDATION: "refreshed_recommendation",
  TRACKING_EVENT_CLICKED_GO_TO_SUBSCRIPTION_SETTINGS: "clicked_go_to_subscription_settings",
  TRACKING_EVENT_TOGGLED_TASTE_PROFILE_GRAPH_VIEW: "toggled_taste_profile_graph_view",
  TRACKING_EVENT_CONFIRMED_CONSENT_FOR_COOKIES: "confirmed_consent_for_cookies",
  TRACKING_EVENT_ADDED_WINE_RECOMMENDATION_CHALLENGES: "added_wine_recommendation_from_challenge_details",
  TRACKING_EVENT_ADDED_WINE_RECOMMENDATION_BADGES: "added_wine_recommendation_from_badge_details",
};

const trackingEventGiftOptions = {
  SHIP_SURPRISE_BOX: "ship_surprise_box",
  SEND_GIFT_VOUCHER: "send_gift_voucher",
  BUILD_OWN_GIFT_BOX: "build_own_gift_box",
};

const trackingEventDropCancellationOptions = {
  [CANCELLATION_CLOSE_TRAY]: "closed_cancellation_trays",
};

const trackingEventSavedListOptions = {
  [WINE_LIST_TYPE_IDS.DB_ID_WINE_LIST_TYPE_MEMBER_WISHLIST]: "viewed_my_wishlist",
  [WINE_LIST_TYPE_IDS.DB_ID_WINE_LIST_TYPE_MEMBER_FAVOURITES]: "viewed_my_favourites",
  [WINE_LIST_TYPE_IDS.DB_ID_WINE_LIST_TYPE_MEMBER_CUSTOM]: "viewed_custom_list",
};

const trackingEventBoxAndSpendOptions = {
  IS_SPECIFIC_BOX_AND_SPEND: "is_specific_box_and_spend",
  MAXIMUM_SPEND_PER_MONTH: "max_spend_per_month",
  AVERAGE_BOTTLE_SPEND: "average_bottle_spend",
  NUMBER_OF_BOTTLES: "number_of_bottles",
};

const trackingEventGrapesToAvoidOptions = {
  IS_OPTION_TURNED_ON: "is_option_turned_on",
  GRAPES_TO_AVOID_TYPE_ID: "grapes_to_avoid_type_id",
  GRAPE_TYPE_ID: "grape_type_id",
  OPTION_LABEL: "option_label",
};

const trackingEventDeliveryFrequencyOptions = {
  PREVIOUS_DELIVERY_FREQUENCY: "previous_delivery_frequency",
  NEW_DELIVERY_FREQUENCY: "new_delivery_frequency",
};

const trackingEventClickedTagOnProductCardOptions = {
  TAG_NAME: "tag_name",
  TAG_VALUE: "tag_value",
  IS_FROM_DETAILS: "is_from_details",
};

const trackingEventSearchedWineOptions = {
  SEARCH_PHRASE: "search_phrase",
};

const trackingEventSortedWineOptions = {
  ORDER_BY: "order_by",
};

const trackingEventViewedWineDetailsOptions = {
  WINE_ID: "wine_id",
  WINE_SLUG: "wine_slug",
};

const trackingEventTasteTestQuizOptions = {
  WINE_SLUG: "wine_slug",
  SCORE: "score",
  AVERAGE_SCORE: "average_score",
};

const trackingEventClickedFeedbackSurveyOptions = {
  SURVEY_NAME: "survey_name",
};

const trackingEventClickedTasteProfileGuideCharacteristicOptions = {
  WINE_CHARACTERISTIC_NAME: "wine_characteristic_name",
  WINE_CHARACTERISTIC_ID: "wine_characteristic_id",
};

const trackingEventFilteredTasteProfileYourPalateDataOptions = {
  SELECTED_FILTER: "selected_filter",
};

const trackingEventClickedUnlockTasteProfileOptions = {
  RATINGS_NEEDED: "ratings_needed",
};

const trackingEventReceivedNoResultsFromRecommendSimilarWineOptions = {
  WINE_CLASS_ID: "wine_class_id",
  WINE_PRICE_RANGE_ID: "wine_price_range_id",
  EXCLUDE_WINE_IDS: "exclude_wine_ids",
};

const trackingEventRetookQuizOptions = {
  QUIZ_VERSION_ID: "quiz_version_id",
  QUIZ_ORIGIN: "quiz_origin",
};

const trackingEventQuizOrigins = {
  TASTE_PROFILE: "taste_profile",
  SETTINGS: "settings",
};

const trackingEventRefreshedRecommendationOptions = {
  REQUESTED_WINE_CLASS_ID: "requested_wine_class_id",
  REQUESTED_WINE_PRICE_RANGE_ID: "requested_wine_price_range_id",
  EXCLUDE_WINE_IDS: "exclude_wine_ids",
  IS_SUCCESSFUL: "is_successful",
  IS_NEW_COLOUR_AND_PRICE: "is_new_colour_and_price",
};

const trackingEventWineRecommendationOptions = {
  WINE_COLOUR: "wine_colour",
  WINE_COLOUR_ID: "wine_colour_id",
  WINE_GRAPE_TYPE: "wine_grape_type",
  WINE_GRAPE_TYPE_ID: "wine_grape_type_id",
  WINE_NAME: "wine_name",
  WINE_PRICE: "wine_price",
};

const errorMessagesToIgnoreInIterable = [
  ERROR_MESSAGE_ITERABLE_USER_UNAUTHORIZED,
  ERROR_MESSAGE_ITERABLE_CONFLICT,
  ERROR_MESSAGE_ITERABLE_BAD_REQUEST,
];

const errorCodesToIgnoreInIterable = [ERROR_CODE_ITERABLE_FORGOTTEN_USER, ERROR_CODE_ITERABLE_GDPR_FORGOTTEN];

/**
 * Formats the data for the filtered wine event.
 * @param {Object} selectedFilters
 * @return {Object}
 */
const formatTrackingEventDataFilteredWines = (selectedFilters) => ({
  country_ids: selectedFilters.countryIds || null,
  search_phrase: selectedFilters.name || null,
  order_by: selectedFilters.orderBy || null,
  wine_acidity_id: selectedFilters.wineAcidityId || null,
  wine_body_id: selectedFilters.wineBodyId || null,
  wine_class_ids: selectedFilters.wineClassIds || null,
  wine_fruit_driven_id: selectedFilters.wineFruitDrivenId || null,
  wine_list_slug: selectedFilters.wineListSlug || null,
  wine_price_range_ids: selectedFilters.winePriceRangeIds || null,
  wine_production_methods_ids: selectedFilters.wineProductionMethodIds || null,
  wine_region_ids: selectedFilters.wineRegionIds || null,
  wine_sweetness_id: selectedFilters.wineSweetnessId || null,
  wine_tannin_id: selectedFilters.wineTanninId || null,
  wine_type_id: selectedFilters.wineTypeIds || null,
});

/**
 * Maps custom event names to standard Facebook event names.
 *
 * @param {string} event
 * @return {string}
 * */
const mapCustomEventToStandardFbEvent = (event) => {
  switch (event) {
    case trackingEvents.TRACKING_EVENT_PLACED_ORDER:
      return TrackableEventNameEnum.Purchase;
    case trackingEvents.TRACKING_EVENT_COMPLETED_QUIZ:
      return TrackableEventNameEnum.Lead;
    case trackingEvents.TRACKING_EVENT_STARTED_CHECKOUT:
      return TrackableEventNameEnum.InitiateCheckout;
    default:
      return event;
  }
};

/**
 * Maps custom event names to standard Google event names.
 * Recommended event names:
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
 * @param {string} event
 * @returns {string}
 */
const mapEventToGoogleTagEvent = (event) => {
  switch (event) {
    case trackingEvents.TRACKING_EVENT_STARTED_CHECKOUT:
      return "begin_checkout";
    case trackingEvents.TRACKING_EVENT_VISITED_PAGE:
      return "page_view";
    case trackingEvents.TRACKING_EVENT_COMPLETED_QUIZ:
      return "generate_lead";
    case trackingEvents.TRACKING_EVENT_SEARCHED_WINES:
      return "search";
    default:
      return event;
  }
};

// Option when website is opened in mobile app.
const TRACKING_EVENT_OPTION_IS_MOBILE_APP = "is_mobile";

const trackIterableEvent = async (event, data) => {
  try {
    const response = await track({
      eventName: event,
      dataFields: data,
    });
    const responseData = response?.data ?? {};
    // Skip logging for GDPR email forgotten email.

    if (responseData?.code === RESPONSE_CODE_ITERABLE_SUCCESS) {
      return;
    }

    if (!errorCodesToIgnoreInIterable.includes(responseData?.code)) {
      const errorMessage = `Error tracking: '${event}' event in Iterable. ${JSON.stringify(response)}`;
      logError(errorMessage, response);
    }
  } catch (error) {
    if (!errorMessagesToIgnoreInIterable.includes(error?.message)) {
      const errorMessage = `Error tracking: '${event}' event in Iterable. ${JSON.stringify(error)}`;
      logError(errorMessage, error);
    }
  }
};

const getItemsForImpactConversionEvent = (orderItems) => {
  const items = [];
  const subscriptionProductTypeItem = orderItems?.find((item) => item?.sku === "SUB-1");
  if (subscriptionProductTypeItem) {
    items.push({
      category: "Subscription",
      sku: subscriptionProductTypeItem.sku,
      quantity: subscriptionProductTypeItem.quantity,
      name: subscriptionProductTypeItem.name,
    });
  }
  return items;
};

const trackConversionsInImpact = (ecommerceData, externalUniqueId) => {
  const signUpEventTypeId = IMPACT_SIGNUP_EVENT_TYPE_ID;
  const campaignId = IMPACT_CAMPAIGN_ID;

  window.ire("identify", {
    customerId: `${externalUniqueId}`,
    customerEmail: ecommerceData?.email,
    customProfileId: `AU-${externalUniqueId}`,
  });

  window.ire(
    "trackConversion",
    signUpEventTypeId,
    {
      orderId: ecommerceData?.order_id,
      customProfileId: `AU-${externalUniqueId}`,
      customerId: `${externalUniqueId}`,
      customerEmail: ecommerceData?.email,
      currencyCode: ecommerceData?.currency,
      orderPromoCode: ecommerceData?.coupon,
      orderDiscount: ecommerceData?.discount,
      eventTypeId: signUpEventTypeId,
      eventDate: new Date().toISOString(),
      campaignId,
      items: getItemsForImpactConversionEvent(ecommerceData?.products ?? []),
    },
    {
      verifySiteDefinitionMatch: true,
    }
  );
};

/**
 * Tracks an ecommerce event using a 3rd party library.
 * Currently using Iterable
 * @param {string} event
 * @param {Object} data
 */
const trackEcommerceEvent = (event, data, isIterableReady = true) => {
  if (!process.env.REACT_APP_ITERABLE_API_KEY) return false;

  const ecommerceExtraSettings = {
    integrations: {
      All: false,
      // Destination flags are case sensitive and match the destination’s name in the docs
      "Google Analytics 4 Web": true, // Web version
      "Google Analytics 4": true, // Cloud version
      "Google Analytics": true, // Legacy version
      "Google Tag Manager": true,
      "Google AdWords New": true,
      "Impact Partnership Cloud": true,
      Iterable: true,
    },
  };
  const ecommerceData = {
    order_id: data.order_id,
    currency: FACEBOOK_TRACKING_CURRENCY,
    revenue: data.revenue,
    value: data.value,
    total: data.revenue,
    discount: data.order_discount,
    coupon: data.order_discount_code,
    category: "Order",
    label: data.order_category_name,
    order_category_id: data.order_category_id,
    order_category_name: data.order_category_name,
    products: [],
    email: data.email,
    ...getUtmParameters(),
  };
  const items = []; // FOr Google Tag Manager.
  if (data?.order_items?.length) {
    data.order_items.forEach((item) => {
      ecommerceData.products.push({
        // Iterable expects product_id to be of type string
        product_id: item.product_id.toString(),
        sku: item.product_sku,
        name: item.product_name,
        quantity: item.quantity,
        price: item.product_price,
      });

      // Google Tag Manager
      items.push({
        item_name: item.product_name,
        item_id: item.product_sku,
        quantity: item.quantity,
        price: item.product_price,
      });
    });
    const ecommerceEvent = "Order Completed";

    // Data for Google Tag Manager.
    const gtagEvent = "purchase";
    const gtagEventData = { ...ecommerceData };
    gtagEventData.items = items;
    window.gtag("event", gtagEvent, gtagEventData);

    const isMemberLoggedIn = isLoggedIn();
    if (isMemberLoggedIn && isIterableReady) {
      const dataFields = {
        ...ecommerceData,
        ...ecommerceExtraSettings,
      };
      trackIterableEvent(ecommerceEvent, dataFields);
    }

    trackConversionsInImpact(ecommerceData, data?.externalUniqueId);
  }
  return true;
};

/**
 * Tracks an event using a 3rd party library.
 * Currently using Iterable.
 * @param {string} event
 * @param {Object} data
 * @param {Boolean} isDuplicate
 */

export const useTrackEvent = () => {
  const { isIterableReady, facebookPixel } = useAppContext();

  const trackEvent = (event, data, isDuplicate = false) => {
    if (!process.env.REACT_APP_ITERABLE_API_KEY) return false;

    if (event === trackingEvents.TRACKING_EVENT_PLACED_ORDER) {
      if (!data.value) {
        return false;
      }
      // Sends first any analytics tracking events for ECOMMERCE
      trackEcommerceEvent(event, data, isIterableReady);
    }

    // isDuplicate means we already tracked this event, but need a second call to send
    // data for third-party who requires a specific setting. (e.g. Facebook does not accept
    // the backend order placed event, so we re-send it on the front end here).
    const extraSettings = isDuplicate && {
      integrations: {
        All: false,
        "Facebook Pixel": true,
        "Pinterest Tag": true,
        "Google Tag Manager": true,
        "Google AdWords New": true,
      },
    };

    const modifiedData = {
      ...data,
      website_variant: WEBSITE_VARIANT,
      event_url: window.location.href,
      ...getUtmParameters(),
    };

    // Track event to Pixel
    const fbEvent = mapCustomEventToStandardFbEvent(event);
    const dataForFbPixel = modifiedData;
    // Remove email field to comply with Pixel Business Tools Terms.
    if (dataForFbPixel?.email) delete dataForFbPixel.email;
    facebookPixel?.trackEvent(fbEvent, dataForFbPixel);

    // Track event to Iterable
    const isMemberLoggedIn = isLoggedIn();
    if (isMemberLoggedIn && isIterableReady) {
      const dataFields = {
        ...modifiedData,
        ...extraSettings,
      };
      trackIterableEvent(event, dataFields);
    }

    const gtagEvent = mapEventToGoogleTagEvent(event);
    window.gtag("event", gtagEvent, modifiedData);

    return true;
  };

  return trackEvent;
};

const identifyIterableUser = async (analyticsIdentityData) => {
  if (!process.env.REACT_APP_ITERABLE_API_KEY) return false;
  try {
    await updateUser(analyticsIdentityData, true, true);
  } catch (error) {
    if (!errorMessagesToIgnoreInIterable.includes(error?.message)) {
      const errorMessage = `Error updating user in Iterable. ${JSON.stringify(error)}`;
      logError(errorMessage, error);
    }
  }
  return true;
};

const trackImpactPageVisited = async (externalUniqueId) => {
  const urlPath = "https://trkapi.impact.com/PageLoad";
  const { userAgent } = navigator || { userAgent: "" };

  const referringUrl = window.location.href;

  const campaignId = IMPACT_CAMPAIGN_ID;
  const pageUrl = `https://trackingdomain/c/234/12/${campaignId}`;
  const data = {
    CampaignId: campaignId,
    PageUrl: pageUrl,
    EventDate: new Date().toISOString(),
    ReferringUrl: referringUrl,
    UserAgent: userAgent,
    CustomProfileId: `AU-${externalUniqueId}`,
    CustomerId: `${externalUniqueId}`,
  };

  const response = await fetch(urlPath, {
    method: "POST",
    body: new URLSearchParams(data),
  });

  if (!response?.ok) {
    const errorMessage = `Error tracking Page Visited event in Impact. ${JSON.stringify(response)}`;
    logError(errorMessage, `${JSON.stringify(response)}`);
  }
};

const trackIterablePageVisited = (title, pageVisitedExtraData) => {
  try {
    track({
      eventName: trackingEvents.TRACKING_EVENT_VISITED_PAGE,
      dataFields: {
        title,
        website_variant: WEBSITE_VARIANT,
        ...pageVisitedExtraData,
      },
    });
  } catch (error) {
    if (!errorMessagesToIgnoreInIterable.includes(error?.message)) {
      const errorMessage = `Error tracking Page Visited event in Iterable. ${JSON.stringify(error)}`;
      logError(errorMessage, error);
    }
  }
};

export const useTrackPageVisited = () => {
  const { facebookPixel, isIterableReady } = useAppContext();

  const trackPageVisited = (title, pageVisitedExtraData = {}, externalUniqueId = null) => {
    if (process.env.REACT_APP_ITERABLE_API_KEY && isIterableReady) {
      trackIterablePageVisited(title, pageVisitedExtraData);
    }

    facebookPixel?.trackEvent(TrackableEventNameEnum.PageView, {
      title,
      website_variant: WEBSITE_VARIANT,
      ...pageVisitedExtraData,
    });

    const gtagEvent = mapEventToGoogleTagEvent(trackingEvents.TRACKING_EVENT_VISITED_PAGE);
    window.gtag("event", gtagEvent, pageVisitedExtraData);

    if (externalUniqueId) {
      trackImpactPageVisited(externalUniqueId);
    }

    if (process.env.REACT_APP_ITERABLE_API_KEY) {
      return true;
    }
    return false;
  };

  return trackPageVisited;
};

/**
 * Returns the UTM parameters used to track the performance of a marketing campaign.
 * TODO: We should be using the UTM parameters from the URL and storing them in cookies,
 * but as a temporary solution we are using the Iterable cookies directly.
 * @returns {Object}
 */
const getUtmParameters = () => {
  if (Cookies.get("iterableEmailCampaignId")) {
    return {
      utm_campaign: Cookies.get("iterableEmailCampaignId"),
      utm_source: "iterable",
      utm_medium: "email",
      utm_content: Cookies.get("iterableTemplateId") ?? "",
    };
  }
  return {};
};

export {
  identifyIterableUser,
  trackingEvents,
  trackingEventGiftOptions,
  trackingEventDropCancellationOptions,
  trackingEventSavedListOptions,
  trackingEventBoxAndSpendOptions,
  trackingEventGrapesToAvoidOptions,
  trackingEventDeliveryFrequencyOptions,
  trackingEventClickedTagOnProductCardOptions,
  formatTrackingEventDataFilteredWines,
  trackingEventSearchedWineOptions,
  trackingEventSortedWineOptions,
  trackingEventViewedWineDetailsOptions,
  trackingEventTasteTestQuizOptions,
  trackingEventClickedFeedbackSurveyOptions,
  trackingEventClickedTasteProfileGuideCharacteristicOptions,
  trackingEventFilteredTasteProfileYourPalateDataOptions,
  trackingEventClickedUnlockTasteProfileOptions,
  trackingEventReceivedNoResultsFromRecommendSimilarWineOptions,
  trackingEventRefreshedRecommendationOptions,
  trackingEventRetookQuizOptions,
  trackingEventQuizOrigins,
  TRACKING_EVENT_OPTION_IS_MOBILE_APP,
  trackingEventWineRecommendationOptions,
  getUtmParameters,
};
