import styled from "styled-components";
import { colors, fonts, transition } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";

export const StyledHeaderMenuList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
`;

export const MenuItemsRow = styled.div<{ shouldAdjustTopMargin?: boolean }>`
  position: relative;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  line-height: 34px;
  top: ${(props) => (props.shouldAdjustTopMargin ? "1px" : "")};
  border-right: 1px solid ${colors.black};
`;

export const StyledHeaderMenuItem = styled.div<{ shouldBeWhite?: boolean }>`
  cursor: pointer;
  margin-right: 40px;
  font-size: 18px;
  font-family: ${fonts.fontBauRegular};
  color: ${(props) => (props.shouldBeWhite ? colors.white : colors.black)};
  &:hover {
    color: ${colors.white};
  }
  transition: ${transition.headerMenuBarTransition};
  ${breakpoints.lgDown} {
    margin-right: 15px;
  }
`;

export const LineBelowMenuItem = styled.div<{
  width?: number;
  left?: number;
  showLineAsWhite?: boolean;
}>`
  cursor: pointer;
  position: relative;
  width: ${(props) => (props.width ? props.width : 0)}px;
  left: ${(props) => props.left}px;
  border: 1px solid ${(props) => (props.showLineAsWhite ? colors.white : colors.black)};
  transition: ${transition.headerMenuBarTransition};
`;
