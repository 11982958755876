import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledBrandGalleryHolder = styled.div`
  .slick-track {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
`;

const BrandGallery = (props) => {
  const { children } = props;

  const settings = {
    autoplay: true,
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          variableWidth: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <StyledBrandGalleryHolder>
      <Slider {...settings}>{children}</Slider>
    </StyledBrandGalleryHolder>
  );
};

BrandGallery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BrandGallery;
