import styled, { css } from "styled-components";
import ButtonMutation from "../../atoms/ButtonMutation";
import { colors, fonts } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";
import ButtonLink from "../../atoms/ButtonLink";

export const WelcomePackWarningContainer = styled.div`
  padding: 0;
`;

export const ImageContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

const ButtonStyles = css`
  margin-right: 8px;
  padding: 11.5px;
  text-transform: uppercase;
  font-family: ${fonts.fontBauMedium};
  line-height: 1.5;
  font-weight: 400;
  font-size: 10px;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${colors.black};
    color: ${colors.white};
  }
  text-align: center;
  background-color: transparent;
  color: ${colors.black};
  border: 1.5px solid ${colors.black};
  justify-content: center;
`;

export const SwitchToSubscriptionButton = styled(ButtonMutation)`
  ${ButtonStyles}
  height: 100%;
  width: 100%;
`;

export const QuizButton = styled(ButtonLink)`
  ${ButtonStyles}
  height: 100%;
`;

export const CheckoutButton = styled(ButtonLink)`
  float: right;
  margin-left: 8px;
  font-family: ${fonts.fontBauMedium};
  line-height: 1.5;
  font-weight: 400;
  font-size: 10px;
  display: flex;
  justify-content: center;
`;

export const WarningText = styled.div`
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-top: 16px;
`;

export const ButtonsContainer = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;

export const OptionsButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height 100%;

  ${breakpoints.xsDown} {
    height: inherit;
  }
`;

// Support for iOS Safari <= 10
export const BuyTextWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
`;

export const GenericButtonWrapper = styled.div`
  flex-grow: 1;
  max-width: 48%;
`;
