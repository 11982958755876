import styled from "styled-components";

import breakpoints from "../../../styles/breakpoints";

const DisplayNoneLgDown = styled.div`
  ${breakpoints.lgDown} {
    display: none !important;
  }
`;
const DisplayNoneMdDown = styled.div`
  ${breakpoints.mdDown} {
    display: none !important;
  }
`;
const DisplayNoneSmDown = styled.div`
  ${breakpoints.smDown} {
    display: none !important;
  }
`;
const DisplayNoneXsDown = styled.div`
  ${breakpoints.xsDown} {
    display: none !important;
  }
`;

const DisplayNoneLgUp = styled.div`
  ${breakpoints.lgDown} {
    display: none !important;
  }
`;
const DisplayNoneMdUp = styled.div`
  ${breakpoints.mdUp} {
    display: none !important;
  }
`;
const DisplayNoneSmUp = styled.div`
  ${breakpoints.smUp} {
    display: none !important;
  }
`;
const DisplayNoneXsUp = styled.div`
  ${breakpoints.xsUp} {
    display: none !important;
  }
`;

export {
  DisplayNoneLgDown,
  DisplayNoneMdDown,
  DisplayNoneSmDown,
  DisplayNoneXsDown,
  DisplayNoneLgUp,
  DisplayNoneMdUp,
  DisplayNoneSmUp,
  DisplayNoneXsUp,
};
