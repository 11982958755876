import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { colors, fonts } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";
import { shouldResumeBarBeShown } from "../../../helpers/tools";
import { Container } from "../Container";
import urlPatterns from "../../../urls";
import { logError } from "../../../helpers/logging";
import { DB_IDS_QUIZ_VERSION, I18N_NAMESPACES } from "../../../helpers/constants";
import quizBtnNext from "../../../assets/images/icons/arrow-right.svg";

const ResumeBarView = styled.div`
  background-color: ${colors.warning};
  align-items: flex-start;
  justify-content: center;
  position: sticky;
  left: 0;
  width: 100vw;
  bottom: 0;
  z-index: 1000;
  cursor: pointer;
  display: ${(props) => (props.show ? "block" : "none")};
`;

const ResumeBarViewTitle = styled.p`
  position: relative;
  ${breakpoints.smUp} {
    font-size: 16px;
    padding: 15px 10px 15px 0px;
  }
  ${breakpoints.smDown} {
    font-size: 14px;
    padding: 10px 10px 10px 0px;
    height: 35px;
  }
  line-height: 16px;
  color: ${colors.black};
  font-family: ${fonts.fontInterstateUltraBlack};
  letter-spacing: 1.4px;
  webkit-letter-spacing: 1.4px;
  text-transform: uppercase;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;

const RightArrowIcon = styled.img`
  position: relative;
  width: 20px;
`;

const ResumeQuizBar = () => {
  const savedAnswers = window.localStorage.getItem("nonMemberQuizAnswersSelected");
  const { t } = useTranslation(I18N_NAMESPACES.QUIZ);

  /**
   * Returns locally saved answers for non-logged in customers.
   *
   * @returns {Object}
   * */
  const getSavedAnswers = () => {
    if (shouldResumeBarBeShown()) {
      try {
        const savedAnswersJson = JSON.parse(savedAnswers) || {};
        return savedAnswersJson;
      } catch (error) {
        logError(error);
        return {};
      }
    }
    return {};
  };

  /**
   * Extracts the saved quiz version from localStorage.
   *
   * @returns {Number}
   * */
  const getQuizVersion = () => {
    try {
      const savedQuizVersion = window.localStorage.getItem("nonMemberQuizVersion");
      if (savedQuizVersion === `${DB_IDS_QUIZ_VERSION.ADVANCED_QUIZ}`) {
        return DB_IDS_QUIZ_VERSION.ADVANCED_QUIZ;
      }
      return DB_IDS_QUIZ_VERSION.SIMPLE_QUIZ;
    } catch (error) {
      logError(error);
      return DB_IDS_QUIZ_VERSION.SIMPLE_QUIZ;
    }
  };

  /**
   * Extracts the latest quiz question from localStorage.
   *
   * @returns {Number}
   * */
  const getQuizQuestion = () => {
    try {
      const savedQuizQuestion = window.localStorage.getItem("nonMemberQuizQuestion");
      return parseInt(savedQuizQuestion, 10);
    } catch (error) {
      logError(error);
      return 1; // question 1 default question for non-logged in users.
    }
  };

  /**
   * Extracts the latest quiz question from localStorage.
   *
   * @returns {String}
   * */
  const getSavedMemberName = () => {
    try {
      const savedMemberName = window.localStorage.getItem("nonMemberSavedName");
      return savedMemberName;
    } catch (error) {
      logError(error);
      return "";
    }
  };

  /**
   * Extracts savedWelcomePackId from localStorage.
   *
   * @returns {String}
   * */
  const getSavedWelcomePackId = () => {
    try {
      const savedWelcomePackId = window.localStorage.getItem("nonMemberQuizWelcomePackId");
      return savedWelcomePackId;
    } catch (error) {
      logError(error);
      return "";
    }
  };

  /**
   * Extracts savedFreeWineColorId from localStorage.
   *
   * @returns {String}
   * */
  const getSavedFreeWineColorId = () => {
    try {
      const savedFreeWineColorId = window.localStorage.getItem("nonMemberQuizFreeWineColorId");
      return savedFreeWineColorId;
    } catch (error) {
      logError(error);
      return "";
    }
  };

  /**
   * Returns locally saved wine quantities for non-logged in customers.
   *
   * @returns {Object}
   * */
  const getWineQuantities = () => {
    const defaultWineQuantities = {
      redBottles: 0,
      whiteBottles: 0,
      roseBottles: 0,
      sparklingBottles: 0,
    };
    try {
      const redBottles = window.localStorage.getItem("redBottles");
      const whiteBottles = window.localStorage.getItem("whiteBottles");
      const roseBottles = window.localStorage.getItem("roseBottles");
      const sparklingBottles = window.localStorage.getItem("sparklingBottles");
      if (redBottles) {
        defaultWineQuantities.redBottles = parseInt(redBottles, 10);
      }
      if (whiteBottles) {
        defaultWineQuantities.whiteBottles = parseInt(whiteBottles, 10);
      }
      if (roseBottles) {
        defaultWineQuantities.roseBottles = parseInt(roseBottles, 10);
      }
      if (sparklingBottles) {
        defaultWineQuantities.sparklingBottles = parseInt(sparklingBottles, 10);
      }
      return defaultWineQuantities;
    } catch (error) {
      logError(error);
      return defaultWineQuantities;
    }
  };

  return (
    <>
      <ResumeBarView show={shouldResumeBarBeShown()}>
        <Link
          to={{
            pathname: urlPatterns.QUIZ,
            state: {
              isResumingQuiz: true,
              savedAnswers: getSavedAnswers(),
              savedQuizVersion: getQuizVersion(),
              savedCurrentQuestionIndex: getQuizQuestion(),
              savedMemberName: getSavedMemberName(),
              savedWelcomePackId: getSavedWelcomePackId(),
              savedFreeWineColorId: getSavedFreeWineColorId(),
              savedWineQuantities: getWineQuantities(),
            },
          }}
        >
          <Container>
            <RowContainer>
              <div>
                <ResumeBarViewTitle>{t("quiz:continueBox")}</ResumeBarViewTitle>
              </div>
              <div>
                <RightArrowIcon src={quizBtnNext} />
              </div>
            </RowContainer>
          </Container>
        </Link>
      </ResumeBarView>
    </>
  );
};

export default ResumeQuizBar;
