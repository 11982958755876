import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import urlPatterns from "../../../urls";

import logoImage from "../../../assets/images/elements/headerLogo.svg";
import logoSecondaryImage from "../../../assets/images/elements/footerLogo.svg";
import breakpoints from "../../../styles/breakpoints";
import { transition } from "../../../styles/variables";
import { isLoggedIn } from "../../../helpers/auth";

const StyledHeaderLogo = styled((props) => {
  // Unpacks styling props to not to pass them to DOM element
  const { isScrolling, isHeaderTransparent, isDesktopNavOpen, ...otherProps } = props;

  return <Link {...otherProps} />;
})`
  display: inline-block;
  width: 200px;
  margin-right: 15px;
  flex-shrink: 0;
  position: relative;
  ${breakpoints.mdUp} {
    ${(props) =>
      !props.isDesktopNavOpen &&
      css`
        &:before {
          content: ${(props.isHeaderTransparent && "''") || (props.isScrolling && "none")};
          width: 210px;
          height: 150px;
          background: url(${logoSecondaryImage}) no-repeat 50% 50% / contain;
          display: ${props.isScrolling ? "none" : "block"};
          position: absolute;
          top: -30px;
          left: 0;
        }
      `}
  }
  ${breakpoints.mdDown} {
    width: 157px;
  }
`;

const StyledHeaderLogoImage = styled.img`
  ${breakpoints.mdUp} {
    display: ${(props) =>
      props.isHeaderTransparent && !props.isScrolling && !props.isDesktopNavOpen && "none"};
  }
  transition: ${transition.headerMenuBarTransition};
  display: block;
  width: 100%;
`;

const HeaderLogo = (props) => {
  const { isHeaderTransparent, isScrolling, isDesktopNavOpen, onLogoClick } = props;

  const shouldRedirectToDashboard = !!isLoggedIn();
  const logoRedirect = shouldRedirectToDashboard ? urlPatterns.MY_DASHBOARD : urlPatterns.HOME;

  return (
    <StyledHeaderLogo
      isHeaderTransparent={isHeaderTransparent}
      isScrolling={isScrolling}
      to={logoRedirect}
      isDesktopNavOpen={isDesktopNavOpen}
      onClick={() => !!onLogoClick && onLogoClick()}
    >
      <StyledHeaderLogoImage
        isHeaderTransparent={isHeaderTransparent}
        isScrolling={isScrolling}
        isDesktopNavOpen={isDesktopNavOpen}
        src={logoImage}
        alt="Good Pair Days"
      />
    </StyledHeaderLogo>
  );
};

HeaderLogo.propTypes = {
  isHeaderTransparent: PropTypes.bool,
  isScrolling: PropTypes.bool,
  isDesktopNavOpen: PropTypes.bool,
  onLogoClick: PropTypes.func,
};

HeaderLogo.defaultProps = {
  isHeaderTransparent: false,
  isScrolling: false,
  isDesktopNavOpen: false,
  onLogoClick: null,
};

export default HeaderLogo;
