import React from "react";
import styled from "styled-components";
import breakpoints from "../../../styles/breakpoints";
import { fonts, transition } from "../../../styles/variables";
import arrowIcon from "../../../assets/images/icons/arrow-down-carat.svg";

const NameAndPointContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const NameAndArrowContainer = styled.div<{ isDesktopHeader: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${(props) => (props.isDesktopHeader ? "0" : "15px")};
`;

const NameLabel = styled.span`
  font-family: ${fonts.fontBauMedium};
  font-size: 14px;
  line-height: 0.9;
  text-transform: uppercase;
  margin-bottom: 0px;
  ${breakpoints.mdDown} {
    font-size: 16px;
    width: 100%;
    word-wrap: break-word;
  }
`;

const ArrowImage = styled.img<{ isHovered: boolean }>`
  width: 12px;
  height: 7px;
  margin-left: 10px;
  transform: rotate(${(props) => (props.isHovered ? "180deg" : "0deg")});
  transition: ${transition.headerMenuBarTransition};
`;

interface HeaderUserNameProps {
  isMemberDropDownOpen?: boolean;
  firstName: string;
  isDesktopHeader?: boolean;
}

const HeaderUserName = ({
  isDesktopHeader = true,
  isMemberDropDownOpen = false,
  firstName,
}: HeaderUserNameProps) => (
  <NameAndPointContainer>
    <NameAndArrowContainer isDesktopHeader={isDesktopHeader}>
      <NameLabel>{firstName}</NameLabel>
      {isDesktopHeader && <ArrowImage src={arrowIcon} alt="arrow icon" isHovered={isMemberDropDownOpen} />}
    </NameAndArrowContainer>
  </NameAndPointContainer>
);

export default HeaderUserName;
