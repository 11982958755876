import { TokenRefreshLink } from "apollo-link-token-refresh";
import { setContext } from "@apollo/client/link/context";
import Cookies from "js-cookie";
import { onError } from "@apollo/client/link/error";
import { CachePersistor } from "apollo-cache-persist";
import fetch from "unfetch";
import merge from "lodash.merge";
import { ApolloLink, Observable, ApolloClient, createHttpLink } from "@apollo/client";

import { getLocalStorageToken, executeRefreshTokenRequest, isLoggedIn, forceLogOut } from "../helpers/auth";
import { getUtmParameters } from "../helpers/Analytics";
import {
  ERROR_CODE_UNAUTHORIZED,
  ERROR_MESSAGE_DATA_NOT_OWNED_BY_MEMBER,
  FETCH_POLICY_NETWORK_ONLY,
} from "../helpers/constants";
import { logError } from "../helpers/logging";
import { getGraphQlURL } from "../helpers/urls.helper";
import {
  GET_AUTH,
  GET_GIFT_FLOW_INFO,
  GET_REFERRAL_DISCOUNT,
  GET_GUEST_FREE_BOX_CAMPAIGN_DISCOUNT,
  GET_GUEST_PROMO_CODE_DISCOUNT,
  GET_LOCAL_SHOPPING_CART,
} from "./queries";
import cache from "./cache";

// Apollo GraphQL Resolvers
import { resolverAuth } from "./resolvers/auth";
import { resolverLocalCart } from "./resolvers/cart";
import { resolverGiftFlow } from "./resolvers/gift";
import { resolverReferralDiscount } from "./resolvers/member";
import { resolverGuestFreeBoxCampaignDiscount } from "./resolvers/order";

const defaultOptions = {
  watchQuery: {
    fetchPolicy: FETCH_POLICY_NETWORK_ONLY,
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: FETCH_POLICY_NETWORK_ONLY,
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

/**
 * Creates instance of Apollo client and Persistence and make it available across the application.
 * @return {{client: ApolloClient<NormalizedCacheObject> ,persistent: CachePersistor<NormalizedCacheObject>}}
 * */
const generateApolloClient = () => {
  const graphqlURL = getGraphQlURL();
  const httpLink = createHttpLink({
    uri: graphqlURL,
    fetch,
    credentials: "include",
  });

  // Returns headers to the context so httpLink can read them
  const authLink = setContext((_, { headers }) => {
    // Gets the authentication token from local storage if it exists
    const token = getLocalStorageToken().accessToken;

    // _fbp and _fbc helps identify the current user by facebook.
    // It's sent to the backend to help event deduplication
    // and improve matching
    const fbp = Cookies.get("_fbp") ? Cookies.get("_fbp") : "";
    const fbc = Cookies.get("_fbc") ? Cookies.get("_fbc") : "";

    // Forwards UTM parameters to base
    const utmParameters = getUtmParameters();
    // HTTP headers with underscore are stripped by the server so we need to change them to dashes
    // e.g. utm_campaign -> utm-campaign
    Object.keys(utmParameters).forEach((key) => {
      const newKey = key.replace("_", "-");
      utmParameters[newKey] = utmParameters[key];
      delete utmParameters[key];
    });

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        fbp,
        fbc,
        ...utmParameters,
      },
    };
  });
  const refreshTokenLink = new TokenRefreshLink({
    accessTokenField: "access_token",

    // Refreshes token only for Members who already have a token which is expired
    isTokenValidOrUndefined: () => !getLocalStorageToken().accessToken || isLoggedIn(true),
    fetchAccessToken: () => executeRefreshTokenRequest(),
    handleError: (err) => {
      logError(err);
      forceLogOut();
    },
  });

  let isAccessValidationBeingExecuted = false;

  // apollo-link that is responsible for refreshing a token or logging user out in case their access token
  // is not valid. It might happen when client thinks user is authenticated, but API returns unauthorized
  // error.
  // eslint-disable-next-line consistent-return
  const validateAuthorizationLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      const hasUnauthorizedError = graphQLErrors.some((err) => err.code === ERROR_CODE_UNAUTHORIZED);
      const isTryingToAccessOthersData = graphQLErrors.some(
        (err) => err.message === ERROR_MESSAGE_DATA_NOT_OWNED_BY_MEMBER
      );

      // In case of multiple operations that fail with unauthorised error we refresh a token only once
      if (isAccessValidationBeingExecuted && hasUnauthorizedError && isLoggedIn()) {
        // Does nothing for other unauthorised operations that need to wait for fresh token as the page
        // will be reloaded after successful refresh
        return new Observable(() => {});
      }

      // If the authorized error is detected
      if (!isAccessValidationBeingExecuted && hasUnauthorizedError && isLoggedIn()) {
        isAccessValidationBeingExecuted = true;

        // If multiple memberIds detected in local cache, clear the cache and force log the user out
        if (isTryingToAccessOthersData) {
          return new Observable(() => {
            // Member trying to access data of other member
            isAccessValidationBeingExecuted = false;
            return forceLogOut();
          });
        }

        // If it is a token issue, refreshes token through async request
        return new Observable((observer) => {
          executeRefreshTokenRequest()
            .then((refreshResponse) => {
              const accessToken = refreshResponse.access_token;

              // Updates headers with a new access token
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  authorization: accessToken ? `Bearer ${accessToken}` : "",
                },
              }));
              isAccessValidationBeingExecuted = false;
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };

              // Retries query/mutation that failed with unauthorised error
              forward(operation).subscribe(subscriber);

              isAccessValidationBeingExecuted = false;

              // Reloads the page to refetch all unauthorised queries/mutations
              // instead of retrying them one by one
              window.location.reload();
            })
            .catch((error) => {
              // If token could not be refreshed user is logged out
              observer.error(error);
              isAccessValidationBeingExecuted = false;
              return forceLogOut();
            });
        });
      }
    }

    if (networkError) {
      if (networkError.statusCode && networkError.statusCode === 400) {
        const errors = networkError.result && networkError.result.errors;
        errors &&
          errors.length &&
          logError(`${operation.operationName ?? "GraphQL operation"}: ${errors[0].message}`);
      }
    }
  });

  const link = ApolloLink.from([refreshTokenLink, validateAuthorizationLink, authLink, httpLink]);

  const persistent = new CachePersistor({
    cache,
    storage: window.localStorage,
    maxSize: false, // set to unlimited (default is 1MB https://github.com/apollographql/apollo-cache-persist)
    debug: process.env.REACT_APP_DEBUG === "true", // enables console logging
    key: process.env.REACT_APP_STORE_LOCAL_STORAGE,
    debounce: 5, // ms of delay before changes are written to the local storage
  });

  const resolvers = {
    ...merge(
      resolverAuth.resolvers,
      resolverGiftFlow.resolvers,
      resolverReferralDiscount.resolvers,
      resolverGuestFreeBoxCampaignDiscount.resolvers,
      resolverLocalCart.resolvers
    ),
  };

  const typeDefs = {
    ...merge(
      resolverAuth.typeDefs,
      resolverGiftFlow.typeDefs,
      resolverReferralDiscount.typeDefs,
      resolverGuestFreeBoxCampaignDiscount.typeDefs,
      resolverLocalCart.typeDefs
    ),
  };

  // Instantiates Apollo Client object for GraphQL
  const client = new ApolloClient({
    defaultOptions,
    cache,
    link,
    typeDefs,
    resolvers,
  });

  /**
   * Populate default cache values for non-logged in member
   * */
  const resetCacheToDefault = () => {
    cache.writeQuery({
      query: GET_AUTH,
      data: resolverAuth.defaults,
    });

    cache.writeQuery({
      query: GET_GIFT_FLOW_INFO,
      data: resolverGiftFlow.defaults,
    });

    cache.writeQuery({
      query: GET_REFERRAL_DISCOUNT,
      data: resolverReferralDiscount.defaults,
    });

    cache.writeQuery({
      query: GET_GUEST_FREE_BOX_CAMPAIGN_DISCOUNT,
      data: resolverGuestFreeBoxCampaignDiscount.defaults,
    });

    cache.writeQuery({
      query: GET_GUEST_PROMO_CODE_DISCOUNT,
      data: resolverGuestFreeBoxCampaignDiscount.defaults,
    });

    cache.writeQuery({
      query: GET_LOCAL_SHOPPING_CART,
      data: resolverLocalCart.defaults,
    });
  };

  // Creates default cache when user lands in the app for the first time
  if (!localStorage.getItem(process.env.REACT_APP_STORE_LOCAL_STORAGE)) {
    resetCacheToDefault();
  }

  // Cleans cache with default data once user logs out and logs back in
  client.onResetStore(() => {
    resetCacheToDefault();
  });

  return { client, persistent, cache };
};

const { client, persistent } = generateApolloClient();

export { persistent, client };
