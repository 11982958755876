import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import urlPatterns from "../../../urls";
import breakpoints from "../../../styles/breakpoints";

import ButtonLink from "../../atoms/ButtonLink";
import { I18N_NAMESPACES } from "../../../helpers/constants";
import { colors, fonts, transition } from "../../../styles/variables";
import HeaderHamburgerMenu from "../../atoms/HeaderHamburgerMenu";

const StyledHeaderSignInBox = styled.div`
  ${breakpoints.mdUp} {
    order: 3;
    margin-left: 20px;
    display: flex;
    align-items: center;
    margin-left: 40px;
    justify-content: flex-end;
  }
  ${breakpoints.mdDown} {
    margin-left: auto;
    align-items: center;
    display: flex;
    flex-direction: row;
  }
`;

const HeaderSignInHolder = styled.div`
  cursor: pointer;
  margin-right: 40px;
  font-size: 18px;
  text-transform: capitalize;
  font-family: ${fonts.fontBauRegular};
  &:hover {
    color: ${colors.white};
  }
  transition: ${transition.headerMenuBarTransition};
  ${breakpoints.mdDown} {
    display: none;
  }
`;

const GetStartedButton = styled(ButtonLink)`
  font-size: 14px;
  font-family: ${fonts.fontBauMedium};
  line-height: 20px;
  padding: 12px 16px;
  font-weight: 500;
  letter-spacing: -0.4px;
  ${breakpoints.mdDown} {
    font-size: 10px;
    line-height: 14px;
  }
`;

const HeaderHamburgerMenuWrapper = styled.div`
  cursor: pointer;
  margin-left: 11px;
  padding: 12px 0 12px 12px;
  ${breakpoints.mdUp} {
    display: none;
  }
`;

interface HeaderSignInBoxProps {
  onClickSignIn: Function;
  toggleMobileMenu: () => void;
  isMobileMenuOpen: boolean;
}

const HeaderSignInBox = ({ onClickSignIn, toggleMobileMenu, isMobileMenuOpen }: HeaderSignInBoxProps) => {
  const { t } = useTranslation([I18N_NAMESPACES.TOP_NAVBAR, I18N_NAMESPACES.COMMON]);
  return (
    <StyledHeaderSignInBox>
      <HeaderSignInHolder onClick={() => onClickSignIn(true)}>{t("topNavbar:login")}</HeaderSignInHolder>
      <GetStartedButton to={urlPatterns.QUIZ} whiteBgOnHover>
        {t("common:getStarted")}
      </GetStartedButton>
      <HeaderHamburgerMenuWrapper onClick={() => toggleMobileMenu()}>
        <HeaderHamburgerMenu isMobileMenuOpen={isMobileMenuOpen} />
      </HeaderHamburgerMenuWrapper>
    </StyledHeaderSignInBox>
  );
};

export default HeaderSignInBox;
