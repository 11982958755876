import React from "react";
import BoxCartSideToggle from "../../atoms/BoxCartSideToggle";
import { BoxCartSideTogglesWrapper, BoxCartSideTogglesInnerWrapper } from "./styles";

interface BoxCartSideTogglesProps {
  onToggleClick: (isMonthlyBox: boolean) => void;
  isMonthlyBoxSelected: boolean;
  boxAmountBottles: number;
  boxAmountPresents: number;
  cartAmountBottles: number;
  cartAmountPresents: number;
}

const BoxCartSideToggles = ({
  onToggleClick,
  isMonthlyBoxSelected,
  boxAmountBottles,
  boxAmountPresents,
  cartAmountBottles,
  cartAmountPresents,
}: BoxCartSideTogglesProps) => (
  <BoxCartSideTogglesWrapper>
    <BoxCartSideTogglesInnerWrapper>
      <BoxCartSideToggle
        isMonthlyBox
        isSelected={isMonthlyBoxSelected}
        onToggleClick={() => onToggleClick(true)}
        amountBottles={boxAmountBottles}
        amountPresents={boxAmountPresents}
      />
      <BoxCartSideToggle
        isMonthlyBox={false}
        isSelected={!isMonthlyBoxSelected}
        onToggleClick={() => onToggleClick(false)}
        amountBottles={cartAmountBottles}
        amountPresents={cartAmountPresents}
      />
    </BoxCartSideTogglesInnerWrapper>
  </BoxCartSideTogglesWrapper>
);

export default BoxCartSideToggles;
